<template>
  <div class="pb-5">
    <fw-heading size="xl">Inscrição</fw-heading>
    <fw-panel v-if="hasUnits" title="Módulos disponíveis" class="my-5">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-5">
        <div
          v-for="(unit, unitKey, index) in edition.units"
          :key="unit.key + '_' + edition.key"
          class="w-full group h-full bg-gray-200 flex-col flex p-4 rounded-xl"
        >
          <div class="font-bold text-xl mb-5">M{{ index + 1 }}</div>
          <div class="flex-1 flex flex-col font-semibold">
            <v-clamp class="w-full flex-1 text-left text-lg items-end flex" autoresize :max-lines="3">
              {{ unit.title }}
            </v-clamp>
            <div class="text-sm w-full text-left mt-2 opacity-50">
              {{ Object.keys(unit.topics).length }} tópico{{ Object.keys(unit.topics).length == 1 ? '' : 's' }}
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <template v-for="meta in metadataFields">
      <fw-panel
        v-if="course[meta] != null && course[meta].length > 0"
        :key="meta"
        :title="$t('title.metadata.' + meta)"
        boxed="sm"
        class="my-5"
      >
        <div class="content" v-html="course[meta]"></div>
      </fw-panel>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    course: Object,
    edition: Object,
  },

  data() {
    return {
      metadataFields: [
        'introduction',
        'prerequisites',
        'goals',
        'professional_goals',
        'certification',
        'evaluation',
        'observations',
      ],
    }
  },

  computed: {
    now() {
      return this.$store.state.now
    },
    hasUnits() {
      return !!(this.edition && this.edition.unitsAsList && this.edition.unitsAsList.length)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do curso",
        "professional_goals": "Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações"
      }
    },
    "hours": "hours"
  },
  "pt": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do curso",
        "professional_goals": "Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações"
      }
    },
    "hours": "horas"
  }
}
</i18n>
