<template>
  <div class="w-full h-full flex justify-between border-l border-gray-200">
    <!-- Sidebar -->
    <div v-if="!isMobile" class="w-full md:w-1/3 h-full flex flex-col" :style="!isMobile ? 'max-width: 300px' : ''">
      <div class="px-5 py-4 flex flex-col">
        <div
          v-if="isMobile"
          class="mb-5 shadow bg-gradient-to-r from-teal-300 to-teal-500 w-full rounded-xl overflow-hidden text-teal-800 h-28 p-5 flex flex-col gap-1 text-sm font-medium items-center justify-center"
        ></div>
        <fw-heading size="h3" muted>
          Tópicos
        </fw-heading>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <div class="px-2">
          <div class=" h-14 bg-gray-100 rounded-xl animate-pulse"></div>
        </div>

        <div class="px-2">
          <div class=" h-14 bg-gray-100 rounded-xl animate-pulse"></div>
        </div>

        <div class="px-2">
          <div class=" h-14 bg-gray-100 rounded-xl animate-pulse"></div>
        </div>
      </div>
    </div>

    <!-- Main -->
    <div v-if="!isMobile" class="flex-1 h-full border-l border-gray-200">
      <div class="flex flex-col h-full relative">
        <div class="w-full h-full flex flex-col justify-between bg-white">
          <!-- Unit view -->
          <div class="flex-1 overflow-y-auto h-full relative p-5 lg:p-10 flex flex-col">
            <div class=" h-96 bg-gray-100 rounded-xl animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isMobile" class="flex-1 h-full flex flex-col opacity-40 justify-center gap-2 text-center px-10">
      <fw-heading>Este curso não está<br />disponível para telemóvel</fw-heading>
      <div>Por favor, utilize um computador<br />para aceder a este curso</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelUnitPlaceholder',
  props: {
    course: Object,
    edition: Object,
    unit: Object,
    isManager: Boolean,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
  },
}
</script>
