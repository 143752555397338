<template>
  <div class="w-full h-full flex justify-between bg-gray-50">
    <!-- Chat list -->
    <div
      v-show="!isEmpty && !chatMeetingOnly && (!isMobile || (isMobile && mobileShowChatsList))"
      class="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 h-full flex flex-col md:border-r border-gray-200"
      :style="!isMobile ? 'max-width: 300px' : ''"
    >
      <div class="px-5 py-4 flex items-center justify-between">
        <fw-heading size="h3">
          {{ panelForGroups ? 'Grupos' : 'Canais' }}
          <fw-badge inline type="simple">{{ activeChats.length }}</fw-badge>
        </fw-heading>
        <div class="flex items-center gap-2">
          <fw-button v-if="addAllowed" type="light" @click.native="addNewChannelModal">
            <fw-icon-user-add class="w-5 h-5" />
          </fw-button>
          <fw-button
            v-if="panelForGroups && !isEmpty"
            type="light"
            class="flex items-center"
            @click.native="addNewGroupModal"
          >
            <fw-icon-group class="w-5 h-5" />
            <fw-icon-add-line class="w-5 h-5" />
          </fw-button>
        </div>
        <div v-if="chatFilters && chatFilters.length">
          <button
            v-for="chatFilter in chatFilters"
            :key="chatFilter.type"
            :class="{ 'is-active': chatFilter.type === activeFilter }"
            class="button is-text is-small"
            @click="toggleFilter(chatFilter.type)"
          >
            {{ chatFilter.title }}
          </button>
        </div>
      </div>
      <div v-if="!chatSearchOpen && chats.length > 1" class="px-4 opacity-50 hover:opacity-90">
        <fw-button class="flex items-center gap-2" type="link-muted" @click.native="chatSearchOpen = !chatSearchOpen">
          <fw-icon-search class="w-5 h-5 mr-2" /> Pesquisar
        </fw-button>
      </div>
      <div v-if="chatSearchOpen || chatSearch.length" class="pb-2 px-4">
        <b-input v-model="chatSearch" placeholder="Pesquisar canais..." autofocus type="search"></b-input>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <RecycleScroller
          v-if="activeChats.length"
          v-slot="{ item }"
          :items="activeChats"
          :item-size="55"
          :buffer="50"
          key-field="key"
          class="px-4"
        >
          <button
            class="flex p-2 gap-2 relative items-center w-full text-left group rounded-lg my-1"
            :class="{
              'text-primary bg-white': item.key == chatActive.key,
              'text-gray-600 bg-gray-200 bg-opacity-30 hover:text-gray-800': item.key != chatActive.key,
            }"
            @click="loadChat(item)"
          >
            <fw-avatar v-if="item.type == 'group'" :group="item" size="sm" class="w-10 h-10" animated />
            <fw-avatar v-else-if="item.type == 'user'" :user="buildChatUser(item)" size="sm" animated />
            <fw-avatar v-else icon="people" size="sm" class="w-10 h-10" animated />
            <div class="flex-1">
              <div class="font-semibold">
                <div
                  v-if="meeting && meeting.chat && meeting.chat.key == item.key"
                  class="flex items-center justify-between"
                >
                  Canal da sala <fw-icon-live class="w-5 h-5" />
                </div>
                <div v-else class="flex gap-2">
                  <v-clamp
                    autoresize
                    :max-lines="1"
                    :class="{ 'font-bold': buildUnread(item.unread) || item.key == chatActive.key }"
                    >{{ item.title }}</v-clamp
                  >
                  <div
                    v-if="item.context && (item.context.academic_year || item.context.tag)"
                    class="text-xs opacity-70 mt-1"
                  >
                    {{ item.context.academic_year || item.context.tag }}
                  </div>
                </div>
              </div>
              <v-clamp
                v-if="item.context && (item.context.unit_title || item.context.subtitle)"
                class="text-xs"
                autoresize
                :max-lines="1"
              >
                {{ item.context.unit_title || item.context.subtitle }}
              </v-clamp>
              <v-clamp
                v-if="meeting && meeting.chat && meeting.chat.key == item.key"
                autoresize
                :max-lines="1"
                :class="{ 'font-bold': buildUnread(item.unread) || item.key == chatActive.key }"
                class="text-xs"
                >{{ item.title }}</v-clamp
              >
              <div
                v-else-if="item.type == 'group' && !item.context?.subtitle"
                class="text-xs"
                autoresize
                :max-lines="1"
              >
                Grupo
              </div>
            </div>
            <fw-badge v-if="buildUnread(item.unread)" aria-hidden="true" class="mt-3 mr-4">{{
              buildUnread(item.unread)
            }}</fw-badge>
          </button>
        </RecycleScroller>
      </div>
    </div>

    <!-- Chat main window -->
    <div
      v-show="(!showNewGroup && chatActive.key && !isMobile) || (isMobile && !viewChatsList)"
      class="flex-1 h-full"
      :class="{
        minimal: chatMeetingOnly,
        'bg-teal-500 bg-opacity-10': chatActive.type == 'group',
        'bg-gray-300 bg-opacity-30': chatActive.type != 'group',
      }"
    >
      <div class="flex flex-col h-full relative">
        <!-- Chat header -->
        <div
          class="flex gap-2 px-4 py-3 items-center bg-white"
          :class="{ 'h-full': view == 'messages' && activeChatIsEmpty }"
        >
          <div
            class="w-full flex gap-2 lg:gap-3 items-center"
            :class="{ 'flex-col': view == 'messages' && activeChatIsEmpty }"
          >
            <div v-if="isMobile" class="-mx-2">
              <fw-button>
                <fw-icon-arrow-up v-if="activeChatIsEmpty" class="w-5 h-5" @click.native="unloadChat" />
                <fw-icon-arrow-left v-else class="w-5 h-5" @click.native="unloadChat" />
              </fw-button>
            </div>
            <div>
              <fw-avatar
                v-if="chatActive.type == 'group'"
                :group="chatActive"
                :size="isMobile || chatMeetingOnly ? 'xs' : view == 'messages' && activeChatIsEmpty ? 'md' : 'sm'"
                animated
              />
              <fw-avatar
                v-else-if="chatActive.type == 'user'"
                :user="chatActive.user"
                :size="isMobile || chatMeetingOnly ? 'xs' : view == 'messages' && activeChatIsEmpty ? 'md' : 'sm'"
                animated
              />
              <fw-avatar
                v-else
                icon="people"
                :size="isMobile || chatMeetingOnly ? 'xs' : view == 'messages' && activeChatIsEmpty ? 'md' : 'sm'"
                animated
              />
            </div>
            <div class="flex-1" :class="{ 'text-center': view == 'messages' && activeChatIsEmpty }">
              <v-clamp class="font-bold" autoresize :max-lines="1">{{ chatActive.title }}</v-clamp>
              <div class="text-xs text-gray-500">
                {{ chatTypeDescription }}
              </div>
            </div>
            <!-- Chat main navigation -->
            <div class="flex gap-2 items-center">
              <div v-if="!isMobile || activeChatIsEmpty" class="flex gap-2 items-center">
                <fw-button
                  aria-label="Mensagens"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': view == 'messages',
                  }"
                  @click.native="view = 'messages'"
                >
                  <fw-icon-chats class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
                <fw-button
                  v-if="chatActive.type == 'group'"
                  aria-label="Ficheiros"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': view == 'buckets',
                  }"
                  @click.native="view = 'buckets'"
                >
                  <fw-icon-box-open class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
                <fw-button
                  v-if="chatActive.type == 'group'"
                  aria-label="Sala virtual"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center"
                  @click.native="$router.push({ name: 'live', params: { key: chatActive.meeting.key_alias } })"
                >
                  <fw-icon-live class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
                <fw-button
                  v-if="panelForGroups && chatActive.type == 'group' && meetingPermissions.permissions"
                  type="basic"
                  aria-label="Utilizadores"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': view == 'users',
                  }"
                  @click.native="view = 'users'"
                >
                  <fw-icon-people class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
                <fw-button
                  v-if="panelForGroups && meetingPermissions.edit"
                  type="basic"
                  aria-label="Configurações"
                  class="rounded-full w-6 h-6 md:w-10 md:h-10 items-center flex justify-center"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': view == 'settings',
                  }"
                  @click.native="view = 'settings'"
                >
                  <fw-icon-list-settings class="w-5 h-5" />
                </fw-button>
              </div>
              <span
                v-if="!isMobile && !activeChatIsEmpty"
                class="border-l border-primary opacity-30 ml-1 w-1 h-5"
              ></span>
              <fw-button
                v-if="!activeChatIsEmpty"
                aria-label="Tradução automática"
                class="rounded-full items-center flex justify-center px-2 relative"
                :class="{
                  'text-primary': aiTranslateOn,
                }"
                @click.native="showAiTranslateModal"
              >
                <fw-icon-translate class="w-5 h-5 md:w-6 md:h-6 mr-1" />
                <fw-icon-robot class="w-4 h-4 md:w-5 md:h-5" />
                <fw-dot-active v-if="aiTranslateOn" class="h-2 w-2 absolute top-1 right-1" />
              </fw-button>
              <b-dropdown v-if="isMobile && !activeChatIsEmpty" aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <fw-button size="sm">
                    <fw-icon-more class="w-6 h-6" :class="{ 'opacity-50': active }" />
                  </fw-button>
                </template>
                <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click.native="view = 'messages'">
                  <span class="w-6"><fw-icon-chats class="w-5 h-5"/></span> Mensagens
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="chatActive.type == 'group'"
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click.native="view = 'buckets'"
                >
                  <span class="w-6"><fw-icon-box-open class="w-5 h-5"/></span> Ficheiros
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click="$router.push({ name: 'live', params: { key: chatActive.meeting.key_alias } })"
                >
                  <span class="w-6"><fw-icon-live class="w-5 h-5"/></span> Sala virtual
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="panelForGroups && chatActive.type == 'group' && meetingPermissions.permissions"
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click="view = 'users'"
                >
                  <span class="w-6"><fw-icon-people class="w-5 h-5"/></span> Utilizadores
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="panelForGroups && meetingPermissions.edit"
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click.native="view = 'settings'"
                >
                  <span class="w-6"><fw-icon-list-settings class="w-6 h-6"/></span> Configurações
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-if="view == 'messages' && activeChatIsEmpty" class="mt-5">
              <fw-label>Canal sem mensagens.</fw-label>
            </div>
          </div>
        </div>
        <!-- Messages panel -->
        <PanelPermissions
          v-if="view == 'users' && meetingPermissions.permissions"
          :meeting-key="chatActive.meeting.key"
          class="w-full flex-1 overflow-y-auto h-full bg-white bg-opacity-50 relative p-5 lg:p-10"
        />
        <PanelBucketAdd
          v-if="view == 'new-bucket'"
          :application-context="{ application: 'meetings', item_type: 'groups', item_key: chatActive.meeting.key }"
          class="mx-auto max-w-prose"
          @go-back="view = 'buckets'"
        />
        <fw-panel v-if="view == 'buckets'" title="Ficheiros" featured class="p-5">
          <template v-if="chatActive.meeting.permissions.bucket_create" #toolbar>
            <fw-button type="regular" @click.native="view = 'new-bucket'">Novo</fw-button>
          </template>
          <PanelBucketList
            :create-allowed="chatActive.meeting.permissions.bucket_create"
            :application-context="{ application: 'meetings', item_type: 'groups', item_key: chatActive.meeting.key }"
            @go-to-new-bucket="view = 'new-bucket'"
          />
        </fw-panel>
        <fw-panel
          v-else-if="view == 'settings' && meetingPermissions.edit"
          class="w-full flex-1 overflow-y-auto h-full bg-white bg-opacity-50 relative p-5 lg:p-10"
          title="Configurações do canal"
        >
          <form @submit.prevent="saveGroup" @keyup.enter="saveGroup">
            <b-field label="Título">
              <b-input v-model="chatActive.title" autofocus></b-input>
            </b-field>
            <div>
              <fw-button v-if="chatActive.title" type="regular" @click.native="saveGroup">Guardar</fw-button>
            </div>
          </form>
          <div
            v-if="panelForGroups && chatActive.type == 'group' && meetingPermissions.delete"
            class="mt-10 pt-5 border-t border-gray-300"
          >
            <fw-heading size="h4">Operações</fw-heading>
            <div class="mt-4">
              <fw-button
                type="light"
                :expanded="isMobile"
                @click.native="deleteGroup(chatActive.key, chatActive.meeting.key)"
              >
                Apagar canal
              </fw-button>
            </div>
          </div>
        </fw-panel>
        <div
          v-else-if="view == 'messages' && !activeChatIsEmpty"
          id="chat-messages"
          class="flex-1 overflow-y-auto h-full relative"
          @dragover="dragover"
        >
          <!-- No messages yet... -->
          <div
            class="chat-channel-messages max-w-prose mx-auto"
            :class="{
              'h-full': activeChatIsEmpty,
              'h-auto': !activeChatIsEmpty,
            }"
          >
            <!-- Lets go... -->
            <DynamicScroller
              v-if="!activeChatIsEmpty"
              :items="chatActive.messages"
              :min-item-size="30"
              :buffer="50"
              key-field="key"
              class="scroller"
              @visible="chatMessagesReady"
            >
              <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :data-index="index">
                  <ChatMessage
                    :message="item"
                    :chat-type="chatActive.type"
                    :chat-key="chatActive.key"
                    :user="getChatUser(item.user_key)"
                    :allow-downloads="allowDownloads"
                    :can-delete-all="chatActive.isManager"
                    :ai-translate-on="aiTranslateOn"
                    @delete-message="deleteChatMessage"
                  />
                </DynamicScrollerItem>
              </template>
            </DynamicScroller>
          </div>
        </div>
        <!-- End messages panel -->

        <div
          v-if="view == 'messages'"
          class="z-10 flex items-center bg-white shadow-md border-b border-gray-200"
          :class="{
            'pr-2 pl-3 py-3': minimal,
            'md:gap-2 px-2 py-3 md:p-4': !minimal,
          }"
        >
          <div v-if="!showPermissions && chatActive.unread && !sendingMessage">
            <!-- New messages -->
            <button
              class="focus:outline-none rounded-full p-2 flex items-center bg-primary text-white"
              :alt="chatActive.unread > 1 ? 'mensagens novas' : 'mensagem nova'"
              @click="scrollToBottom"
            >
              <fw-icon-mail-unread class="w-5 h-5"></fw-icon-mail-unread>
              <span class="font-bold mx-1">{{ buildUnread(chatActive.unread) }}</span>
              <span class="animate-bounce">&darr;</span>
            </button>
          </div>
          <div v-if="editable && showUploader">
            <Uploader
              :is-docked="true"
              :layout="showFullDragZoneUploader ? 'full-dragzone' : 'tiny'"
              reference-id="upload"
              allowed="any"
              :clear-after="true"
              bucket-code="files"
              bucket-type="file"
              input-id="upload"
              @upload="chatUploadFileToMessage"
              @dragleave="dragleave"
            />
          </div>
          <div class="flex-1">
            <ChatEditor v-if="editable" v-model="chatActive.message" @enter="addMessageToChannel(chatActive.key)" />
            <div v-else class="flex gap-2 text-gray-400 text-xs items-center px-4 py-2">
              <fw-icon-lock class="h-4 w-4" />
              O envio de mensagens está desativado
            </div>
          </div>
          <div v-if="editable" class="ml-2">
            <button
              :loading="sendingMessage"
              :disabled="sendIsDisabled"
              class="focus:outline-none flex items-center p-2"
              :class="{
                'text-primary': !sendIsDisabled,
                'text-gray-500': sendIsDisabled,
              }"
              @click="addMessageToChannel(chatActive.key)"
            >
              <span v-if="chatActive.files.length" class="flex items-center font-bold mr-3">
                <fw-icon-attachment class="w-5 h-5"></fw-icon-attachment>
                <span>{{ chatActive.files.length }}</span>
              </span>
              <span class="relative w-5 h-5">
                <svg
                  class="fill-current w-6 h-6 absolute right-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      v-if="isAiTranslateModalActive"
      :can-cancel="true"
      trap-focus
      :active.sync="isAiTranslateModalActive"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="360"
      scroll="keep"
    >
      <div class="px-5 pt-3 pb-5">
        <div class="flex items-center justify-between">
          <fw-label>Tradução automática de mensagens</fw-label>
          <fw-button type="link" size="sm" @click.native="isAiTranslateModalActive = false">Fechar</fw-button>
        </div>
        <div class="p-5 flex flex-col items-center justify-center mx-auto">
          <div class="mb-7 font-medium text-center">
            Traduza automaticamente as mensagens dos participantes no idioma que precisar.
          </div>
          <div
            class="flex items-center gap-1 relative"
            :class="{ 'text-primary': aiTranslateOn, 'text-gray-500': !aiTranslateOn }"
          >
            <fw-icon-translate class="w-10 h-10" />
            <fw-icon-robot class="w-10 h-10" />
            <fw-dot-active v-if="aiTranslateOn" class="h-3 w-3 absolute -top-1 -right-1" />
          </div>
          <b-switch
            v-model="aiTranslateOn"
            class="-mr-2 p-2"
            @input="updateOption('chat_show_translated', aiTranslateOn)"
          ></b-switch>
          <div class="text-xs font-medium" :class="{ 'text-primary': aiTranslateOn, 'text-gray-300': !aiTranslateOn }">
            {{ !aiTranslateOn ? 'Desativado' : 'Ativado' }}
          </div>
          <fw-label class="mt-4">Traduzir para</fw-label>
          <b-select
            v-model="aiTranslateLanguage"
            placeholder="Indique um idioma"
            @input="updateOption('language', aiTranslateLanguage)"
          >
            <option v-for="language in aiTranslateLanguages" :key="language.key" :value="language.key">
              {{ language.title }}
            </option>
          </b-select>
        </div>
        <div class="mt-4 pt-4 border-t border-gray-300 text-sm text-gray-500">
          Com esta funcionalidade pode traduzir individualmente cada mensagem, através do botão "Traduzir". O texto
          original está também sempre disponível.
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ChatEditor from '../ChatEditor'
import { RecycleScroller } from 'vue-virtual-scroller'
import { DynamicScroller } from 'vue-virtual-scroller'
import { DynamicScrollerItem } from 'vue-virtual-scroller'
import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ChatUtils from '@/fw-modules/fw-core-vue/chats/services/utilities'
import ChatMessage from '@/fw-modules/fw-core-vue/chats/components/ChatMessage'
import PanelPermissions from '@/fw-modules/fw-core-vue/chats/components/panels/PanelPermissions'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import PanelBucketAdd from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketAdd'
import PanelBucketList from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketList'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelBucketAdd,
    PanelBucketList,
    ChatMessage,
    PanelPermissions,
    Uploader,
    RecycleScroller,
    DynamicScroller,
    DynamicScrollerItem,
    ChatEditor,
  },

  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    allowDownloads: {
      type: Boolean,
      default: true,
    },
    chatFilters: {
      type: Array,
      deep: true,
      default: null,
    },
    chats: {
      type: Array,
      deep: true,
    },
    chatActive: Object,
    meeting: Object,
    chatMeetingOnly: {
      type: Boolean,
      default: false,
    },
    panelForGroups: {
      type: Boolean,
      default: false,
    },
    addAllowed: {
      type: Boolean,
      default: false,
    },
    chatUsers: Array,
    allChatUsers: Object,
    emptyTitle: {
      type: String,
      default: 'Não existem canais de conversação para apresentar.',
    },
    loadChatOnCreate: {
      type: Boolean,
      default: true,
    },
    viewChatsList: {
      type: Boolean,
      default: true,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    showUploader: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const user = this.$store.getters.getUser
    return {
      mobileShowChatsList: true,
      mobileShowActiveChat: false,
      loading: true,
      activeFilter: null,
      sendingMessage: false,
      groupLoading: false,
      newGroupTitle: '',
      showNewGroup: false,
      showPermissions: false,
      search: '',
      emailLoading: false,
      email: null,
      showFullDragZoneUploader: false,
      view: 'messages',
      aiTranslateOn: user && user.options ? user.options.chat_show_translated : false,
      aiTranslateLanguages: [],
      aiTranslateLanguage: user && user.options ? user.options.language : 'pt',
      isAiTranslateModalActive: false,
      chatSearch: '',
      chatSearchOpen: false,
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
    chatTabDescription() {
      const tabMap = {
        messages: 'Mensagens',
        users: 'Permissões',
        buckets: 'Ficheiros',
        settings: 'Configurações',
      }
      return tabMap[this.view]
    },
    chatTypeDescription() {
      const typeMap = {
        group: 'Grupo',
        user: 'Utilizador/a',
        class: 'Turma',
      }
      return !this.chatMeetingOnly ? typeMap[this.chatActive.type] : 'Canal da sala'
    },
    activeChats() {
      const chats = []
      if (!this.activeFilter && !this.chatSearch) {
        chats.push(...this.chats)
        for (let user of this.chatUsers) {
          chats.push(user.chat)
        }
      } else {
        const search = this.chatSearch ? utils.existsInSearchFactory(this.chatSearch) : null
        for (let chat of this.chats) {
          if (
            (!this.activeFilter || chat.type === this.activeFilter) &&
            (!search ||
              search(chat.title) ||
              (chat.type == 'class' &&
                chat.context &&
                (search(chat.context.academic_year) || search(chat.context.unit_title))))
          ) {
            chats.push(chat)
          }
        }

        if (!this.activeFilter || this.activeFilter === 'user') {
          for (let user of this.chatUsers) {
            if (!search || search(user.name)) {
              chats.push(user.chat)
            }
          }
        }
      }

      return chats
    },
    isEmpty() {
      return !this.chats.length && !this.chatUsers.length
    },
    activeChatIsEmpty() {
      return !this.chatActive.messages.length
    },
    authUser() {
      return this.$store.getters.getUser
    },
    sendIsDisabled() {
      return !this.chatActive.files.length && !this.chatActive.message
    },
    meetingPermissions() {
      const meeting = this.chatActive.meeting
      if (meeting && meeting.permissions) return meeting.permissions
      else return {}
    },
    emailIsReady() {
      if (!this.email) return false

      const parts = this.email
        .toLocaleLowerCase()
        .trim()
        .split('@')
      return parts.length == 2
    },
  },

  watch: {
    viewChatsList(value) {
      if (value) this.unloadChat()
    },
  },

  beforeDestroy() {
    if (this.chatActive.key) {
      this.$emit('unload-chat', this.chatActive.key)
    }
  },

  created() {
    if (this.loadChatOnCreate && !this.isMobile) {
      this.$emit('load-main-chat', this.chatLoadDone)
    }
  },

  methods: {
    unloadChat() {
      this.mobileShowChatsList = true
      this.mobileShowActiveChat = false
      this.$emit('unload-chat')
    },

    toggleFilter(type) {
      if (this.activeFilter && this.activeFilter === type) {
        this.activeFilter = null
      } else {
        this.activeFilter = type
      }
    },

    buildChatUser(chat) {
      return {
        name: chat.title,
        initials: chat.initials,
        photo: chat.icon,
      }
    },

    activateShowNewGroup() {
      if (this.chatActive.key) this.$emit('unload-chat', this.chatActive.key, true)
      this.showNewGroup = true
      this.showPermissions = false
    },
    loadChat(chat) {
      this.view = 'messages'
      this.showNewGroup = false
      this.showPermissions = false
      this.$emit('load-chat', chat, false, false, this.chatLoadDone)

      // Make sure mobile works
      this.mobileShowActiveChat = true
      this.mobileShowChatsList = false
    },

    insertEmoji(emoji) {
      if (!this.chatActive.message) this.chatActive.message = ''
      if (this.chatActive.message.length) {
        this.chatActive.message = this.chatActive.message + '\u00a0' + emoji + '\u00a0'
      } else {
        this.chatActive.message = '' + emoji + '\u00a0'
      }
      this.editor.setContent(this.chatActive.message)
    },
    scrollToBottom() {
      const self = this
      ChatUtils.scrollToBottom(10, () => {
        self.chatActive.selfUnread = 0
      })
    },
    chatMessagesReady() {
      ChatUtils.scrollToBottom()
    },
    chatLoadDone() {
      this.loading = false
    },
    chatUploadFileToMessage(files) {
      for (let fileEntry of files) {
        const key = fileEntry.response.data.file.key
        if (!this.chatActive.files.includes(key)) this.chatActive.files.push(key)
      }
      this.showFullDragZoneUploader = false
    },

    buildUnread(unread) {
      if (unread > 99) return '99'
      else if (unread > 0) return unread.toString()
      else return ''
    },

    getChatUser(userKey) {
      if (this.authUser.key == userKey) return this.authUser
      let user = this.allChatUsers[userKey]
      if (user) return user
      else return { name: userKey }
    },

    deleteChatMessage(chatKey, key) {
      this.$emit('delete-chat-message', chatKey, key)
    },

    async addMessageToChannel() {
      if (this.sendIsDisabled) return

      if (this.chatActive.files.length && !this.chatActive.message) {
        this.chatActive.message = 'Mensagem para partilha de ficheiros.'
      }

      this.sendingMessage = true
      try {
        const response = await ServiceChat.addMessage(
          this.chatActive.key,
          this.chatActive.message,
          this.chatActive.files
        )

        if (response.status == 200) {
          this.chatActive.message = null
          this.chatActive.files.splice(0, this.chatActive.files.length)
          this.$emit('set-chat-messages', this.chatActive.key, [response.data], false, true)
        } else {
          // TODO
          this.$buefy.snackbar.open({
            message: `Não foi possível enviar a mensagem`,
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 5000,
          })
        }
      } finally {
        this.sendingMessage = false
      }
    },

    async addNewGroup() {
      if (!this.newGroupTitle.length || this.groupLoading) return
      this.groupLoading = true

      try {
        const meeting = await ServiceMeetings.create(this.newGroupTitle)
        const chat = await ServiceChat.getChannel(meeting.chat.key)

        this.$emit('set-chat', chat, true, false, false, this.chatLoadDone, true)
        this.newGroupTitle = ''
        this.showNewGroup = false
        this.showPermissions = false
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        let message = `Não foi possível adicionar o email. Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`
        if (!errorKey) {
          message = 'Ocorreu um erro, por favor tente novamente mais tarde'
          console.error('Failed to add chat by email', error)
        }

        this.$buefy.snackbar.open({
          duration: 5000,
          message: message,
          type: 'is-danger',
          position: 'is-top-right',
          queue: false,
        })
      } finally {
        this.groupLoading = false
      }
    },
    async saveGroup() {
      if (this.chatActive.title) {
        await ServiceMeetings.update(this.chatActive.meeting.key, this.chatActive.title)
        this.chatActive.meeting.title = this.chatActive.title
        this.$emit('update-chat', this.chatActive.key, this.chatActive.title)
      } else {
        this.chatActive.title = this.chatActive.meeting.title
      }
    },
    showGroupPermissions() {
      this.showPermissions = true
      this.showNewGroup = false
    },
    async deleteGroup(key, meetingKey) {
      this.$buefy.dialog.confirm({
        title: 'Apagar grupo',
        message:
          'Tem a certeza que deseja <b>eliminar</b> este grupo? Todas as mensagens e ficheiros associados serão eliminados, bem como a sala virtual..',
        confirmText: 'Apagar grupo',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          await ServiceMeetings.delete(meetingKey)
          this.$emit('remove-chat', key, this.chatLoadDone)
        },
      })
    },

    async addUser() {
      if (this.emailLoading) return
      this.emailLoading = true

      try {
        const chat = await ServiceChat.addUser(this.email)
        this.$emit('set-chat', chat, true, false, false, this.chatLoadDone, true)
        this.email = ''
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        let message = `Não foi possível adicionar o email. Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`

        if (!errorKey) {
          message = 'Ocorreu um erro, por favor tente novamente mais tarde'
          console.error('Failed to add chat by email', error)
        } else if (errorKey == 'ItemNotFound') {
          message = 'O email indicado não está registado.'
        } else if (errorKey == 'InvalidEmail') {
          message = 'O email não é válido.'
        } else if (errorKey == 'CannotAddYourself') {
          message = 'Não pode adicionar a sua conta.'
        }

        this.$buefy.snackbar.open({
          duration: 5000,
          message: message,
          type: 'is-danger',
          position: 'is-top-right',
          queue: false,
        })
      } finally {
        this.emailLoading = false
      }
    },

    addNewChannelModal() {
      this.$buefy.dialog.prompt({
        title: 'Novo canal',
        message: `Indique o email do utilizador institucional UC:`,
        inputAttrs: {
          type: 'email',
          placeholder: 'e.g. pessoa@uc.pt',
          maxlength: 100,
          min: 7,
        },
        confirmText: 'Adicionar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: value => {
          this.email = value
          this.addUser()
        },
      })
    },

    addNewGroupModal() {
      this.$buefy.dialog.prompt({
        title: 'Novo grupo',
        message: `<div>Indique o nome do novo grupo que pretende criar.</div>
        <div class="text-sm">Mais tarde poderá alterar esta configuração nas preferências do grupo.</div>`,
        inputAttrs: {
          type: 'text',
          placeholder: 'e.g. O meu grupo',
          maxlength: 150,
          min: 2,
        },
        confirmText: 'Adicionar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: value => {
          this.newGroupTitle = value
          this.addNewGroup()
        },
      })
    },

    async showAiTranslateModal() {
      let user = await ServiceAuth.getSelfUser(!this.aiTranslateLanguages.length)

      if (!this.aiTranslateLanguages.length) {
        for (const [key, value] of Object.entries(user.supported_languages)) {
          this.aiTranslateLanguages.push({ key: key, title: value })
        }
        this.aiTranslateLanguages.sort((a, b) => a.title.localeCompare(b.title))
      }

      this.aiTranslateLanguage = user.options.language
      this.aiTranslateOn = user.options.chat_show_translated
      this.isAiTranslateModalActive = true
    },
    async updateOption(key, value) {
      const data = {}
      data[`option.${key}`] = value
      const user = await ServiceAuth.updateSelfUser(data)
      this.$store.dispatch('setUser', { user: user })
    },

    // Handle uploads
    dragover(event) {
      event.preventDefault()
      if (!this.showFullDragZoneUploader) {
        this.showFullDragZoneUploader = true
      }
    },
    dragleave() {
      this.showFullDragZoneUploader = false
    },
  },
}
</script>
