<template>
  <div v-if="checks.isCourseEditionUnlocked" class="w-full h-full flex justify-between border-l border-gray-200">
    <!-- Sidebar -->
    <div v-if="!isMobile" class="w-full md:w-1/3 h-full flex flex-col" :style="!isMobile ? 'max-width: 300px' : ''">
      <div class="px-5 py-4 flex flex-col">
        <div
          v-if="isMobile"
          class="mb-5 shadow bg-gradient-to-r from-teal-300 to-teal-500 w-full rounded-xl overflow-hidden text-teal-800 h-28 p-5 flex flex-col gap-1 text-sm font-medium items-center justify-center"
        ></div>
        <fw-heading size="h3" muted>
          Tópicos
          <fw-badge inline type="simple">{{ topics.length }}</fw-badge>
        </fw-heading>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <RecycleScroller
          v-if="topics && topics.length > 0"
          v-slot="{ item, index }"
          :items="topics"
          :item-size="57"
          :buffer="25"
          key-field="key"
          class="px-2"
        >
          <div class="px-2">
            <button
              class="flex p-2 gap-3 my-1 relative w-full text-left group rounded-lg"
              :class="{
                'text-primary bg-white shadow-md': isActive(item.key),
                'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': !isActive(item.key),
              }"
              @click="loadTopic(item, typeof topics[index + 1] !== 'undefined', index)"
            >
              <div
                class="font-bold text-sm p-1 w-5"
                :class="{
                  'text-primary': checkTopicAvailable(item),
                  'text-gray-500': !checkTopicAvailable(item),
                }"
              >
                <span v-if="item.type == 'normal'">{{ item.index + 1 }}</span>
                <fw-icon-survey v-else class="w-4 h-4" />
              </div>
              <div class="flex-1">
                <v-clamp class="font-medium" autoresize :max-lines="1">{{ item.title }} </v-clamp>
                <div v-if="item.type == 'normal'" class="text-xs opacity-80">
                  <div v-if="checkTopicAvailable(item)" class="font-medium">Disponível</div>
                  <div v-else-if="checkTopicAvailableSoon(item)" class="font-medium">
                    Disponível a {{ item.session.start_date | formatDateTime }}
                  </div>
                  <div v-else-if="checkTopicExpired(item) && isTopicDone(item.key)" class="font-medium">
                    Terminado
                  </div>
                  <div v-else-if="checkTopicBlocked(item)" class="font-medium">Bloqueado</div>
                  <div v-else>Indisponível</div>
                </div>
                <div v-else class="text-xs opacity-80">
                  <div class="font-medium">Disponível a {{ item.session.start_date | formatDateTime }}.</div>
                </div>
              </div>
              <div class="relative">
                <fw-icon-check v-if="isTopicDone(item.key)" class="w-5 h-5 text-primary" />
                <fw-dot v-else-if="checkTopicAvailable(item)" />
              </div>
            </button>
          </div>
        </RecycleScroller>
        <div v-else class="flex text-center justify-center items-center flex-col h-full text-sm text-gray-400 ">
          Sem tópicos para apresentar.
        </div>
      </div>
    </div>

    <!-- Main -->
    <div v-if="!isMobile && activeTopic" :key="activeTopic.key" class="flex-1 h-full border-l border-gray-200">
      <div class="flex flex-col h-full relative">
        <div class="w-full h-full flex flex-col justify-between bg-white">
          <!-- Unit view -->
          <div class="flex-1 overflow-y-auto h-full relative p-5 lg:p-10 flex flex-col">
            <!-- Normal -->
            <div v-if="activeTopic.type == 'normal'" class="fw-active-course w-full h-full mx-auto max-w-screen-lg">
              <!-- Topic content -->
              <div v-if="isManager || isActiveTopicAvailable">
                <div class="flex items-start justify-between gap-5 mb-2 px-2">
                  <fw-heading :size="activeTopic.video ? 'h3' : 'h2'">{{ activeTopic.title }}</fw-heading>
                  <fw-button v-if="isManager" :type="'xlight'" @click.native="editTopic()">
                    Editar conteúdo
                  </fw-button>
                  <fw-button
                    v-else
                    :loading="savingProgress"
                    :type="activeTopic.seen_date == null ? 'primary' : 'xlight'"
                    :disabled="activeTopic.seen_date !== null"
                    @click.native="saveProgress()"
                  >
                    <fw-icon-check v-if="activeTopic.seen_date !== null" class="w-5 h-5 text-primary mr-1" />
                    {{ activeTopic.seen_date == null ? 'Marcar como concluído' : 'Concluído' }}
                  </fw-button>
                </div>
                <div class="text-sm px-2.5">
                  <div v-if="activeTopic.session && activeTopic.session.start_date && activeTopic.session.end_date">
                    Este tópico está disponível entre
                    {{ activeTopic.session.start_date | formatDateTime }} e
                    {{ activeTopic.session.end_date | formatDateTime }}.
                  </div>
                  <div v-else-if="activeTopic.session && activeTopic.session.start_date">
                    Este tópico está disponível a partir de {{ activeTopic.session.start_date | formatDateTime }}.
                  </div>
                  <div v-else>Este tópico não tem calendarização definida para a turma.</div>
                  <fw-panel-info v-if="isManager" type="orange" icon boxed class="mt-3">
                    Enquanto gestor(a) desta edição do curso consegue visualizar este conteúdo em qualquer altura.
                  </fw-panel-info>
                </div>
                <PanelPagePreview
                  v-if="activeTopic.page_key"
                  :page-id="activeTopic.page_key"
                  paddingless
                  class="pt-5"
                />
                <div v-else class="flex flex-col gap-5 items-center justify-center flex-1 m-5">
                  <fw-icon-sun class="w-16 h-16 text-gray-500 opacity-40"></fw-icon-sun>
                  <div class="text-center text-sm font-medium text-gray-500">
                    Sem conteúdos para apresentar neste tópico.
                  </div>
                </div>
              </div>
              <div
                v-else-if="
                  isActiveTopicAvailableSoon ||
                    isActiveTopicNotAvailable ||
                    isActiveTopicExpired ||
                    (isActiveTopicBlocked && nextAvailableTopic !== null && !isActiveTopicFirst)
                "
                class="flex-1 flex flex-col items-center justify-center mx-auto max-w-screen-sm h-full"
              >
                <div class="flex my-5 items-center">
                  <fw-heading size="h2" class="text-center flex-1">{{ activeTopic.title }}</fw-heading>
                </div>
                <div>
                  <fw-tag
                    v-if="isTopicDone(activeTopic.key)"
                    type="primary"
                    size="md"
                    class="flex items-center gap-1 my-5"
                    >Tópico concluído <fw-icon-check class="w-5 h-5"
                  /></fw-tag>
                  <fw-tag v-else class="my-5" type="light" size="md">Tópico não concluído</fw-tag>
                </div>
                <div
                  v-if="isActiveTopicBlocked && nextAvailableTopic !== null && !isActiveTopicFirst"
                  class="flex flex-col gap-5 items-center justify-center"
                >
                  <fw-icon-lock-line class="h-10 w-10 fill-current text-gray-500 opacity-50" />
                  <div class="text-center text-sm">
                    O conteúdo deste tópico ficará disponível depois de concluir o tópico anterior.
                  </div>
                </div>
                <div v-if="isActiveTopicAvailableSoon" class="flex flex-col gap-5 items-center justify-center">
                  <div class="text-center text-bold text-primary mx-auto max-w-xs text-lg">
                    Este tópico estará disponível entre
                    {{ activeTopic.session.start_date | formatDateTime }} e
                    {{ activeTopic.session.end_date | formatDateTime }}.
                  </div>
                </div>
                <div v-if="isActiveTopicNotAvailable" class="flex flex-col gap-5 items-center justify-center">
                  <fw-icon-lock-line class="h-10 w-10 fill-current text-gray-500 opacity-50" />
                  <div class="text-center text-sm text-gray-500">
                    Este tópico não está disponível para a sua turma ou ainda não foi agendada a sua disponibilização.
                  </div>
                </div>
                <div v-if="isActiveTopicExpired" class="flex flex-col gap-5 items-center justify-center">
                  <div class="text-center text-sm font-medium text-gray-500">
                    <div>Este tópico já não está disponível para a sua turma.</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Evaluation -->
            <div v-if="activeTopic.type == 'evaluation'" class="w-full h-full flex flex-col">
              <div class="flex-1 flex flex-col gap-4 items-center justify-center mx-auto max-w-prose">
                <fw-icon-survey
                  class="h-14 drop-shadow-lg w-14 fill-current text-gray-800 text-opacity-40 flex-shrink-0"
                />
                <div class="flex-shrink-0">
                  <fw-heading size="h2" class="text-center">Sessão de avaliação do módulo</fw-heading>
                  <fw-heading size="h3" class="text-center mb-5">{{ unit.title }}</fw-heading>
                  <div v-if="!isManager" class="flex items-center justify-center">
                    <fw-tag v-if="examSubmitted" type="primary" size="md" class="flex items-center gap-1 my-5"
                      >Exame realizado <fw-icon-check class="w-5 h-5"
                    /></fw-tag>
                    <fw-tag v-else type="light" size="md" class="my-5">Exame por realizar</fw-tag>
                  </div>
                </div>
                <div class="mt-5">
                  <div
                    v-if="
                      isActiveTopicAvailable &&
                        activeTopic.session &&
                        activeTopic.session.item_key &&
                        activeTopic.session.context == null
                    "
                    class="flex flex-col items-center"
                  >
                    <!-- activeTopic.session.context = null when there is no error -->
                    <OpenExamButton
                      :instance-id="activeTopic.session.item_key"
                      :resume="examSubmitted"
                      :type="'exam'"
                    ></OpenExamButton>
                    <div class="text-center text-sm mt-5">
                      Disponível até {{ activeTopic.session.end_date | formatDateTime }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      (!isActiveTopicAvailable && activeTopic.session) ||
                        (isActiveTopicAvailable &&
                          activeTopic.session &&
                          activeTopic.session.context &&
                          activeTopic.session.context.code == 'missing_topics')
                    "
                    class="text-center"
                  >
                    <div
                      v-if="
                        activeTopic.session &&
                          activeTopic.session.context &&
                          activeTopic.session.context.code == 'missing_topics' &&
                          isActiveTopicAvailable
                      "
                      class=" text-gray-600 mb-5 font-bold pt-5"
                    >
                      O exame está disponível mas existem tópicos deste módulo por avaliar.
                    </div>
                    <div v-else class="text-gray-600 mb-5 font-bold pt-5">Não disponível.</div>
                    <div class="text-sm">
                      Exame disponível entre<br />
                      {{ activeTopic.session.start_date | formatDateTime }} e
                      {{ activeTopic.session.end_date | formatDateTime }}.
                    </div>
                  </div>
                  <div v-else class="text-center text-gray-500 text-sm">
                    Este exame não está disponível para a sua turma ou ainda não foi agendado a sua disponibilização.
                  </div>
                  <div
                    v-if="
                      activeTopic.session &&
                        activeTopic.session.context &&
                        activeTopic.session.context.code == 'missing_topics'
                    "
                    class="py-5"
                  >
                    <div
                      v-if="
                        !isManager &&
                          !examSubmitted &&
                          activeTopic.session &&
                          activeTopic.session.context &&
                          activeTopic.session.context.keys
                      "
                      :class="{ ' max-h-48 overflow-hidden': !expandMissingTopics }"
                      class="text-center bg-yellow-50 rounded-sm p-7 relative text-sm"
                      @click="expandMissingTopics = !expandMissingTopics"
                    >
                      <div class="text-left pb-5">
                        Para conseguir aceder à avaliação (de acordo com o agendamento definido), todos os tópicos do
                        módulo devem estar concluídos / avaliados. Por favor, avalie os tópicos seguintes em falta:
                      </div>
                      <div
                        v-for="topic in activeTopic.session.context.keys"
                        :key="topic"
                        class="flex items-center gap-3 px-7 py-2"
                      >
                        <div class="opacity-80">
                          <fw-tag type="border-light" size="xs">Não avaliado</fw-tag>
                        </div>
                        <div class="font-semibold">
                          <v-clamp autoresize :max-lines="1">{{ unit.topics[topic].title }}</v-clamp>
                        </div>
                      </div>
                      <div
                        v-if="!expandMissingTopics"
                        class="absolute bottom-0 left-0 right-0 p-3 text-center bg-gradient-to-t from-yellow-50"
                      >
                        <div
                          class="px-5 py-1.5 font-semibold inline-block text-gray-800 mx-auto shadow-md rounded-full bg-gradient-to-t from-gray-100 to-white"
                        >
                          Ver todos
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Quality Evaluation -->
            <div v-if="activeTopic.type == 'quality_evaluation'" class="w-full h-full flex flex-col">
              <div class="flex-1 flex flex-col gap-4 items-center justify-center mx-auto max-w-prose">
                <fw-icon-exam class="h-20 drop-shadow-lg w-20 fill-current text-gray-800 text-opacity-40" />
                <div>
                  <fw-heading size="h3" class="text-center" marginless>Questionário de satisfação</fw-heading>
                  <div class="font-medium text-gray-500 text-center">{{ unit.title }}</div>
                </div>

                <div
                  v-if="
                    (!isActiveTopicAvailable && activeTopic.session) ||
                      (isActiveTopicAvailable &&
                        activeTopic.session &&
                        activeTopic.session.context &&
                        activeTopic.session.context.code == 'missing_topics')
                  "
                  class="text-center"
                >
                  <div
                    v-if="
                      activeTopic.session &&
                        activeTopic.session.context &&
                        activeTopic.session.context.code == 'missing_topics' &&
                        isActiveTopicAvailable
                    "
                    class=" text-gray-600 mb-5 font-bold pt-5"
                  >
                    O questionário está disponível mas existem tópicos deste módulo por avaliar ou exame por realizar.
                  </div>
                  <div v-else class="text-gray-600 mb-5 font-bold pt-5">Não disponível.</div>
                  <div class="text-sm">
                    Questionário disponível entre<br />
                    {{ activeTopic.session.start_date | formatDateTime }} e
                    {{ activeTopic.session.end_date | formatDateTime }}.
                  </div>
                </div>
                <div v-else class="text-center text-gray-500 text-sm">
                  Este questionário não está disponível para a sua turma ou ainda não foi agendada a sua
                  disponibilização.
                </div>
                <div
                  v-if="
                    activeTopic.session &&
                      activeTopic.session.context &&
                      activeTopic.session.context.code == 'missing_topics'
                  "
                  class="py-5"
                >
                  <div
                    v-if="
                      !isManager &&
                        !examSubmitted &&
                        activeTopic.session &&
                        activeTopic.session.context &&
                        activeTopic.session.context.keys
                    "
                    :class="{ ' max-h-48 overflow-hidden': !expandMissingTopics }"
                    class="text-center bg-yellow-50 rounded-sm p-7 relative text-sm"
                    @click="expandMissingTopics = !expandMissingTopics"
                  >
                    <div class="text-left pb-5">
                      Para conseguir aceder ao questionário de satisfação, todos os tópicos do módulo devem estar
                      concluídos / avaliados. Por favor, avalie os tópicos seguintes em falta:
                    </div>
                    <div
                      v-for="topic in activeTopic.session.context.keys"
                      :key="topic"
                      class="flex items-center gap-3 px-7 py-2"
                    >
                      <div class="opacity-80">
                        <fw-tag type="border-light" size="xs">Não avaliado</fw-tag>
                      </div>
                      <div class="font-semibold">
                        <v-clamp autoresize :max-lines="1">{{ unit.topics[topic].title }}</v-clamp>
                      </div>
                    </div>
                    <div
                      v-if="!expandMissingTopics"
                      class="absolute bottom-0 left-0 right-0 p-3 text-center bg-gradient-to-t from-yellow-50"
                    >
                      <div
                        class="px-5 py-1.5 font-semibold inline-block text-gray-800 mx-auto shadow-md rounded-full bg-gradient-to-t from-gray-100 to-white"
                      >
                        Ver todos
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="!isMobile && !activeTopic"
      class="flex-1 h-full border-l border-gray-200 text-gray-400 flex flex-col justify-center items-center"
    >
      Sem tópico selecionado
    </div>

    <div v-else-if="isMobile" class="flex-1 h-full flex flex-col opacity-40 justify-center gap-2 text-center px-10">
      <fw-heading>Este curso não está<br />disponível para telemóvel</fw-heading>
      <div>Por favor, utilize um computador<br />para aceder a este curso</div>
    </div>

    <!-- Congrats modal -->
    <b-modal
      :active="showCongrats"
      :width="550"
      scroll="keep"
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      custom-class="z-100 bg-gray-900 bg-opacity-50 backdrop-blur-sm rounded-buefy-modal"
      style="z-index: 9999"
      @close="showCongrats = false"
    >
      <CongratsModal
        :title="'Obrigado!'"
        :subtitle="'Tópico concluído.'"
        :next-text="'Próximo tópico'"
        :has-next="hasNextTopic"
        @close="showCongrats = false"
        @next="openNextTopic()"
      />
    </b-modal>

    <!-- Answers modal -->
    <user-answers-modal v-if="seeAnswers !== null" :instance-id="seeAnswers" @close="seeAnswers = null" />
  </div>
  <div v-else class="w-full h-full flex flex-col py-10 text-center text-gray-300">
    O conteúdo do curso ainda não está disponível.
  </div>
</template>

<script>
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import { RecycleScroller } from 'vue-virtual-scroller'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import CongratsModal from '@/fw-modules/fw-core-vue/ui/components/modals/CongratsModal'
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import Vue from 'vue'
import OpenExamButton from '@/fw-modules/fw-core-vue/ui/components/buttons/OpenExamButton'
import UserAnswersModal from '@/fw-modules/fw-core-vue/exams/views/UserAnswers'
import PanelPagePreview from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagePreview'

export default {
  components: {
    RecycleScroller,
    CongratsModal,
    OpenExamButton,
    UserAnswersModal,
    PanelPagePreview,
  },

  props: {
    course: Object,
    edition: Object,
    checks: Object,
    unit: Object,
    isManager: Boolean,
  },

  data() {
    return {
      feedbackItems: [
        { text: 'Os conteúdos são relevantes para a minha prática profissional.', key: 'content_relevant' },
        { text: 'Os conteúdos estão bem organizados.', key: 'content_organized' },
        { text: 'O orador comunicou de forma clara.', key: 'speaker' },
        { text: 'Os conteúdos são esteticamente apelativos (áudio e vídeo).', key: 'content_looks' },
      ],
      feedback: this.activeTopic ? this.activeTopic.feedback || {} : {},
      savingFeedback: false,
      activeTopic: null,
      allowFeedback: false,
      isActiveTopicAvailable: false,
      isActiveTopicBlocked: false,
      isActiveTopicEmpty: true,
      showCongrats: false,
      showOverlay: false,
      hasNextTopic: false,
      currentActiveTopicIndex: 0,
      currentExamInstance: null,
      videoHeight: 300,
      examSubmitted: false,
      seeAnswers: null,
      allowSeeAnswers: false,
      numAnswers: 0,
      nextAvailableTopic: null,
      expandMissingTopics: false,
      savingProgress: false,
    }
  },

  computed: {
    courseKey() {
      return this.$route.params.courseKey
    },
    activeTopicKey() {
      return this.$route.params.topicKey
    },
    now() {
      return this.$store.state.now
    },
    isMobile() {
      return window.innerWidth < 640
    },
    isActiveTopicFirst() {
      return this.topics.length > 0 ? this.topics[0].key == this.activeTopic.key : false
    },
    topics() {
      return this.unit.topics
    },

    // Tests
    isUnitDone() {
      if (!this.progress) return 0
      const done = this.progress.unitsDone[this.unit.key]
      const todo = this.progress.unitsTodo[this.unit.key]
      return Math.round((100 * this.progress.unitsDone[this.unit.key]) / done + todo) || 0
    },
    isUnitAllNormalTopicsDone() {
      const progress = this.unitNormalTopicsProgress
      return progress.done === progress.total
    },
    unitNormalTopicsProgress() {
      let normalTopicsDone = 0
      let totalTopics = 0

      // Make sure every normal / video topic is done
      for (let topic of this.topics) {
        if (topic.type == 'normal') {
          if (this.isTopicDone(topic.key)) normalTopicsDone++
          totalTopics++
        }
      }
      return {
        done: normalTopicsDone,
        todo: Math.abs(normalTopicsDone - totalTopics),
        total: totalTopics,
      }
    },
    isActiveTopicHasSession() {
      return this.checkTopicHasSession(this.activeTopic)
    },
    /*isActiveTopicAvailable() {
      return this.checkTopicAvailable(this.activeTopic)
    },*/
    /*isActiveTopicEmpty() {
      return this.checkTopicEmpty(this.activeTopic)
    },*/
    isActiveTopicAvailableSoon() {
      return this.checkTopicAvailableSoon(this.activeTopic)
    },
    isActiveTopicNotAvailable() {
      return this.checkTopicNotAvailable(this.activeTopic)
    },
    isActiveTopicExpired() {
      return this.checkTopicExpired(this.activeTopic)
    },
  },

  watch: {
    now: function() {
      if (this.activeTopic !== null) {
        let isActiveTopicAvailable = this.checkTopicAvailable(this.activeTopic)
        //let isActiveTopicAvailableSoon = this.checkTopicAvailableSoon(this.activeTopic)
        //let isActiveTopicNotAvailable = this.checkTopicNotAvailable(this.activeTopic)

        let isActiveTopicEmpty = this.checkTopicEmpty(this.activeTopic)
        //let isActiveTopicBlocked = this.checkTopicBlocked(this.activeTopic)
        if (isActiveTopicAvailable !== this.isActiveTopicAvailable) {
          this.isActiveTopicAvailable = isActiveTopicAvailable
        }
        //if (isActiveTopicAvailableSoon !== this.isActiveTopicAvailableSoon) {
        //  this.isActiveTopicAvailableSoon = isActiveTopicAvailableSoon
        //}
        /*if (isActiveTopicNotAvailable !== this.isActiveTopicNotAvailable) {
          this.isActiveTopicNotAvailable = isActiveTopicNotAvailable
        }*/
        //if (isActiveTopicBlocked !== this.isActiveTopicBlocked) {
        //  this.isActiveTopicBlocked = isActiveTopicBlocked
        //}
        if (isActiveTopicEmpty !== this.isActiveTopicEmpty) {
          this.isActiveTopicEmpty = isActiveTopicEmpty
        }
      }
    },
    /*topics(newValue, oldValue) {
      if (newValue != oldValue && typeof newValue === 'object') {
        this.initData()
      }
    },*/
  },

  mounted() {
    this.initData()
  },

  methods: {
    editTopic() {
      this.$router.push({
        name: 'content-pages-editor',
        params: {
          key: this.activeTopic.page_key,
        },
      })
    },
    async saveProgress() {
      if (this.activeTopic.seen_date != null) return
      console.log('saveProgress')
      this.savingProgress = true
      try {
        const progress = await ServiceCourses.saveProgress(
          this.courseKey,
          this.edition.key,
          this.unit.key,
          this.activeTopic.key
        )
        console.log(progress)
        this.showCongrats = true
        //update topic
        let date = new Date().toISOString()
        let topicIndex = this.topics.findIndex(element => element.key == this.activeTopic.key)
        if (topicIndex) {
          Vue.set(this.unit.topics[topicIndex], 'seen_date', date)
        }
        this.activeTopic.seen_date = date
        let self = this
        Vue.nextTick(function() {
          self.verifyNextAvailableTopic()
        })
        //this.updateModuleData()
        //this.$emit('update-course-progress', progress)
      } catch (e) {
        console.error(e)
      } finally {
        this.savingProgress = false
      }
    },

    async updateModuleData() {
      let classKey = this.$route.query.class_key || null
      let editionKey = this.edition.key
      let sections = ['units']
      let data = await ServiceCourses.getCourseEdition(this.courseKey, editionKey, sections, classKey)
      console.log('updateModuleData', data)
      for (let index = 0; index < data.units.length; index++) {
        const element = data.units[index]
        if (element.key == this.unit.key) {
          //loop topics array
          for (let t = 0; t < element.topics.length; t++) {
            //for (const topicKey in element.topics) {
            //Update topic sessions to see if we can access the topic and which topics are missing in an exam
            this.unit.topics[t]['session'] = element.topics[t]['session']
            this.unit.topics[t]['seen_date'] = element.topics[t]['seen_date']
          }
          break
        }
      }
    },
    initData() {
      this.nextAvailableTopic = null
      console.log('Loading init data from units')

      if (this.unit && this.topics && this.topics && !this.activeTopic) {
        this.activeTopic = this.topics[0]

        if (this.activeTopicKey) {
          this.activeTopic = this.topics.find(topic => topic.key === this.activeTopicKey)
        }

        if (this.activeTopic && this.activeTopic != null) {
          this.isActiveTopicBlocked = false
          console.log('Loading first unit topic: ', this.activeTopic)
          this.loadTopic(this.activeTopic, false, 0, false)
        }
        if (typeof this.topics[1] !== 'undefined') {
          this.hasNextTopic = true
        }

        if (this.topics) {
          console.log('unit topics', this.topics.length)
          const firstSession = this.topics.find(
            element =>
              element.session != null &&
              element.session &&
              element.session.start_date != null &&
              element.session.time_to_start != null
          )
          console.log('firstSession', firstSession)

          if (firstSession) {
            //correct current time - sync it with server
            //add time offset
            let nowDate = new Date()
            let startDate = new Date(firstSession.session.start_date)
            console.log('expected', firstSession.session.time_to_start)
            let real = Math.floor((startDate.getTime() - nowDate.getTime()) / 1000)
            console.log('real', real)
            let diff = real - firstSession.session.time_to_start
            console.log('diff', diff)
            let override = nowDate.getTime() + diff //* 1000
            console.log('override', override)
            this.$store.dispatch('setTimerRef', override)
          }
        }

        this.isActiveTopicAvailable = this.checkTopicAvailable(this.topics[0])
        this.isActiveTopicEmpty = this.checkTopicEmpty(this.topics[0])
      }

      let self = this
      Vue.nextTick(function() {
        self.verifyNextAvailableTopic()
      })
    },
    verifyNextAvailableTopic() {
      this.nextAvailableTopic = null
      // this method is responsible to check what is the next available topic not seen
      // if there is no next available topic, it will set the next topic to the first one
      let nextTopic
      for (let index = 0; index < this.topics.length; index++) {
        const element = this.topics[index]
        if (element.seen_date == null) {
          nextTopic = element
          break
        }
      }

      if (nextTopic) this.nextAvailableTopic = nextTopic.key
    },
    hideOverlay() {
      this.showOverlay = false
    },
    loadTopic(topic, hasNext = false, currentIndex = 0, click = true) {
      this.showOverlay = false
      this.activeTopic = topic
      this.hasNextTopic = hasNext
      this.currentActiveTopicIndex = currentIndex
      this.isActiveTopicAvailable = this.checkTopicAvailable(topic)
      this.isActiveTopicBlocked = this.checkTopicBlocked(topic)
      this.isActiveTopicEmpty = this.checkTopicEmpty(topic)
      this.examSubmitted = false //reset examSubmitted
      this.allowSeeAnswers = false //reset see answers permission
      if (topic.session && topic.session.item_key) {
        //get instance type
        this.loadExamInstanceInfo(topic.session.item_key)
      } else {
        this.currentExamInstance = null
      }
      if (topic.type === 'quality_evaluation') {
        //verify if we can answer the quality evaluation
        //get the latest information from the backend
        this.updateModuleData()
      }

      // Set feedback
      this.feedback = this.activeTopic.feedback || {}

      this.$store.dispatch('saveActivityAction', {
        service: 'courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key,
        },
        action: 'open',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: click,
      })
      this.$router.push({
        name: 'course-edition-unit',
        params: {
          courseKey: this.courseKey,
          editionKey: this.edition.key,
          unitKey: this.unit.key,
          topicKey: this.activeTopic.key,
        },
      })
    },

    // Helpers
    getCloudFlareVideoUrl(topic) {
      let videoDeliveryUrl = `https://iframe.videodelivery.net/${topic.video_key}?preload=true`
      if (topic.video_cover) {
        videoDeliveryUrl =
          videoDeliveryUrl + '&poster=' + encodeURIComponent(topic.video_cover) + '%3Ftime%3D%26height%3D600'
      }
      return videoDeliveryUrl
    },
    openNextTopic() {
      this.showCongrats = false
      this.showOverlay = false
      if (typeof this.topics[this.currentActiveTopicIndex + 1] !== 'undefined') {
        this.loadTopic(
          this.topics[this.currentActiveTopicIndex + 1],
          typeof this.topics[this.currentActiveTopicIndex + 2] !== 'undefined',
          this.currentActiveTopicIndex + 1
        )
      }
    },

    // Exams
    openAnswersModal() {
      // TODO: PARA TESTES USAR INSTANCIA 2z2j72u5gsjq
      // this.seeAnswers = '2z2j72u5gsjq'
      if (this.activeTopic.session && this.activeTopic.session.item_key) {
        this.seeAnswers = this.activeTopic.session.item_key
      }
    },
    /*openExam() {
      console.log(this.activeTopic)
      if (this.activeTopic && this.activeTopic.session.exam_key) {
        this.$router.push({ path: `/ex/${this.activeTopic.session.exam_key}` })
      }
    },
    openQuestionary() {
      if (this.activeTopic && this.activeTopic.session.exam_key) {
        this.$router.push({ path: `/form/${this.activeTopic.session.exam_key}` })
      }
    },*/
    async loadExamInstanceInfo(instaceKey) {
      try {
        let instance = await ServiceExams.getExamineeInstance(instaceKey)
        if (instance) {
          this.currentExamInstance = instance
          if (instance.withAnswer) {
            this.examSubmitted = true
          } else {
            this.examSubmitted = false
          }
          // If user has answered the exam and the exam is now closed, markit has done
          /*if (instance.withAnswer && !this.isTopicDone(this.activeTopic.key)) {
            this.toggleTopic(this.activeTopic.key)
          }*/
        } else {
          this.examSubmitted = false
        }
      } catch (e) {
        console.error(e)
      }
    },

    isActive(topicId) {
      return this.activeTopic && this.activeTopic.key == topicId
    },

    // Check sessions
    checkTopicHasSession(topic) {
      return topic && topic.session && topic.session.start_date
    },
    isNextTopic(topic) {
      //if free consumption, we override this saying true
      if (this.edition && this.edition.options && this.edition.options.topics_consume_mode == 'free') return true
      return this.nextAvailableTopic == topic.key
    },
    isTopicSeen(topic) {
      return topic.seen_date !== null
    },
    checkTopicAvailable(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      if (this.isManager && topic.type === 'normal') return true

      const sessionStart = Dates.build(topic.session.start_date)
      let isInDateRange = this.now >= sessionStart.valueOf()
      if (isInDateRange && topic.session.end_date != null) {
        const sessionEnd = Dates.build(topic.session.end_date)
        isInDateRange = this.now >= sessionStart.valueOf() && this.now < sessionEnd.valueOf()
      }

      if (topic.key == this.activeTopic.key) {
        console.log(
          this.activeTopic.key,
          sessionStart.valueOf(),
          this.now,
          'faltam: ' + Math.floor((sessionStart.valueOf() - this.now) / 1000) + 's',
          this.now >= sessionStart.valueOf()
        )
      }
      // console.log('checkTopicAvailable', sessionStart.valueOf(), sessionEnd.valueOf(), this.now)
      let isAvailable = topic.type == 'evaluation' || this.isTopicSeen(topic) || this.isNextTopic(topic)
      return isInDateRange && isAvailable
      // return topic.sessions.can_view
    },
    checkTopicEmpty(topic) {
      return topic && topic.type == 'normal' && topic.video_key === null
    },
    checkTopicAvailableSoon(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      return topic.session.time_to_start > 0
    },
    checkTopicNotAvailable(topic) {
      return !this.checkTopicHasSession(topic) && !this.checkTopicAvailableSoon(topic) && !this.checkTopicExpired(topic)
    },
    checkTopicExpired(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      //allow consumption of the topic if its content its available after ending date
      if (this.edition.options && this.edition.options.access_course_content == 'always') return true
      if (topic.session.end_date) {
        const sessionEnd = Dates.build(topic.session.end_date)
        return sessionEnd.valueOf() < this.now && topic.session.can_view === false
      } else {
        return false
      }
    },
    //Check if the topic is blocked because the previous topics were not completed
    checkTopicBlocked(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      if (this.isManager) return false

      const sessionStart = Dates.build(topic.session.start_date)
      let inDateRange = this.now >= sessionStart.valueOf()
      if (inDateRange && topic.session.end_date != null) {
        const sessionEnd = Dates.build(topic.session.end_date)
        inDateRange = this.now >= sessionStart.valueOf() && this.now < sessionEnd.valueOf()
      }
      // console.log('checkTopicAvailable', sessionStart.valueOf(), sessionEnd.valueOf(), this.now)
      let isBlocked = !this.isTopicSeen(topic) && !this.isNextTopic(topic)
      return inDateRange && isBlocked
    },

    // Progress
    isTopicDone(topicKey) {
      let found = this.unit.topics.find(element => element.key == topicKey)
      if (found) {
        return found.seen_date != null
      }
      return false
    },
    async saveTopicFeedback() {
      this.savingFeedback = true

      // Verify if we have feedback of all topics
      let canSave = true
      console.log('quality_evaluation', this.activeTopic.type)
      if (this.activeTopic.type === 'quality_evaluation') {
        //verify if all questions were answered
        let feedbackItems = [
          'content_quality',
          'speakers_competence',
          'clinicalcases_interest',
          'video_quality',
          'ucstudent',
          'recommendation',
          'global_satisfation',
        ]

        for (let index = 0; index < feedbackItems.length; index++) {
          if (this.feedback[feedbackItems[index]] === undefined) {
            canSave = false
            break
          }
        }
      } else {
        for (let index = 0; index < this.feedbackItems.length; index++) {
          if (this.feedback[this.feedbackItems[index].key] === undefined) {
            canSave = false
            break
          }
        }
      }

      if (!canSave) {
        this.$buefy.dialog.alert({
          message: 'Por favor, responda a todas as questões.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
        this.savingFeedback = false
        return
      }

      try {
        const feedbackUpdated = await ServiceAcademic.saveTopicFeedback(
          this.course.key,
          this.edition.key,
          this.unit.key,
          this.activeTopic.key,
          this.feedback
        )
        // await ServiceSettings.setSetting(
        //   `feedback-course-${this.course.key}-${this.unit.key}-${this.activeTopic.key}`,
        //   JSON.stringify(this.feedback)
        // )
        /*this.$buefy.dialog.alert({
          message: 'Feedback recebido. Obrigado!',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })*/

        this.$emit('update-course-progress', feedbackUpdated.progress)

        if (this.activeTopic.type === 'quality_evaluation') {
          this.$buefy.dialog.alert({
            message: 'Feedback recebido. Obrigado!',
            ariaRole: 'alertdialog',
            ariaModal: true,
          })
        } else {
          this.showCongrats = true
        }

        // Update all topics feedback
        const unitTopicsFeedback = feedbackUpdated['units'][this.unit.key]['topics']

        this.$emit(
          'update-unit-progress',
          feedbackUpdated['units'][this.unit.key].progess ?? feedbackUpdated['units'][this.unit.key].progress
        )

        // Verify missing topics for evaluation

        for (let topicKey of Object.keys(unitTopicsFeedback)) {
          if (unitTopicsFeedback[topicKey]) {
            //this.unit.topics[topicKey]['feedback'] = unitTopicsFeedback[topicKey].feedback
            //this.unit.topics[topicKey]['seen_date'] = unitTopicsFeedback[topicKey].seen_date
            console.log('received', unitTopicsFeedback[topicKey])
            Vue.set(this.unit.topics[topicKey], 'feedback', unitTopicsFeedback[topicKey].feedback)
            Vue.set(this.unit.topics[topicKey], 'seen_date', unitTopicsFeedback[topicKey].seen_date)
          }
        }
        this.verifyNextAvailableTopic()
        this.updateModuleData()
      } catch (e) {
        console.error(e)
      } finally {
        this.savingFeedback = false
      }
    },

    // Video player
    onPlayerPlay(player) {
      console.debug('player play!', player)
      this.$store.dispatch('saveActivityAction', {
        service: 'non-degree-courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key,
        },
        action: 'play-video',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: true,
      })
    },
    onPlayerPause(player) {
      console.debug('player pause!', player)
      this.$store.dispatch('saveActivityAction', {
        service: 'non-degree-courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key,
        },
        action: 'pause-video',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: true,
      })
    },
    onPlayerEnded(player) {
      console.debug('player ended!', player)
      this.showOverlay = true
      this.$store.dispatch('saveActivityAction', {
        service: 'non-degree-courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key,
        },
        action: 'ended-video',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: true,
      })
    },
    playerStateChanged(playerCurrentState) {
      console.debug('player current update state', playerCurrentState)
    },
    playerReadied(player) {
      console.debug('the player is readied', player)
    },
  },
}
</script>

<style lang="scss">
.content.fixed-content p:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
