<template>
  <div v-if="steps.length > 0" ref="vertical-steps" class="relative">
    <div v-if="title" class="text-gray-500 font-semibold m-2 flex gap-2" :class="[`text-${size}`]">
      <div>{{ title }}</div>
      <div v-if="counter" class="opacity-50">{{ steps.length }}</div>
    </div>
    <div
      class="hidden timeline-vertical absolute top-4 bottom-4 rounded-xl bg-gray-200"
      style="left: 16px; width: 2px"
    ></div>
    <div
      class="hidden timeline-vertical absolute top-4 rounded-xl bg-primary bg-opacity-50"
      style="left: 16px; width: 2px"
      :style="{ height: timeline_done_height + 'px' }"
    ></div>

    <button
      v-for="step in steps"
      :ref="step.status === 'finished' ? 'stepdone' : 'notdone'"
      :key="'step_' + step.value + '_' + step.status"
      class="group inline-flex gap-3 rounded px-2 py-1 w-full transition-colors duration-150 items-center"
      :class="[
        {
          stepdone: step.status === 'finished',
          'text-primary font-medium': step.value === currentStep,
          'font-medium text-gray-600': step.value !== currentStep,
          'cursor-not-allowed': step.status === 'locked',
          'hover:text-primary': step.status !== 'locked',
        },
        `text-${size}`,
      ]"
      @click="step.status !== 'locked' ? clicked(step.value) : null"
    >
      <div
        v-if="step.status === 'finished'"
        class="flex-shrink-0 bg-white rounded-full flex items-center justify-center"
        style="width: 21px; height: 21px"
      >
        <fw-icon-check class="text-primary w-4 h-4" />
      </div>
      <div
        v-else-if="step.status === 'error'"
        class="flex-shrink-0 bg-red-500 rounded-full flex items-center justify-center"
        style="width: 21px; height: 21px"
      >
        <fw-icon-close class="text-white w-4 h-4" />
      </div>
      <div
        v-else-if="step.status === 'next'"
        class="flex-shrink-0 rounded-full flex items-center p-0.5 justify-center"
        style="width: 21px; height: 21px"
      >
        <div class="bg-gray-200 rounded-full inline-block" style="width: 12px; height: 12px"></div>
      </div>
      <div
        v-else-if="step.status === 'locked'"
        class="flex-shrink-0 bg-white rounded-full text-gray-400 flex items-center p-0.5 justify-center"
        style="width: 21px; height: 21px; margin-left: 5px; line-height: 12px; padding-left: 1px; padding-top: 1px"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="fill-current"
          style="width: 11px; height: 10px"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zm-2 0V9A5 5 0 0 0 7 9v1h10zm-6 4v4h2v-4h-2z"
          />
        </svg>
      </div>
      <div
        v-else-if="step.status === 'letter'"
        class="flex-shrink-0 bg-white rounded-full flex items-center p-0.5 justify-center text-primary"
        :class="[`text-${size}`]"
        style="width: 21px; height: 21px; margin-left: 5px; line-height: 12px; padding-left: 1px; padding-top: 1px"
      >
        {{ step.letter }}
      </div>
      <div
        v-else
        class="flex-shrink-0 mx-1 bg-gray-200 rounded-full"
        style="width: 12px; height: 12px; margin-left: 5px; margin-top: 1px"
      ></div>
      <div
        class="group-opacity-100 text-left pr-1"
        :class="{
          'opacity-50 group-opacity-50': step.status === 'locked',
          truncate: truncateText,
          'text-red-500': step.status === 'error',
        }"
      >
        <span v-html="step.text"></span>
        <fw-label v-if="step.meeting_datetime !== null">{{ step.meeting_datetime | formatDayWithTime }}</fw-label>
      </div>
    </button>
  </div>
</template>

<script>
/*
STEP TEMPLATE:
{
  text: string,
  status: string (finished | unfinished | next | locked),
  meeting_datetime: YYYY/MM/DD HH:MM,
  value: string,
}
 */
export default {
  name: 'VerticalSteps',
  props: {
    steps: {
      type: Array,
      default: function() {
        return []
      },
    },
    currentStep: {
      type: String,
    },
    truncateText: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    title: {
      type: String,
      default: '',
    },
    counter: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      timeline_done_height: 0,
    }
  },
  watch: {
    steps: {
      handler: function() {
        this.$nextTick(function() {
          this.calculateLine()
        })
      },
      deep: true,
    },
  },
  mounted() {
    //calculate height of steps line after dom ready
    this.$nextTick(function() {
      this.calculateLine()
    })
  },
  methods: {
    calculateLine() {
      //let vert_steps_box = document.getElementById('vertical-steps')
      let vert_steps_box = this.$refs['vertical-steps']
      //console.log(vert_steps_box)
      if (vert_steps_box) {
        //console.log('vert_steps_box')
        let steps = this.$refs['stepdone'] //vert_steps_box.querySelectorAll('.stepdone')
        //console.log(steps)
        if (steps && steps.length > 0) {
          let last_done_step = steps[steps.length - 1].getBoundingClientRect()
          let last_step_top = last_done_step.top
          this.timeline_done_height = last_step_top - vert_steps_box.getBoundingClientRect().top
        }
      }
    },
    clicked(value) {
      this.$emit('clicked', value)
    },
  },
}
</script>

<style scoped></style>
