import { debounce } from 'debounce'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

const WARN_SESSION_END_MINUTES = 5
const WARN_MEETING_END_MINUTES = 5
const DAY_SECONDS = 60 * 60 * 24

export default {
  data() {
    return this.getDefaultData()
  },

  computed: {
    inParticipantsView() {
      return this.isRunning && this.view === 'participants'
    },
    isTx() {
      return process.env.VUE_APP_KEY == 'ucteacher'
    },
    isClassEdition() {
      if (!this.meeting) return false
      else {
        if (this.meeting.application === 'courses') return true
        return (
          this.meeting.application === 'academic' &&
          ['class_edition', 'non_degree_class'].includes(this.meeting.item_type)
        )
      }
    },
    isClassEditionTeacher() {
      return this.isClassEdition && !!this.classEdition
    },
    allowMeetingChanges() {
      if (!this.meeting || !this.meeting.is_owner) return false
      return true
    },
    asWebinar() {
      return Boolean(this.allowMeetingChanges && this.instance && !this.instance.withRole('show_to_everyone'))
    },
    isWebinarActive() {
      return Boolean(this.instance && !this.instance.withRole('show_to_everyone'))
    },
    allowClassSessionView() {
      return this.isRunning && this.isClassEditionTeacher && !this.$device.isMobile()
    },
    isMeetingOwner() {
      return Boolean(this.meeting && this.meeting.is_owner)
    },
    loggedUser() {
      return this.authUser || this.$store.getters.getUser
    },
    isMobile() {
      return window.innerWidth < 640
    },
  },

  watch: {
    $route() {
      // Avoid reload or page changes
      // If we need to set any route, we need to rethink this
      this.leaveMeetingCore('leave')
    },
  },

  destroyed() {
    if (this.reloadPageEventListener) {
      window.removeEventListener('resize', this.reloadPageEventListener)
      this.reloadPageEventListener = null
    }
    if (this.meetingTimer) {
      clearInterval(this.meetingTimer)
      this.meetingTimer = null
    }
    if (this.networkIssues && this.networkIssues.timer) {
      clearInterval(this.networkIssues.timer)
      this.networkIssues.timer = null
    }
  },

  beforeDestroy() {
    if (this.meetingSubscription) {
      ServiceMeetings.unsubscribe(this.meetingSubscription)
      this.meetingSubscription = null
    }

    if (this.classSessionEndTimer) {
      clearTimeout(this.classSessionEndTimer)
    }
    if (this.classSessionMeetingEndTimer) {
      clearTimeout(this.classSessionMeetingEndTimer)
    }

    this.$store.dispatch('setContext')
    this.$store.commit('unsubscribeWS', { code: 'ws-reconnect', name: 'MeetingsLive' })
    this.$store.commit('unsubscribeWS', { code: 'meetings', name: 'MeetingsLive' })
    if (this.unregisterChat) this.unregisterChat()

    // Exit
    // window.removeEventListener('beforeunload', this.onBeforeUnload)
    this.leaveMeetingCore('leave')
  },

  created() {
    this.setMeeting()
    this.$store.commit('subscribeWS', { code: 'ws-reconnect', name: 'MeetingsLive', callback: this.meetingReconnect })
    this.$store.commit('subscribeWS', { code: 'meetings', name: 'MeetingsLive', callback: this.meetingsWSMessages })
    if (this.registerChat) this.registerChat()
  },

  methods: {
    meetingReconnectJanus(connectionId) {
      return this.meetingReconnect(connectionId, true)
    },
    async meetingReconnect(connectionId, restartJanus = false) {
      if (!connectionId) {
        console.error('WS reconnect without connectionId, retrying')

        const retryMethod = restartJanus ? this.meetingReconnectJanus : this.meetingReconnect
        setTimeout(() => {
          this.$store.commit('checkWebsocket', { name: 'retry-meeting', callback: retryMethod })
        }, 2500)
        return
      } else if (this.waitingToBeAccepted) {
        // TODO refresh waiting
        return
      }

      const janusActive = Boolean(this.janus && this.janus.isConnected())
      console.warn(`Reconnect started ${connectionId} restartJanus:${restartJanus} janusActive:${janusActive}`)

      if (restartJanus) {
        // Make sure we set previous media
        const startWithAudio = this.audio.active
        const startWithCamera = this.camera.active

        // TODO show reconnect modal
        await this.leaveJanus()

        this.startWithAudio = startWithAudio
        this.startWithCamera = startWithCamera
      }

      const meetingKey = this.meeting && this.meeting.key ? this.meeting.key : this.$route.params.key
      if (!meetingKey) {
        console.error('WS reconnect without meeting')
        return
      } else if (!this.attendee || !this.isRunning) {
        this.meeting = await ServiceMeetings.getMeeting(meetingKey)
        await this.subscribeMeeting()
        return
      }

      const joinResponse = await ServiceMeetings.joinMeeting({
        key: meetingKey,
        connectionId: connectionId,
        rejoining: true,
        withHD: this.startWithHD,
      })
      Object.assign(this.attendee, joinResponse.attendee)
      Object.assign(this.instance, joinResponse.instance)
      this.setAudios(joinResponse.audios, 'diff')

      // TODO Remove attendees that dont exist in joinResponse.attendees
      // TODO set new: this.setAttendees([{ instance_key: this.instance.key, attendees: joinResponse.attendees }], false)

      if (this.attendee.withRole('can_accept')) {
        this.waitingAttendees = joinResponse.waiting_attendees
        console.debug('Meeting with waiting attendees', this.waitingAttendees)
      }

      if (this.withJanus && (restartJanus || !janusActive)) {
        this.joinJanus()
      }
    },
    async meetingsWSMessages(messages, connectionId) {
      const meeting = this.meeting
      const instance = this.instance

      if (meeting) {
        const meetingKey = meeting.key

        if (messages.meetingDelta) {
          for (let message of messages.meetingDelta) {
            if (message.key !== meetingKey) continue

            Object.assign(meeting, message.delta)
            console.debug(`Meeting updated ${JSON.stringify(message)}`)

            if (!meeting.running && this.enterRequestRejected) {
              this.enterRequestRejected = null
            }
          }
        }

        if (messages.rejected) {
          for (let message of messages.rejected) {
            if (message.meeting_key !== meetingKey) continue

            // TODO check message.instance_key
            console.debug(`Rejected to join meeting ${JSON.stringify(message)}`)
            this.rejectedToJoin({ message: message.message })
          }
        }

        if (messages.accepted) {
          for (let message of messages.accepted) {
            if (message.meeting_key !== meetingKey) continue

            // TODO check message.instance_key
            console.debug(`Accepted to join meeting ${JSON.stringify(message)}`)
            this.startMeeting()
          }
        }

        if (messages.end) {
          for (let message of messages.end) {
            if (message.meeting_key !== meeting.key) continue
            else if (instance && instance.key !== message.instance_key) continue

            console.debug(`Meeting ended ${JSON.stringify(message)}`)
            this.leaveMeetingOnEnd()
          }
        }
      }

      if (instance) {
        if (messages.reload) {
          for (let message of messages.reload) {
            if (instance.key === message.instance_key) {
              console.error(`Instance ${instance.key} reload required`)
              await this.meetingReconnect(connectionId, true)
            }
          }
        }

        if (messages.instanceDelta) {
          for (let message of messages.instanceDelta) {
            if (instance.key !== message.key) continue

            Object.assign(instance, message.delta)
            console.debug(`Instance updated ${JSON.stringify(message)}`)
          }
        }

        if (messages.audioAdd) {
          for (const message of messages.audioAdd) {
            if (instance.key === message.instance_key) {
              this.setAudios(message.attendees)
            }
          }
        }
        if (messages.audioDelete) {
          for (const message of messages.audioDelete) {
            if (instance.key === message.instance_key) {
              this.removeAudios(message.keys)
            }
          }
        }
      }

      if (messages.waiting) this.setWaitingAttendees(messages.waiting)
      if (messages.waitingLeave) this.removeWaitingAttendees(messages.waitingLeave)
      if (messages.attendee) this.setAttendees(messages.attendee)
      if (messages.show) this.setAttendees(messages.show, true, false)
      if (messages.attendeeDelta) this.setAttendeesDelta(messages.attendeeDelta)
      if (messages.leave) this.removeAttendees(messages.leave)
      if (messages.hide) this.removeAttendees(messages.hide, true, false)

      const attendee = this.attendee
      if (attendee) {
        if (messages.kick) {
          const attendeeKey = attendee.key
          for (let message of messages.kick) {
            if (attendeeKey !== message.key) continue

            console.debug(`Kicked ${JSON.stringify(message)}`)
            this.isRunning = false
            const rejectData = { message: message.message, title: 'Foi removido da sessão virtual' }
            this.leaveMeetingCore(null, rejectData)

            // TODO show model after leave
            // if (message.by_attendee_key) {
            //   const byAttendee = this.attendees[message.by_attendee_key]
            // }
          }
        }
      }
    },

    // Basic method to set the active view
    setView(view) {
      this.view = view
      if (view !== 'participants') {
        this.isToolboxCallExpanded = false
        this.isToolboxCallForceCollapsed = true
      } else {
        this.isToolboxCallExpanded = true
        this.isToolboxCallForceCollapsed = false
      }
      this.reloadPage()
    },

    onBeforeUnload(e) {
      e.preventDefault()
      e.returnValue = ''
    },

    getDefaultData(meeting = null, rejected = null) {
      return {
        view: 'participants',
        loading: false,

        isRunning: false,
        withJanus: false,
        startedDate: null,
        duration: null,
        startMeetingLock: false,
        meetingTimer: null,
        reloadPageEventListener: null,
        startWithHD: false,

        showQualityModal: false,
        showAudioLockedModal: false,
        showAudioUnlockedModal: false,
        allowToDisableCameras: true,
        lowestQuality: false,
        camerasDisabled: false,
        screenSharesDisabled: false,
        withNetworkIssues: false,
        networkIssues: {
          timer: null,
          period: 15 * 1000,
          lastLostLog: new Date(),
          logPeriod: 10 * 1000,
          audio: {
            lost: [],
            warnAfter: 100,
          },
          video: {
            lost: [],
            warnAfter: 100,
          },
        },

        authUser: null,
        meetingNotFound: false,
        meeting: meeting,
        attendee: null,
        instance: null,
        meetingSubscription: null,
        isToolboxCallExpanded: true,
        isToolboxCallForceCollapsed: false,

        timerInterval: null,
        updateAllStreamStatsInterval: null,

        ignoreApps: false,

        // Academic
        classEdition: null,
        activeClassSession: null,
        classSessionSubscription: null,
        classSessionEndTimer: null,
        classSessionMeetingEndTimer: null,

        // Fw Websocket
        connection: null,
        waitingToBeAccepted: false,
        enterRequestRejected: rejected,

        ModalPersonalizeMeetingKeyIsActive: false,
        ModalPersonalizeMeetingKeyIntroViewed: false,

        ModalBulkPermissionsVisibility: 'hidden',
      }
    },

    async resetDefaultData(keepRejected = false) {
      if (process.env.VUE_APP_KEY == 'ucmeetingscreen') {
        if (this.$route.name != 'meetingRoom') {
          this.$router.push({ name: 'meetingRoom' })
        }
        return
      } else if (!this.$route.params.key) {
        return
      }

      const rejected = keepRejected ? this.enterRequestRejected : null
      const meeting = await ServiceMeetings.getMeeting(this.$route.params.key)
      Object.assign(this, this.getDefaultData(meeting, rejected))
      await this.setMeeting(meeting, !keepRejected)

      if (this.getChatDefaultData) Object.assign(this, this.getChatDefaultData())
      Object.assign(this, this.getJanusDefaultData())
      Object.assign(this, this.getJanusSelfDefaultData())
      Object.assign(this, this.getJanusSubscribersDefaultData())
      Object.assign(this, this.getPodsDefaultData())
    },

    async syncAppsData() {
      if (this.ignoreApps) return
      if (this.isClassEdition) {
        await this.syncAcademicData()
      }
    },
    async syncAcademicData() {
      if (this.isTx) {
        try {
          let response = await ServiceAcademic.getTeacherClass(this.meeting.item_key)
          this.classEdition = response.edition
          this.classEdition.sessions = response.sessions
          this.classEdition.students = response.students
          this.activeClassSession = await ServiceAcademic.loadActiveSession(true, this.classEdition)
          this.classSessionSubscription = ServiceAcademic.createSubscription(
            this.classEdition.key,
            this.activeClassSession
          )
        } catch (error) {
          console.error(`Failed to get class edition ${this.meeting.item_key}`, error)
          this.classEdition = null
        }

        this.checkVisibilityModalBulkPermissions()

        if (
          this.withJanus &&
          this.classEdition &&
          this.classEdition.students.length >= 50 &&
          !this.asWebinar &&
          this.attendee.withRole('can_promote')
        ) {
          setTimeout(this.requestWebinarChange, 2000)
        }
      }

      if (this.activeClassSession && this.activeClassSession.end_date) {
        let sessionWarnSeconds = Dates.nowDiff(this.activeClassSession.end_date, 'seconds')
        sessionWarnSeconds -= WARN_SESSION_END_MINUTES * 60
        if (this.classSessionEndTimer) {
          clearTimeout(this.classSessionEndTimer)
        }
        if (sessionWarnSeconds > 0 && sessionWarnSeconds < DAY_SECONDS) {
          this.classSessionEndTimer = setTimeout(() => {
            this.$buefy.dialog.alert({
              title: `A aula termina em ${WARN_SESSION_END_MINUTES} minutos`,
              message: `<div>Esta sessão termina daqui a ${WARN_SESSION_END_MINUTES} minutos</div>`,
              type: 'is-dark',
              ariaRole: 'alertdialog',
            })
          }, sessionWarnSeconds * 1000)
        }

        let meetingWarnSeconds = Dates.nowDiff(this.activeClassSession.end_meeting_date, 'seconds')
        meetingWarnSeconds -= WARN_MEETING_END_MINUTES * 60
        if (this.classSessionMeetingEndTimer) {
          clearTimeout(this.classSessionMeetingEndTimer)
        }
        if (meetingWarnSeconds > 0 && meetingWarnSeconds < DAY_SECONDS) {
          this.classSessionMeetingEndTimer = setTimeout(() => {
            this.$buefy.dialog.alert({
              title: `A meeting termina em ${WARN_MEETING_END_MINUTES} minutos`,
              message: `<div>Esta meeting termina daqui a ${WARN_MEETING_END_MINUTES} minutos</div>`,
              type: 'is-dark',
              ariaRole: 'alertdialog',
            })
          }, meetingWarnSeconds * 1000)
        }

        console.info(`Class warnings defined for end:${sessionWarnSeconds} meetingEnd:${meetingWarnSeconds}`)
      }

      if (this.chats.length && this.setAllChatUsers) {
        if (this.classEdition) {
          const users = {}
          for (let user of this.classEdition.teachers) users[user.key] = user
          for (let student of this.classEdition.students) users[student.user.key] = student.user
          this.setAllChatUsers(users)
        } else {
          for (const chat of this.chats) {
            this.setAllChatUsers(await ServiceChat.getChatUsers(chat.key))
          }
        }
      }
    },

    async subscribeMeeting() {
      if (this.meeting) {
        const meetingKey = this.meeting.key
        await ServiceMeetings.subscribe(meetingKey)
        this.meetingSubscription = meetingKey
      }
    },
    async setMeeting(meeting = null, clearRejected = true) {
      if (!meeting && !this.$route.params.key) {
        this.meetingNotFound = true
        return
      }

      this.meetingNotFound = false

      if (meeting) {
        this.meeting = meeting
      } else {
        try {
          this.meeting = await ServiceMeetings.getMeeting(this.$route.params.key)
        } catch (error) {
          if (utils.errors(error).exists('NotFound')) {
            this.meetingNotFound = true
            return
          } else {
            throw error
          }
        }
      }

      if (this.meeting.ban) {
        this.banJoin(this.meeting.ban.message)
      } else if (clearRejected) {
        this.enterRequestRejected = null
      }

      if (this.meetingSubscription !== this.meeting.key) {
        this.$store.commit('subscribeWS', { code: 'auth', name: 'MeetingsLive', callback: this.subscribeMeeting })
      }

      if (process.env.VUE_APP_KEY == 'ucmeetingscreen') {
        if (this.meeting.ban) {
          console.debug('ucmeetingscreen meeting banned', this.meeting.key)
          this.$router.push({ name: 'meetingRoom' })
        } else {
          console.debug('ucmeetingscreen autojoin meeting', this.meeting.key)
          this.startWithAudio = false
          this.startWithCamera = false
          this.startMeeting()
        }
        return
      }

      if (!this.isJanusInitialized && this.isMeetingOwner && !this.isRunning && !this.isClassEdition) {
        const settingKey = 'intro-personalize-meeting-' + this.meeting.key
        this.ModalPersonalizeMeetingKeyIntroViewed = await ServiceSettings.getSetting(settingKey)
        this.ModalPersonalizeMeetingKeyIsActive = !this.ModalPersonalizeMeetingKeyIntroViewed
      }
    },
    reloadMeetingData(meeting) {
      this.meeting = meeting
    },

    async checkVisibilityModalBulkPermissions() {
      if (this.activeClassSession && this.isClassEditionTeacher) {
        let modalVisibility = await ServiceSettings.getSetting('modal-room-config-v3-' + this.meeting.key || '')
        this.ModalBulkPermissionsVisibility = modalVisibility ? modalVisibility.value || 'hidden' : 'visible'

        if (this.ModalBulkPermissionsVisibility == 'visible') {
          setTimeout(() => {
            this.toggleBulkPermissions()
          }, 1000)
        }
      }
    },
    async updateVisibilityModalBulkPermissions(hidden) {
      const code = hidden ? 'hidden' : 'visible'
      if (code !== this.ModalBulkPermissionsVisibility) {
        this.ModalBulkPermissionsVisibility = code
        await ServiceSettings.setSetting(`modal-room-config-v3-${this.meeting.key}`, code)
      }
    },

    async acceptParticipants(keys) {
      await ServiceMeetings.acceptWaitingParticipants(this.meeting.key, keys)
      this.removeWaitingAttendees([{ keys: keys }])
    },
    async rejectParticipants(keys, data) {
      data.keys = keys
      await ServiceMeetings.rejectWaitingParticipants(this.meeting.key, data)
      this.removeWaitingAttendees([{ keys: keys }])
    },

    async kickAttendee(attendee) {
      this.openRemoveAttendeeModal({
        action: 'kick',
        message: 'Tem a certeza que deseja remover o participante?',
        buttonMessage: 'Remover',
        callback: async data => {
          const kickData = { message: data.message, ban: data.ban }
          await ServiceMeetings.kickAttendee(this.meeting.key, attendee.key, kickData)
          this.removeAttendees([{ instance_key: this.instance.key, keys: [attendee.key] }])
        },
      })
    },

    toggleQualityModal() {
      this.showQualityModal = !this.showQualityModal
    },
    setLostPackets(type, uplink, lost) {
      if (uplink) {
        if (this.audio.active || this.camera.active || this.screenShare.active) {
          // Ignore lost packets from janus if we are sending
          lost = 0
        } else {
          // Incoming packets should weight less
          lost = Math.floor(lost / 3)
        }
      }

      if (lost) {
        this.networkIssues[type].lost.push([new Date(), lost])
        this.checkLostPackets()
      }
    },
    checkLostPackets() {
      let withIssues = false
      const lostPackets = {}
      const networkRef = this.networkIssues
      const period = networkRef.period
      const now = new Date()

      for (let type of ['audio', 'video']) {
        const options = networkRef[type]
        const lost = options.lost
        if (!lost.length) continue

        let oldIdx = null
        let itemLost = 0
        for (let i = 0; i < lost.length; i++) {
          if (now - lost[i][0] > period) oldIdx = i
          else itemLost += lost[i][1]
        }
        if (oldIdx !== null) lost.splice(0, oldIdx + 1)

        if (itemLost > options.warnAfter) {
          withIssues = true
          lostPackets[type] = itemLost
        }
      }

      if (!withIssues && this.withNetworkIssues) this.withNetworkIssues = false
      else if (withIssues && !this.withNetworkIssues) this.withNetworkIssues = true

      this.adjustBitratePercentage()

      if (withIssues && now - networkRef.lastLostLog > networkRef.logPeriod) {
        console.info(`Lost packets sum: ${JSON.stringify(lostPackets)}`)
        networkRef.lastLostLog = now
      }
    },

    async togglePromote(attendee, noModal = false, promote = true) {
      if (!noModal) {
        // Do everything inside the modal
        this.permissionsAttendee = attendee
        this.permissionsAttendeeModalActive = true
        return
      }

      const roles = {}
      roles[ServiceMeetings.getRole('show_to_everyone')] = !!promote
      roles[ServiceMeetings.getRole('sharescreen_allowed')] = !!promote
      if (promote) {
        roles[ServiceMeetings.getRole('camera_allowed')] = true
        roles[ServiceMeetings.getRole('audio_allowed')] = true
      }
      attendee.roles = await ServiceMeetings.changeAttendeeRoles(this.meeting.key, attendee.key, roles)
      this.reloadPage()
    },

    async toggleBulkPermissions() {
      this.bulkPermissionsModalActive = true
    },

    async updateRecording(action) {
      if (action == 'start') {
        const messageFirstUse = {
          title: 'Funcionalidade de Gravação',
          message: `
            <div>Na sua primeira experiência com a funcionalidade de gravação
              tenha em conta os seguintes pontos:</div>
            <div class="content">
              <ul>
                <li>Cada vez que inicia e pára uma gravação, a plataforma irá criar um vídeo;</li>
                <li>Caso pretenda dividir a gravação em capítulos, utilize a opção "Pausa". Por cada pausa,
                  a plataforma cria um capítulo no vídeo. Os capítulos podem ser personalizados com um título
                  e subtítulo, durante a sessão ou posteriomente;</li>
                <li>O vídeo final da gravação só ficará disponível depois de terminar a sessão e indicar
                  que a mesma deve ser compilada (processo que junta a identidade institucional UC,
                    os títulos e subtítulos dos capítulos e do próprio vídeo);</li>
                <li>Neste momento, o vídeo final que resulta da gravação, não incluirá o vídeo
                  dos restantes participantes;</li>
                <li>Os participantes têm a indicação que a sessão está a ser gravada (ícone vermelho
                  no canto superior direito da página).</li>
              </ul>
            </div>
          `,
          confirmText: 'Iniciar gravação',
          cancelText: 'Cancelar',
          onConfirm: async () => {
            await ServiceMeetings.startRecording(this.meeting.key)
            this.instance.recording = 'running'
          },
        }
        const messagePermanentUse = {
          title: 'Funcionalidade de Gravação',
          message: `
            <div><p>Na versão atual da funcionalidade de gravação, o vídeo <strong>incluirá o som de todos os
            participantes</strong> e a <strong>imagem da sua câmara e da partilha de ecrã</strong>.</p>
            <p class="has-text-small has-text-muted">Por favor, reporte-nos as dificuldades e sugestões que considerar
            pertinentes. Como habitual, com a sua ajuda, vamos procurar ser rápidos na melhoria desta funcionalidade,
            para disponibilizar brevemente uma versão estável.</p></div>
          `,
          confirmText: 'Iniciar gravação',
          cancelText: 'Cancelar',
          onConfirm: async () => {
            await ServiceMeetings.startRecording(this.meeting.key)
            this.instance.recording = 'running'
          },
        }

        const infoRecordShowed = await ServiceSettings.getSetting('info-record-first-use')
        if (!infoRecordShowed) {
          this.$buefy.dialog.confirm(messageFirstUse)
          await ServiceSettings.setSetting('info-record-first-use', true)
        } else {
          this.$buefy.dialog.confirm(messagePermanentUse)
        }
      } else if (action == 'pause') {
        await ServiceMeetings.pauseRecording(this.meeting.key)
        this.instance.recording = 'paused'

        // First use of stop
        const infoRecordShowed = await ServiceSettings.getSetting('info-record-first-pause')
        if (!infoRecordShowed) {
          this.$buefy.dialog.confirm({
            title: 'Gravação em pausa',
            message: `
              <div class="is-size-3 has-margin-bottom">A sua gravação está em pausa.</div>
              <div class="has-margin-top">Quando retomar, a plataforma irá criar um novo capítulo na gravação,
              que poderá personalizar, definindo um título / subtítulo para que seja adicionado um separador
                no vídeo final.</div>
              <div>Esta configuração é opcional e poderá ser realizada durante a própria gravação,
                acedendo ao separador Gravações.</div>
              <div class="has-text-tiny has-margin-top">Esta mensagem não voltará a ser apresentada.</div>
            `,
            confirmText: 'Continuar',
            cancelText: 'Ver gravações',
            type: 'is-dark',
            onConfirm: () => {
              //
            },
            onCancel: () => {
              this.setView('recordings')
            },
          })
          // Do not show this msg again
          await ServiceSettings.setSetting('info-record-first-pause', true)
        }
      } else if (action == 'stop') {
        await ServiceMeetings.stopRecording(this.meeting.key)
        this.instance.recording = 'stopped'

        // First use of stop
        const infoRecordShowed = await ServiceSettings.getSetting('info-record-first-stop')
        if (!infoRecordShowed) {
          this.$buefy.dialog.confirm({
            title: 'Gravação terminada',
            message: `
              <div class="is-size-3 has-margin-bottom">Acabou de terminar a sua primeira gravação!</div>
              <div class="has-margin-top">Para que a plataforma possa disponibilizar o vídeo final,
                entre no separador <strong>Gravações</strong>, reveja e edite os metadados da nova gravação e carregue
                em <strong>Compilar</strong>.</div>
              <div class="has-margin-top">O processo de compilação irá criar um vídeo com o título e subtítulo
                que indicou, a personalização dos capítulos que definiu e com a marca institucional UC.</div>
              <div>Após a compilação (que poderá demorar algum tempo),
                poderá visualizar o vídeo e criar um link para partilhar com outros utilizadores.</div>
              <div class="has-text-tiny has-margin-top">Esta mensagem não voltará a ser apresentada.</div>
            `,
            confirmText: 'Continuar',
            cancelText: 'Ver gravações',
            type: 'is-dark',
            onConfirm: () => {
              //
            },
            onCancel: () => {
              this.setView('recordings')
            },
          })
          // Do not show this msg again
          await ServiceSettings.setSetting('info-record-first-stop', true)
        }
      }
    },

    async updateRoles(roles, keepForMeetings = true) {
      const response = await ServiceMeetings.changeRoles(this.meeting.key, roles, true, keepForMeetings)
      this.instance.roles = response.instance
      this.instance.meeting_roles = response.meeting
      for (const [key, value] of Object.entries(response.attendees)) {
        const attendee = this.attendees[key]
        if (attendee) attendee.roles = value
      }

      this.reloadPage()
    },
    setModeWebinar(keepForMeetings = true) {
      const setRoles = ['show_to_everyone']
      if (process.env.VUE_APP_KEY !== 'ucdigitaldesk') {
        setRoles.push('sharescreen_allowed')
      }

      const roles = {}
      for (let key of setRoles) {
        roles[ServiceMeetings.getRole(key)] = false
      }
      this.updateRoles(roles, keepForMeetings)
    },
    setModeMeeting(keepForMeetings = true) {
      const setRoles = ['show_to_everyone']
      if (process.env.VUE_APP_KEY !== 'ucdigitaldesk') {
        setRoles.push(...['audio_allowed', 'camera_allowed', 'sharescreen_allowed'])
      }

      const roles = {}
      for (let key of setRoles) {
        roles[ServiceMeetings.getRole(key)] = true
      }
      this.updateRoles(roles, keepForMeetings)
    },

    requestWebinarChange() {
      this.$buefy.dialog.confirm({
        confirmText: 'Alterar para modo Webinar',
        type: 'is-primary',
        cancelText: 'Manter',
        title: 'Modo Webinar',
        message: `<div>Esta aula tem um número elevado de estudantes inscritos. Em sessões com
        muitos participantes, em modo vídeo-conferência, os dispositivos de alguns utilizadores podem
        não cumprir os requisitos minimos necessários para garantir um correto funcionamento da plataforma.</div>
        <div class="has-margin-top">Desta forma, se possível, <strong>sugerímos</strong> que dinamize esta sessão
        em <strong>modo Webinar</strong>.</div>
        <div class="has-margin-top has-text-small">Neste modo, um participante (não orador) consegue ouvir os restantes
        <span class="has-text-primary">(novidade após a versão 0.5.4)</span> mas apenas o vídeo dos oradores
        será partilhado por todos. Caso pretenda que o vídeo de um participante (não orador) seja visto por
        todos, poderá promovê-lo a Orador.</div>`,
        onConfirm: this.setModeWebinar,
      })
    },
    informUserOfWebinar() {
      this.$buefy.dialog.alert({
        title: `Modo Webinar`,
        message: `<div>Esta sessão está a ser dinamizada em modo Webinar. Neste modo poderá ouvir todos
          os participantes <span class="has-text-primary">(novidade após a versão 0.5.4)</span> mas verá apenas
          o vídeo dos oradores.</div><div class="has-margin-top has-text-small">Para que os restantes
          participantes vejam o seu vídeo, o responsável pela sessão precisará promovê-lo a orador.</div>`,
        type: 'is-dark',
        ariaRole: 'alertdialog',
      })
    },

    startMeeting(config) {
      this.loading = true
      this.$store.commit('checkWebsocket', {
        name: 'join-meeting',
        callback: this.startMeetingCore,
        callbackConfig: config || {},
      })
    },
    async startMeetingCore(config) {
      while (this.startMeetingLock) await utils.sleep(50)

      if (this.isRunning) {
        console.info(`Meeting ${this.meeting.key} already running`)
        return
      }

      this.startMeetingLock = true
      this.loading = true

      try {
        if (!this.startWithHD) this.startWithHD = !!localStorage.getItem('device.video.hd')

        let joinResponse = null
        try {
          joinResponse = await ServiceMeetings.joinMeeting({
            key: this.meeting.key,
            connectionId: config.connectionId,
            rejoining: false,
            withHD: this.startWithHD,
            asExternal: config.asExternal,
            externalDescription: config.externalDescription,
            externalAutoAccept: config.externalAutoAccept,
          })
        } catch (error) {
          const firstError = utils.errors(error).get()
          if (!firstError) {
            console.error('Failed to join', error)
          } else if (firstError.key == 'WaitingToBeAccepted') {
            this.waitingToBeAccepted = true
            console.info(`Waiting to be accepted into ${this.meeting.key}`)
          } else if (firstError.key == 'BannedFromMeeting') {
            this.banJoin(firstError.message)
            console.info(`Banned to join into ${this.meeting.key}`)
          } else if (firstError.key == 'RejectedAttendee') {
            this.rejectedToJoin()
            console.info(`Rejected to join into ${this.meeting.key}`)
          } else if (firstError.key == 'MeetingBlocked') {
            this.meeting.unlock_in = firstError.unlock_in
            console.info(`Meeting ${this.meeting.key} is locked until ${this.meeting.unlock_in}`)
          } else if (firstError.key == 'MeetingNotRunning' || firstError.key == 'MeetingJustEnded') {
            this.meeting.running = false
            console.info(`Meeting ${this.meeting.key} not running`)
          } else {
            console.error(`Failed to join: ${firstError.key}`, error)
          }
        }

        if (!ServiceMeetings.withRole(this.meeting.roles, 'audio_allowed')) this.startWithAudio = false
        if (!ServiceMeetings.withRole(this.meeting.roles, 'camera_allowed')) this.startWithCamera = false

        if (!joinResponse || !joinResponse.attendee) {
          console.debug('Failed to join meeting')

          if (process.env.VUE_APP_KEY == 'ucmeetingscreen') {
            this.$router.push({ name: 'meetingRoom' })
          }
          return
        }

        if (this.meetingSubscription) {
          ServiceMeetings.unsubscribe(this.meeting.key)
          this.meetingSubscription = null
        }

        this.isRunning = true
        this.$store.dispatch('setContext', { code: 'in-meeting', key: this.meeting.key })
        this.startedDate = Dates.now()
        this.startTimer()

        this.attendee = joinResponse.attendee
        this.instance = joinResponse.instance
        this.withJanus = !this.instance.external
        this.setAudios(joinResponse.audios, 'new')

        if (this.withJanus) {
          if (!this.reloadPageEventListener) {
            this.reloadPageEventListener = debounce(this.reloadPage, 250)
            window.addEventListener('resize', this.reloadPageEventListener)
          }
          if (!this.meetingTimer) {
            this.meetingTimer = setInterval(this.reloadPage, 10 * 1000)
          }
          if (this.networkIssues && !this.networkIssues.timer) {
            this.networkIssues.timer = setInterval(this.checkLostPackets, 5 * 1000)
          }
          this.buildMediaOptions()
        }

        // Add some utilities
        this.instance.withRole = function(key) {
          const keyRef = ServiceMeetings.getRole(key)
          for (const [key, value] of Object.entries(this.roles)) {
            if (value && key === keyRef) return true
          }
          return false
        }
        this.attendee.withRole = function(key) {
          return ServiceMeetings.withRole(this.roles, key)
        }

        this.setAttendees([{ instance_key: this.instance.key, attendees: joinResponse.attendees }], false)
        console.debug('Meeting with attendees', this.attendees)

        if (this.setChat) {
          const chat = this.meeting.chat || this.meeting.context.chat
          if (chat) {
            chat.title = this.instance.title
            this.setChat(chat)
          }
        }
        this.waitingToBeAccepted = false
        if (this.withJanus) this.joinJanus()

        if (this.attendee.withRole('can_accept')) {
          this.waitingAttendees = joinResponse.waiting_attendees
          console.debug('Meeting with waiting attendees', this.waitingAttendees)
        }

        if (this.withJanus) {
          const withDebug = localStorage.getItem('fw-debug')
          this.updateAllStreamStatsInterval = setInterval(
            () => {
              this.updateAllStreamStats(withDebug)
            },
            withDebug ? 2000 : 5000
          )
        }

        this.syncAppsData()

        if (this.withJanus) {
          if (this.asWebinar && !this.attendee.withRole('show_to_everyone')) {
            this.informUserOfWebinar()
          }
          if (!this.attendee.withRole('audio_allowed')) {
            this.showAudioLockedModal = true
          }
        }
      } finally {
        this.loading = false
        this.startMeetingLock = false
        if (config.callback) config.callback()
      }
    },

    rejectedToJoin(data) {
      this.waitingToBeAccepted = false

      if (!data.title) data.title = 'O seu pedido foi rejeitado'
      this.enterRequestRejected = data
    },
    banJoin(message = null) {
      this.rejectedToJoin({ message: message, title: 'Foi bloqueado para esta meeting', blocked: true })
    },

    async leaveMeetingCore(action = null, rejectData = null) {
      if (this.classSessionSubscription) {
        this.classSessionSubscription.destroy()
        this.classSessionSubscription = null
      }

      if (this.timerInterval) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
      if (this.updateAllStreamStatsInterval) {
        clearInterval(this.updateAllStreamStatsInterval)
        this.updateAllStreamStatsInterval = null
      }

      if (this.reloadPageEventListener) {
        window.removeEventListener('resize', this.reloadPageEventListener)
        this.reloadPageEventListener = null
      }
      if (this.meetingTimer) {
        clearInterval(this.meetingTimer)
        this.meetingTimer = null
      }
      if (this.networkIssues.timer) {
        clearInterval(this.networkIssues.timer)
        this.networkIssues.timer = null
      }

      if (this.isRunning) {
        this.isRunning = false
        this.withJanus = false
      }
      if (rejectData) {
        this.rejectedToJoin(rejectData)
      }

      if (this.instance || this.waitingToBeAccepted) {
        console.debug('Leaving meeting...')

        if (this.instance) {
          await this.leaveJanus()
          await this.$store.dispatch('setUser')
        }

        if (action === 'end') {
          await ServiceMeetings.endMeeting(this.meeting.key)
        } else if (process.env.VUE_APP_KEY != 'ucmeetingscreen' && (action === 'leave' || this.waitingToBeAccepted)) {
          try {
            await ServiceMeetings.leaveMeeting(this.meeting.key)
          } catch (error) {
            const firstErrorKey = utils.errors(error).getKey()
            if (firstErrorKey == 'NotInMeeting') {
              console.info(`Already left meeting ${this.meeting.key}`)
            } else {
              console.error(`Failed to leave meeting ${this.meeting.key}`, error)
            }
          }
        }

        if (this.stopDummyAudio) this.stopDummyAudio()
        await ServiceStorage.sendLogsToBackend(true)

        if (this.leaveCallback) this.leaveCallback()
        await this.resetDefaultData(Boolean(rejectData))
      } else {
        await ServiceStorage.sendLogsToBackend(true)
      }
    },

    async leaveMeetingOnEnd() {
      if (this.waitingToBeAccepted) this.rejectedToJoin({ title: 'A sala foi fechada' })
      else await this.leaveMeetingCore()
    },

    leaveMeeting() {
      var self = this
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: `<div class="is-size-4">Pretende sair da reunião?</div>`,
        indefinite: true,
        queue: false,
        confirmText: 'Sair',
        cancelText: 'Cancelar',
        canCancel: true,
        onConfirm: () => {
          self.leaveMeetingCore('leave')
        },
      })
    },
    endMeeting() {
      // TODO: Send request to clients to end the meeting from their side
      var self = this
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: `Pretende terminar a reunião para todos os participantes?`,
        indefinite: true,
        queue: false,
        confirmText: 'Terminar',
        cancelText: 'Cancelar',
        canCancel: true,
        onConfirm: () => {
          self.leaveMeetingCore('end')
        },
      })
    },

    startTimer() {
      this.timerInterval = setInterval(() => {
        this.duration = Dates.convertMillisecondsToDuration(Dates.now().diff(this.startedDate))
      }, 1000)
    },

    buildBitrateStats(config, stats, bytes, withStats) {
      const vars = config.vars

      const newQualityLimitationReason = stats.qualityLimitationReason !== 'none' ? stats.qualityLimitationReason : null
      if (newQualityLimitationReason !== config.qualityLimitationReason) {
        config.qualityLimitationReason = newQualityLimitationReason
      }

      if (!withStats) return

      vars.bsnow = bytes
      vars.tsnow = stats.timestamp

      if (stats.frameWidth && stats.frameWidth !== config.width) config.width = stats.frameWidth
      if (stats.frameHeight && stats.frameHeight !== config.height) config.height = stats.frameHeight

      if (vars.tsbefore) {
        // Calculate bitrate
        let timePassed = vars.tsnow - vars.tsbefore
        if (this.isSafari) timePassed = timePassed / 1000
        let bitRate = Math.round(((vars.bsnow - vars.bsbefore) * 8) / timePassed)
        if (this.isSafari) bitRate = parseInt(bitRate / 1000)
        config.value = `${bitRate} kbits/sec`
      }

      vars.bsbefore = vars.bsnow
      vars.tsbefore = vars.tsnow
    },
    async updateAllStreamStats(withStats) {
      await this.updateSelfStreamStats(withStats)

      for (let pod of this.activePods) {
        const video = pod.video
        if (video) {
          await this.updateStreamStats(video, withStats)
        }
      }

      if (!withStats) return

      for (let pod of this.activePods) {
        const statsEl = document.querySelector(`#${pod.id} .stats`)
        if (!statsEl) continue

        const video = pod.video
        if (video) {
          const bitrate = video.bitrate
          statsEl.innerHTML = `
            ${bitrate.value || 0} / ${bitrate.width || 0}x${bitrate.height || 0}
            ${video.substream || 0} / ${video.temporal || 0} / ${video.simulcastLevel}`
        } else if (statsEl.innerHTML) {
          statsEl.innerHTML = ''
        }
      }
    },
  },
}
