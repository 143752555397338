<template>
  <editor-content class="max-w-3xl" :editor="editor" />
</template>

<script>
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import CodeBlock from '@tiptap/extension-code-block'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Underline from '@tiptap/extension-underline'
import OrderedList from '@tiptap/extension-ordered-list'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import { Node, mergeAttributes } from '@tiptap/core'
import Link from '@tiptap/extension-link'
const MetaParagraph = Node.create({
  name: 'meta',
  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  content: 'inline*',
  group: 'block',
  defining: true,
  priority: 1000,
  addAttributes() {
    return {
      class: {
        default: 'meta',
        rendered: true,
        parseHTML() {
          return [
            {
              tag: 'p',
              attrs: { class: 'meta' },
            },
          ]
        },
      },
    }
  },
  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addCommands() {
    return {
      setMetaStyle: () => ({ commands }) => {
        return commands.setNode(this.name)
      },
    }
  },
})
export default {
  components: {
    EditorContent,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let self = this
    return {
      editor: new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: 'Escreva algo...',
          }),
          Underline,
          BulletList,
          ListItem,
          CodeBlock,
          OrderedList,
          Superscript,
          Subscript,
          MetaParagraph,
          Link.configure({
            openOnClick: false,
          }),
        ],
        editable: this.editable,
        content: this.content,
        autofocus: true,
        onFocus({ editor, event }) {
          //console.log('editor focused', editor, event)
          self.$emit('focus', { editor, event })
        },
        onUpdate({ editor }) {
          self.$emit('content-changed', editor.getHTML())
        },
        onSelectionUpdate({ editor }) {
          self.emitCurrentStyles(editor)
        },
      }),
    }
  },
  methods: {
    emitCurrentStyles(editor) {
      //console.log('editor SELECTION', editor)
      let currentStyle = 'paragraph'
      if (editor.isActive('heading', { level: 1 })) {
        currentStyle = 'h1'
      } else if (editor.isActive('heading', { level: 2 })) {
        currentStyle = 'h2'
      } else if (editor.isActive('heading', { level: 3 })) {
        currentStyle = 'h3'
      } else if (editor.isActive('meta')) {
        currentStyle = 'meta'
      } else if (editor.isActive('codeBlock')) {
        currentStyle = 'codeblock'
      }
      this.$emit('selection-update', {
        bold: editor.isActive('bold'),
        italic: editor.isActive('italic'),
        underline: editor.isActive('underline'),
        strike: editor.isActive('strike'),
        subscript: editor.isActive('subscript'),
        superscript: editor.isActive('superscript'),
        bulletlist: editor.isActive('bulletList'), //TODO: check if this is correct
        orderedlist: editor.isActive('orderedList'),
        style: currentStyle,
      })
    },
    execCommand(cmd) {
      console.log('execCommand', cmd)
      if (cmd == 'bold') {
        this.editor
          .chain()
          .focus()
          .toggleBold()
          .run()
      } else if (cmd == 'italic') {
        this.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()
      } else if (cmd == 'underline') {
        this.editor
          .chain()
          .focus()
          .toggleUnderline()
          .run()
      } else if (cmd == 'strike') {
        this.editor
          .chain()
          .focus()
          .toggleStrike()
          .run()
      } else if (cmd == 'subscript') {
        this.editor
          .chain()
          .focus()
          .toggleSubscript()
          .run()
      } else if (cmd == 'superscript') {
        this.editor
          .chain()
          .focus()
          .toggleSuperscript()
          .run()
      } else if (cmd == 'orderedlist') {
        this.editor
          .chain()
          .focus()
          .toggleOrderedList()
          .run()
      } else if (cmd == 'bulletlist') {
        this.editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()
      } else if (cmd == 'codeblock') {
        this.editor
          .chain()
          .focus()
          .toggleCodeBlock()
          .run()
      } else if (cmd == 'h1') {
        console.log('h1')
        this.editor
          .chain()
          .focus()
          .toggleHeading({ level: 1 })
          .run()
      } else if (cmd == 'h2') {
        this.editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run()
      } else if (cmd == 'h3') {
        this.editor
          .chain()
          .focus()
          .toggleHeading({ level: 3 })
          .run()
      } else if (cmd == 'paragraph') {
        this.editor
          .chain()
          .focus()
          .setParagraph()
          .run()
      } else if (cmd == 'meta') {
        this.editor
          .chain()
          .focus()
          .setMetaStyle()
          .run()
      } else if (cmd == 'unsetlink') {
        this.editor
          .chain()
          .focus()
          .unsetLink()
          .run()
      } else if (cmd == 'setlink') {
        const previousUrl = this.editor.getAttributes('link').href
        //const url = window.prompt('URL', previousUrl)
        this.$buefy.dialog.prompt({
          message: 'URL',
          inputAttrs: {
            value: previousUrl,
          },
          onConfirm: url => {
            // cancelled
            if (url === null) {
              return
            }

            // empty
            if (url === '') {
              this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()

              return
            }

            // update link
            this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: url })
              .run()
          },
        })
      } else {
        console.error('Command not implemented: ' + cmd)
      }
      this.emitCurrentStyles(this.editor)
    },
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  @apply w-full bg-white;
}
.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
    list-style: initial;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-position: outside;
  }

  ul li {
    list-style-type: disc;
    padding-left: 10px;
  }

  ol li {
    padding-left: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  p.meta {
    font-size: 0.75rem;
    font-weight: 700;
    color: #616161;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1rem;
    font-weight: 700;
  }
  a {
    color: #0bb470;
    text-decoration: underline;
  }
}
</style>
