<template>
  <div
    class="bg-white option-cards"
    :class="{
      'rounded-xl': size === 'big',
      'rounded-md': size !== 'big',
      disabled: disabled,
    }"
  >
    <button
      v-for="(option, o) in options"
      :key="'option_' + o"
      class="fw-option w-full text-left flex group transition duration-150 items-center gap-3"
      :class="{
        'rounded-t-xl': o === 0 && size === 'big',
        'rounded-b-xl': o === options.length - 1 && size === 'big',
        'rounded-t-md': o === 0 && size !== 'big',
        'rounded-b-md': o === options.length - 1 && size !== 'big',
        'border-b border-gray-100': o !== options.length - 1,
        selected: multiple ? selected.includes(option.value) : option.value === selected,
        'px-3 py-2 lg:text-md font-semibold': size === 'big',
        'px-3 py-2 text-md font-semibold': size === 'small',
        'opacity-70 hover:opacity-90 hover:text-primary cursor-pointer ': !disabled && !option.disabled,
        'opacity-50 cursor-not-allowed': disabled || option.disabled,
      }"
      :disabled="disabled || option.disabled"
      @click.self="clickSelect(option)"
    >
      <div class="check border-2 rounded-2xl flex-shrink-0" @click.self="clickSelect(option)"></div>
      <div class="flex-1" @click.self="clickSelect(option)">
        {{
          option.text == null || typeof option.text == 'undefined'
            ? 'Sem nome'
            : typeof option.text === 'string'
            ? option.text
            : option.text[language]
        }}
      </div>
      <div v-if="option.right_text" class="text-gray-500 font-medium" @click.self="clickSelect(option)">
        {{ option.right_text }}
      </div>
      <fw-button
        v-if="showSecondaryTrailButton"
        type="xlight"
        @click.native="$emit('secondary-trail-button-clicked', option.value)"
      >
        {{ secondaryTrailButtonText }}
      </fw-button>
      <fw-button v-if="showTrailButton" type="xlight" @click.native="$emit('trail-button-clicked', option.value)">
        {{ trailButtonText }}
      </fw-button>
    </button>
  </div>
</template>

<script>
import FwButton from '../buttons/FwButton.vue'
export default {
  name: 'OptionsCard',
  components: { FwButton },
  props: {
    secondaryTrailButtonText: {
      type: String,
      default: 'Ver mais',
    },
    showSecondaryTrailButton: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      default: function() {
        return []
      },
    },
    language: {
      type: String,
      default: 'pt',
    },
    selected: {
      type: [String, Number, Array],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'big',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trailButtonText: {
      type: String,
      default: 'Customizar',
    },
    showTrailButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickSelect(option) {
      if (this.disabled || option.disabled) return

      this.$emit('selected', option.value)
    },
  },
}
</script>

<style scoped>
.fw-option.selected .check {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 80%;
}

.fw-option .check {
  border-color: #30a898;
  height: 16px;
  width: 16px;
}
.option-cards.disabled {
  @apply cursor-not-allowed;
}
</style>
