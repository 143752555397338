<template>
  <div class="max-w-3xl mx-auto">
    <div v-if="showNavigation" class="py-3 font-semibold text-gray-400 text-xl flex items-center gap-4">
      <fw-button type="light" size="sm" @click.native="goBack()">
        <fw-icon-arrow-left class="w-4 h-4" />
      </fw-button>
      Publicação
    </div>
    <div class="bg-white rounded-xl">
      <div v-if="post == null && !loading" class="text-gray-300 text-center py-14 rounded-b-xl">
        Publicação não encontrada
      </div>
      <div v-else-if="loading" class="text-gray-300 text-center py-14 rounded-b-xl flex justify-center">
        <fw-icon-loading class="w-8 h-8" />
      </div>
      <div v-else>
        <BlockPost
          :post="post"
          :users="users"
          :comments="comments"
          :show-comment-action="true"
          :hide-comment-button="true"
          class="border-b border-gray-200"
          @new-comment="newComment()"
          @updated="$emit('saved', $event)"
          @edit="editPost($event)"
          @deleted="deletePost($event)"
        />
        <BlockNewPostPlaceholder
          v-if="false"
          type="comment"
          :post="post"
          :user="me"
          class="border-b border-gray-200"
          @create="newComment()"
        />
        <BlockPost
          v-for="(comment, c) in comments"
          :key="comment.key"
          :post="comment"
          :users="users"
          :show-comment-action="false"
          type="comment"
          :class="{ 'border-b border-gray-200': c != comments.length - 1 }"
          @new-comment="newComment()"
          @edit="editPost($event)"
          @deleted="deletePost($event)"
        />
        <BlockPagination
          v-if="pagination.totalPages > 1"
          :per-page="pagination.limit"
          :total="pagination.totalResults"
          :total-pages="pagination.totalPages"
          :current.sync="pagination.page"
          @page-changed="pageChanged($event)"
        />
      </div>
    </div>
    <fw-modal
      v-if="showModalEditPost"
      :active.sync="showModalEditPost"
      :can-cancel="true"
      paddingless
      height-fit-screen
      size="min"
      width="42rem"
      @close="close"
    >
      <template #default>
        <BlockEditPost
          :collection-key="collectionKey"
          :parent-post-key="parentPostKey"
          :post="editing"
          :type="editingType"
          @saved="postSaved($event)"
          @comment-saved="commentSaved($event)"
          @close="close"
        />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import BlockPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockPost'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import BlockNewPostPlaceholder from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockNewPostPlaceholder'
import BlockEditPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'
export default {
  name: 'PanelPosts',
  components: {
    BlockPost,
    BlockPagination,
    BlockNewPostPlaceholder,
    BlockEditPost,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    collectionKey: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'post',
    },
    showNavigation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      post: null,
      comments: [],
      users: {},
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25,
      },
      loading: true,
      showModalEditPost: false,
      editing: null,
      editingType: null,
      loadingComments: false,
    }
  },
  computed: {
    allowComments() {
      return this.post && this.post.validations.can_comment
    },
    parentPostKey() {
      return this.post.key
    },
    me() {
      return this.$store.getters.getUser
    },
  },
  mounted() {
    this.loadPost()
    this.loadComments()
  },
  methods: {
    async pageChanged(page) {
      console.log('pageChanged', page)
      this.pagination.page = page
      await this.loadComments()
    },
    goBack() {
      this.$router.go(-1)
    },
    postsPageChanged(page) {
      console.log('postsPageChanged', page)
    },
    newPost() {
      console.log('newPost')
      this.editing = null
      this.showModalEditPost = true
      this.editingType = 'post'
    },
    editPost(post) {
      console.log('editPost', post)
      this.editing = post
      this.showModalEditPost = true
      this.editingType = post.type
    },
    newComment() {
      console.log('newComment')
      this.editing = null
      this.showModalEditPost = true
      this.editingType = 'comment'
    },
    editComment(post) {
      console.log('editComment', post)
      this.editing = post
      this.showModalEditPost = true
      this.editingType = 'comment'
    },
    close() {
      this.showModalEditPost = false
    },
    updatePost(post) {
      console.log('updatePost', post)
    },
    deletePost(post) {
      if (this.post && this.post.key == post.key) {
        this.$emit('reset-route')
      } else {
        this.comments = this.comments.filter(p => p.key != post.key)
      }
      this.$emit('deleted', post)
    },
    async loadPost() {
      this.loading = true
      try {
        let data = await ServicePosts.getPost(this.id)
        console.log('loadPost :>> ', data)
        let users = data.users
        //add me info to users
        if (this.me) {
          users[this.me.key] = this.me
        }
        this.users = users
        this.post = data.post
      } finally {
        this.loading = false
      }
    },
    async loadComments() {
      this.loadingComments = true
      try {
        let data = await ServicePosts.getPostComments(this.id, this.pagination.page, this.pagination.limit)
        console.log('loadComments :>> ', data)
        this.pagination = data.pagination
        this.comments = data.comments
        let users = { ...this.users, ...data.users }
        if (this.me) {
          users[this.me.key] = this.me
        }
        this.users = users
      } finally {
        this.loadingComments = false
      }
    },
    postSaved(post) {
      console.log('savePost', post)
      if (this.editing != null) {
        if (this.post.key == post.key) {
          this.post = post
        } else {
          //is a comment
          this.comments = this.comments.map(p => {
            if (p.key == post.key) {
              return post
            }
            return p
          })
        }
      }
      this.$emit('saved', post)
      this.close()
    },
    commentSaved(post) {
      console.log('comment saved', post)
      if (this.post.key == post.key) {
        this.post = post
      } else {
        this.comments.find(p => p.key == post.key)
          ? (this.comments = this.comments.map(p => (p.key == post.key ? post : p)))
          : this.comments.unshift(post)
      }
      this.close()
    },
  },
}
</script>
