import ViewCourse from './views/ViewCourse'
import ViewCourseEdition from './views/ViewCourseEdition'
//import ViewCourses from './views/ViewCourses'

export default [
  /*{
    path: '/courses',
    name: 'courses',
    component: ViewCourses,
  },*/
  {
    path: '/course/:key',
    name: 'course',
    component: ViewCourse,
  },
  {
    path: '/course/:courseKey/edition/:editionKey',
    name: 'course-edition',
    component: ViewCourseEdition,
  },
  {
    path: '/course/:courseKey/edition/:editionKey/:subpage/collection/:collectionId/:postId?',
    name: 'course-edition-post-collection',
    component: ViewCourseEdition,
  },
  {
    path: '/course/:courseKey/edition/:editionKey/:subpage',
    name: 'course-edition-subpage',
    component: ViewCourseEdition,
  },
  {
    path: '/course/:courseKey/edition/:editionKey/unit/:unitKey/:topicKey?',
    name: 'course-edition-unit',
    component: ViewCourseEdition,
  },
  {
    path: '/course/:courseKey/edition/:editionKey/unit/:unitKey/topic/:topicKey',
    name: 'course-edition-unit-topic',
    component: ViewCourseEdition,
  },
]
