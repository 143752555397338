<template>
  <fw-layout
    :full="view && view != 'dashboard'"
    :loading="loading"
    :notfound="!loading && course == null"
    :forbidden="!loading && forbidden"
    back-to="/"
  >
    <template #header-nav>
      <div class="flex gap-3 items-center">
        <div class="flex flex-col">
          <fw-heading size="md" marginless class="line-clamp-1">{{ course.title }}</fw-heading>
          <fw-label v-if="currentEdition" class="hidden md:flex" marginless>Edição {{ currentEdition.title }}</fw-label>
        </div>
        <fw-tag size="sm" type="primary" custom-class="px-3">
          Inscrito
        </fw-tag>
      </div>
    </template>

    <template v-if="isCourseEditionUnlocked" #main-sidebar>
      <SidebarCourseEdition
        :course="course"
        :units="units"
        :view="view"
        :edition="currentEdition"
        :progress="progress"
        :is-manager="isCourseManager"
        :classroom="classroom"
        :is-unlocked="isCourseEditionUnlocked"
        :active-class-id="activeClassId"
        :active-unit-id="unit ? unit.key : null"
        @go-to-modules="goToView('modules')"
        @set-active-class="activeClassId = $event"
        @set-active-edition="changeEdition($event)"
        @go-to-summary="goToView('summary')"
        @go-to-unit="goToView('unit', $event)"
      />
    </template>

    <template #main-content>
      <PanelDashboard
        v-if="!view || view == 'summary'"
        :course="course"
        :progress="progress"
        :edition="currentEdition"
        @sign-up="startSignup()"
        @go-to-unit="goToView('unit', $event)"
      />
      <PanelUnit
        v-else-if="(view == 'unit' || view == 'modules') && unit"
        :key="unit ? unit.key : 'unit'"
        :course="course"
        :unit="unit"
        :is-manager="isCourseManager"
        :progress="progress"
        :active-class-id="activeClassId"
        @toggle-topic-progress="toggleTopicProgress"
      />
      <PanelAbout v-if="view == 'about'" :course="course" :progress="progress" :edition="currentEdition" />
    </template>

    <template #modals>
      <b-modal
        :width="850"
        class="rounded-buefy-modal"
        :can-cancel="true"
        :active.sync="openEnrollmentModal"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <ModalCourseEnrollment
          :course="course"
          :edition="currentEdition"
          @close="closeSignupModal"
          @success="loadCourse()"
        ></ModalCourseEnrollment>
      </b-modal>
    </template>
  </fw-layout>
</template>

<script>
import remove from 'lodash/remove'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import ModalCourseEnrollment from '@/fw-modules/fw-core-vue/nondegree-courses/components/modals/ModalCourseEnrollment'
import SidebarCourseEdition from '@/fw-modules/fw-core-vue/nondegree-courses/components/sidebars/SidebarCourseEdition'
import PanelAbout from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelAbout'
import PanelDashboard from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelDashboard'
import PanelUnit from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelUnit'
import Vue from 'vue'

export default {
  components: {
    ModalCourseEnrollment,
    SidebarCourseEdition,
    PanelUnit,
    PanelAbout,
    PanelDashboard,
  },

  data() {
    return {
      course: null,
      unit: {},
      units: null,
      loading: true,
      forbidden: false,
      progress: {},
      classroom: {},
      answersInstance: null,
      activeEditionKey: this.$route.query.edition || null,
      activeClassId: null,
      openEnrollmentModal: false,
      view: this.$route.params.subpage,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },

    courseKey() {
      return this.$route.params.key
    },
    currentEditionKey() {
      return this.course && this.course.current_edition ? this.course.current_edition.edition_key : null
    },
    currentEdition() {
      if (this.course != null && this.course['editions'] && (this.activeEditionKey || this.currentEditionKey)) {
        return this.activeEditionKey != null
          ? this.course['editions'][this.activeEditionKey]
          : this.course['editions'][this.currentEditionKey]
      } else {
        return null
      }
    },
    edition() {
      return this.course && this.course.current_edition ? this.course.current_edition : {}
    },

    // Checks
    isMobile() {
      return window.innerWidth < 640
    },
    isCourseEditionUnlocked() {
      return this.isCourseManager || this.isEnrollmentDefinitive
    },
    isCourseManager() {
      if (
        this.user &&
        this.course &&
        this.course.user &&
        (this.course.user.role == 'leader' || this.course.user.role == 'manager')
      ) {
        return true
      }
      return false
    },
    isEnrollmentDefinitive() {
      return this.course.dates && this.course.dates.is_enrolled_current_edition
      /*if (this.course.enrolled_in && this.currentEdition) {
        let currentEditionKey = this.activeEditionKey ?? this.currentEdition.key
        return this.course.enrolled_in.includes(currentEditionKey)
      } else {
        return false
      }*/
    },

    // Helpers
    topicsAsList() {
      let topics = []
      if (this.unit.topics) {
        for (let topicKey of Object.keys(this.unit.topics)) {
          topics.push(this.unit.topics[topicKey])
        }
      }
      topics.sort((a, b) => a.index - b.index)
      return topics
    },
  },

  watch: {
    activeEditionKey(newValue) {
      if (newValue != null) {
        Vue.set(this.course, 'dates', this.courseDates(this.course))
        this.loadCourseEditon()
      }
    },
  },

  mounted() {
    this.loadCourse()
  },

  created() {
    this.$store.dispatch('startTimer', true)
  },

  beforeDestroy() {
    this.$store.dispatch('startTimer', false)
  },

  methods: {
    changeEdition(editionKey) {
      this.activeEditionKey = editionKey
      this.loadCourseProgress()
      this.view = 'dashboard'
      this.$router.push({
        name: 'nondegree-course-edition',
        params: { key: this.$route.params.key, editionKey: editionKey },
      })
    },

    // TODO: Change all this to the backend
    courseDates(course) {
      console.log('courseDates!')
      let selectedCourseKey = this.activeEditionKey
      let currentEdition =
        selectedCourseKey != null && course['editions'][selectedCourseKey]
          ? course['editions'][selectedCourseKey]
          : course.current_edition != null && course['editions'][course.current_edition.edition_key]
          ? course['editions'][course.current_edition.edition_key]
          : null
      var result = {
        is_enrolled_current_edition: false,
        is_current_edition_open: false, //enable/disable open button
        is_signup_open: false, //enable/disable signup button
        signup_passed: false,
        can_signup: true, //are there enough vacancies?
        next_signup_date: null, //date when next edition signup will be open
        signup_end_date: null, //when signup will be closed
        next_session_date: null, //date when next edition will start
        show_notification_button: false, //show notification button
        has_current_edition: false,
        is_active: false,
      }
      console.log('currentEdition', currentEdition)
      if (currentEdition != null) {
        console.log('enrolled in', course.enrolled_in)
        var now = new Date()
        var courseOpenDate = new Date(currentEdition.start_date)
        var courseEndDate = new Date(currentEdition.end_date)
        var courseSignupOpenDate = new Date(currentEdition.signup_start_date)
        var courseSignupEndDate = new Date(currentEdition.signup_end_date)
        result['has_current_edition'] = true

        result['is_preenrolled_current_edition'] =
          course.enrolled_in != null &&
          course.enrolled_in.length > 0 &&
          course.enrolled_in.findIndex(
            element => element['edition_key'] == currentEdition.key && element['enrollment_type'] == 'pre-admitted'
          ) >= 0

        result['is_enrolled_current_edition'] =
          course.enrolled_in != null &&
          course.enrolled_in.length > 0 &&
          course.enrolled_in.findIndex(
            element => element['edition_key'] == currentEdition.key && element['enrollment_type'] == 'definitive'
          ) >= 0

        result['is_current_edition_open'] = now >= courseOpenDate
        result['is_signup_open'] = now >= courseSignupOpenDate && now <= courseSignupEndDate
        result['is_active'] = now >= courseOpenDate && now <= courseEndDate
        result['can_signup'] =
          currentEdition.seats_total != null && currentEdition.seats_occupied != null
            ? currentEdition.seats_occupied < currentEdition.seats_total
            : true
        result['signup_passed'] = now >= courseSignupEndDate
        result['next_signup_date'] = this.$options.filters.formatDate(courseSignupOpenDate)
        result['signup_end_date'] = this.$options.filters.formatDate(courseSignupEndDate)
        result['next_session_date'] = this.$options.filters.formatDate(courseOpenDate)
        result['show_notification_button'] = !(
          result['is_enrolled_current_edition'] ||
          result['is_current_edition_open'] ||
          result['is_signup_open']
        )
      } else {
        result['show_notification_button'] = true
      }
      return result
    },

    // Control modals
    startSignup() {
      this.openEnrollmentModal = true
    },

    closeSignupModal() {
      this.openEnrollmentModal = false
    },

    async loadCourseEditon() {
      if (this.currentEdition != null) {
        try {
          this.units = null

          let currentEditionKey = this.$route.query.edition ?? this.currentEdition.key
          this.activeEditionKey = currentEditionKey
          let editionDetails = await ServiceAcademic.getCourseEditionDetails(this.courseKey, currentEditionKey)
          Vue.set(this, 'units', editionDetails.units)

          this.currentEdition.units = editionDetails.units
          this.currentEdition.unitsAsList = this.parseUnitsAsList(editionDetails.units)

          //this.units = editionDetails.units
          if (this.$route.params.subpage && this.$route.params.subpage == 'unit') {
            this.loadUnit(this.$route.params.unitId)
          } ///else { // } //load edition class information
          /*else if(this.$route.params.subpage && this.$route.params.subpage == 'about'){
            // ignore
          } else {
            //if (loadDefaultUnit) {
            var unitsKeys = Object.keys(editionDetails.units)
            if (unitsKeys.length > 0) {
              let url = '/nondegree/course/' + this.$route.params.key + '/unit/' + unitsKeys[0]
              this.loadUnit(unitsKeys[0])
              this.$router.push(url)
            }
          }*/ let classroom = {}
          if (this.course.enrolled_in.length > 0) {
            let enrolledEdition = this.course.enrolled_in.find(
              element => element['edition_key'] == this.currentEdition.key
            )
            if (enrolledEdition != null) {
              classroom = enrolledEdition.class
            }
          }
          this.classroom = classroom

          //console.log('editionDetails', editionDetails)
        } catch (e) {
          console.error(e)
        }
      }
    },

    // Load and manage data
    async loadCourse() {
      if (this.courseKey) {
        try {
          this.loading = true

          // Check if user can open course

          let course = await ServiceAcademic.getCourseDetails(this.courseKey)

          course['dates'] = this.courseDates(course)
          this.course = course
          console.log('loaded', this.course)

          //LOAD COURSE DETAILS
          this.loadCourseEditon()

          this.loadCourseProgress()
        } catch (e) {
          console.log(e)
        } finally {
          setTimeout(() => {
            this.loading = false
          }, 250)
        }
      }
    },

    loadUnit(unitId) {
      /*for (let unit of this.edition.units) {
        if (unit.key == unitId) {
          this.unit = unit
          break
        }
      }*/
      if (this.units != null && this.units[unitId]) {
        this.unit = this.units[unitId]
      } else if (this.units != null) {
        //find a default value
        let units = Object.values(this.units)
        if (units.length > 0) {
          this.unit = units[0]
        }
      }
      /*if (this.currentEdition) {
        console.log('===== LOADING UNIT =====', this.currentEdition, this.currentEdition.units[unitId])
        this.unit = this.currentEdition.units[unitId]
      }*/
    },

    // Manage views
    goToView(name, unitId = null) {
      this.view = name

      const reqCourseKey = this.$route.params.key
      const reqEditionKey = this.$route.params.editionKey

      let url = '/nondegree/course/' + reqCourseKey

      if (reqEditionKey) {
        url = url + `/edition/${reqEditionKey}`
      }

      if (!name || name == 'summary') {
        this.unit = {}
        url = url + '/summary'
      } else if (name == 'unit' && unitId) {
        url = url + '/unit/' + unitId
        this.loadUnit(unitId)
      } else if (name === 'modules' && this.currentEdition && this.units != null) {
        var unitsKeys = Object.keys(this.units)
        if (unitsKeys.length > 0) {
          unitId = unitsKeys[0]
          /*console.log('CURRENT EDITION', this.currentEdition)
        Object.entries(this.currentEdition).forEach(([key]) => {
          if (unitId == null) {
            unitId = key
          }
        })*/
          console.log(unitId)
          url = url + '/unit/' + unitId
          this.loadUnit(unitId)
        }
      }
      if (this.$router.currentRoute.path !== url) {
        this.$router.push({ path: url })
      }
    },

    // TODO: Change this to the backend
    async loadCourseProgress() {
      if (!this.$store.state.nonDegreeCoursesEditionProgress) this.$store.state.nonDegreeCoursesEditionProgress = {}
      if (!this.$store.state.nonDegreeCoursesEditionProgress[this.course.key]) {
        const progress = await ServiceSettings.getSetting(
          `nondegree-progress-course-${this.course.key}-${this.currentEdition.key}`
        )
        if (progress && progress.value) {
          this.$store.state.nonDegreeCoursesEditionProgress[
            this.course.key + '_' + this.currentEdition.key
          ] = JSON.parse(progress.value)
        }
      }
      this.progress = this.$store.state.nonDegreeCoursesEditionProgress[this.course.key + '_' + this.currentEdition.key]
    },

    // TODO: Change this to the backend
    async toggleTopicProgress(topicId) {
      const courseEditionProgressKey = `nondegree-progress-course-${this.course.key}-${this.currentEdition.key}`

      let courseProgress = this.$store.state.nonDegreeCoursesEditionProgress[
        this.course.key + '_' + this.currentEdition.key
      ] || {
        doneTopics: [],
        todoTopics: [],
        unitsDone: {},
        unitsTodo: {},
        totalTopics: 0,
      }

      // Update todo / done topics
      if (courseProgress.doneTopics.includes(topicId)) {
        remove(courseProgress.doneTopics, function(n) {
          return n == topicId
        })
      } else {
        courseProgress.doneTopics.push(topicId)
      }

      // Update topics
      courseProgress.todoTopics = []
      for (let unit of this.currentEdition.units) {
        // Init unit key
        courseProgress.unitsDone[unit.key] = 0
        courseProgress.unitsTodo[unit.key] = 0

        for (let topic of this.topicsAsList) {
          // Include just normal topics for evaluation
          if (topic.type == 'normal') {
            // Make sure we include all topics
            if (!courseProgress.doneTopics.includes(topic.key) && !courseProgress.todoTopics.includes(topic.key)) {
              courseProgress.todoTopics.push(topic.key)
              courseProgress.unitsTodo[unit.key]++
            }
            // Update counter
            if (courseProgress.doneTopics.includes(topic.key)) {
              courseProgress.unitsDone[unit.key]++
            }
          }
        }
      }

      // Update total
      courseProgress.totalTopics = courseProgress.doneTopics.length + courseProgress.todoTopics.length

      // Save progress
      await ServiceSettings.setSetting(courseEditionProgressKey, JSON.stringify(courseProgress))
      // Make sure Vue updates "progress" (set new object)
      this.progress = this.$store.state.nonDegreeCoursesEditionProgress[this.currentEdition.key] = Object.assign(
        {},
        courseProgress
      )
    },

    // Helpers
    parseUnitsAsList(unitsDict) {
      let units = []
      if (unitsDict) {
        units = Object.entries(unitsDict).map(([key, unit]) => {
          unit['key'] = key
          return unit
        })
      }
      units.sort((a, b) => a.index - b.index)
      return units
    },
  },
}
</script>
