import { render, staticRenderFns } from "./PanelAbout.vue?vue&type=template&id=ccc80684"
import script from "./PanelAbout.vue?vue&type=script&lang=js"
export * from "./PanelAbout.vue?vue&type=script&lang=js"
import style0 from "./PanelAbout.vue?vue&type=style&index=0&id=ccc80684&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PanelAbout.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports