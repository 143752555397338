<template>
  <div class="h-full flex flex-col">
    <div class="flex-1 flex flex-col min-h-0 overflow-y-auto px-4">
      <div class="mx-auto w-full max-w-3xl py-5">
        <div class="mb-3">
          <fw-heading size="h2" muted>{{ type == 'post' ? 'Atualizações' : 'Tópicos de discussão' }}</fw-heading>
        </div>
        <BlockNewPostPlaceholder
          v-if="collectionKey"
          type="post"
          :collection="collectionKey"
          :users="users"
          class="border-b border-gray-100"
          @create="newPost()"
        />
        <div v-if="loading" class="text-gray-300 text-center py-14 rounded-b-xl flex justify-center">
          <fw-icon-loading class="w-8 h-8" />
        </div>
        <div v-else-if="posts.length == 0" class="bg-white text-gray-300 text-center py-14 rounded-b-xl">
          Sem publicações
        </div>
        <div v-else>
          <a v-for="(post, p) in posts" :key="post.key" href @click.prevent="openPost(post)">
            <RecordPost
              minimal
              :post="post"
              :users="users"
              :class="{ 'mb-5': p != post.length - 1 }"
              :type="post.type"
            />
          </a>
        </div>
      </div>
    </div>
    <BlockPagination
      v-if="pagination.totalPages > 1"
      :per-page="pagination.limit"
      :total="pagination.totalResults"
      :total-pages="pagination.totalPages"
      :current.sync="pagination.page"
      @page-changed="changePage($event)"
    />
    <fw-modal
      v-if="showModalEditPost"
      :active.sync="showModalEditPost"
      :can-cancel="true"
      paddingless
      size="min"
      height-fit-screen
      @close="close"
    >
      <template #default>
        <BlockEditPost
          :collection-key="collectionKey"
          :context="'post'"
          :post="editing"
          :type="type"
          @saved="postSaved($event)"
          @close="close"
        />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import BlockNewPostPlaceholder from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockNewPostPlaceholder'
import RecordPost from '@/fw-modules/fw-core-vue/posts/components/records/RecordPost'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import BlockEditPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'

export default {
  name: 'PanelPosts',

  components: {
    RecordPost,
    BlockPagination,
    BlockNewPostPlaceholder,
    BlockEditPost,
  },

  props: {
    collectionKey: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'post',
    },
    collections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      posts: [],
      users: {},
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25,
      },
      loading: false,
      showModalEditPost: false,
      editing: null,
    }
  },
  mounted() {
    this.loadPosts()
  },
  methods: {
    changePage(page) {
      this.pagination.page = page
      this.loadPosts()
    },
    openPost(post) {
      this.$emit('open', post)
    },
    postsPageChanged(page) {
      console.log('postsPageChanged', page)
    },
    newPost() {
      this.editing = null
      this.showModalEditPost = true
    },
    editPost(post) {
      console.log('editPost', post)
      this.editing = post
      this.showModalEditPost = true
    },
    close() {
      this.showModalEditPost = false
    },
    updatePost(post) {
      console.log('updatePost', post)
    },
    deletePost(post) {
      this.posts = this.posts.filter(p => p.key != post.key)
    },
    async loadPosts() {
      console.log('loadPosts', this.collections, this.collectionKey)
      this.loading = true
      try {
        let data = null
        if (this.collections.length > 0) {
          data = await ServicePosts.getFeed(this.pagination.page, this.pagination.limit, this.collections, this.type)
        } else {
          data = await ServicePosts.getCollectionPosts(
            this.collectionKey,
            this.pagination.page,
            this.pagination.limit,
            this.type
          )
        }
        console.log('loadPosts :>> ', data)
        this.posts = data.posts
        this.users = data.users
        this.pagination = data.pagination
      } finally {
        this.loading = false
      }
    },
    postSaved(post) {
      console.log('savePost', post)
      if (this.editing) {
        this.posts = this.posts.map(p => {
          if (p.key == post.key) {
            return post
          }
          return p
        })
      } else {
        this.posts.unshift(post)
      }
      this.close()
    },
  },
}
</script>
