import ViewExamination from '@/fw-modules/fw-core-vue/exams/views/ViewExamination'

export default [
  {
    path: '/ex/:key',
    name: 'exams-examination',
    component: ViewExamination,
    meta: {},
  },
]
