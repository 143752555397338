<template>
  <b-modal
    :active="true"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div v-if="waitingAttendees.length" class="modal-card is-width-auto has-text-centered">
      <section class="modal-card-body">
        <div
          v-if="waitingAttendees.length > 1"
          class="has-margin-bottom-medium is-flex is-flex-right is-flex-align-center"
        >
          <b-button type="is-text" @click="rejectAll">Rejeitar todos</b-button>
          <b-button
            v-if="waitingAttendees.length > 1"
            class="has-margin-left"
            type="is-primary"
            outlined
            @click="acceptAll"
          >
            Aceitar todos {{ autoAccept && continueTimer ? '(' + countdown + ')' : '' }}
          </b-button>
        </div>
        <div class="columns is-multiline is-flex is-flex-justify-center">
          <div
            v-for="attendee in waitingAttendees"
            :key="attendee.key"
            class="has-background-light is-rounded is-auto has-margin max-w-xs min-w-xs py-5"
          >
            <div class="flex items-center justify-center">
              <UserAvatar size="md" :user="attendee.user" />
            </div>
            <div v-if="!attendee.user.key" class="has-margin-top has-text-tiny has-text-weight-bold text-center">
              Convidado
            </div>
            <div class="has-margin-top">
              <h1 class="text-base font-bold">{{ attendee.user.name }}</h1>
            </div>
            <div class="text-sm text-gray-500">está a solicitar autorização para entrar.</div>
            <div class="has-margin-top-medium">
              <b-button
                icon-left="check"
                aria-label="Aceitar participante"
                type="is-primary"
                outlined
                class="has-margin-right"
                @click="$emit('accept-participants', [attendee.key])"
              >
                Aceitar {{ autoAccept && continueTimer ? '(' + countdown + ')' : '' }}
              </b-button>
              <b-button
                aria-label="Rejeitar participante"
                icon-left="times"
                outlined
                type="is-danger"
                @click="rejectOne(attendee.key)"
              >
                Rejeitar
              </b-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'

export default {
  components: {
    UserAvatar,
  },

  props: {
    waitingAttendees: {
      type: Array,
    },
    autoAccept: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      countdown: 3,
      timer: null,
      continueTimer: true,
    }
  },

  /*computed: {
    notAnonymousWaitingAttendees() {
      return this.waitingAttendees.filter(attendee => !!attendee.user.key)
    },
  },*/

  mounted() {
    if (this.autoAccept) {
      this.timer = setInterval(() => {
        if (this.continueTimer) {
          if (this.countdown > 1) {
            this.countdown--
          } else {
            this.acceptAll()
            //this.countdown = 10
            clearInterval(this.timer)
          }
        }
      }, 1000)
    }
  },

  methods: {
    closeModal() {
      this.continueTimer = false
      // Accept all attendees closing the modal
      // in most cases, by mistake, for sure
      if (this.autoAccept) {
        this.acceptAll()
        setTimeout(() => {
          this.$emit('close')
        }, 500)
      } else {
        this.$emit('close')
      }
    },

    acceptAll() {
      const keys = this.waitingAttendees.map(i => i.key)
      if (keys.length) {
        this.$emit('accept-participants', keys)
      }
    },
    rejectAll() {
      this.continueTimer = false
      this.$emit('open-remove-attendee-modal', {
        action: 'reject',
        multiple: true,
        message: '<h2>Rejeitar todos os participantes em espera?</h2>',
        buttonMessage: 'Rejeitar todos',
        callback: async data => {
          const keys = this.waitingAttendees.map(i => i.key)
          if (keys.length) {
            this.$emit('reject-participants', keys, data)
          }
        },
      })
    },
    rejectOne(userKey) {
      this.continueTimer = false
      this.$emit('open-remove-attendee-modal', {
        action: 'reject',
        message: '<h2>Rejeitar o participante?</h2>',
        buttonMessage: 'Rejeitar',
        callback: async data => {
          this.$emit('reject-participants', [userKey], data)
        },
      })
    },
  },
}
</script>
