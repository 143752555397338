<template>
  <div v-if="people">
    <fw-heading size="xl">{{ $t('people') }}</fw-heading>
    <fw-panel
      v-if="people.staff"
      :title="$t('classManagers')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="people.staff.length"
    >
      <RecycleScroller
        v-if="people.staff.length"
        v-slot="{ item }"
        key-field="key"
        :items="people.staff"
        :item-size="57"
        :buffer="50"
      >
        <div class="p-1 border-b border-gray-100 flex gap-2">
          <Person :person="item" />
        </div>
      </RecycleScroller>
      <fw-panel-info v-else type="basic">
        Não existem diretores(as) de turma associados(as) a esta edição.
      </fw-panel-info>
    </fw-panel>

    <fw-panel
      v-if="people.students"
      :title="$t('participants')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="people.students.length"
    >
      <RecycleScroller
        v-if="people.students.length"
        v-slot="{ item }"
        key-field="key"
        :items="people.students"
        :item-size="60"
        :buffer="50"
      >
        <div class="p-1 border-b border-gray-100 flex gap-2">
          <Person
            :person="item"
            class="flex-1"
            :is-active="!!!item.activated_date"
            clickable
            @clicked="$emit('select-user', item)"
          >
            <template #options>
              <div class="gap-10 items-end text-right hidden md:flex">
                <div v-if="item.enrollment">
                  <fw-label marginless size="xs">Inscrição</fw-label>
                  <div class="text-sm" :class="{ 'text-gray-500': !item.enrollment.is_definitive }">
                    {{ item.enrollment.is_definitive ? 'Inscrito(a)' : 'Não inscrito' }}.
                  </div>
                </div>
                <div v-if="!isExceptionCourse" class="w-44 flex-shrink-0">
                  <fw-label marginless size="xs">Progresso do curso</fw-label>
                  <ProgressIcon
                    :progress="item.progress"
                    show-percentage
                    percentage-pos="right"
                    :color="
                      item.progress == 100 ? 'bg-gradient-to-r from-teal-300 to-teal-400' : 'bg-gray-500 bg-opacity-80'
                    "
                  />
                </div>
              </div>
            </template>
          </Person>
        </div>
      </RecycleScroller>
      <fw-panel-info v-else type="basic">
        Não existem participantes associados a esta edição.
      </fw-panel-info>
    </fw-panel>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  components: {
    RecycleScroller,
    Person,
    ProgressIcon,
  },

  props: {
    course: Object,
    edition: Object,
    people: Object,
    units: Array,
    userData: Object,
  },

  data() {
    return {
      classProgress: {},
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    // TODO: Do not forget to remove this, Valentim
    isExceptionCourse() {
      return (process.env.VUE_APP_ENV == 'development' && this.course.key === 'oowvtc') || this.course.key === '4slkfk'
    },
  },

  mounted() {
    this.getClassProgress()
  },

  methods: {
    async getClassProgress() {
      this.classProgress = await ServiceAcademic.getClassProgress(
        this.course.key,
        this.edition.key,
        this.userData.class.key
      )
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "people": "Pessoas",
    "classManagers": "Diretores/as de turma",
    "participants": "Participantes"
  },
  "en": {
    "people": "People",
    "classManagers": "Class Managers",
    "participants": "Participants"
  }
}
</i18n>
