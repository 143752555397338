<template>
  <div>
    <div class="flex gap-5 my-1 items-center">
      <div class="flex-1 flex gap-2 items-start">
        <fw-icon-book-mark-solid class="w-5 h-5 mt-1 flex-shrink-0" />
        <v-clamp class="w-full flex-1 text-left font-bold text-lg items-end flex" autoresize :max-lines="3">
          {{ unit.title }}
        </v-clamp>
      </div>
      <fw-button v-if="!editable" type="link" @click.native="editable = !editable">Editar</fw-button>
      <fw-button v-if="editable" type="primary" :loading="saving" @click.native="save()">Guardar</fw-button>
    </div>
    <FwMessage v-if="error" type="warning">
      {{ error }}
    </FwMessage>
    <div class="flex flex-col mt-3 bg-white py-3 px-4 rounded-lg">
      <div class="text-black font-semibold">
        Disponibilidade do conteúdo módulo
      </div>
      <div>
        <fw-label>Data de início</fw-label>
        <b-datetimepicker
          v-if="editable"
          v-model="schedule.normal.start_date"
          placeholder="Data de início"
        ></b-datetimepicker>
        <div v-else-if="schedule.normal.start_date">{{ schedule.normal.start_date | formatDateTime }}</div>
        <div v-else class="text-sm text-gray-500">Sem data de início definida</div>
      </div>
      <div>
        <fw-label>Data de fim</fw-label>
        <b-datetimepicker
          v-if="editable"
          v-model="schedule.normal.end_date"
          placeholder="Data de fim"
          :min-datetime="schedule.normal.start_date"
        >
        </b-datetimepicker>
        <div v-else-if="schedule.normal.start_date">{{ schedule.normal.end_date | formatDateTime }}</div>
        <div v-else class="text-sm text-gray-500">Sem data de fim definida</div>
      </div>
      <div v-if="schedule.normal.updated_date" class=" text-sm text-gray-500 mt-2">
        Atualizado em {{ schedule.normal.updated_date | formatDate }}
      </div>
    </div>
    <div class="flex flex-col mt-3 bg-white py-3 px-4 rounded-lg">
      <div class="text-black font-semibold">
        Disponibilidade da avaliação do módulo
      </div>
      <div>
        <fw-label>Data de início</fw-label>
        <b-datetimepicker
          v-if="editable"
          v-model="schedule.evaluation.start_date"
          placeholder="Data de início"
        ></b-datetimepicker>
        <div v-else-if="schedule.evaluation.start_date">{{ schedule.evaluation.start_date | formatDateTime }}</div>
        <div v-else class="text-sm text-gray-500">Sem data de início definida</div>
      </div>
      <div>
        <fw-label>Data de fim</fw-label>
        <b-datetimepicker
          v-if="editable"
          v-model="schedule.evaluation.end_date"
          placeholder="Data de fim"
          :min-datetime="schedule.evaluation.start_date"
        >
        </b-datetimepicker>
        <div v-else-if="schedule.evaluation.end_date">{{ schedule.evaluation.end_date | formatDateTime }}</div>
        <div v-else class="text-sm text-gray-500">Sem data de fim definida</div>
      </div>
      <div v-if="false">
        <fw-label>Duração da avaliação (em minutos)</fw-label>
        <b-numberinput
          v-if="editable"
          v-model="schedule.evaluation.duration"
          placeholder="Duração da avaliação"
          :min="15"
        ></b-numberinput>
        <div v-else-if="schedule.evaluation.duration">{{ schedule.evaluation.duration }} minutos</div>
      </div>
      <div v-if="schedule.evaluation.updated_date" class=" text-sm text-gray-500 mt-2">
        Atualizado em {{ schedule.evaluation.updated_date | formatDate }}
      </div>
    </div>
    <div class="flex flex-col mt-3 bg-white py-3 px-4 rounded-lg">
      <div class="text-black font-semibold">
        Disponibilidade do inquérito de qualidade do módulo
      </div>
      <div>
        <fw-label>Data de início</fw-label>
        <b-datetimepicker
          v-if="editable"
          v-model="schedule.quality_evaluation.start_date"
          placeholder="Data de início"
        ></b-datetimepicker>
        <div v-else-if="schedule.quality_evaluation.start_date">
          {{ schedule.quality_evaluation.start_date | formatDateTime }}
        </div>
        <div v-else class="text-sm text-gray-500">Sem data de início definida</div>
      </div>
      <div>
        <fw-label>Data de fim</fw-label>
        <b-datetimepicker
          v-if="editable"
          v-model="schedule.quality_evaluation.end_date"
          placeholder="Data de fim"
          :min-datetime="schedule.quality_evaluation.start_date"
        >
        </b-datetimepicker>
        <div v-else-if="schedule.quality_evaluation.end_date">
          {{ schedule.quality_evaluation.end_date | formatDateTime }}
        </div>
        <div v-else class="text-sm text-gray-500">Sem data de fim definida</div>
      </div>
      <div v-if="schedule.quality_evaluation.updated_date" class=" text-sm text-gray-500 mt-2">
        Atualizado em {{ schedule.quality_evaluation.updated_date | formatDate }}
      </div>
    </div>
  </div>
</template>
<script>
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'

export default {
  props: {
    unit: Object,
    course: Object,
    edition: Object,
    classroom: Object,
  },
  data() {
    return {
      schedule: null,
      editable: false,
      error: null,
      saving: false,
    }
  },
  computed: {
    allHaveSameSchedule() {
      if (this.unit.topics[0].schedule.length > 1) return false
      let first = this.unit.topics[0].schedule[0]
      this.unit.topics
        .filter(el => el.type == 'normal')
        .forEach(topic => {
          if (topic.schedule.length > 1) {
            return false
          } else if (topic.schedule.length == 1) {
            if (topic.schedule[0].start_date != first.start_date || topic.schedule[0].end_date != first.end_date) {
              return false
            }
          }
        })
      return true
    },
  },
  created() {
    if (this.allHaveSameSchedule) {
      let normalTopic = this.unit.topics.filter(el => el.type == 'normal')[0]
      let evaluationTopic = this.unit.topics.filter(el => el.type == 'evaluation')[0]
      let qualityEvaluationTopic = this.unit.topics.filter(el => el.type == 'quality_evaluation')[0]
      this.schedule = {
        normal: {
          start_date: normalTopic && normalTopic.schedule[0] ? new Date(normalTopic.schedule[0].start_date) : null,
          end_date: normalTopic && normalTopic.schedule[0] ? new Date(normalTopic.schedule[0].end_date) : null,
          updated_date: normalTopic && normalTopic.schedule[0] ? normalTopic.schedule[0].updated_date : null,
        },
        evaluation: {
          start_date:
            evaluationTopic && evaluationTopic.schedule[0] ? new Date(evaluationTopic.schedule[0].start_date) : null,
          end_date:
            evaluationTopic && evaluationTopic.schedule[0] ? new Date(evaluationTopic.schedule[0].end_date) : null,
          updated_date:
            evaluationTopic && evaluationTopic.schedule[0] ? new Date(evaluationTopic.schedule[0].updated_date) : null,
          duration:
            evaluationTopic && evaluationTopic.schedule[0] && evaluationTopic.schedule[0].exam
              ? evaluationTopic.schedule[0].exam.duration
              : 15,
        },
        quality_evaluation: {
          start_date:
            qualityEvaluationTopic && qualityEvaluationTopic.schedule[0]
              ? new Date(qualityEvaluationTopic.schedule[0].start_date)
              : null,
          end_date:
            qualityEvaluationTopic && qualityEvaluationTopic.schedule[0]
              ? new Date(qualityEvaluationTopic.schedule[0].end_date)
              : null,
          updated_date:
            qualityEvaluationTopic && qualityEvaluationTopic.schedule[0]
              ? new Date(qualityEvaluationTopic.schedule[0].updated_date)
              : null,
        },
      }
    }
  },
  methods: {
    async save() {
      if (this.saving) return
      this.saving = true
      let structure = {}
      let create = false
      let update = false
      let updateStructure = {}
      structure[this.unit.key] = {}
      for (let index = 0; index < this.unit.topics.length; index++) {
        const topic = this.unit.topics[index]
        if (topic.schedule.length == 0) {
          create = true
          structure[this.unit.key][topic.key] = {}
          if (this.schedule[topic.type].start_date && this.schedule[topic.type].end_date) {
            structure[this.unit.key][topic.key] = {
              start_date: this.schedule[topic.type].start_date.toISOString(),
              end_date: this.schedule[topic.type].end_date.toISOString(),
            }
          }
        } else {
          let schedule = topic.schedule[0]
          update = true
          if (this.schedule[topic.type].start_date && this.schedule[topic.type].end_date) {
            updateStructure[schedule.key] = {
              start_date: this.schedule[topic.type].start_date.toISOString(),
              end_date: this.schedule[topic.type].end_date.toISOString(),
            }
          }
        }
      }
      this.error = null
      try {
        if (create) {
          let createResult = await ServiceAcademic.createCourseSession(
            this.course.key,
            this.edition.key,
            this.classroom.key,
            structure
          )
          if (createResult['__errors__'] && createResult['__errors__'].length > 0) {
            this.error = createResult['__errors__'][0]
            return
          }
          console.log('createResult', createResult)
        }
        if (update) {
          let updateResult = await ServiceAcademic.updateCourseSession(
            this.course.key,
            this.edition.key,
            this.classroom.key,
            updateStructure
          )
          if (updateResult['__errors__'] && updateResult['__errors__'].length > 0) {
            this.error = updateResult['__errors__'][0]
            return
          }
          console.log('updateResult', updateResult)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.saving = false
        this.editable = !this.editable
        //send data already formated in the right way?
        this.$emit('save', this.tmp)
      }
    },
  },
}
</script>
