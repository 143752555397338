<template>
  <div v-if="activeSessions.length || todaySessions.length || thisWeekSessions.length || nextWeekSessions.length">
    <fw-panel v-if="activeSessions.length" title="Aulas a decorrer" class="my-5">
      <div v-if="activeSessions.length" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-5 mb-5">
        <div
          v-for="session in activeSessions"
          :key="session.key"
          class="w-full group bg-gradient-to-r from-teal-200 to-teal-400 h-full p-4 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer"
        >
          <div class="flex justify-between gap-2">
            <fw-label marginless class="text-teal-700">{{ session.start_date | formatDayWithTime }}</fw-label>
            <fw-label v-if="session.space || session.building" marginless class="text-teal-700 flex-1 text-right">
              <v-clamp autoresize :max-lines="1">{{ spaceAndBuildingText(session) }}</v-clamp>
            </fw-label>
          </div>
          <div class="flex gap-2 justify-between md:block mt-2 md:mt-0">
            <div class="w-full flex-1 md:flex md:flex-col md:items-center md:my-3 lg:my-5">
              <v-clamp autoresize :max-lines="1" class="font-bold md:text-xl">
                {{ session.title }}
              </v-clamp>
              <v-clamp class="font-medium md:text-lg" autoresize :max-lines="2">
                {{ session.unit.title }}
              </v-clamp>
              <div
                v-if="session.meeting_presence_type == 'online'"
                class="flex md:items-center md:justify-center flex-col pt-2 md:p-3"
              >
                <div class="text-sm font-bold flex items-center gap-2 mb-2">
                  <fw-icon-live class="w-5 h-5"></fw-icon-live> Online
                </div>
                <div class="text-xs md:text-center md:max-w-xs md:mx-auto opacity-70">
                  A sua participação nesta aula deve ser online, através da sala virtual da turma.
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-4 opacity-70 group-hover:opacity-100">
              <div
                class="flex flex-col items-center justify-center rounded-xl p-1 bg-white bg-opacity-30 hover:bg-opacity-50"
              >
                <fw-button
                  label="Entrar na sala virtual"
                  size="sm"
                  class="flex flex-col items-center gap-1"
                  @click.native="$router.push({ name: 'live', params: { key: session.edition.meeting.key_alias } })"
                >
                  <fw-icon-live
                    :class="{
                      'animate-pulse': session.edition.meeting.running,
                    }"
                  ></fw-icon-live>
                  <span class="hidden md:flex">Sala virtual</span>
                  <span
                    class="text-xs font-normal opacity-80 hidden md:flex"
                    :class="{ 'animate-pulse font-bold': session.edition.meeting.running }"
                  >
                    {{ session.edition.meeting.running ? 'Aberta' : 'Fechada' }}
                  </span>
                </fw-button>
              </div>
              <div
                class="flex flex-col items-center justify-center rounded-xl p-1 bg-white bg-opacity-30 hover:bg-opacity-50"
              >
                <fw-button
                  size="sm"
                  label="Entrar na sala virtual"
                  class="flex flex-col items-center gap-1"
                  @click.native="$router.push({ name: 'class', params: { key: session.edition.key } })"
                >
                  <fw-icon-team></fw-icon-team>
                  <span class="hidden md:flex">Área da turma</span>
                  <span class="hidden md:flex gap-1 items-center justify-center bg-opacity-80">
                    <fw-icon-box-open class="w-3 h-3"></fw-icon-box-open>
                    <fw-icon-chats class="w-3 h-3"></fw-icon-chats>
                    <fw-icon-file class="w-3 h-3"></fw-icon-file>
                  </span>
                </fw-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fw-panel>
    <fw-panel
      v-if="todaySessions.length || thisWeekSessions.length || nextWeekSessions.length"
      title="Próximas aulas"
      see-more-path="/units"
      class="my-5"
    >
      <b-tabs>
        <b-tab-item v-if="todaySessions.length" label="Hoje">
          <PanelClassSessions :sessions="todaySessions" />
        </b-tab-item>
        <b-tab-item v-if="thisWeekSessions.length" label="Esta semana">
          <PanelClassSessions :sessions="thisWeekSessions" />
        </b-tab-item>
        <b-tab-item v-if="nextWeekSessions.length" label="Próxima semana">
          <PanelClassSessions :sessions="nextWeekSessions" />
        </b-tab-item>
      </b-tabs>
    </fw-panel>
  </div>
</template>

<script>
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import AcademicUtilities from '@/fw-modules/fw-core-vue/academic/services/utilities'
import PanelClassSessions from '@/fw-modules/fw-core-vue/academic/components/panels/PanelClassSessions'

export default {
  components: {
    PanelClassSessions,
  },

  props: {
    profile: {
      type: String,
      default: 'teacher',
    },
  },

  data() {
    return {
      activeSessions: [],
      todaySessions: [],
      thisWeekSessions: [],
      nextWeekSessions: [],
      nextSessionInterval: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher') && this.profile === 'teacher')
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student') && this.profile === 'student')
    },
  },

  mounted() {
    if (this.isTeacher || this.isStudent) this.getNextSessions()
  },

  beforeDestroy() {
    if (this.nextSessionInterval) clearInterval(this.nextSessionInterval)
  },

  methods: {
    async getNextSessions() {
      let sessions
      if (this.isTeacher) sessions = await ServiceAcademic.getNextTeacherSessions()
      else if (this.isStudent) sessions = await ServiceAcademic.getNextStudentSessions()

      if (sessions) {
        AcademicUtilities.buildSessionDates(
          sessions,
          this.activeSessions,
          this.todaySessions,
          this.thisWeekSessions,
          this.nextWeekSessions
        )
        this.nextSessionInterval = setInterval(this.checkSessionDates, 30 * 1000)
      }
    },
    spaceAndBuildingText(session) {
      if (session.space && session.building) {
        return session.space.title + ' ' + session.building.title
      } else if (session.space) {
        return session.space.title
      } else if (session.building) {
        return session.building.title
      } else {
        return ''
      }
    },
    checkSessionDates() {
      AcademicUtilities.checkSessionDates(
        this.activeSessions,
        this.todaySessions,
        this.thisWeekSessions,
        this.nextWeekSessions
      )
    },
  },
}
</script>
