<template>
  <div
    class="inline-block relative flex-shrink-0"
    :class="{
      'mr-3': addPaddingRight && active,
    }"
  >
    <span
      :style="{
        color: active ? activeColor : inactiveColor,
      }"
      class="font-bold"
      ><slot
    /></span>
    <fw-dot-active v-if="active" :active-color="activeColor" class="h-2 w-2 absolute top-1 -right-3" />
  </div>
</template>

<script>
export default {
  name: 'TextStatus',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    activeColor: {
      type: String,
      default: '#30a898',
    },
    inactiveColor: {
      type: String,
      default: 'rgb(75, 85, 99)',
    },
    addPaddingRight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
