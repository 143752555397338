<template>
  <div v-if="meeting && !isRunning" class="inner-panel is-flex-grow is-flex-justify-center">
    <div class="has-text-centered is-container-small" :class="isMeetingOwner ? 'is-flex is-flex-direction-column' : ''">
      <div v-if="isClassEdition">
        <div class="has-margin-top">
          <div class="is-size-1 has-text-weight-bold is-width-small">
            {{ meeting.title }}
          </div>
          <div class="is-size-3" :class="{ 'has-margin-top-small': isMeetingOwner }">
            {{ meeting.context.unit.title }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center justify-center">
          <UserAvatar size="lg" :user="meeting.context.user" />
        </div>
        <div class="has-margin-top">
          <h1 v-if="isMeetingOwner" class="is-size-2 font-bold">A minha sala</h1>
          <h1 v-else class="is-size-2 font-bold">{{ meeting.title }}</h1>
        </div>
        <div v-if="!isMeetingOwner" class="is-size-3">
          Sala Pessoal
        </div>
      </div>

      <div class="has-text-small has-margin-top">
        <div class="has-margin">
          <div class="label has-margin-right-small is-marginless">ID da sala</div>
          <div>
            <div class="is-size-3 is-lowercase is-family-code">{{ meeting.key_alias }}</div>
          </div>
          <div v-if="isMeetingOwner">
            <a class="text-sm text-primary" href="" @click.prevent="$emit('personalize-meeting-key')">
              Personalizar
            </a>
          </div>
        </div>
      </div>

      <div :class="isMeetingOwner ? 'has-margin-top' : 'has-margin-top-large'">
        <div
          v-if="meeting.running"
          class="has-text-primary"
          :class="isMeetingOwner ? 'has-margin-top' : 'has-margin-top-large'"
        >
          <faicon icon="circle" size="sm" class="animate-flicker has-margin-right-small"></faicon>Sala aberta
        </div>
        <div v-else-if="meeting.unlock_in" class="has-margin-top-large">
          <div class="has-text-muted">Sala fechada (fora do horário previsto).</div>
        </div>
        <div v-else class="has-margin-top-large">
          <div class="has-text-muted">Sala fechada.</div>
        </div>
      </div>

      <div
        v-if="!toggleExternalMeetingPanel && (enterRequestRejected || meeting.running || allowedToStartMeeting)"
        class="has-margin-top"
      >
        <div v-if="enterRequestRejected" class="mt-5 p-5 rounded-xl shadow-xl bg-white">
          <div class="has-text-weight-bold">{{ enterRequestRejected.title }}.</div>
          <div v-if="enterRequestRejected.message" class="has-margin-top is-size-4">
            <div class="has-text-tiny has-text-muted has-margin-bottom-small">
              <faicon icon="comment" class="has-margin-right-small"></faicon> Mensagem
            </div>
            <div class="is-size-3 is-width-tiny">"{{ enterRequestRejected.message }}"</div>
            <div v-if="!enterRequestRejected.blocked" class="has-margin-top has-text-small">
              <a @click.prevent="$router.go()">Fechar mensagem</a>
            </div>
          </div>
        </div>
        <div
          v-else-if="meeting.running || allowedToStartMeeting"
          class="is-flex"
          :class="isMeetingOwner ? 'is-flex-space-around is-width-auto' : 'is-flex-align-center is-flex-justify-center'"
        >
          <div class="mt-5" :class="!isMeetingOwner ? 'is-flex is-flex-align-center' : ''">
            <b-button
              v-if="!meeting.is_external && !cameraAllowed"
              class="has-text-danger has-margin-left is-rounded"
              :class="!isMeetingOwner ? 'is-min-width-small' : ''"
              type="is-dark"
              aria-label="Câmara não autorizada"
              icon-left="video-slash"
            >
              <faicon class="has-margin-left-small" icon="lock"></faicon>
            </b-button>
            <b-button
              v-else-if="!meeting.is_external"
              :class="{ 'has-text-danger': !startWithCamera, 'is-min-width-small': !isMeetingOwner && !audioAllowed }"
              :type="startWithCamera ? 'is-primary' : 'is-dark'"
              :icon-right="startWithCamera ? 'video' : 'video-slash'"
              class="has-margin-right is-rounded x-toggle-video"
              :aria-label="startWithCamera ? 'Desligar câmara' : 'Ligar câmara'"
              @click="$emit('toggle-camera-start-option')"
            ></b-button>
            <b-button
              v-if="!meeting.running && allowedToStartMeeting"
              type="is-primary"
              rounded
              :loading="loading"
              aria-label="Abrir a sala"
              @click="$emit('start-meeting')"
            >
              <span class="has-margin-left-small">Abrir a sala</span>
            </b-button>
            <b-button
              v-else-if="meeting.running && (allowedToStartMeeting || allowedToJoinMeeting)"
              type="is-primary"
              :loading="loading"
              rounded
              @click="$emit('start-meeting')"
            >
              <span
                class="has-margin-left-small x-enter-room"
                :class="isMeetingOwner ? 'has-padding-left has-padding-right' : ''"
              >
                Entrar na sala
              </span>
            </b-button>
            <b-button
              v-else-if="!waitingToBeAccepted && !allowedToStartMeeting && !allowedToJoinMeeting"
              type="is-primary"
              :loading="loading"
              class="is-rounded"
              icon-left="concierge-bell"
              @click="$emit('start-meeting')"
            >
              <span class="has-margin-left-small">Pedir para entrar</span>
            </b-button>
            <span v-else class="animate-flicker is-rounded text-sm has-text-weight-bold has-text-dark">
              Aguarda autorização para entrar!
            </span>
            <b-button
              v-if="!meeting.is_external && !audioAllowed"
              class="has-text-danger has-margin-left is-rounded is-min-width-small"
              type="is-dark"
              icon-left="microphone-slash"
              aria-label="Microfone não autorizado"
            >
              <faicon class="has-margin-left-small" icon="lock"></faicon>
            </b-button>
            <b-button
              v-else-if="!meeting.is_external"
              :class="{ 'has-text-danger': !startWithAudio }"
              :type="startWithAudio ? 'is-primary' : 'is-dark'"
              :icon-right="startWithAudio ? 'microphone' : 'microphone-slash'"
              class="has-margin-left is-rounded x-toggle-micro"
              :aria-label="startWithAudio ? 'Desligar microfone' : 'Ligar microfone'"
              @click="$emit('toggle-audio-start-option')"
            ></b-button>
          </div>
        </div>
      </div>

      <div v-if="!meeting.running && allowedToStartMeeting && canStartExternal" class="has-margin-top-medium">
        <div v-if="!toggleExternalMeetingPanel">
          <div class="has-text-muted has-text-tiny is-width-tiny">
            Em alternativa, pode abrir a sala sem vídeo e utilizar outra plataforma como o
            <span class="has-text-weight-bold">Zoom, Google Meet ou Microsoft Teams</span>
          </div>
          <div class="has-margin-top">
            <b-button type="is-light is-small" @click="toggleExternalMeetingPanel = !toggleExternalMeetingPanel"
              >Utilizar outra plataforma</b-button
            >
          </div>
        </div>
        <div v-else class="has-margin-top has-text-left">
          <div class="is-width-xsmall">
            <b-field
              label="Indique um endereço válido do Zoom, Meet ou Teams"
              :type="isValidExternalVideoApp ? 'is-success' : ''"
            >
              <b-input
                v-model="externalMeetingDetails"
                validation-message="Indique um endereço válido"
                required
                placeholder="URL"
                type="url"
              ></b-input>
            </b-field>
            <div v-if="isValidExternalVideoApp">
              <div class="has-text-tiny has-text-muted">
                O endereço parece válido (plataforma
                <span class="has-text-weight-bold is-capitalized has-text-primary">{{ isValidExternalVideoApp }}</span
                >) mas não podemos confirmar se está ativo ou funcional. Se abrir a sala, este endereço será partilhado
                com os estudantes.
              </div>
              <div class="has-margin-top-small has-margin-bottom-medium has-text-tiny">
                O endereço será guardado para a próxima aula.
              </div>
              <div class="has-margin-top has-margin-bottom has-text-muted">
                <b-checkbox v-model="externalAutoAccept">
                  <faicon
                    style="width: 25px"
                    icon="user-check"
                    size="lg"
                    class="has-margin-right-small has-text-primary"
                  ></faicon>
                  Autorizar automaticamente a entrada de participantes
                </b-checkbox>
              </div>
            </div>
            <div class="has-margin-top-small is-flex is-flex-right">
              <b-button
                type="is-text"
                :loading="loading"
                aria-label="Cancelar"
                @click="toggleExternalMeetingPanel = !toggleExternalMeetingPanel"
              >
                Cancelar
              </b-button>
              <b-button
                type="is-primary"
                :loading="loading"
                aria-label="Abrir a sala"
                class="has-margin-left-small"
                :disabled="!isValidExternalVideoApp"
                @click="startExternalMeeting"
              >
                Abrir a sala
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!meeting.running && !allowedToStartMeeting && (ownerCalled || canCallOwner)">
        <div v-if="!ownerCalled" class="has-margin-top-small">
          <b-button
            icon-left="bell"
            :loading="ownerCalledLoading"
            type="is-primary"
            rounded
            @click="callOwnerToMeeting"
          >
            Notificar utilizador
          </b-button>
          <div class="m-5 text-gray-500 text-sm max-w-xs mx-auto">
            Ao carregar, o responsável pela sala receberá uma SMS a informar que se encontra à espera.
          </div>
        </div>
        <div v-else class="has-text-small has-text-muted">
          Foi enviada uma SMS para notificar o utilizador.
        </div>
      </div>

      <div v-if="isClassEdition && isSt && classSessionPresence">
        <div v-if="!classSessionPresence.is_active && !meeting.running" class="has-margin-top-large is-width-tiny">
          <div class="label is-size-4 is-marginless">Marcação de presença</div>
          <div class="label has-text-tiny">Local ou virtual fora da UC Student</div>
          <div>A marcação de presença está disponível durante o horário previsto da aula.</div>
        </div>
        <div v-else-if="classSessionPresence.locked" class="has-margin-top-medium is-width-tiny">
          <div class="label is-size-4 is-marginless">Assiduidade</div>
          <div v-if="classSessionPresence.type" class="has-text-primary has-margin-top">
            <div v-if="classSessionPresence.type == 'local'" class="is-size-2"><faicon icon="building"></faicon></div>
            <div v-if="classSessionPresence.type == 'online'" class="is-size-2"><faicon icon="desktop"></faicon></div>
            <div class="is-size-3">Presente</div>
            <div class="has-text-tiny">{{ classSessionPresence.type }}</div>
          </div>
          <div v-else>
            <div class="is-size-2">Ausente</div>
          </div>
          <div class="has-text-tiny has-margin-top has-text-muted">
            O estado da sua assiduidade foi definida pelo docente.
          </div>
        </div>
        <div v-else class="has-margin-top-medium is-width-tiny">
          <div class="is-size-3">Está presente na aula? Local ou virtualmente fora da UC Student?</div>
          <div class="label has-margin-top-small">Marque a sua presença</div>
          <div class="has-margin-top">
            <div class="is-flex is-flex-align-center has-margin-top">
              <b-button
                expanded
                :type="classSessionPresence.type == 'local' ? 'is-primary' : 'is-dark'"
                :outlined="classSessionPresence.type != 'local'"
                icon-right="building"
                class="has-margin-right-small"
                @click="toggleSelfStudentPresence('local')"
                >Local</b-button
              >
              <b-button
                expanded
                :type="classSessionPresence.type == 'online' ? 'is-primary' : 'is-dark'"
                :outlined="classSessionPresence.type != 'online'"
                icon-right="desktop"
                @click="toggleSelfStudentPresence('online')"
                >Online</b-button
              >
            </div>
          </div>
          <div class="has-margin-top has-text-small">
            Se entrar nesta aula através da UC Student (em computador), a sua presença é marcada automaticamente.
            Utilize este painel de marcação
            <strong>apenas se estiver na sala física ou numa plataforma diferente da UC Student</strong>.
          </div>
        </div>
      </div>

      <div
        v-if="meeting.running && loggedUser.isAnonymous && !allowedToStartMeeting && !allowedToJoinMeeting"
        class="mt-5 mb-2 text-sm text-gray-500 max-w-xs mx-auto"
      >
        <div>
          Se não conseguir entrar na sala, por favor, recarregue esta página e tente novamente.
        </div>
        <div class="mt-2">
          <a class="font-bold text-xs text-primary" href="">Recarregar página</a>
        </div>
      </div>

      <div v-else-if="!loggedUser.isAnonymous" class="has-margin-top-large">
        <fw-button size="xs" type="link-muted" @click.native="$router.push('/')">voltar à página anterior</fw-button>
      </div>

      <div v-else-if="loggedUser.isAnonymous" class="has-margin-top-large">
        <fw-button size="xs" type="link-muted" @click.native="$router.push('/logout')"
          >Terminar sessão de convidado</fw-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'

export default {
  components: {
    UserAvatar,
  },

  props: {
    loading: Boolean,
    isRunning: Boolean,
    meeting: Object,
    isMeetingOwner: Boolean,
    isClassEdition: Boolean,
    waitingToBeAccepted: Boolean,
    enterRequestRejected: {
      type: Object,
      default: null,
    },
    startWithAudio: Boolean,
    startWithCamera: Boolean,
  },

  data() {
    return {
      ownerCalled: false,
      ownerCalledLoading: false,
      classSessionPresence: null,
      classSessionSubscription: null,
      externalMeetingDetails: '',
      externalAutoAccept: true,
      toggleExternalMeetingPanel: false,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    canStartExternal() {
      return false
      // Disable for all meetings after 2023-10-30
      // return Boolean(this.meeting && this.meeting.item_type === 'class_edition')
    },
    allowedToStartMeeting() {
      return this.meeting && !this.meeting.unlock_in && ServiceMeetings.withRole(this.meeting.roles, 'can_start')
    },
    allowedToJoinMeeting() {
      return Boolean(
        this.meeting &&
          !this.meeting.unlock_in &&
          !this.loggedUser.isAnonymous &&
          ServiceMeetings.withRole(this.meeting.roles, 'can_join')
      )
    },
    audioAllowed() {
      return this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'audio_allowed')
    },
    cameraAllowed() {
      return this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'camera_allowed')
    },
    isMobile() {
      return this.$device.isMobile() && !this.$device.isTablet()
    },
    isSt() {
      return process.env.VUE_APP_KEY == 'ucstudent'
    },
    canCallOwner() {
      return Boolean(
        this.meeting &&
          !this.meeting.running &&
          !this.ownerCalled &&
          this.meeting.item_type === 'user' &&
          this.meeting.context.can_call
      )
    },
    isValidExternalVideoApp() {
      return utils.getExternalVideoAppFromUrl(this.externalMeetingDetails)
    },
  },

  watch: {
    meeting(meet) {
      this.loadStudentSession(meet)
      this.setExternalData()
    },
  },

  beforeDestroy() {
    if (this.classSessionSubscription) {
      this.classSessionSubscription.destroy()
      this.classSessionSubscription = null
    }
  },
  mounted() {
    this.loadStudentSession()
    this.setExternalData()
  },

  methods: {
    setExternalData() {
      if (this.meeting && this.meeting.previous_external) {
        this.externalMeetingDetails = this.meeting.previous_external.description || ''
      }
    },
    startExternalMeeting() {
      const data = {
        asExternal: true,
        externalDescription: this.externalMeetingDetails,
        externalAutoAccept: this.externalAutoAccept,
      }
      this.$emit('start-meeting', data)
      this.externalAutoAccept = true
    },

    async callOwnerToMeeting() {
      if (!this.canCallOwner || this.ownerCalledLoading) return
      this.ownerCalledLoading = true

      try {
        await ServiceMeetings.callOwner(this.meeting.key)
        this.ownerCalled = true
      } finally {
        this.ownerCalledLoading = false
      }
    },

    async loadStudentSession(meeting = null) {
      if (!meeting) meeting = this.meeting
      if (!this.isSt || !meeting || !meeting.context.is_student) return

      try {
        this.classSessionPresence = await ServiceAcademic.getStudentClassPresence(meeting.item_key)
        this.classSessionSubscription = ServiceAcademic.createSubscription(
          meeting.item_key,
          this.classSessionPresence,
          true
        )
      } catch (error) {
        console.error('Failed to load student presence', error)
        this.classSessionPresence = null
        this.classSessionSubscription = null
      }
    },

    async toggleSelfStudentPresence(type) {
      if (type === this.classSessionPresence.type) {
        await ServiceAcademic.deleteSelfStudentPresence(this.meeting.item_key, this.classSessionPresence.key)
        this.classSessionPresence.type = null
      } else {
        this.$buefy.dialog.confirm({
          type: 'is-primary',
          message: `<p class="is-size-4 has-margin-top">Tem a certeza que deseja marcar a sua presença nesta aula?</p>
          <p class="has-margin-top-small is-meta">
            A sua presença é marcada automaticamente caso entre na sala virtual.
          </p>`,
          indefinite: true,
          queue: false,
          cancelText: 'Cancelar',
          confirmText: 'Marcar presença',
          canCancel: true,
          onConfirm: async () => {
            await ServiceAcademic.setSelfStudentPresence(this.meeting.item_key, this.classSessionPresence.key, type)
            this.classSessionPresence.type = type
          },
        })
      }
    },

    toClipboardMeetingUrl() {
      this.$copyText(`https://${location.host}/live/${this.meeting.key_alias}`).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Endereço copiado`,
          position: 'is-top',
        })
      })
    },
  },
}
</script>
