<template>
  <LayoutDefault :header-session-nav="false" :header-apps="false" :header-notifications="false" :main-footer="false">
    <template #main>
      <LoadingPage :icon="icon" :title="title"></LoadingPage>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import LoadingPage from '@/fw-modules/fw-core-vue/ui/components/loading/LoadingPage'

export default {
  name: 'ViewFullPageLoading',
  components: {
    LayoutDefault,
    LoadingPage,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
}
</script>
