import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import store from '@/store'
import querystring from 'querystring'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlBuckets
    return api
  },

  async getBuckets(applicationContext = null, limit = null, permissions = []) {
    let data = null
    if (applicationContext) {
      if (!data) data = { params: {} }
      if (applicationContext.include_unit_buckets || applicationContext.include_unit_edition_buckets) {
        //Include buckets of the unit
        let params = new URLSearchParams()
        let filterContent = []
        if (applicationContext.include_unit_buckets) {
          filterContent.push(applicationContext.application)
          filterContent.push('unit')
          filterContent.push(applicationContext.include_unit_buckets)
          params.append('filter', filterContent)
        }

        if (applicationContext.include_unit_edition_buckets) {
          filterContent = []
          filterContent.push(applicationContext.application)
          filterContent.push('unit_edition')
          filterContent.push(applicationContext.include_unit_edition_buckets)
          params.append('filter', filterContent)
        }

        filterContent = []
        filterContent.push(applicationContext.application)
        filterContent.push(applicationContext.item_type)
        filterContent.push(applicationContext.item_key)
        params.append('filter', filterContent)

        data.params = params
      } else {
        data.params.application = applicationContext.application
        data.params.item_type = applicationContext.item_type
        data.params.item_key = applicationContext.item_key
      }
    }
    if (limit) {
      if (!data) data = { params: {} }
      data.params.limit = limit
    }

    if (permissions.length > 0) {
      if (!data) data = { params: {} }
      data.params.permissions = permissions.join(',')
    }

    const response = await this.base().get('/v1/buckets', data)

    for (let bucket of response.data) {
      bucket.updated_date_obj = Dates.build(bucket.updated_date)
    }
    response.data.sort((a, b) => {
      if (a.updated_date_obj < b.updated_date_obj) return 1
      else if (a.updated_date_obj > b.updated_date_obj) return -1
      else return 0
    })

    return response.data
  },
  async getBucketsWithFilters(filters = [], academic_year = null) {
    let data = { params: {} }

    if (filters.length > 0) {
      data['params']['type'] = filters.join(',')
    }
    if (academic_year !== null && academic_year !== -1 && academic_year !== '-1') {
      data['params']['academic_year'] = academic_year
    }

    const response = await this.base().get('/v1/buckets', data)

    for (let bucket of response.data) {
      bucket.updated_date_obj = Dates.build(bucket.updated_date)
    }
    response.data.sort((a, b) => {
      if (a.updated_date_obj < b.updated_date_obj) return 1
      else if (a.updated_date_obj > b.updated_date_obj) return -1
      else return 0
    })

    return response.data
  },
  async addBucket(title, applicationContext = null) {
    const data = { title: title }
    if (applicationContext) {
      data.application = applicationContext.application
      data.item_type = applicationContext.item_type
      data.item_key = applicationContext.item_key
    }

    const response = await this.base().post('/v1/buckets', data)
    return response.data.key
  },
  async createClassEditionBucket(title, classEditionKey) {
    const data = { title: title }
    data.application = 'academic'
    data.item_type = 'class_edition'
    data.item_key = classEditionKey
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.base().post('/v1/buckets', data)
        resolve(response.data.key)
      } catch (e) {
        reject(e)
      }
    })
  },
  sortBucketItems(items) {
    items.sort((a, b) => {
      if (a.type == 'folder') {
        if (b.type != 'folder') {
          return -1
        }
      } else if (b.type == 'folder') {
        return 1
      }

      return a.title.localeCompare(b.title)
    })
  },
  async getBucket(key, folderKey = null, subscribe = false) {
    const orgArgs = Array.from(arguments)
    const callback = this.getBucket

    let data = null
    if (folderKey) {
      if (!data) data = { params: {} }
      data.params.folder_key = folderKey
    }

    let subscribed = subscribe && store.state.socket.connectionId
    if (subscribed) {
      if (!data) data = { params: {} }
      data.params.subscribe_connection_id = store.state.socket.connectionId
    }

    const config = { ignoreInvalidConnectionID: true }
    try {
      const response = await this.base(config).get(`/v1/bucket/${key}`, data)
      response.data.subscribed = subscribed
      this.sortBucketItems(response.data.items)
      return response.data
    } catch (error) {
      //retry!
      if (utils.errors(error).exists('InvalidConnectionID')) {
        store.commit('checkWebsocket', {
          retryCallback: callback,
          retryCallbackArgs: orgArgs,
          retryCallbackWait: 100,
          retryCallbackExpectReturn: true,
        })
        return store.state.callbackReturnData
      } else {
        throw error
      }
    }
  },
  async updateBucket(key, data) {
    const response = await this.base().post(`/v1/bucket/${key}`, data)
    return response.data
  },
  async addItemsToBucket(key, data) {
    data.ignore_connection_id = store.state.socket.connectionId
    const response = await this.base().post(`/v1/bucket/${key}/items`, data)
    return response.data
  },
  async updateBucketItem(key, itemKey, data) {
    data.ignore_connection_id = store.state.socket.connectionId
    const response = await this.base().post(`/v1/bucket/${key}/item/${itemKey}`, data)
    return response.data
  },
  //Copy files from one bucket to other buckets
  copyBucketItemToBucket(key, fileKeys, recipientBuckets = [], recipientFolderName) {
    //verify if we need to create a bucket first
    let create_bucket_promises = []
    let positions = []
    for (let i = 0; i < recipientBuckets.length; i++) {
      if (recipientBuckets[i].startsWith('new_repo;')) {
        positions.push(i)
        let parts = recipientBuckets[i].split(';')
        let title = parts[3]
        let class_key = parts[2]
        create_bucket_promises.push(this.createClassEditionBucket(title, class_key))
      }
    }
    return Promise.all(create_bucket_promises).then(async promise_results => {
      //replace placeholders for the keys
      for (let i = 0; i < promise_results.length; i++) {
        let pos = positions[i]
        console.log('created:', promise_results[i])
        recipientBuckets[pos] = promise_results[i]
        console.log('replaced:', recipientBuckets[pos])
      }

      console.log('RECIPIENTS', recipientBuckets)

      const data = {
        keys: fileKeys,
        buckets: recipientBuckets,
      }

      if (typeof recipientFolderName !== 'undefined' && recipientFolderName !== null) {
        data['folder_title'] = recipientFolderName
      }
      const response = await this.base().post(`/v1/bucket/${key}/items/copy`, data)
      return response.data
    })
  },
  async createZipFolder(key, fileKeys = []) {
    const response = await this.base().post(`/v1/bucket/${key}/zip`, {
      keys: fileKeys,
    })
    return response.data
  },
  //Mark file as read
  async markFileAsRead(bucketKey, fileKeys = [], type = 'preview') {
    //type: read | preview
    const response = await this.base().post(`/v1/bucket/${bucketKey}/unread`, {
      keys: fileKeys,
      type: type,
    })
    return response.data
  },
  async loadReadFiles(bucketKey) {
    //try to get remote settings, if not available read locally
    console.log(bucketKey)
    return [] //JSON.parse(window.localStorage.getItem(bucketKey + '_readfiles'))
  },
  async moveBucketItem(key, folderKey, keys) {
    const data = {
      folder: folderKey,
      keys: keys,
      ignore_connection_id: store.state.socket.connectionId,
    }

    const response = await this.base().post(`/v1/bucket/${key}/items/move`, data)
    return response.data
  },

  async deleteItemsFromBucket(key, itemKeys) {
    const data = {
      params: {
        keys: itemKeys,
        ignore_connection_id: store.state.socket.connectionId,
      },
      paramsSerializer: params => {
        return querystring.stringify(params)
      },
    }

    await this.base().delete(`/v1/bucket/${key}/items`, data)
  },
  async deleteBucket(key) {
    await this.base().delete(`/v1/bucket/${key}`)
  },

  async getBucketPermissions(key) {
    const response = await this.base().get(`/v1/bucket/${key}/permissions`)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async getBucketPermissions_v2(key) {
    const response = await this.base().get(`/v2/bucket/${key}/permissions`)
    let result = response.data.auto
    result.map(u => (u['auto'] = true))
    //result = result.concat(response.data.manual)
    //result.sort((a, b) => a.name.localeCompare(b.name))
    //console.log(response.data, result)
    return {
      auto: result,
      manual: response.data.manual,
    }
  },
  async addBucketPermission(key, data) {
    const response = await this.base().post(`/v1/bucket/${key}/permissions`, data)
    response.data.users.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async updateBucketPermission(key, userKey, data) {
    const response = await this.base().post(`/v1/bucket/${key}/permissions/${userKey}`, data)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async deleteBucketPermission(key, userKey) {
    const response = await this.base().delete(`/v1/bucket/${key}/permissions/${userKey}`)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },

  async getPublicItems(keys) {
    const config = { ignoreError: true }
    const data = {
      paramsSerializer: params => {
        return querystring.stringify(params)
      },
      params: { key: keys },
    }

    const response = await this.base(config).get(`/v1/public/items`, data)
    if (response && response.data) return response.data
    else return {}
  },

  createBucketSubscription(
    bucket,
    folder,
    items,
    bucketDeleteCallback,
    goToFolderCallback,
    addItems,
    removeItems,
    subscribe = true
  ) {
    let subscriptionName = `Bucket-${bucket.key}`
    if (folder) subscriptionName += `-${folder.key}`

    const cls = {
      bucket: bucket,
      folder: folder,
      items: items,
      addItems: addItems,
      removeItems: removeItems,
      bucketDeleteCallback: bucketDeleteCallback,
      goToFolderCallback: goToFolderCallback,

      async subscribeBucket() {
        const data = {
          application: 'buckets',
          code: 'subscribe',
          key: cls.bucket.key,
          folder_key: cls.folder ? cls.folder.key : null,
          as_manager: cls.bucket.is_manager,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Bucket ${cls.bucket.key} subscribe manager:${cls.bucket.is_manager}`)
      },
      async unsubscribe() {
        const data = {
          application: 'buckets',
          code: 'unsubscribe',
          key: cls.bucket.key,
          folder_key: cls.folder ? cls.folder.key : null,
          as_manager: cls.bucket.is_manager,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Bucket ${cls.bucket.key} unsubscribe`)
      },
      async reconnect() {
        cls.subscribeBucket()
      },

      async WSMessages(messages) {
        if (messages.bucketDelta) {
          for (let message of messages.bucketDelta) {
            if (message.key === cls.bucket.key) {
              Object.assign(cls.bucket, message.delta)
            }
          }
        }
        if (messages.bucketPermissions) {
          for (let message of messages.bucketPermissions) {
            if (message.key === cls.bucket.key) {
              cls.bucket.permissions = message.permissions
            }
          }
        }
        if (messages.bucketDelete) {
          for (let message of messages.bucketDelete) {
            if (message.key === cls.bucket.key) {
              cls.bucketDeleteCallback()
            }
          }
        }

        if (messages.newItems) {
          const files = []
          const newItems = []
          for (let message of messages.newItems) {
            if (
              message.key === cls.bucket.key &&
              ((!message.folder_key && !cls.folder) || (cls.folder && cls.folder.key === message.folder_key))
            ) {
              newItems.push(...message.items)
              for (let item of message.items) {
                if (item.file) files.push(item.file)
              }
            }
          }

          if (files.length) {
            await ServiceStorage.setFilesMetadata(files)
          }
          if (newItems.length) {
            cls.addItems(newItems)
          }
        }
        if (messages.bucketItemDelta) {
          for (let message of messages.bucketItemDelta) {
            if (message.bucket_key === cls.bucket.key) {
              for (let item of cls.items) {
                if (item.key === message.key) {
                  for (const [key, value] of Object.entries(message.delta)) {
                    if (key === 'metadata') Object.assign(item.metadata, value)
                    else item[key] = value
                  }
                  break
                }
              }
            }
          }
        }
        if (messages.bucketItemDelete) {
          const deleteItemsKeys = []
          for (let message of messages.bucketItemDelete) {
            if (message.bucket_key === cls.bucket.key) {
              if (cls.folder && cls.folder.key === message.key) {
                cls.goToFolderCallback(message.go_to_folder_key || null)
              } else {
                deleteItemsKeys.push(message.key)
              }
            }
          }
          if (deleteItemsKeys.length) {
            cls.removeItems(deleteItemsKeys)
          }
        }
      },

      destroy() {
        cls.unsubscribe()
        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: subscriptionName })
        store.commit('unsubscribeWS', { code: 'buckets', name: subscriptionName })
      },
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: subscriptionName, callback: cls.reconnect })
    store.commit('subscribeWS', { code: 'buckets', name: subscriptionName, callback: cls.WSMessages })
    if (subscribe) cls.subscribeBucket()
    return cls
  },

  async addItem(bucketKey, data) {
    ///v1/bucket/{{bucketsBucketKey}}/items
    //data: {page_title: ''}
    const response = await this.base().post(`/v1/bucket/${bucketKey}/items`, data)
    return response.data
  },
}
