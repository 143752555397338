<template>
  <fw-panel :title="title" :loading="loading" after-loading-checked>
    <div v-if="courses.length" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
      <CardCourse
        v-for="course in courses"
        :key="course.id"
        :course="course"
        :show-meta="false"
        :show-calendar="false"
        @click.native="goToCourse(course)"
        @register="register(course)"
      >
        <template #overlay>
          <div class="flex flex-col gap-2 justify-between h-full w-full">
            <div class="flex items-start gap-2">
              <fw-tag size="sm" type="white">
                <span class="font-normal mr-1">Edição</span> {{ course.edition.title }}
              </fw-tag>
              <div class="flex-1"></div>
              <ButtonNotificationsToogle
                v-if="false"
                :active="course.activeNotifications"
                :short="true"
                :saving="course.savingNotification"
                @toogle="subscribe(course)"
              />
            </div>
            <div class="flex gap-10 justify-between">
              <div class="flex gap-5 items-center flex-1">
                <ProgressIcon
                  v-if="(course.edition.status == 'running') & false"
                  type="light"
                  :progress="course.edition.progress"
                  show-percentage
                  percentage-pos="right"
                  class="w-full flex-shrink-0"
                />
                <fw-tag v-else-if="course.is_prr" size="sm" :rounded="false" type="primary">PRR</fw-tag>
              </div>
              <div class="text-white font-bold flex gap-5 items-center">
                <div v-if="checkIsManager(course.user)">
                  Direção da edição
                </div>
                <div v-else-if="checkIsClassLeader(course.user)">
                  Direção de turma
                </div>
                <div v-else-if="course.edition.enrollment_type === 'pre-admitted'">
                  Inscreva-se aqui
                </div>
                <div v-else-if="course.edition.status == 'running'">Inscrito(a)</div>
              </div>
            </div>
          </div>
        </template>
        <template #main></template>
      </CardCourse>
    </div>
    <LoadingPlaceholder v-else-if="loading" :list="false" title="Curso não conferente de grau"></LoadingPlaceholder>
  </fw-panel>
</template>

<script>
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardCourse from '@/fw-modules/fw-core-vue/nondegree-courses/components/cards/CardCourse'
import ButtonNotificationsToogle from '../buttons/ButtonNotificationsToogle.vue'
export default {
  components: {
    ProgressIcon,
    LoadingPlaceholder,
    CardCourse,
    ButtonNotificationsToogle,
  },

  props: {
    title: {
      type: String,
      default: 'Cursos disponíveis',
    },
    courses: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
  },

  methods: {
    subscribe(course) {
      this.$emit('subscribe', course)
    },
    register(course) {
      this.$emit('register', course)
    },
    toogleNotifications(course) {
      course.activeNotifications = !course.activeNotifications
    },
    goToCourse(course) {
      let params = { courseKey: course.key, editionKey: course.edition.key }
      let query = {}

      if (this.checkIsManager(course.user) && course.classes.length) {
        // Set one class as default
        query['class_key'] = course.classes[0]
      }
      this.$router.push({
        name: 'nondegree-course-edition',
        params: params,
        query: query,
      })
    },

    // Edition manager (can access all classes)
    checkIsManager(userData) {
      for (const role of userData.roles) {
        if (['manager'].includes(role)) return true
      }
      return false
    },

    // Class manager / leader
    checkIsClassLeader(userData) {
      for (const role of userData.roles) {
        if (['leader', 'staff', 'coordinator'].includes(role)) return true
      }
      return false
    },
  },
}
</script>
