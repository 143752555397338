<template>
  <fw-layout
    :full="isFullLayout"
    :loading="loading"
    :notfound="!loading && !courseEdition"
    :forbidden="!loading && forbidden"
    back-to="/"
  >
    <template #header-nav>
      <div class="flex gap-3 items-center">
        <div class="flex flex-col">
          <fw-heading size="md" marginless class="line-clamp-1">{{ courseEdition.course.title }}</fw-heading>
          <fw-label v-if="courseEdition.edition" class="hidden md:flex" marginless
            >Edição {{ courseEdition.edition.title }}</fw-label
          >
        </div>
        <fw-tag
          v-if="isEnrollmentDefinitive && isEditionRunning"
          size="sm"
          type="primary"
          custom-class="px-3"
          class="hidden"
          >Em curso</fw-tag
        >
        <fw-tag
          v-if="!isEnrollmentDefinitive && isSignUpOpen"
          size="sm"
          type="orange"
          custom-class="px-3"
          class="hidden"
          >Inscrições abertas</fw-tag
        >
      </div>
    </template>

    <template v-if="isCourseEditionUnlocked" #main-sidebar>
      <SidebarCourseEdition
        :course="courseEdition.course"
        :units="courseEdition.units"
        :view="view"
        :edition="courseEdition.edition"
        :progress="courseEdition.progress"
        :classroom="courseEdition.user.class"
        :classes="courseEdition.user.classes || []"
        :checks="checks"
        :active-class-key="activeClassKey"
        :active-unit-key="activeUnit ? activeUnit.key : null"
        @set-active-class="changeClass($event)"
        @set-active-edition="changeEdition($event)"
        @go-to-view="goToView($event)"
        @go-to-unit="goToView('unit', $event)"
      />
    </template>

    <template #main-content>
      <PanelDashboard
        v-if="!view || view == 'dashboard'"
        :course="courseEdition.course"
        :progress="courseEdition.progress"
        :edition.sync="courseEdition.edition"
        :units="courseEdition.units"
        :people="courseEdition.people"
        :user-data="courseEdition.user"
        :checks="checks"
        :class="{ 'my-5': !isCourseEditionUnlocked }"
        @sign-up="startSignup()"
        @go-to-unit="goToView('unit', $event)"
      />
      <PanelUnit
        v-else-if="(view == 'unit' || view == 'units') && activeUnit && isObject(activeUnit.topics)"
        :key="activeUnit ? activeUnit.key : 'unit'"
        :course="courseEdition.course"
        :edition="courseEdition.edition"
        :unit="activeUnit"
        :checks="checks"
        :is-manager="isManager"
        @update-course-progress="updateCourseProgress"
        @update-unit-progress="updateUnitProgress"
      />
      <PanelUnitPlaceholder
        v-else-if="(view == 'unit' || view == 'units') && activeUnit && !isObject(activeUnit.topics)"
      />
      <PanelPeople
        v-if="view == 'people' && isManager"
        :course="courseEdition.course"
        :edition="courseEdition.edition"
        :units="courseEdition.units"
        :people="courseEdition.people"
        :user-data="courseEdition.user"
        @select-user="openUserDetails($event)"
      />
      <PanelResults
        v-if="view == 'results' && checks.isManager"
        :course="courseEdition.course"
        :edition="courseEdition.edition"
        :units="courseEdition.units"
        :results="courseEdition.results"
      />
      <PanelCalendar
        v-if="view == 'calendar' && checks.isManager"
        :course="courseEdition.course"
        :edition="courseEdition.edition"
        :units="courseEdition.units"
        :classroom="courseEdition.user.class"
      />
      <PanelAbout
        v-if="view == 'about'"
        :course="courseEdition.course"
        :units="courseEdition.units"
        :edition="courseEdition.edition"
      />
      <PanelEnrollment
        v-if="view == 'enrollment'"
        :course="courseEdition.course"
        :progress="courseEdition.progress"
        :edition="courseEdition.edition"
      />
    </template>

    <template #modals>
      <b-modal
        :width="850"
        class="rounded-buefy-modal"
        :can-cancel="true"
        :active.sync="openEnrollmentModal"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <ModalCourseEnrollment
          :course="courseEdition.course"
          :edition="courseEdition.edition"
          :enrollment="courseEdition.enrollment"
          @close="closeSignupModal"
          @success="loadCourseEdition()"
        ></ModalCourseEnrollment>
      </b-modal>
      <b-modal
        :active="activeUser !== null"
        scroll="keep"
        :can-cancel="true"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :width="700"
        :on-cancel="closeUserDetails"
        :custom-class="'rounded-buefy-modal'"
      >
        <UserDetails
          v-if="activeUser != null"
          :user="activeUser"
          @updated-user="activeUser = $event"
          @close="activeUser = null"
        >
          <template v-if="activeUser != null" #more-meta>
            <!-- Course progress -->
            <div>
              <div>
                <fw-label marginless>Estado da conta</fw-label>
                <div
                  v-if="activeUser.activated_date != null"
                  class="font-semibold text-primary flex items-center gap-1"
                >
                  <span>Conta ativa</span>
                  <fw-icon-check class="h-5"></fw-icon-check>
                </div>
                <div v-else>Conta inativa</div>
              </div>

              <div>
                <fw-label>Data de inscrição na edição do curso</fw-label>
                <div v-if="activeUser.enrollment && activeUser.enrollment.date">
                  {{ activeUser.enrollment.date | formatDate }}
                </div>
                <div v-else class="text-gray-500 text-sm">Não inscrito.</div>
              </div>
            </div>
          </template>
          <template v-if="activeUser != null" #default>
            <fw-panel title="Progresso no curso" subtitle="Por módulo" class="my-5">
              <template #toolbar>
                <div class="flex-shrink-0 w-56 mx-auto">
                  <fw-label size="xs">Progresso geral</fw-label>
                  <ProgressIcon
                    :progress="userProgress.progress"
                    show-percentage
                    percentage-pos="right"
                    :color="
                      userProgress.progress == 100
                        ? 'bg-gradient-to-r from-teal-300 to-teal-400'
                        : 'bg-gray-500 bg-opacity-80'
                    "
                  />
                </div>
              </template>
              <template #default>
                <div class="mt-5">
                  <div v-for="unit in userProgress.units" :key="unit.index" class="my-5">
                    <div class="flex gap-5">
                      <div class="flex-1 flex gap-2">
                        <div class="my-1 flex-shrink-0">
                          <fw-icon-book-mark-solid class="w-5 h-5" />
                        </div>
                        <div>
                          <v-clamp
                            class="w-full flex-1 text-left font-bold text-lg items-end flex"
                            autoresize
                            :max-lines="3"
                          >
                            {{ unit.name }}
                          </v-clamp>
                        </div>
                      </div>
                      <div class="w-44 flex-shrink-0 my-1">
                        <ProgressIcon
                          :progress="unit.progress"
                          show-percentage
                          percentage-pos="right"
                          :color="
                            unit.progress == 100
                              ? 'bg-gradient-to-r from-teal-300 to-teal-400'
                              : 'bg-gray-500 bg-opacity-80'
                          "
                        />
                      </div>
                    </div>
                    <div class="flex flex-col mt-3">
                      <div
                        v-for="topic in unit.topics"
                        :key="topic.key"
                        class="flex items-center gap-3 p-3 border-b hover:bg-gray-100"
                      >
                        <faicon v-if="topic.seen_date !== null" class="text-primary" icon="check-circle" />
                        <faicon v-else class="text-gray-200" icon="circle" />
                        <div class="flex-1 font-medium">
                          <v-clamp class="w-full flex-1 text-left items-end flex" autoresize :max-lines="3">
                            {{ topic.title }}
                          </v-clamp>
                          <div v-if="topic.seen_date != null" class="text-sm text-gray-500">
                            Concluído a {{ topic.seen_date | formatDateTime }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </fw-panel>
          </template>
        </UserDetails>
      </b-modal>
    </template>
  </fw-layout>
</template>

<script>
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ModalCourseEnrollment from '@/fw-modules/fw-core-vue/nondegree-courses/components/modals/ModalCourseEnrollment'
import SidebarCourseEdition from '@/fw-modules/fw-core-vue/nondegree-courses/components/sidebars/SidebarCourseEdition'
import PanelAbout from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelAbout'
import PanelEnrollment from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelEnrollment'
import PanelPeople from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelPeople'
import PanelDashboard from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelDashboard'
import PanelUnit from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelUnit'
import PanelResults from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelResults'
import PanelUnitPlaceholder from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelUnitPlaceholder'
import UserDetails from '@/components/modals/UserDetails'
import PanelCalendar from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelCalendar'

export default {
  components: {
    ModalCourseEnrollment,
    SidebarCourseEdition,
    PanelUnit,
    PanelResults,
    PanelUnitPlaceholder,
    PanelAbout,
    PanelEnrollment,
    PanelPeople,
    PanelDashboard,
    UserDetails,
    PanelCalendar,
  },

  data() {
    return {
      courseEdition: null,
      activeUnit: null,
      activeClassKey: this.$route.query.class_key || null,
      forbidden: false,
      courseKey: this.$route.params.courseKey || null,
      editionKey: this.$route.params.editionKey || null,
      openEnrollmentModal: false,
      view: this.$route.params.unitKey ? 'unit' : this.$route.params.subpage,
      activeUser: null,
      loading: true,
      loadingUserDetails: false,
      classProgress: null,
    }
  },
  computed: {
    userProgress() {
      return this.activeUser != null && this.classProgress != null
        ? this.classProgress['users'][this.activeUser.key]
        : {}
    },
    user() {
      return this.$store.getters.getUser
    },

    // Checks
    isFullLayout() {
      return this.view && ['units', 'unit'].includes(this.view)
    },
    isMobile() {
      return window.innerWidth < 640
    },
    isCourseEditionUnlocked() {
      return (
        this.isCourseManager ||
        this.isCourseEditionManager ||
        this.isCourseEditionClassManager ||
        this.isEnrollmentDefinitive
      )
    },
    isEnrollmentDefinitive() {
      return (
        this.courseEdition && this.courseEdition.validation.is_enrolled && this.courseEdition.validation.is_definitive
      )
    },
    isEditionRunning() {
      return this.courseEdition.validation.is_running
    },
    isSignUpOpen() {
      return this.courseEdition.validation.can_signup
    },

    // Permissions
    userRoles() {
      if (this.user && this.courseEdition && this.courseEdition.user && this.courseEdition.user.roles) {
        return this.courseEdition.user.roles
      }
      return []
    },

    // Course (all editions) manager
    isCourseManager() {
      for (const role of this.userRoles) {
        if (['admin'].includes(role)) return true
      }
      return false
    },
    // Edition manager (can access all classes)
    isCourseEditionManager() {
      return this.courseEdition && this.courseEdition.validation && this.courseEdition.validation.is_manager
    },
    // Class manager / leader
    isCourseEditionClassManager() {
      return this.courseEdition && this.courseEdition.validation && this.courseEdition.validation.is_leader
    },
    // Any manager role
    isManager() {
      return this.isCourseManager || this.isCourseEditionManager || this.isCourseEditionClassManager
    },
    isStudent() {
      return this.courseEdition && this.courseEdition.validation && this.courseEdition.validation.is_student
    },

    canSignUp() {
      return this.isStudent && !this.isManager && !this.isEnrollmentDefinitive
    },

    checks() {
      return {
        canSignUp: this.canSignUp,
        isEnrollmentDefinitive: this.isEnrollmentDefinitive,
        isStudent: this.isStudent,
        isManager: this.isManager,
        isCourseManager: this.isCourseManager,
        isCourseEditionManager: this.isCourseEditionManager,
        isCourseEditionClassManager: this.isCourseEditionClassManager,
        isCourseEditionUnlocked: this.isCourseEditionUnlocked,
        isEditionRunning: this.isEditionRunning,
      }
    },

    // Helpers
    /*topicsAsList() {
      let topics = []
      if (this.activeUnit.topics) {
        for (let topicKey of Object.keys(this.activeUnit.topics)) {
          topics.push(this.activeUnit.topics[topicKey])
        }
      }
      topics.sort((a, b) => a.index - b.index)
      return topics
    },*/
  },

  // watch: {
  //   view(newVal) {
  //     if (newVal) this.loadCourseEdition(true)
  //   },
  // },

  mounted() {
    this.loadCourseEdition()
  },

  created() {
    this.$store.dispatch('startTimer', true)
  },

  beforeDestroy() {
    this.$store.dispatch('startTimer', false)
  },

  methods: {
    isObject(entry) {
      return entry && typeof entry === 'object'
    },
    updateCourseProgress(progress) {
      this.courseEdition.progress = progress
    },
    updateUnitProgress(progress) {
      this.activeUnit.progress = progress
    },
    closeUserDetails() {
      this.activeUser = null
    },
    openUserDetails(user) {
      this.loadClassProgress()
      console.log('openUserDetails', user)
      this.activeUser = user
    },
    async loadClassProgress() {
      this.loadingUserDetails = true
      try {
        let classKey = this.activeClassKey ?? this.courseEdition.user.class.key
        this.classProgress = await ServiceAcademic.getClassProgress(this.courseKey, this.editionKey, classKey)
      } finally {
        this.loadingUserDetails = false
      }
    },
    // Control modals
    startSignup() {
      this.openEnrollmentModal = true
    },

    closeSignupModal() {
      this.openEnrollmentModal = false
    },

    // Load and manage data
    async loadCourseEdition(noLoading = false) {
      this.loading = !noLoading

      // Grab just the data we need
      // For the next version, we may need to ask for more than one section
      let sections = [this.view || 'dashboard']
      let subpage = this.$route.params.subpage || 'dashboard'
      let unitKey = this.$route.params.unitKey || null // Available when nondegree-course-edition-unit view is loaded
      let classKey = this.activeClassKey || null // Available when nondegree-course-edition-unit view is loaded

      if (sections.includes('unit')) {
        sections.push('units')
      }

      // Ask for data
      this.courseEdition = await ServiceAcademic.getCourseEdition(this.courseKey, this.editionKey, sections, classKey)

      // Make sure we show units ordered by index
      if (this.courseEdition.units) {
        this.courseEdition.units.sort((a, b) => a.index - b.index)
      }

      // Parse requested unit from url
      if (unitKey || subpage === 'units') {
        this.loadUnit(unitKey)
      }

      setTimeout(() => {
        this.loading = false
      }, 250)
    },

    loadUnit(unitKey) {
      // Parse units array to object
      const units = this.courseEdition.units.reduce((ac, unit) => {
        return { ...ac, [unit.key]: unit }
      }, {})

      // Grab active unit from array (converted)
      if (units != null && units[unitKey]) {
        this.activeUnit = units[unitKey]
      }

      // Set default
      else if (this.courseEdition.units.length) {
        this.activeUnit = this.courseEdition.units[0]
      }
    },

    changeClass(classKey) {
      this.activeClassKey = classKey
      this.$router.push({ query: { class_key: classKey } })
      this.goToView('dashboard')
    },

    // Manage views
    goToView(view, unitKey = null) {
      let baseUrl = `/nondegree/course/${this.courseKey}/edition/${this.editionKey}/${view}`

      if (!unitKey) {
        unitKey = this.$route.params.unitKey || null
      }

      // Set active unit key
      if (view == 'unit' && unitKey) {
        this.loadUnit(unitKey)
        baseUrl = `${baseUrl}/${unitKey}`
      }

      // Grab first unit
      else if (view == 'units') {
        if (this.courseEdition.units.length) {
          this.loadUnit(this.courseEdition.units[0].key)
        }
      }

      if (this.$router.currentRoute.path !== baseUrl) {
        // Set active class key for edition managers
        if (this.activeClassKey) {
          baseUrl = baseUrl + `?class_key=${this.activeClassKey}`
        }
        this.$router.push({ path: baseUrl })
      }

      // Update view name
      this.view = view

      this.loadCourseEdition(true)
    },
  },
}
</script>
