<template>
  <div class="pb-5">
    <fw-heading size="xl">Dashboard</fw-heading>
    <DashboardHero
      :title="course.title"
      :title-label="edition ? `Edição ${edition.title}` : null"
      title-position="end"
      class="mt-2 mb-5"
      type="cover"
      size="lg"
      :background-image-url="course.cover"
    >
      <template v-if="checks.canSignUp" #default>
        <div class="flex justify-center mb-5">
          <fw-button type="primary" size="lg" class="w-56" @click.native="signup">Inscrever</fw-button>
        </div>
      </template>
      <template #footer>
        <div class="flex gap-5 text-white items-center justify-center">
          <div class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-mode class="h-5 hidden md:block"></fw-icon-course-mode>
            {{ course.working_mode }}
          </div>
          <div class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-ects class="h-5 hidden md:block"></fw-icon-course-ects>
            {{ course.ects }}
          </div>
          <div v-if="course.price && course.price > 0" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-price class="h-5 hidden md:block"></fw-icon-course-price>
            {{ course.price }}€
          </div>
          <div class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-time class="h-4 hidden md:block"></fw-icon-course-time>
            {{ course.duration }} {{ $t('hours') }}
          </div>
          <div v-if="false" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-calendar class="h-5 hidden md:block"></fw-icon-course-calendar>
            {{ $t('startsat') }} {{ course.current_edition | formatDate }}
          </div>
        </div>
      </template>
    </DashboardHero>

    <fw-panel :title="$t('enrolledInfo')" boxed="xs" custom-class="bg-primary bg-opacity-10" class="my-5">
      <div class="flex px-4 pb-3 pt-1 w-full gap-12">
        <div v-if="checks.isManager" class="flex-shrink-0 flex flex-col">
          <fw-label>Papel</fw-label>
          <div v-if="checks.isCourseEditionClassManager" class="font-semibold flex-1 flex items-center">
            Diretor/a de turma
          </div>
        </div>
        <div
          v-else-if="
            checks.isStudent &&
              ((checks.isEnrollmentDefinitive && userData.enrollment.enrolled_date) ||
                (!checks.isEnrollmentDefinitive && checks.canSignUp))
          "
          class="flex flex-col"
        >
          <fw-label>Data de inscrição</fw-label>
          <div class="flex gap-2 items-center flex-1 flex-shrink-0">
            <div v-if="!checks.isEnrollmentDefinitive && checks.canSignUp" class="flex flex-col gap-0.5">
              <span class="text-sm opacity-80">Foi pré-admitido(a) nesta edição mas ainda não está inscrito(a).</span>
              <a class="font-semibold text-primary text-sm" href="" @click.prevent="signup">Inscrever agora</a>
            </div>
            <div v-else-if="checks.isEnrollmentDefinitive" class="flex flex-shrink-0 items-center">
              <fw-icon-calendar class="w-5 h-5 opacity-50 mr-1" />
              {{ userData.enrollment.enrolled_date | formatDateTime }}
            </div>
          </div>
        </div>
        <div v-if="userData.class" class="flex flex-col">
          <fw-label>Turma</fw-label>
          <div class="flex-1 flex gap-2 items-center font-medium">
            <div><fw-icon-people class="w-5 h-5" /></div>
            <div>{{ userData.class.name }}</div>
          </div>
        </div>
        <div class="flex-1"></div>
        <div v-if="userData.class">
          <fw-label>Funcionalidades da turma</fw-label>
          <div class="flex-1 flex items-center gap-3">
            <div
              v-if="
                (userData.class.meeting && userData.class.meeting.key_alias) ||
                  (userData.class.meeting && userData.class.meeting.key_alias)
              "
              class="flex gap-2 mt-2"
            >
              <fw-button
                :type="userData.class.meeting.running ? 'primary' : 'light-primary'"
                label="Sala virtual"
                class="flex gap-1 relative"
                @click.native="$router.push({ name: 'live', params: { key: userData.class.meeting.key_alias } })"
              >
                <fw-icon-live class="w-5 h-5" />
                <span class="ml-1 mr-2">Sala virtual</span>
                <fw-dot v-if="userData.class.meeting.running" color="white" class="top-2 right-2" />
              </fw-button>
              <fw-button
                type="light-primary"
                label="Canal de conversação"
                class="flex gap-1"
                @click.native="$router.push({ name: 'chats', hash: '#' + userData.class.chat.key })"
              >
                <fw-icon-chats class="w-5 h-5" />
                <span class="ml-1">Conversação</span>
              </fw-button>
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel v-if="checks.isCourseEditionUnlocked" title="Módulos" class="my-7">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-5">
        <a
          v-for="unit in units"
          :key="unit.index + '_' + edition.key"
          href=""
          class="group bg-gray-200 bg-gradient-to-r from-teal-200 to-teal-300 hover:to-teal-200 flex-col flex p-5 rounded-xl"
          @click.prevent="openUnit(unit)"
        >
          <div class="flex gap-3 justify-between items-center mb-3">
            <div class="opacity-50 group-hover:opacity-100">
              <fw-icon-book-mark-solid class="w-5 h-5" />
            </div>
            <div class="hidden font-bold text-lg">M{{ unit.index + 1 }}</div>
            <div v-if="!isExceptionCourse" class="w-40">
              <ProgressIcon :progress="unit.progress" show-percentage percentage-pos="right" />
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-end">
            <div class="font-semibold flex-1">
              <v-clamp autoresize :max-lines="3">
                {{ unit.title }}
              </v-clamp>
            </div>
            <div v-if="typeof unit.topics == 'number'" class="w-full text-left mt-2 opacity-70 font-semibold">
              {{ unit.topics }} tópico{{ unit.topics == 1 ? '' : 's' }}
            </div>
          </div>
        </a>
      </div>
    </fw-panel>

    <fw-panel v-if="edition" :title="$t('metadata')" boxed="xs" custom-class="bg-white" class="my-5">
      <div class="flex px-4 pb-3 pt-1 w-full gap-12">
        <div v-if="edition.status == 'running'" class="flex-shrink-0">
          <fw-label>Estado</fw-label>
          <div class="font-bold flex gap-2 items-center text-primary">
            <fw-icon-run class="w-5 h-5 animate-pulse" /> Em curso
          </div>
        </div>
        <div>
          <fw-label>Tipo de subscrição</fw-label>
          <div class="font-medium flex gap-2 items-center">Pré-admissão</div>
        </div>
        <div v-if="!isExceptionCourse">
          <fw-label>Período de inscrição</fw-label>
          <div v-if="edition.signup_start_date" class="font-medium flex gap-2 items-center">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> {{ edition.signup_start_date | formatDate }} -
            {{ edition.signup_end_date | formatDate }}
          </div>
          <div v-else class="font-medium flex gap-2 items-center text-sm text-gray-500">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> Não definido.
          </div>
        </div>
        <div>
          <fw-label>Realização do curso</fw-label>
          <div v-if="edition.start_date" class="font-medium flex gap-2 items-center">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> {{ edition.start_date | formatDate }} -
            {{ edition.end_date | formatDate }}
          </div>
          <div v-else class="font-medium flex gap-2 items-center text-sm text-gray-500">
            <fw-icon-calendar class="w-5 h-5 opacity-50" /> Não definido.
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel :title="$t('people')" class="my-5">
      <div class="flex justify-items-stretch gap-3">
        <div
          v-if="checks.isManager && people && people.count"
          class="bg-white rounded-xl px-4 py-2 flex gap-1 flex-col w-40"
        >
          <fw-label>{{ $t('participants') }}</fw-label>
          <div class="mt-2">
            <div class="flex gap-2 items-center flex-1 leading-5">
              <fw-icon-people class="w-7 h-7" />
              <span class="text-lg font-bold">{{ people.count.enrolled }}</span>
              <span class="text-sm text-gray-500">/ {{ people.count.total }}</span>
            </div>
          </div>
          <div class="text-sm text-gray-500">{{ $t('enrolled') }}</div>
        </div>
        <div class="bg-white rounded-xl px-4 py-2 flex-1">
          <fw-label>{{ $t('classManagers') }}</fw-label>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <Person v-for="person in people.staff" :key="person.key" :no-style="true" :person="person" />
          </div>
        </div>
      </div>
    </fw-panel>

    <div v-if="!checks.isCourseEditionUnlocked" class="mt-10 border-t pt-10">
      <PanelAbout :course="course" :edition="edition" class="mx-auto max-w-screen-lg" />
    </div>
  </div>
</template>

<script>
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import PanelAbout from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelAbout'

export default {
  components: {
    ProgressIcon,
    Person,
    DashboardHero,
    PanelAbout,
  },

  props: {
    course: Object,
    progress: Number,
    edition: Object,
    units: Array,
    userData: Object,
    people: [Object, Array],
    checks: Object,
  },

  data() {
    return {
      metadataFields: [
        'introduction',
        'prerequisites',
        'goals',
        'professional_goals',
        'certification',
        'evaluation',
        'observations',
      ],
    }
  },

  computed: {
    backgroundImage() {
      return `url(${this.course.cover})`
    },
    now() {
      return this.$store.state.now
    },
    me() {
      return this.$store.getters.getUser
    },
    // TODO: Do not forget to remove this, Valentim
    isExceptionCourse() {
      return (process.env.VUE_APP_ENV == 'development' && this.course.key === 'oowvtc') || this.course.key === '4slkfk'
    },
  },

  methods: {
    openUnit(unit) {
      console.log('openUnit', unit)
      if (unit.topics.length > 0) {
        this.$emit('go-to-unit', unit.key)
      }
    },
    signup() {
      this.$emit('sign-up')
    },
    getTopicsLength(topics) {
      // API always send "total" as key... :(
      return Object.keys(topics).length - 1
    },
    /*getUnitProgressValue(unitId) {
      if (!this.progress) return 0
      const total = this.progress.unitsDone[unitId] + this.progress.unitsTodo[unitId]
      return Math.round((100 * this.progress.unitsDone[unitId]) / total) || 0
    },*/
    getUnitProgressValue(unitId) {
      if (this.progress && this.progress.unitsDone && this.progress.unitsTodo) {
        const total =
          (this.progress.unitsDone[unitId] ? this.progress.unitsDone[unitId] : 0) +
          (this.progress.unitsTodo[unitId] ? this.progress.unitsTodo[unitId] : 0)
        return Math.round((100 * (this.progress.unitsDone[unitId] ? this.progress.unitsDone[unitId] : 0)) / total) || 0
      } else {
        return 0
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do curso",
        "professional_goals": "Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações"
      }
    },
    "hours": "hours",
    "enrolled": "Enrolled",
    "participants": "Participants",
    "classManagers": "Staff",
    "people": "People",
    "calendar": "Edition calendar",
    "metadata": "Edition metadata",
    "enrolledInfo": "Enrolled info"
  },
  "pt": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do curso",
        "professional_goals": "Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações"
      }
    },
    "hours": "horas",
    "enrolled": "Inscritos",
    "participants": "Participantes",
    "classManagers": "Direção de turma",
    "people": "Pessoas",
    "calendar": "Calendarização da edição",
    "metadata": "Metadados da edição",
    "enrolledInfo": "Inscrição"
  }
}
</i18n>
