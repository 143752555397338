var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.academicYears.length > 0)?_c('b-dropdown',{ref:"academicYearsDropdown",attrs:{"scrollable":"","max-height":"500","aria-role":"list","position":"is-bottom-left"}},[(_vm.selectedAcademicYear !== -1)?_c('fw-button',{staticClass:"flex gap-1 items-center",attrs:{"slot":"trigger","aria-label":"Escolher ano letivo","type":"dropdown","size":"sm"},slot:"trigger"},[(_vm.showSemesters)?_c('svg',{staticClass:"fill-current h-6 w-6 mr-1 text-gray-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"}})]):_vm._e(),(_vm.showSemesters && !_vm.isMobile)?_c('span',[_vm._v(_vm._s(_vm.selectedSemester)+"º Semestre")]):_vm._e(),(!_vm.isMobile)?_c('span',[_vm._v(_vm._s(_vm.selectedAcademicYear))]):_vm._e(),_c('fw-icon-chevron-down',{staticClass:"w-4 h-4"})],1):_vm._e(),(_vm.showSemesters)?_c('b-dropdown-item',{key:'semester_label',staticClass:"font-semibold",attrs:{"aria-role":"menu-item","focusable":false,"paddingless":"","custom":""}},[_c('fw-label',{staticClass:"ml-4"},[_vm._v("Semestre")])],1):_vm._e(),(_vm.showSemesters)?_c('b-dropdown-item',{staticClass:"px-4 py-2 font-semibold flex justify-start gap-3 items-center",attrs:{"aria-role":"menu-item","focusable":true},nativeOn:{"click":function($event){return _vm.selectSemester(1)}}},[_c('div',{staticClass:"border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5",class:{
        'bg-primary bg-opacity-90': _vm.selectedSemester === 1,
        'bg-gray-200': _vm.selectedSemester !== 1,
      }}),_c('div',[_vm._v("1º Semestre")])]):_vm._e(),(_vm.showSemesters)?_c('b-dropdown-item',{staticClass:"px-4 py-2 font-semibold flex justify-start gap-3 items-center",attrs:{"aria-role":"menu-item","focusable":true},nativeOn:{"click":function($event){return _vm.selectSemester(2)}}},[_c('div',{staticClass:"border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5",class:{
        'bg-primary bg-opacity-90': _vm.selectedSemester === 2,
        'bg-gray-200': _vm.selectedSemester !== 2,
      }}),_c('div',[_vm._v("2º Semestre")])]):_vm._e(),(_vm.academicYears.length > 0)?_c('b-dropdown-item',{key:'academic_year_label',staticClass:"font-semibold",attrs:{"aria-role":"menu-item","focusable":false,"paddingless":"","custom":""}},[_c('fw-label',{staticClass:"ml-4"},[_vm._v("Ano lectivo")])],1):_vm._e(),_vm._l((_vm.academicYearsSorted),function(academicYear,a){return _c('b-dropdown-item',{key:'academic_selector_' + a,staticClass:"px-4 py-2 font-semibold flex justify-start gap-3 items-center",attrs:{"aria-role":"menu-item","focusable":true},nativeOn:{"click":function($event){return _vm.selectAcademicYear(academicYear)}}},[_c('div',{staticClass:"border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5",class:{
        'bg-primary bg-opacity-90': _vm.selectedAcademicYear === academicYear,
        'bg-gray-200': _vm.selectedAcademicYear !== academicYear,
      }}),_c('div',[_vm._v(_vm._s(academicYear))])])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }