/* eslint-disable no-console */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import Nprogress from 'nprogress'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { NotificationProgrammatic as Notification } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

let REQUEST_COUNT = 0

const IGNORE_400_ERRORS = [
  'InvalidUCEmailOrPassword',
  'WaitingToBeAccepted',
  'NotInMeeting',
  'AnonymousNotAllowed',
  'MeetingNotRunning',
]
const IGNORE_401_ERRORS_APP = ['ucplugin', 'ucmotionscreen', 'ucmeetingscreen']

export default mainConfig => {
  if (!mainConfig) mainConfig = {}

  const headers = {
    accept: 'application/json',
    'Accept-Language': store.state.language || this.$i18n.locale,
  }
  if (!mainConfig.ignoreAppHeader) {
    headers.FwApp = `${process.env.VUE_APP_KEY}|${process.env.VUE_APP_VERSION}|${store.state.currentSessionKey}`
  }

  if (localStorage.getItem('deviceKey')) {
    headers['FwDeviceKey'] = localStorage.getItem('deviceKey')
  }

  const instance = axios.create({
    baseURL: mainConfig.baseURL,
    withCredentials: false,
    headers: headers,
    transformRequest: mainConfig.transformRequest,
  })

  instance.interceptors.request.use(
    function(config) {
      config.startAt = new Date()
      config.transactionID = REQUEST_COUNT
      REQUEST_COUNT += 1

      let message = `Request (${config.transactionID}) start ${config.method} ${config.baseURL + config.url}`
      if (config.data) {
        const messageData = utils.JSONSafeStringify(config.data)
        message += ` data:${messageData.length}`
        if (!mainConfig.ignoreDataLog) message += `:${messageData}`
      }
      console.debug(message)

      store.dispatch('setApiStatus', true)
      if (!config.quietly) Nprogress.start()
      return config
    },
    function(error) {
      Nprogress.done()
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function(response) {
      const took = parseFloat((new Date() - response.config.startAt) / 1000).toFixed(3)
      let message = `Response (${response.config.transactionID}) done took:${took}`
      if (response.data) {
        const messageData = utils.JSONSafeStringify(response.data)
        message += ` data:${messageData.length}`
        if (!mainConfig.ignoreResponseLog && messageData.length <= 5000) message += `:${messageData}`
      }
      console.debug(message)

      Nprogress.done()
      return response
    },

    function(error) {
      const config = error.config || {}

      const took = parseFloat((new Date() - config.startAt) / 1000).toFixed(3)
      const statusCode = error.response ? error.response.status : null
      let message = `Response (${config.transactionID}) done error:${statusCode} took:${took}`
      if (error.response && error.response.data) {
        const messageData = utils.JSONSafeStringify(error.response.data)
        message += ` data:${messageData.length}`
        if (!mainConfig.ignoreResponseLog && messageData.length <= 5000) message += `:${messageData}`
      }
      if (!statusCode || statusCode == 500) {
        console.error(message)
      } else {
        console.debug(message)
      }

      Nprogress.done()

      if (mainConfig.ignoreError) {
        return
      }

      if (!error.response) {
        store.dispatch('setApiStatus', false)
      } else {
        const errorKey = utils.errors(error).getKey()

        // Deal with 500
        if (error.response.status == 500) {
          Notification.open({
            duration: 5000,
            message: `<div class="text-sm font-bold">Ocorreu um erro interno.</div>
              <div class="text-xs">Por favor, tente mais tarde.</div>`,
            position: 'is-bottom-left',
            type: 'is-danger',
            queue: false,
            hasIcon: false,
          })
        }

        // Deal with 400
        else if (error.response.status == 400) {
          // Deal with InvalidConnectionID
          if (utils.errors(error).exists('InvalidConnectionID')) {
            if (mainConfig.ignoreInvalidConnectionID) {
              return Promise.reject(error)
            }
            // Close socket active connection
            Vue.prototype.$socket.close()

            Dialog.alert({
              title: 'Ocorreu um erro',
              message: `Ocorreu um erro no registo da sua sessão.<br>
                Por favor, tente novamente dentro de alguns segundos.`,
              type: 'is-danger',
              position: 'is-bottom-left',
              queue: false,
              hasIcon: false,
              duration: 5000,
            })
          }

          // Ignore some errors
          else if (!IGNORE_400_ERRORS.includes(errorKey)) {
            Notification.open({
              duration: 5000,
              message: `<div class="text-sm">Error key: ${errorKey}</div>`,
              position: 'is-bottom-left',
              type: 'is-warning',
              queue: false,
              hasIcon: false,
            })
          }
        }

        // Deal with 403 (from background api response)
        else if (error.response.status == 403) {
          Notification.open({
            message: `<div class='max-w-xs text-sm'>Não tem permissões para realizar esta operação ou aceder
            a este recurso.</div><div class="text-sm opacity-80 mt-1">Error key: ${errorKey}</div>`,
            type: 'is-danger',
            position: 'is-bottom-right',
            queue: false,
            hasIcon: false,
            duration: 5000,
          })
        }

        // Deal with 401
        else if (
          error.response.status == 401 &&
          !store.state.api.ignore401 &&
          !IGNORE_401_ERRORS_APP.includes(process.env.VUE_APP_KEY)
        ) {
          console.warn('Expired session, going to login')
          store.dispatch('logout')
        }
      }

      return Promise.reject(error)
    }
  )

  return instance
}
