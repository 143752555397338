import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlCourses
    return api
  },

  //PUBLIC
  async getCourseEdition(courseKey, courseEditionKey, section, class_key = null) {
    const response = await this.base().get('/v1/courses/' + courseKey + '/editions/' + courseEditionKey, {
      params: { section, class_key },
    })
    return response.data
  },
  async getCoursesCatalogue(filters) {
    var parsedFilter = ''
    if (filters) {
      //is_prr filter
      if (filters['is_prr'] != null) {
        parsedFilter += 'is_prr=' + filters['is_prr']
      }
      //scientific_area filter
      if (filters['scientific_area'] != null) {
        if (parsedFilter.length > 0) {
          parsedFilter += '&'
        }
        parsedFilter += 'scientific_area=' + filters['scientific_area']
      }
      //language filter
      if (filters['languages'] != null) {
        if (parsedFilter.length > 0) {
          parsedFilter += '&'
        }
        parsedFilter += 'lang=' + filters['languages']
      }
      //working_mode filter
      if (filters['working_mode'] != null) {
        if (parsedFilter.length > 0) {
          parsedFilter += '&'
        }
        parsedFilter += 'working_mode=' + filters['working_mode']
      }
      parsedFilter = '?' + parsedFilter
    }
    const response = await this.base().get(`/v1/courses${parsedFilter}`)
    return response.data
  },
  async getUserEnrollments() {
    const response = await this.base().get(`/v1/user`)
    return response.data
  },
  async getUserLastEnrollment() {
    const response = await this.base().get(`/v1/courses/last-enrollment`)
    return response.data
  },
  async getCourseDetails(courseKey) {
    const response = await this.base().get(`/v1/courses/${courseKey}`)
    return response.data
  },
  async signUpCourseEdition(courseKey, editionKey) {
    const response = await this.base().post(`/v1/courses/${courseKey}/editions/${editionKey}`)
    return response.data
  },
  async saveProgress(courseKey, editionKey, unitKey, topicKey) {
    //{{coursesHost}}/v1/courses/{{coursesCourseKey}}/editions/{{coursesCourseEditionKey}}/progress
    const response = await this.base().post(`/v1/courses/${courseKey}/editions/${editionKey}/progress`, {
      unit_key: unitKey,
      topic_key: topicKey,
    })
    return response.data
  },
  //END PUBLIC

  //MANAGEMENT
  async getAllCourses(data = {}) {
    return await this.base().get('/v1/manage/courses', {
      params: data,
    })
  },
  async createCourse(course) {
    return await this.base().post('/v1/manage/courses', course)
  },
  async getCourse(courseKey) {
    return await this.base().get('/v1/manage/courses/' + courseKey)
  },
  async updateCourse(courseKey, course) {
    return await this.base().put('/v1/manage/courses/' + courseKey, course)
  },
  async courseActions(courseKey, status) {
    return await this.base().post('/v1/manage/courses/' + courseKey, {
      status: status,
    })
  },
  async getCourseManagers(courseKey) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/managers')
  },
  async addCourseManagers(courseKey, managers = []) {
    return await this.base().post('/v1/manage/courses/' + courseKey + '/managers', managers)
  },
  async removeCourseManagers(courseKey, managers = []) {
    return await this.base().delete('/v1/manage/courses/' + courseKey + '/managers', {
      data: managers,
    })
  },
  async updateCourseOwner(courseKey, ownerKey) {
    return await this.base().put('/v1/manage/courses/' + courseKey, {
      user_key: ownerKey,
    })
  },
  //EDITIONS
  async createEdition(courseKey, edition) {
    return await this.base().post('/v1/manage/courses/' + courseKey + '/editions', edition)
  },
  async getEditions(courseKey) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/editions')
  },
  async updateEdition(courseKey, courseEditionKey, edition) {
    return await this.base().put('/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey, edition)
  },
  async updateEditionStatus(courseKey, courseEditionKey, status) {
    return await this.base().post('/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey, {
      status: status,
    })
  },
  async getEdition(courseKey, courseEditionKey) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey)
  },
  async getStatistics(courseKey, courseEditionKey) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey + '/statistics')
  },
  async deleteEdition(courseKey, courseEditionKey) {
    return await this.base().delete('/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey)
  },
  //CLASSES
  async getClasses(courseKey, courseEditionKey, page) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey + '/classes', {
      params: {
        page: page,
      },
    })
  },
  async createClass(courseKey, courseEditionKey, courseClass) {
    return await this.base().post(
      '/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey + '/classes',
      courseClass
    )
  },
  async updateClass(courseKey, courseEditionKey, classKey, courseClass) {
    return await this.base().put(
      '/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey + '/classes/' + classKey,
      courseClass
    )
  },
  async getClass(courseKey, courseEditionKey, classKey) {
    return await this.base().get(
      '/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey + '/classes/' + classKey
    )
  },
  async deleteClass(courseKey, courseEditionKey, classKey) {
    return await this.base().delete(
      '/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey + '/classes/' + classKey
    )
  },
  async searchUsers(courseKey, editionKey, params) {
    params['course_key'] = courseKey
    if (editionKey) {
      params['edition_key'] = editionKey
    }
    return await this.base().get('/v1/manage/users/search', {
      params: params,
    })
  },
  async bulkSearchUsers(courseKey, editionKey, data = []) {
    let params = {}
    params['course_key'] = courseKey
    if (editionKey) {
      params['edition_key'] = editionKey
    }
    return await this.base().post('/v1/manage/users/search-bulk', data, {
      params: params,
    })
  },
  async addStudentsToClass(courseKey, courseEditionKey, classKey, students = []) {
    return await this.base().post(
      '/v1/manage/courses/' + courseKey + '/editions/' + courseEditionKey + '/classes/' + classKey + '/students',
      students
    )
  },
  async getUnits(courseKey, editionKey, page = 1) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units', {
      params: {
        page: page,
      },
    })
  },
  async createUnit(courseKey, editionKey, unit) {
    return await this.base().post('/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units', unit)
  },
  async updateUnit(courseKey, editionKey, unitKey, unit) {
    return await this.base().put(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey,
      unit
    )
  },
  async changeOrderUnit(courseKey, editionKey, unitKey, order) {
    return await this.base().put('/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey, {
      index: order,
    })
  },
  async deleteUnit(courseKey, editionKey, unitKey) {
    return await this.base().delete('/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey)
  },
  async getUnit(courseKey, editionKey, unitKey) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey)
  },
  async getTopics(courseKey, editionKey, unitKey, page = 1) {
    return await this.base().get(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey + '/topics',
      {
        params: {
          page: page,
        },
      }
    )
  },
  async createTopic(courseKey, editionKey, unitKey, topic) {
    return await this.base().post(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey + '/topics',
      topic
    )
  },
  async updateTopic(courseKey, editionKey, unitKey, topicKey, topic) {
    return await this.base().put(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey + '/topics/' + topicKey,
      topic
    )
  },
  async changeOrderTopic(courseKey, editionKey, unitKey, topicKey, order) {
    return await this.base().put(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey + '/topics/' + topicKey,
      {
        index: order,
      }
    )
  },
  async deleteTopic(courseKey, editionKey, unitKey, topicKey) {
    return await this.base().delete(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey + '/topics/' + topicKey
    )
  },
  async getTopic(courseKey, editionKey, unitKey, topicKey) {
    return await this.base().get(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/units/' + unitKey + '/topics/' + topicKey
    )
  },
  async getClassStudents(courseKey, editionKey, classKey, params) {
    return await this.base().get(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/students',
      {
        params: params,
      }
    )
  },
  async addClassStudent(courseKey, editionKey, classKey, students = []) {
    return await this.base().post(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/students',
      students
    )
  },
  async removeClassStudent(courseKey, editionKey, classKey, students = []) {
    return await this.base().delete(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/students',
      {
        data: students,
      }
    )
  },
  async getClassStaff(courseKey, editionKey, classKey, params) {
    return await this.base().get(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/staff',
      {
        params: params,
      }
    )
  },
  async addClassStaff(courseKey, editionKey, classKey, staff = []) {
    return await this.base().post(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/staff',
      staff
    )
  },
  async removeClassStaff(courseKey, editionKey, classKey, staff = []) {
    return await this.base().delete(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/staff',
      {
        data: staff,
      }
    )
  },
  async getClassSessions(courseKey, editionKey, classKey, params) {
    return await this.base().get(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/sessions',
      {
        params: params,
      }
    )
  },
  async createClassSession(courseKey, editionKey, classKey, unitSession) {
    //unitSession = {module_key: { topic_key: {start_date, end_date, duration}}}
    return await this.base().post(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/sessions',
      unitSession
    )
  },
  async updateClassSession(courseKey, editionKey, classKey, session) {
    //session = {session_key: {start_date, end_date, duration}}
    return await this.base().put(
      '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/sessions',
      session
    )
  },
  async getEditionPostsCollections(courseKey, editionKey) {
    return await this.base().get('/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/collections')
  },
  // ActivityAPI handler
  async getActivity(courseKey, params) {
    console.log('getActivityLogs :>> ', params)
    const response = await this.base().get('/v1/manage/courses/' + courseKey + '/activity', { params })
    return response.data
  },

  // NotificationsAPI handler
  async getNotifications(courseKey, params) {
    console.log('getNotifications :>> ', params)
    const response = await this.base().get('/v1/manage/courses/' + courseKey + '/notifications', { params })
    return response.data
  },

  async getNotification(courseKey, key) {
    const response = await this.base().get('/v1/manage/courses/' + courseKey + '/notifications/' + key)
    return response.data
  },

  async subscribeEditionWS(connectionId, editionKey, code = 'subscribe_edition') {
    const response = await this.base().post('/v1/ws', {
      code: code,
      connectionId: connectionId,
      body: {
        edition_key: editionKey,
      },
    })
    return response.data
  },
}
