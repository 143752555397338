<template>
  <button
    class="hover:bg-white hover:bg-opacity-20 h-full flex flex-col px-4 py-3 text-left gap-1 hover:shadow-md rounded-xl cursor-pointer w-full min-h-24 items-stretch relative"
    :aria-label="title"
    :class="{
      'bg-gray-200 bg-opacity-80': type == 'light',
      'bg-yellow-800 bg-opacity-10': type == 'yellow',
      'bg-primary bg-opacity-10': type == 'primary',
    }"
    @click="$emit('clicked')"
  >
    <div class="font-semibold text-sm text-gray-500">{{ label }}</div>
    <div class="flex-1 text-left flex gap-1 mt-2">
      <div class="flex-1 flex flex-col justify-end">
        <div v-if="icon" class="mb-1">
          <component
            :is="'fw-icon-' + icon"
            :class="{
              'w-6 h-6': size == 'base',
              'w-7 h-7': size == 'md',
              'w-8 h-8': size == 'lg',
              'w-9 h-9': size == 'xl',
            }"
          />
        </div>
        <div class="font-bold sm:text-md" :class="[`text-${size}`]">
          {{ title }}
        </div>
      </div>
      <div class="flex items-end">
        <fw-icon-chevron-right class="w-6 h-6 -mr-1.5" />
      </div>
    </div>
    <fw-tag v-if="tag" class="absolute -top-1 -right-1" :type="tagType">{{ tag }}</fw-tag>
  </button>
</template>

<script>
export default {
  name: 'ButtonQuickAccess',
  props: {
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: null,
    },
    tagType: {
      type: String,
      default: 'medium',
    },
    type: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'base',
    },
  },
}
</script>
