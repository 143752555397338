<template>
  <SidebarBase>
    <template #main>
      <div ref="sidebarspace" class="h-full flex flex-col">
        <ButtonSidebar
          label="Inbox"
          icon="inbox"
          :active="type == 'inbox' || type == 'unread'"
          :counter="stats ? stats.unread : '0'"
          @click.native="$emit('change-type', 'inbox')"
        />
        <div
          ref="scrollinbox"
          class="overflow-y-auto transition-all no-scrollbar"
          :class="{
            'mb-3': type == 'inbox' || type == 'unread',
            'mb-0': type != 'inbox' && type != 'unread',
          }"
          :style="{ height: `${inboxSubtreeHeight}px` }"
        >
          <div v-for="(context, c) in orderedContextOptions" :key="c" class="pl-4">
            <ButtonSidebar
              v-if="context.stats.total > 0"
              :label="appLabels[context.name] ? appLabels[context.name] : context.name"
              :active="context.name == app"
              size="sm"
              :counter="context.stats.unread"
              counter-type="xlight"
              @click.native="$emit('change-app', context.name)"
            />
          </div>
        </div>
        <ButtonSidebar
          label="Arquivadas"
          icon="archive"
          :active="type == 'archived'"
          :counter="stats ? stats.archived : '0'"
          counter-type="xlight"
          @click.native="$emit('change-type', 'archived')"
        />
        <div
          ref="scrollarchived"
          class="overflow-y-auto transition-all no-scrollbar"
          :class="{
            'mb-3': type == 'archived',
            'mb-0': type != 'archived',
          }"
          :style="{ height: `${archivedSubtreeHeight}px` }"
        >
          <div v-for="(context, c) in orderedContextOptions" :key="c" class="pl-8">
            <ButtonSidebar
              v-if="context.stats.archived > 0"
              :label="appLabels[context.name] ? appLabels[context.name] : context.name"
              :active="context.name == app"
              size="sm"
              :counter="context.stats.archived"
              counter-type="xlight"
              @click.native="$emit('change-app', context.name)"
            />
          </div>
        </div>
        <div v-if="false" class="ml-1 my-3">
          <fw-label size="xs" class="ml-2">Por tipo</fw-label>
          <div>
            <ButtonSidebar label="Pessoal" size="sm" counter="99" counter-type="light" />
            <ButtonSidebar label="Gestão" size="sm" counter="99" counter-type="light" />
          </div>
        </div>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'SidebarNotifications',

  components: {
    ButtonSidebar,
    SidebarBase,
  },

  props: {
    contextOptions: {
      type: Array,
      default: () => [],
    },
    stats: {
      type: Object,
      default: () => ({}),
    },
    app: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inboxSubtreeHeight: 0,
      archivedSubtreeHeight: 0,
    }
  },

  computed: {
    orderedContextOptions() {
      let copy = [...this.contextOptions]
      return copy.sort((a, b) => {
        //order by name
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    appLabels() {
      return utils.appLabels()
    },
    user() {
      return this.$store.getters.getUser
    },
    permissions() {
      return this.$store.getters.userPermissions
    },
    language() {
      return this.$store.state.language
    },
  },

  watch: {
    type() {
      this.calculateAvailableSpace()
    },
  },

  mounted() {
    this.calculateAvailableSpace()
  },

  methods: {
    calculateAvailableSpace() {
      this.$nextTick(() => {
        let availableSpace = this.$refs.sidebarspace.offsetHeight - 82
        if (this.type == 'inbox' || this.type == 'unread') {
          if (this.$refs.scrollinbox.scrollHeight > availableSpace) {
            this.inboxSubtreeHeight = availableSpace
          } else {
            this.inboxSubtreeHeight = this.$refs.scrollinbox.scrollHeight
          }
          this.archivedSubtreeHeight = 0
        } else {
          if (this.$refs.scrollarchived.scrollHeight > availableSpace) {
            this.archivedSubtreeHeight = availableSpace
          } else {
            this.archivedSubtreeHeight = this.$refs.scrollarchived.scrollHeight
          }
          //this.archivedSubtreeHeight = this.$refs.sidebarspace.offsetHeight - 82
          this.inboxSubtreeHeight = 0
        }
      })
    },
  },
}
</script>

<style>
.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
</style>
