<template>
  <div
    class="card-unit w-full group flex bg-white flex-col cursor-pointer"
    :class="{
      'text-center h-full justify-center md:p-4 shadow-lg hover:shadow-2xl rounded-xl p-3 pt-5': view === 'card',
      'shadow-sm hover:shadow-lg rounded-lg p-3 px-4 border border-gray-100 md:border-none': view !== 'card',
    }"
  >
    <div
      class="flex"
      :class="{
        'justify-center items-center flex-col flex-1': view === 'card',
        'text-sm md:text-base': view === 'row',
      }"
    >
      <div v-if="view === 'row'" :aria-label="onlyProfile ? unit.profile : unit.title">
        {{ onlyProfile ? unit.profile : unit.title }}
      </div>
      <fw-heading v-else :size="'h5'">{{ onlyProfile ? unit.profile : unit.title }}</fw-heading>

      <div v-if="view === 'row'" class="flex-1 flex justify-end items-center text-xs gap-2 text-gray-500 opacity-90">
        <div v-if="unit.stats && unit.stats.classes" class="flex items-center gap-1 leading-6">
          {{ unit.stats.classes }} Turma{{ unit.stats.classes == 1 ? '' : 's' }}
        </div>
        <div
          v-if="unit.stats && unit.stats.classes && unit.stats.students"
          class="bg-gray-200 h-1.5 w-1.5 rounded-full mx-1 mb-0.5 inline-block"
        ></div>
        <div v-if="unit.stats && unit.stats.students" class="flex items-center gap-1">
          <fw-icon-people class="w-4 h-4" />
          {{ unit.stats.students }}
        </div>
      </div>
      <div
        v-if="!onlyProfile"
        class="font-medium text-sm"
        :class="{
          'mb-3': view === 'card',
        }"
      >
        {{ unit.profile }}
      </div>
      <div
        v-if="unit.courses && unit.courses.length && view === 'card'"
        class="p-2 flex flex-col gap-2 items-center justify-center text-gray-500"
      >
        <div v-for="course in unit.courses" :key="course.key" class="text-xs">
          {{ course.title }}
        </div>
      </div>
    </div>
    <div v-if="view === 'card'" class="w-full text-xs flex justify-between gap-2 text-gray-500 mt-5">
      <div class="flex items-center gap-1">
        <span v-if="unit.regime === 'semiannual' && showSemester" class="mr-0.5">
          {{ unit.regime_occurrence }}º Semestre
          <div class="inline-block rounded-full bg-gray-200 mb-0.5 ml-0.5" style="height: 4px; width: 4px;"></div
        ></span>
        {{ unit.stats.classes }} Turma{{ unit.stats.classes == 1 ? '' : 's' }}
      </div>
      <div>
        <div class="flex items-center gap-1">
          <fw-icon-people class="w-4 h-4" />
          {{ unit.stats.students }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardUnit',
  props: {
    unit: Object,
    for: {
      type: String,
      default: 'teacher',
    },
    view: {
      type: String,
      default: 'card',
    },
    showSemester: {
      type: Boolean,
      default: true,
    },
    onlyProfile: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
