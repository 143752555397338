import ViewCourse from './views/ViewCourse'
import ViewCourseEdition from './views/ViewCourseEdition'
import ViewCourses from './views/ViewCourses'

export default [
  {
    path: '/nondegree',
    name: 'nondegree-courses',
    component: ViewCourses,
  },
  {
    path: '/nondegree/course/:key',
    name: 'nondegree-course',
    component: ViewCourse,
  },
  {
    path: '/nondegree/course/:courseKey/edition/:editionKey',
    name: 'nondegree-course-edition',
    component: ViewCourseEdition,
  },
  {
    path: '/nondegree/course/:courseKey/edition/:editionKey/:subpage',
    name: 'nondegree-course-edition-subpage',
    component: ViewCourseEdition,
  },
  {
    path: '/nondegree/course/:courseKey/edition/:editionKey/unit/:unitKey',
    name: 'nondegree-course-edition-unit',
    component: ViewCourseEdition,
  },
  {
    path: '/nondegree/course/:courseKey/edition/:editionKey/unit/:unitKey/topic/:topicKey',
    name: 'nondegree-course-edition-unit-topic',
    component: ViewCourseEdition,
  },
]
