<template>
  <div>
    <fw-heading size="xl">Sobre o curso</fw-heading>
    <fw-panel v-if="units" title="Módulos" class="my-7">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-5">
        <div
          v-for="unit in units"
          :key="unit.index + '_' + edition.key"
          class="w-full group h-full bg-gray-200 flex-col flex p-5 rounded-xl"
          @click="$emit('go-to-unit', unit.key)"
        >
          <div class="opacity-50 group-hover:opacity-100">
            <fw-icon-book-mark-solid class="w-5 h-5" />
          </div>
          <div class="hidden mb-5 font-bold text-xl">M{{ unit.index }}</div>
          <div class="flex-1 flex flex-col mt-3">
            <div class="flex-1">
              <v-clamp class="w-full flex-1 text-left text-lg items-end flex font-semibold" autoresize :max-lines="3">
                {{ unit.title }}
              </v-clamp>
            </div>
            <div v-if="typeof unit.topics == 'number'" class="w-full text-left mt-2 opacity-50 font-semibold">
              {{ unit.topics }} tópico{{ unit.topics == 1 ? '' : 's' }}
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <template v-for="meta in metadataFields">
      <fw-panel
        v-if="course[meta] != null && course[meta].length > 0"
        :key="meta"
        :title="$t('title.metadata.' + meta)"
        boxed="sm"
        custom-class="bg-gray-100 text-gray-800"
        class="my-5"
      >
        <div class="content fixed-content" v-html="course[meta]"></div>
      </fw-panel>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    course: Object,
    edition: Object,
    units: Array,
  },

  data() {
    return {
      metadataFields: [
        'introduction',
        'goals',
        'professional_goals',
        'prerequisites',
        'methodology',
        'scientific_area',
        'certification',
        'evaluation',
        'bibliography',
        'observations',
      ],
    }
  },

  computed: {
    // TODO: Do not forget to remove this, Valentim
    isExceptionCourse() {
      return (process.env.VUE_APP_ENV == 'development' && this.course.key === 'oowvtc') || this.course.key === '4slkfk'
    },
  },
}
</script>

<style lang="scss">
.content.fixed-content p:not(:last-child) {
  margin-bottom: 1rem;
}
</style>

<i18n>
{
  "en": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do curso",
        "professional_goals": "Competências a desenvolver / Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações",
        "bibliography": "Bibliografia",
        "methodology": "Metodologia"
      }
    },
    "hours": "hours"
  },
  "pt": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do curso",
        "professional_goals": "Competências a desenvolver / Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações",
        "bibliography": "Bibliografia",
        "methodology": "Metodologia"
      }
    },
    "hours": "horas"
  }
}
</i18n>
