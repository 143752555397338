<template>
  <div class="fw-modal-course-enrollment flex flex-col">
    <div class="flex justify-between text-gray-600 px-6 pt-5 pb-3">
      <div class="text-base font-bold w-1/5">Nova inscrição</div>
      <div class="flex font-semibold pt-1 text-xs w-3/6 gap-5 justify-between">
        <div class="flex flex-col items-center">
          <fw-icon-run class="h-6 w-6 my-0.5 icon-progress" :class="{ done: isDone(0) }" />
          <div
            class="pt-3 pb-1 border-b"
            :class="{ 'border-primary text-primary': formStep == 0, 'border-transparent': formStep != 0 }"
          >
            Início
          </div>
        </div>
        <div class="flex flex-col items-center">
          <fw-icon-progress-check class="h-6 w-6 my-0.5 icon-progress" :class="{ done: isDone(1) }" />
          <div
            class="pt-3 pb-1 border-b"
            :class="{ 'border-primary text-primary': formStep == 1, 'border-transparent': formStep != 1 }"
          >
            Contactos
          </div>
        </div>

        <div class="flex flex-col items-center">
          <fw-icon-progress-check class="h-6 w-6 my-0.5 icon-progress" :class="{ done: isDone(2) }" />
          <div
            class="pt-3 pb-1 border-b"
            :class="{ 'border-primary text-primary': formStep == 2, 'border-transparent': formStep != 2 }"
          >
            Pessoal
          </div>
        </div>
        <div class="flex flex-col items-center">
          <fw-icon-progress-check class="h-6 w-6 my-0.5 icon-progress" :class="{ done: isDone(3) }" />
          <div
            class="pt-3 pb-1 border-b whitespace-nowrap"
            :class="{ 'border-primary text-primary': formStep == 3, 'border-transparent': formStep != 3 }"
          >
            Endereço / Filiação
          </div>
        </div>
        <div class="flex flex-col items-center">
          <fw-icon-progress-finish class="h-6 w-6 my-0.5 icon-progress" :class="{ done: success }" />
          <div
            class="pt-3 pb-1 border-b"
            :class="{ 'border-primary text-primary': formStep == 4, 'border-transparent': formStep != 4 }"
          >
            Confirmação
          </div>
        </div>
      </div>
      <div class="text-sm cursor-pointer font-semibold text-right w-1/5" @click="$emit('close')">Fechar</div>
    </div>

    <div v-if="course == null || edition == null" class="text-center py-10">
      <div class="text-2xl font-bold mb-5">Não foi possível encontrar a edição do curso</div>
      <div class="text-lg mb-5">Por favor, tente novamente mais tarde.</div>
      <div class="text-lg mb-5">Se o problema persistir, por favor contacte o suporte.</div>
      <div class="text-lg mb-5">Obrigado.</div>
      <div class="flex justify-center">
        <fw-button type="light" size="xs" @click="$emit('close')">
          <div class="px-5 py-1 text-base">Fechar</div>
        </fw-button>
      </div>
    </div>
    <div
      v-else-if="formStep == 0"
      class="form-page-1 flex-1 overflow-y-auto text-center flex flex-col items-center px-5"
    >
      <div
        class="bg-gray-300 rounded-2xl shadow-lg overflow-hidden mb-5"
        :style="{ backgroundImage: 'url(' + course.cover + ')' }"
      >
        <div class="bg-course-cover-linear-gradient pb-8 px-5 text-white flex flex-col items-center">
          <div class="font-extrabold text-3xl pt-10 pb-8">
            {{ $t('form.title') }}
          </div>

          <div class="font-semibold text-base leading-5 w-2/3">
            {{ $t('form.instructions') }}
          </div>

          <div class="flex flex-col w-full md:w-auto md:flex-row gap-5 mt-10 justify-center">
            <div
              class="rounded-lg flex flex-col flex-auto md:flex-1 text-left text-sm gap-5 justify-start items-start py-3 px-4"
            >
              <div class="flex h-10">
                <div class="text-center font-extrabold text-4xl text-primary w-14 flex-shrink-0">1.</div>
                <div class="font-semibold text-lg leading-5" v-html="$t('form.step1')"></div>
              </div>

              <div class="pl-14">
                {{ $t('form.step1Fullname') }}<br />
                {{ $t('form.step1Email') }}<br />
                {{ $t('form.step1PhoneNumber') }}
              </div>
            </div>
            <div
              class="rounded-lg flex flex-col flex-auto md:flex-1 text-left text-sm gap-5 justify-start items-start py-3 px-4"
            >
              <div class="flex h-10">
                <div class="text-center font-extrabold text-4xl text-primary w-14 flex-shrink-0">2.</div>
                <div class="font-semibold text-lg leading-5" v-html="$t('form.step2')"></div>
              </div>
              <div class="pl-14">
                {{ $t('form.step2NIF') }}<br />
                {{ $t('form.step2CC') }}<br />
                {{ $t('form.step2nationality') }}<br />
                {{ $t('form.step2gender') }}<br />
                {{ $t('form.step2birthday') }}<br />
              </div>
            </div>
            <div
              class="rounded-lg flex flex-col flex-auto md:flex-1 text-left text-sm gap-5 justify-start items-start py-3 pl-4 pr-8"
            >
              <div class="flex h-10">
                <div class="text-center font-extrabold text-4xl text-primary w-14 flex-shrink-0">3.</div>
                <div class="font-semibold text-lg leading-5" v-html="$t('form.step3')"></div>
              </div>
              <div class="pl-14">
                {{ $t('form.step3Address') }}<br />
                {{ $t('form.step3PostalCode') }}<br />
                {{ $t('form.step3City') }}<br />
                {{ $t('form.step3Country') }}<br />
                {{ $t('form.step2Filiation') }}
              </div>
            </div>
          </div>
          <div class="font-semibold text-base pt-5 pb-6">{{ $t('form.startInstructions') }}</div>

          <div class="font-bold text-2xl pt-6 border-t border-white text-white w-full opacity-60">
            {{ course.title }}
          </div>
          <div class="font-semibold text-base text-white pb-4 pt-0.5 opacity-60">
            {{ edition.title }}
          </div>

          <div class="flex gap-5 text-sm opacity-60">
            <div class="flex gap-2.5 text-white font-semibold items-center">
              <fw-icon-course-mode class="h-4 hidden md:block"></fw-icon-course-mode>
              {{ course.working_mode }}
            </div>
            <div class="flex gap-2.5 text-white font-semibold items-center">
              <fw-icon-course-ects class="h-4 hidden md:block"></fw-icon-course-ects>
              {{ course.ects }} ECTS
            </div>
            <div v-if="course.price && course.price > 0" class="flex gap-2.5 text-white font-semibold items-center">
              <fw-icon-course-price class="h-4 hidden md:block"></fw-icon-course-price>
              {{ course.price }}€
            </div>
            <div class="flex gap-2.5 text-white font-semibold items-center">
              <fw-icon-course-time class="h-4 hidden md:block"></fw-icon-course-time>
              {{ course.duration }} {{ course.duration == 1 ? $t('form.hour') : $t('form.hours') }}
            </div>
            <div v-if="edition && edition != null" class="flex gap-2.5 text-white font-semibold items-center">
              <fw-icon-course-calendar class="h-4 hidden md:block"></fw-icon-course-calendar>
              {{ $t('form.startsat') }} {{ edition.start_date | formatDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="formStep > 0" class="form-page-1 flex-1 overflow-y-auto flex flex-col">
      <CongratsModal
        v-if="success"
        :title="$t('form.success')"
        :subtitle="''"
        :confettis="false"
        :bottom-padding="false"
        :show-close-button="false"
      >
        <div class="relative mt-5">
          <div v-if="result !== null" class="receipt-bg rounded-md">
            <div class="px-12 py-5 text-left text-sm">
              <span class="font-semibold text-opacity-70 text-sm mt-3">{{ $t('form.receiptNumber') }}</span>
              {{ result.key }}<br />
              <span class="font-semibold text-opacity-70 text-sm mt-3">{{ $t('form.receiptCourse') }}</span>
              {{ course.title }}<br />
              <span class="font-semibold text-opacity-70 text-sm mt-3">{{ $t('form.receiptEdition') }}</span>
              {{ edition.title }}
            </div>
          </div>
        </div>
      </CongratsModal>
      <div v-else-if="formStep == 1" class="p-5">
        <div class="font-semibold text-lg">{{ $t('form.step1title') }}</div>
        <b-field
          :label="$t('labels.full_name')"
          :type="$v.full_name.$error ? 'is-danger' : ''"
          :message="$v.full_name.$error ? $t('errorMessages.InvalidFullName') : ''"
          label-for="full_name"
        >
          <b-input
            v-model="full_name"
            name="full_name"
            :placeholder="$t('placeholders.full_name')"
            type="text"
          ></b-input>
        </b-field>
        <b-field
          :label="$t('labels.email')"
          :type="$v.email.$error ? 'is-danger' : ''"
          :message="$v.email.$error ? $t('errorMessages.InvalidEmail') : ''"
          label-for="email"
        >
          <b-input v-model="email" name="email" disabled :placeholder="$t('placeholders.email')" type="text"></b-input>
        </b-field>
        <b-field
          :type="$v.phone.$error ? 'is-danger' : ''"
          :message="
            $v.phone.$error ? $t('errorMessages.InvalidPhoneNumber') : 'Exemplo: +351123456789 (cód. país + número)'
          "
          :label="$t('labels.phone')"
          label-for="phone"
        >
          <b-input v-model="phone" name="phone" :placeholder="$t('placeholders.phone')" type="text"></b-input>
        </b-field>
      </div>
      <div v-else-if="formStep == 2" class="px-5 pt-5 pb-8">
        <div class="font-semibold text-lg">{{ $t('form.step2title') }}</div>

        <b-field
          :type="$v.birthday.$error ? 'is-danger' : ''"
          :message="$v.birthday.$error ? $t('errorMessages.InvalidBirthdate') : ''"
          :label="$t('labels.birthday')"
        >
          <b-datepicker
            v-model="birthday"
            class="birthday"
            position="is-bottom-left"
            :max-date="today"
            :placeholder="$t('placeholders.birthday')"
            trap-focus
          >
          </b-datepicker>
        </b-field>

        <b-field
          :label="$t('labels.id_type')"
          label-for="id_type"
          :type="$v.id_type.$error ? 'is-danger' : ''"
          :message="$v.id_type.$error ? $t('errorMessages.InvalidIdtype') : ''"
        >
          <b-select v-model="id_type" class="w-full" :disabled="sending" :placeholder="$t('placeholders.id_type')">
            <option value="cc">Cartão de Cidadão (PT)</option>
            <option value="bi">Bilhete de identidade</option>
            <option value="passport">Passaporte</option>
            <option value="other">Outro</option>
          </b-select>
        </b-field>

        <div class="flex gap-5 pt-4 pb-3">
          <div class="flex-1">
            <b-field
              :type="$v.id_number.$error || ccPTNotValid ? 'is-danger' : ''"
              :message="$v.id_number.$error || ccPTNotValid ? $t('errorMessages.InvalidIdNumber') : ''"
              :label="$t('labels.id_number')"
              label-for="id_number"
              class="flex-1"
            >
              <b-input
                v-model="id_number"
                name="id_number"
                :placeholder="$t('placeholders.id_number')"
                type="text"
                @focus="ccfocus = true"
                @blur="ccfocus = false"
              ></b-input>
            </b-field>

            <b-field
              :type="$v.id_expire.$error ? 'is-danger' : ''"
              :message="$v.id_expire.$error ? $t('errorMessages.InvalidDate') : ''"
              :label="$t('labels.id_expire')"
            >
              <b-datepicker
                v-model="id_expire"
                :class="{ id_expire: id_type == 'cc', id_expire: id_type != 'cc' }"
                :years-range="[0, 15]"
                position="is-bottom-left"
                :min-date="today"
                :placeholder="$t('placeholders.id_expire')"
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
          <div v-if="id_type == 'cc'" class="pl-2 flex-shrink-0 w-80">
            <div
              class="bg-cyan-500 border border-cyan-500 border-opacity-40 bg-opacity-10 text-cyan-600 rounded-md text-xs pl-5 pr-5 pt-3 pb-5 relative items-stretch flex gap-5"
            >
              <div class="relative">
                <div class="flex gap-2 mb-2 mt-1">
                  <div class="text-sm font-medium">
                    O seu número de cartão de cidadão é constituído por 8 números e um conjunto de 4 caracteres, como
                    mostra o seguinte exemplo:
                  </div>
                  <fw-icon-info class="w-6 h-6 flex-shrink-0" />
                </div>
                <div class="flex items-center gap-0.5 py-0.5 px-1 opacity-90">
                  <div>Nº DOCUMENTO</div>
                  <div class="h-4 ml-2 w-2 border-l-2 border-gray-400 opacity-40"></div>
                  <div>DOCUMENT No.</div>
                </div>
                <div class="flex items-end opacity-90">
                  <div class="bg-cyan-500 bg-opacity-20">
                    <div class="flex items-center gap-0.5 py-0.5 px-1">
                      <div>Nº ID CIVIL</div>
                      <div class="h-4 ml-2 w-2 border-l-2 border-gray-400 opacity-40"></div>
                      <div>CIVIL ID No.</div>
                    </div>
                    <div class="text-center text-base text-black">00231231</div>
                  </div>

                  <div class="text-center text-base text-black px-1">5 ZX8</div>
                </div>

                <div
                  :class="{ show_animation_cc: ccfocus }"
                  class="cc_indicator h-3 absolute -bottom-3 -left-3 -right-2 rounded-sm"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <b-field
          :label="$t('labels.nationality')"
          label-for="nationality"
          :type="$v.nationality.$error ? 'is-danger' : ''"
          :message="$v.nationality.$error ? $t('errorMessages.InvalidNationality') : ''"
        >
          <b-select
            v-model="nationality"
            class="w-full"
            :disabled="sending"
            :placeholder="$t('placeholders.nationality')"
          >
            <option
              v-for="(nationalityNameOption, nationalityKey) in availableCountries"
              :key="nationalityKey"
              :value="nationalityKey"
            >
              {{ nationalityNameOption }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="pt-3"
          :type="$v.gender.$error ? 'is-danger' : ''"
          :message="$v.gender.$error ? $t('errorMessages.InvalidGender') : ''"
          :label="$t('labels.gender')"
          label-for="gender"
        >
          <b-select v-model="gender" class="w-full" :disabled="sending" :placeholder="$t('placeholders.gender')">
            <option value="m">Masculino</option>
            <option value="f">Feminino</option>
            <option value="other">Outro</option>
          </b-select>
        </b-field>

        <b-field
          class="pt-3"
          :type="$v.vat.$error || vatPTNotValid ? 'is-danger' : ''"
          :message="$v.vat.$error || vatPTNotValid ? $t('errorMessages.InvalidVat') : ''"
          :label="$t('labels.vat')"
          label-for="vat"
        >
          <b-input v-model="vat" name="vat" :placeholder="$t('placeholders.vat')" type="text"></b-input>
        </b-field>

        <b-field
          :label="$t('labels.vat_country_code')"
          label-for="vat_country_code"
          :type="$v.vat_country_code.$error ? 'is-danger' : ''"
          :message="$v.vat_country_code.$error ? $t('errorMessages.InvalidCountryCode') : ''"
        >
          <b-select
            v-model="vat_country_code"
            class="w-full"
            :disabled="sending"
            :placeholder="$t('placeholders.vat_country_code')"
          >
            <option
              v-for="(vatCountryNameOption, vatCountryKey) in availableCountries"
              :key="vatCountryKey"
              :value="vatCountryKey"
            >
              {{ vatCountryNameOption }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="pt-3"
          :type="$v.external_id.$error ? 'is-danger' : ''"
          :message="$v.external_id.$error ? $t('errorMessages.InvalidExternalId') : ''"
          :label="$t('labels.external_id')"
          label-for="external_id"
        >
          <b-input
            v-model="external_id"
            name="external_id"
            :placeholder="$t('placeholders.external_id')"
            type="text"
          ></b-input>
        </b-field>
      </div>
      <div v-else-if="formStep == 3" class="p-5">
        <div class="font-semibold text-lg">{{ $t('form.step3title') }}</div>

        <b-field
          :label="$t('labels.address')"
          label-for="address"
          :type="$v.address.$error ? 'is-danger' : ''"
          :message="$v.address.$error ? $t('errorMessages.InvalidAddress') : ''"
        >
          <b-input
            v-model="address"
            name="address"
            :placeholder="$t('placeholders.address')"
            type="text"
            :disabled="sending"
          ></b-input>
        </b-field>
        <div class="flex md:gap-5 flex-col md:flex-row">
          <b-field
            :type="$v.postal_code.$error || $v.postal_code2.$error ? 'is-danger' : ''"
            :message="$v.postal_code.$error || $v.postal_code2.$error ? $t('errorMessages.InvalidPostalCode') : ''"
            :label="$t('labels.postal_code')"
            label-for="postal_code"
          >
            <div class="flex items-center w-full md:w-auto">
              <b-input
                v-model="postal_code"
                class="flex-1"
                name="postal_code"
                :placeholder="''"
                type="text"
                :disabled="sending"
              ></b-input>
              <div class="p-3">-</div>
              <b-input
                v-model="postal_code2"
                name="postal_code2"
                :placeholder="''"
                type="text"
                custom-class="flex-1 md:flex-auto w-auto md:w-20"
                :disabled="sending"
              ></b-input>
            </div>
          </b-field>

          <b-field
            :type="$v.city.$error ? 'is-danger' : ''"
            :message="$v.city.$error ? $t('errorMessages.InvalidCity') : ''"
            class="flex-1"
            :label="$t('labels.city')"
            label-for="city"
          >
            <b-input
              v-model="city"
              name="city"
              :disabled="sending"
              :placeholder="$t('placeholders.city')"
              type="text"
            ></b-input>
          </b-field>
        </div>

        <b-field
          class="mb-3"
          :label="$t('labels.country')"
          label-for="country"
          :type="$v.country.$error ? 'is-danger' : ''"
          :message="$v.country.$error ? $t('errorMessages.InvalidCountry') : ''"
        >
          <b-select v-model="country" class="w-full" :disabled="sending" :placeholder="$t('placeholders.country')">
            <option v-for="(countryNameOption, countryKey) in availableCountries" :key="countryKey" :value="countryKey">
              {{ countryNameOption }}
            </option>
          </b-select>
        </b-field>

        <div class="font-semibold text-lg pt-3">{{ $t('form.step3title2') }}</div>

        <b-field
          :type="$v.fil_mother.$error ? 'is-danger' : ''"
          :message="$v.fil_mother.$error ? $t('errorMessages.InvalidFiliationMother') : ''"
          :label="$t('labels.mother')"
          label-for="fil_mother"
        >
          <b-input
            v-model="fil_mother"
            name="fil_mother"
            :placeholder="$t('placeholders.mother')"
            type="text"
          ></b-input>
        </b-field>

        <b-field
          :type="$v.fil_father.$error ? 'is-danger' : ''"
          :message="$v.fil_father.$error ? $t('errorMessages.InvalidFiliationFather') : ''"
          :label="$t('labels.father')"
          label-for="fil_father"
        >
          <b-input
            v-model="fil_father"
            name="fil_father"
            :placeholder="$t('placeholders.father')"
            type="text"
          ></b-input>
        </b-field>
      </div>
      <div v-else-if="formStep == 4" class="p-5">
        <div class="font-semibold text-lg text-center">Confirmação dos dados</div>
        <div class="font-semibold text-sm text-center py-5">
          A sua inscrição está praticamente pronta!<br />Basta confirmar todos os dados e carregar em Inscrever.
        </div>

        <div class="flex mb-5">
          <div
            class="rounded-lg flex flex-col flex-auto md:flex-1 text-left text-sm gap-5 justify-start items-start py-3 px-4"
          >
            <div class="font-semibold text-lg leading-5 h-7" v-html="$t('form.step1title')"></div>
            <div>
              <div class="text-xs text-gray-500">{{ $t('form.step1Fullname') }}</div>
              <div class="text-sm font-semibold mb-2">{{ full_name }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step1Email') }}</div>
              <div class="text-sm font-semibold mb-2">{{ email }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step1PhoneNumber') }}</div>
              <div class="text-sm font-semibold">{{ phone }}</div>
            </div>
          </div>
          <div class="flex flex-col flex-auto md:flex-1 text-left text-sm gap-5 justify-start items-start py-3 px-4">
            <div class="font-semibold text-lg leading-5 h-7" v-html="$t('form.step2title')"></div>

            <div>
              <div class="text-xs text-gray-500">{{ $t('form.step2NIF') }}</div>
              <div class="text-sm font-semibold mb-2">{{ vat }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step2CC') }}</div>
              <div class="text-sm font-semibold mb-2">{{ id_number }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step2gender') }}</div>
              <div class="text-sm font-semibold mb-2">{{ gender }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step2birthday') }}</div>
              <div class="text-sm font-semibold mb-2">{{ birthday | formatDate }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step2nationality') }}</div>
              <div class="text-sm font-semibold mb-2">{{ nationalityName }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step2externalId') }}</div>
              <div class="text-sm font-semibold mb-2">{{ external_id }}</div>
            </div>
          </div>
          <div
            class="flex flex-col flex-auto md:flex-1 text-left text-sm gap-5 justify-start items-start py-3 pl-4 pr-8"
          >
            <div class="font-semibold text-lg leading-5 h-7" v-html="$t('form.step3')"></div>

            <div>
              <div class="text-xs text-gray-500">{{ $t('form.step3Address') }}</div>
              <div class="text-sm font-semibold mb-2">{{ address }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step3PostalCode') }}</div>
              <div class="text-sm font-semibold mb-2">{{ postal_code }}-{{ postal_code2 }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step3City') }}</div>
              <div class="text-sm font-semibold mb-2">{{ city }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step3Country') }}</div>
              <div class="text-sm font-semibold mb-2">{{ countryName }}</div>
              <div class="text-xs text-gray-500">{{ $t('form.step2Filiation') }}</div>
              <div class="text-sm font-semibold">{{ fil_mother }}</div>
              <div class="text-sm font-semibold">{{ fil_father }}</div>
            </div>
          </div>
        </div>

        <b-field
          :type="$v.accept1.$error ? 'is-danger' : ''"
          :message="$v.accept1.$error ? 'Obrigatório' : ''"
          custom-class="is-small"
        >
          <b-checkbox v-model="accept1" size="is-small"
            >Tomei conhecimento do tratamento dos dados nos termos abaixo enunciados</b-checkbox
          >
        </b-field>
        <div class="text-xs text-gray-500 -mt-3 mb-3 ml-7">
          A UC, enquanto responsável pelo tratamento dos dados pessoais, garante o cumprimento do disposto no RGPD, bem
          como na demais legislação aplicável. Os dados solicitados no presente formulário são os estritamente
          necessários à inscrição no curso “{{ course.title }}”, promovido pela UC, e são conservados pelo período de 8
          anos, findo o qual serão destruídos. Não haverá transferências de dados para países terceiros, mas poderão
          existir comunicações ou conexões de dados com a plataforma de gestão académica da UC e com as Entidades
          financiadoras. Enquanto titular, e nos termos da lei, tenho os direitos de acesso, de retificação, de
          limitação do tratamento, de portabilidade, de oposição e de não ficar sujeito a decisões automatizadas,
          podendo estes ser exercidos a qualquer momento, por escrito, para lfa.prr@uc.pt. O Encarregado de Proteção de
          Dados da UC poderá ser contactado através de epd@uc.pt e a “Política de Privacidade e de Proteção de Dados da
          UC”, está disponível em https://www.uc.pt/protecao-de- dados/politica-de-privacidade/.
        </div>
        <b-field
          :type="$v.accept2.$error ? 'is-danger' : ''"
          :message="$v.accept2.$error ? 'Obrigatório' : ''"
          custom-class="is-small"
        >
          <b-checkbox v-model="accept2" size="is-small"
            >Declaro que as informações prestadas neste formulário são verdadeiras e da minha inteira
            responsabilidade.</b-checkbox
          >
        </b-field>
        <b-field
          :type="$v.accept3.$error ? 'is-danger' : ''"
          :message="$v.accept3.$error ? 'Obrigatório' : ''"
          custom-class="is-small"
        >
          <b-checkbox v-model="accept3" size="is-small"
            >Declaro ter conhecimento do incentivo sob a forma de desconto total/parcial no preço definido para o curso
            ou iniciativa, e/ou despesas de frequência e autorizo que o mesmo seja concedido, caso seja decidida a sua
            atribuição de acordo com o Regulamento em vigor.</b-checkbox
          >
        </b-field>
      </div>
    </div>

    <div class="flex justify-between px-5 pb-5 pt-0 gap-5 items-center">
      <div v-if="!success" class="text-xs text-gray-400 leading-4 w-1/2">
        Todos os dados que vamos solicitar servem apenas para fins académicos relacionados com o curso ao qual se está a
        inscrever.
      </div>
      <div class="flex-1"></div>
      <fw-button
        v-if="!success"
        :type="sending ? 'disabled' : 'light'"
        size="sm"
        class="px-10"
        @click.native="previous"
      >
        <span v-if="formStep == 0">{{ $t('form.cancel') }}</span
        ><span v-else>{{ $t('form.previous') }}</span>
      </fw-button>
      <div v-else></div>

      <fw-button
        :type="canGoNext && !sending ? 'primary' : 'disabled'"
        size="sm"
        class="px-10"
        :class="{ 'cursor-wait': sending }"
        @click.native="next"
      >
        <InnerLoader v-if="sending" class="my-1" />
        <span v-else-if="success">Fechar</span>
        <span v-else-if="formStep == 0">{{ $t('form.start') }}</span>
        <span v-else-if="formStep == 4">{{ $t('form.enroll') }}</span
        ><span v-else>{{ $t('form.next') }}</span>
      </fw-button>
    </div>
  </div>
</template>

<script>
const mustBeIntPhone = value => {
  var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/
  if (regex.test(value)) {
    //is valid international phone number
    return true
  } else {
    //is not a valid international phone number
    return false
  }
}

import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import CongratsModal from '@/fw-modules/fw-core-vue/ui/components/modals/CongratsModal'
import { required, email, minLength, sameAs, numeric } from 'vuelidate/lib/validators'
import InnerLoader from '@/fw-modules/fw-core-vue/ui/components/animation/InnerLoader.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ModalCourseEnrollment',
  components: { CongratsModal, InnerLoader },
  props: {
    course: {
      type: Object,
      required: true,
    },
    edition: {
      type: Object,
      required: true,
    },
    enrollment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      success: false,
      error: false,
      sending: false,
      result: null,

      formStep: 0,
      ccfocus: false,

      email: '',

      full_name: this.enrollment && this.enrollment.name ? this.enrollment.name : '',
      notify_by_phone: false,
      accept1: false,
      accept2: false,
      accept3: false,
      phone: this.enrollment && this.enrollment.phone ? '+351' + this.enrollment.phone : '',
      id_number: '',
      id_expire: null,
      birthday: null,
      vat: this.enrollment && this.enrollment.vat ? this.enrollment.vat : '',
      fil_mother: '',
      fil_father: '',
      postal_code: '',
      postal_code2: '',
      city: '',
      address: '',
      gender: '',
      country: 'PT',
      nationality: 'PT',
      vat_country_code: 'PT',
      external_id: null,
      id_type: 'cc',

      vatPTNotValid: false,
      ccPTNotValid: false,
    }
  },
  validations: {
    email: { required, email, min: minLength(5) },
    full_name: { required, min: minLength(5) },
    phone: { required, mustBeIntPhone },
    id_number: { required },
    id_type: { required },
    id_expire: { required },
    birthday: { required },
    gender: { required },
    nationality: { required },
    vat: { required },
    vat_country_code: { required },
    external_id: { required, min: minLength(4), numeric },
    fil_mother: { required, min: minLength(3) },
    fil_father: { required, min: minLength(3) },
    postal_code: { required, min: minLength(4), numeric },
    postal_code2: { required, min: minLength(3), numeric },
    city: { required, min: minLength(3) },
    address: { required, min: minLength(3) },
    country: { required },
    accept1: { required, sameAs: sameAs(() => true) },
    accept2: { required, sameAs: sameAs(() => true) },
    accept3: { required, sameAs: sameAs(() => true) },
  },
  computed: {
    availableCountries() {
      return utils.countryListAlpha2()
    },
    storeFullName() {
      return this.$store.state.session.user.full_name
    },
    storeEmail() {
      return this.$store.state.session.user.email
    },
    today() {
      return new Date()
    },
    progress() {
      return (this.formStep / 3) * 100 + '%'
    },
    canGoNext() {
      if (
        this.formStep === 0 ||
        (this.formStep === 1 && !this.$v.full_name.$error && !this.$v.email.$error && !this.$v.phone.$error)
      ) {
        return true
      } else if (
        this.formStep === 2 &&
        !this.$v.id_expire.$error &&
        !this.$v.vat.$error &&
        !this.$v.birthday.$error &&
        !this.$v.gender.$error &&
        !this.$v.nationality.$error &&
        !this.$v.id_type.$error &&
        !this.$v.id_number.$error
      ) {
        return true
      } else if (
        this.formStep === 3 &&
        !this.$v.postal_code.$error &&
        !this.$v.postal_code2.$error &&
        !this.$v.city.$error &&
        !this.$v.address.$error &&
        !this.$v.country.$error &&
        !this.$v.fil_father.$error &&
        !this.$v.fil_mother.$error
      ) {
        return true
      } else if (this.formStep === 4 && !this.$v.accept1.$error && !this.$v.accept2.$error && !this.$v.accept3.$error) {
        return true
      } else {
        return false
      }
    },
    countryName() {
      return this.availableCountries[this.country]
    },
    nationalityName() {
      return this.availableCountries[this.nationality]
    },
  },
  mounted() {
    this.email = this.storeEmail
    this.full_name = this.storeFullName
  },
  /*watch: {
    // whenever question changes, this function will run
    storeEmail(newEmail) {
      if (newEmail) {
        this.email = '' + newEmail
      }
    },
    storeFullName(newName) {
      if (newName) {
        this.full_name = '' + newName
      }
    },
  },*/

  methods: {
    isDone(step) {
      return step == 0 ? true : this.formStep > step
    },
    next() {
      if (this.success) {
        this.$emit('success')
        this.$emit('close')
        return
      }
      if (this.sending) return
      if (this.formStep === 1) {
        //validate page 1
        this.$v.full_name.$touch()
        this.$v.email.$touch()
        this.$v.phone.$touch()
        if (!this.$v.full_name.$error && !this.$v.email.$error && !this.$v.phone.$error) {
          this.formStep += 1
        }
      } else if (this.formStep === 0) {
        this.formStep += 1
      } else if (this.formStep === 2) {
        this.$v.id_number.$touch()
        this.$v.id_expire.$touch()
        this.$v.vat.$touch()
        this.$v.external_id.$touch()
        this.$v.birthday.$touch()
        this.$v.gender.$touch()
        this.$v.nationality.$touch()
        this.$v.id_type.$touch()

        if (
          !this.$v.id_expire.$error &&
          !this.$v.vat.$error &&
          !this.$v.vat_country_code.$error &&
          !this.$v.external_id.$error &&
          !this.$v.birthday.$error &&
          !this.$v.gender.$error &&
          !this.$v.nationality.$error &&
          !this.$v.id_type.$error &&
          !this.$v.id_number.$error
        ) {
          // Reset helpers
          this.vatPTNotValid = false
          this.ccPTNotValid = false

          // Manual check vat and cc
          if (this.vat_country_code === 'PT' && !this.validVATPT(this.vat)) {
            this.vatPTNotValid = true
          } else if (this.id_type === 'cc' && !this.validCC(this.id_number)) {
            this.ccPTNotValid = true
          } else {
            this.formStep += 1
          }
        }
      } else if (this.formStep === 3) {
        this.$v.postal_code.$touch()
        this.$v.postal_code2.$touch()
        this.$v.city.$touch()
        this.$v.address.$touch()
        this.$v.country.$touch()
        this.$v.fil_father.$touch()
        this.$v.fil_mother.$touch()

        if (
          !this.$v.postal_code.$error &&
          !this.$v.postal_code2.$error &&
          !this.$v.city.$error &&
          !this.$v.address.$error &&
          !this.$v.country.$error &&
          !this.$v.fil_father.$error &&
          !this.$v.fil_mother.$error
        ) {
          this.formStep += 1
        }
      } else if (this.formStep === 4) {
        this.$v.accept1.$touch()
        this.$v.accept2.$touch()
        this.$v.accept3.$touch()
        if (!this.$v.accept1.$error && !this.$v.accept2.$error && !this.$v.accept3.$error) {
          this.sendData()
        }
      }
    },
    previous() {
      if (this.sending) return
      if (this.formStep > 0) {
        this.formStep -= 1
      } else {
        this.$emit('close')
      }
    },
    async sendData() {
      this.sending = true
      try {
        var result = await ServiceAcademic.enrollCourse(this.course.key, {
          // Course and edition
          ignore_signup_dates: true,
          course_edition: this.edition.key,

          // Personal details
          full_name: this.full_name,
          email: this.email,
          phone: this.phone,
          id_type: this.id_type,
          id_number: this.id_number,
          id_expire: this.id_expire.toISOString().split('T')[0],
          birthday: this.birthday.toISOString().split('T')[0],
          nationality: this.nationality,
          gender: this.gender,
          vat: this.vat,
          vat_country_code: this.vat_country_code,
          external_id: this.external_id,
          notify_by_phone: this.notify_by_phone,
          filiation: {
            mother: this.fil_mother,
            father: this.fil_father,
          },
          address: {
            street: this.address,
            postal_code: this.postal_code + '-' + this.postal_code2,
            location: this.city,
            country: this.country,
          },
        })
        this.result = result
        this.success = true
        this.error = false
      } catch (error) {
        const errorKey = utils.errors(error).getKey()

        let message = this.$t(`errorMessages.${errorKey}` || 'errorMessages.GeneralError')
        console.error(message, error)

        this.$buefy.toast.open({
          duration: 5000,
          message: message,
          type: 'is-danger',
        })

        this.success = false
        this.error = true
      }
      this.sending = false
    },

    // Manual validators
    validCC(value) {
      value = value.replace(/\s/g, '')
      function getNumberFromChar(charCode) {
        switch (charCode) {
          case '0':
            return 0
          case '1':
            return 1
          case '2':
            return 2
          case '3':
            return 3
          case '4':
            return 4
          case '5':
            return 5
          case '6':
            return 6
          case '7':
            return 7
          case '8':
            return 8
          case '9':
            return 9
          case 'A':
            return 10
          case 'B':
            return 11
          case 'C':
            return 12
          case 'D':
            return 13
          case 'E':
            return 14
          case 'F':
            return 15
          case 'G':
            return 16
          case 'H':
            return 17
          case 'I':
            return 18
          case 'J':
            return 19
          case 'K':
            return 20
          case 'L':
            return 21
          case 'M':
            return 22
          case 'N':
            return 23
          case 'O':
            return 24
          case 'P':
            return 25
          case 'Q':
            return 26
          case 'R':
            return 27
          case 'S':
            return 28
          case 'T':
            return 29
          case 'U':
            return 30
          case 'V':
            return 31
          case 'W':
            return 32
          case 'X':
            return 33
          case 'Y':
            return 34
          case 'Z':
            return 35
          default:
            throw 'Invalid character'
        }
      }
      try {
        var sum = 0
        var secondDigit = false
        if (value.length !== 12) {
          return false
        }

        for (var i = value.length - 1; i >= 0; --i) {
          var valor = getNumberFromChar(value[i])
          if (secondDigit) {
            valor *= 2
            if (valor > 9) valor -= 9
          }
          sum += valor
          secondDigit = !secondDigit
        }
        return sum % 10 == 0
      } catch (e) {
        return false
      }
    },
    validVATPT(value) {
      // Check vat country (if not PT, accept any)
      if (
        value.substr(0, 1) != '1' && // pessoa singular
        value.substr(0, 1) != '2' && // pessoa singular
        value.substr(0, 1) != '3' && // pessoa singular
        value.substr(0, 2) != '45' && // pessoa singular não residente
        value.substr(0, 1) != '5' && // pessoa colectiva
        value.substr(0, 1) != '6' && // administração pública
        value.substr(0, 2) != '70' && // herança indivisa
        value.substr(0, 2) != '71' && // pessoa colectiva não residente
        value.substr(0, 2) != '72' && // fundos de investimento
        value.substr(0, 2) != '77' && // atribuição oficiosa
        value.substr(0, 2) != '79' && // regime excepcional
        value.substr(0, 1) != '8' && // empresário em nome individual (extinto)
        value.substr(0, 2) != '90' && // condominios e sociedades irregulares
        value.substr(0, 2) != '91' && // condominios e sociedades irregulares
        value.substr(0, 2) != '98' && // não residentes
        value.substr(0, 2) != '99' // sociedades civis
      ) {
        return false
      }
      var check1 = value.substr(0, 1) * 9
      var check2 = value.substr(1, 1) * 8
      var check3 = value.substr(2, 1) * 7
      var check4 = value.substr(3, 1) * 6
      var check5 = value.substr(4, 1) * 5
      var check6 = value.substr(5, 1) * 4
      var check7 = value.substr(6, 1) * 3
      var check8 = value.substr(7, 1) * 2
      var comparador = 0

      var total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8
      var divisao = total / 11
      var modulo11 = total - parseInt(divisao) * 11
      if (modulo11 == 1 || modulo11 == 0) {
        comparador = 0
      } // excepção
      else {
        comparador = 11 - modulo11
      }

      var ultimoDigito = value.substr(8, 1) * 1
      if (ultimoDigito != comparador) {
        return false
      }

      return true
    },
  },
}
</script>
<style>
.show_animation_cc {
  animation: show_cc 1s infinite;
}
@keyframes show_cc {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cc_indicator {
  @apply border-primary;
  border-right-width: 2.5px;
  border-left-width: 2.5px;
  border-bottom-width: 2.5px;
}
.bg-course-cover-linear-gradient {
  background: rgb(50, 68, 87);
  background: linear-gradient(0deg, rgba(50, 68, 87, 1) 20%, rgba(0, 0, 0, 0.5) 80%);
}
.icon-progress {
  color: #eeeeee;
}
.icon-progress.done {
  color: #30a898;
}
.zig-zag-top:before {
  background: linear-gradient(-45deg, #f1f1ec 16px, red 16px, blue 16px, transparent 0),
    linear-gradient(45deg, #f1f1ec 16px, transparent 0);
  background-position: left top;
  background-repeat: repeat-x;
  background-size: 22px 32px;
  content: ' ';
  display: block;
  height: 32px;
  width: 100%;
  position: relative;
  bottom: 32px;
  left: 0;
}
.receipt-bg {
  background-color: #f1f1ec;
}
.datepicker .dropdown-trigger .input[readonly] {
  min-height: 43px;
  background-color: rgba(225, 225, 225, 0.75);
}
.w-full .select {
  @apply w-full;
}

.w-full .select select {
  @apply w-full h-12;
}
.select:not(.is-multiple):not(.is-loading)::after {
  margin-top: -0.23em;
}
.is-danger.input {
  @apply border border-solid;
}
.control.has-icons-right .icon {
  top: 7px;
}
.control.has-icons-right .icon.is-right {
  right: 7px;
}

@media only screen and (min-width: 769px) {
  .datepicker.cc_expire .dropdown-menu {
    right: auto !important;
    left: 105% !important;
    top: -25px !important;
  }
  .datepicker.id_expire .dropdown-menu {
    right: 0px !important;
    top: -25px !important;
  }
}
</style>
<i18n>

{
  "en": {
    "link": {
      "anotherAccount": "Other account?"
    },
    "form": {
      "title": "Start course enrollment",
      "instructions": "The enrollment process is very simple. We just need the following data to submit your registration in this course:",
      "startInstructions": "To fill the form press the button “Start”.",
      "next": "Next",
      "success": "You are enrolled!",
      "receiptNumber": "Registration number:",
      "receiptCourse": "Course:",
      "receiptEdition": "Edition:",
      "step1": "Contact<br />Information",
      "step1title": "Contact Information",
      "step1Fullname": "Full name",
      "step1Email": "E-mail",
      "step1PhoneNumber": "Phone number",
      "step2": "Personal<br />Information",
      "step2title": "Personal Information",
      "step2NIF": "Tax payer number",
      "step2CC": "Document number of the citizen card",
      "step2nationality": "Nationality",
      "step2externalId": "Professional ID number",
      "step2gender": "Sex",
      "step2birthday": "Date of birth",
      "step2Filiation": "Filitaion",
      "step3": "Postal Address<br />Filiation",
      "step3title": "Postal Address",
      "step3title2": "Filiation",
      "step3Address": "Street",
      "step3PostalCode": "Postal code",
      "step3City": "City",
      "step3Country": "Country",
      "smsReminder": "Remind me of the start of the course by text message",
      "cancel": "Cancel",
      "previous": "Previous",
      "next": "Next",
      "enroll": "Finish",
      "startsat": "Starts at",
      "hour": "hour",
      "hours": "hours",
      "start": "Start"
    },
    "errorMessages": {
      "InvalidBirthdate": "The birthdate entered is not valid.",
      "InvalidNationality": "The nationality entered is not valid.",
      "InvalidIdtype": "The id type entered is not valid.",
      "InvalidGender": "The sex entered is not valid.",
      "ApplicationsNotOpen": "Registration for this course is not yet open.",
      "GeneralError": "Unable to enroll in the course. Please try again later. If the problem persists, please contact us through our support channel.",
      "InvalidIdNumber": "The citizen card number is not valid.",
      "InvalidDate": "The citizen card expiration date is not valid.",
      "MinDate": "The citizen card expiration date is not valid.",
      "InvalidVat": "The portuguese taxpayer number entered is not valid.",
      "InvalidExternalId": "O número de cédula profissional inserido não é válido.",
      "InvalidPostalCode": "The postal code entered is not valid.",
      "InvalidEmail": "The email entered is not valid.",
      "InvalidPhoneNumber": "The phone number entered is not valid. Example: +351123456789 (country code + number).",
      "InvalidFullName": "The full name entered is not valid.",
      "InvalidFiliationMother": "The mother name entered is not valid.",
      "InvalidFiliationFather": "The father name entered is not valid.",
      "InvalidAddress": "The address entered is not valid.",
      "InvalidCity": "The city entered is not valid.",
      "InvalidCountry": "The country entered is not valid.",
      "InvalidID": "The document number entered is not valid."
    },
    "labels": {
      "full_name": "Full name",
      "email": "E-mail",
      "phone": "Phone number",
      "id_expire": "Expiration date",
      "vat": "Tax payer number",
      "vat_country_code": "Tax payer country",
      "external_id": "Profissional ID number",
      "mother": "Filiation - mother full name",
      "father": "Filiation - father full name",
      "postal_code": "Postal code",
      "city": "City",
      "address": "Address",
      "country": "Country",
      "birthday": "Birthday",
      "nationality": "Nationality",
      "id_type": "Document type",
      "gender": "Sex",
      "id_number": "Document number"
    },
    "placeholders": {
      "full_name": "Tell us your full name",
      "email": "Tell us your e-mail",
      "phone": "Tell us your phone number",
      "id_expire": "Expiration date",
      "vat": "Insert your tax payer number",
      "vat_country_code": "Insert your tax payer country",
      "external_id": "Insert your professional ID",
      "mother": "Tell us your mother name",
      "father": "Tell us your father name",
      "city": "City",
      "address": "Street, number, floor, door",
      "country": "Select your residency country",
      "gender": "Insert your sex",
      "id_type": "Insert your document type",
      "nationality": "Tell us your nationality",
      "birthday": "Tell us your birthday",
      "id_number": "Insert your identification number"
    }
  },
  "pt": {
    "link": {
      "anotherAccount": "Outra conta?"
    },
    "form": {
      "title": "Iniciar inscrição no curso",
      "instructions": "O processo de inscrição é muito simples e precisamos apenas dos seguintes dados para submeter a sua inscrição neste curso:",
      "startInstructions": "Para começar o preenchimento, carregue no botão “Iniciar”.",
      "next": "Seguinte",
      "success": "Está inscrito(a)!",
      "receiptNumber": "Nº Inscrição:",
      "receiptCourse": "Curso:",
      "receiptEdition": "Edição:",
      "step1": "Informação de<br />Contacto",
      "step1title": "Informação de Contacto",
      "step1Fullname": "Nome completo",
      "step1Email": "E-mail",
      "step1PhoneNumber": "Número de telefone",
      "step2": "Informação<br />Pessoal",
      "step2title": "Informação Pessoal",
      "step2NIF": "Número de contribuinte",
      "step2CC": "Número do Documento do Identificação",
      "step2nationality": "Nacionalidade",
      "step2externalId": "Cédula profissional",
      "step2gender": "Género",
      "step2birthday": "Data de nascimento",
      "step2Filiation": "Filiação",
      "step3": "Endereço Postal<br />Filiação",
      "step3title": "Endereço Postal",
      "step3title2": "Filiação",
      "step3Address": "Morada",
      "step3PostalCode": "Código Postal",
      "step3City": "Localidade",
      "step3Country": "País",
      "smsReminder": "Lembrar-me do início do curso por SMS",
      "cancel": "Cancelar",
      "previous": "Anterior",
      "start": "Iniciar",
      "next": "Próximo",
      "enroll": "Finalizar",
      "startsat": "Começa a",
      "hour": "hora",
      "hours": "horas"
    },
    "labels": {
      "full_name": "Nome completo",
      "email": "E-mail",
      "phone": "Número de telemóvel",
      "id_expire": "Data de validade",
      "vat": "Número de contribuinte",
      "vat_country_code": "Indique o país do seu número de contribuinte",
      "external_id": "Número de cédula profissional",
      "mother": "Filiação - mãe",
      "father": "Filiação - pai",
      "postal_code": "Código Postal",
      "city": "Localidade",
      "address": "Morada",
      "country": "País",
      "birthday": "Data de nascimento",
      "nationality": "Nacionalidade",
      "id_type": "Tipo de documento de identidade",
      "gender": "Género",
      "id_number": "Número de documento de identidade"
    },
    "placeholders": {
      "full_name": "Insira o seu nome completo",
      "email": "Insira o seu e-mail",
      "phone": "Insira o seu número de telemóvel",
      "id_expire": "Data de validade",
      "vat": "Insira o seu NIF",
      "vat_country_code": "Indique o país do seu número de contribuinte",
      "external_id": "Insira o número de cédula profissional",
      "mother": "Insira o nome da sua mãe",
      "father": "Insira o nome do seu pai",
      "city": "Localidade",
      "address": "Rua, número, andar, porta",
      "country": "Seleccione o seu país de residência",
      "gender": "Insira o seu género",
      "id_type": "Insira o tipo de documento de identificação",
      "nationality": "Insira a sua nacionalidade",
      "birthday": "Insira a sua data de nascimento",
      "id_number": "Insira o número do seu documento de identificação"
    },
    "errorMessages": {
      "InvalidBirthdate": "A data de nascimento inserida não é válida.",
      "InvalidNationality": "A nacionalidade inserida não é válida.",
      "InvalidIdtype": "O tipo de documento de identificação inserido não é válido.",
      "InvalidGender": "O género inserido não é válido.",
      "ApplicationsNotOpen": "As inscrições para este curso ainda não estão abertas.",
      "GeneralError": "Não foi possível inscrever-se no curso. Por favor, tente mais tarde. Caso o problema persista, contacte-nos através do nosso canal de suporte.",
      "InvalidIdNumber": "O número do documento de identificação não é válido.",
      "InvalidVat": "O número de contribuinte português inserido não é válido.",
      "InvalidExternalId": "O número de cédula profissional inserido não é válido.",
      "InvalidPostalCode": "O código postal inserido não é válido. Exemplo: 3000-001.",
      "InvalidDate": "A data de validade para o documento de identificação não é válida.",
      "MinDate": "The citizen card expiration date is not valid.",
      "InvalidEmail": "O email inserido não é válido.",
      "InvalidPhoneNumber": "O número de telefone inserido não é válido.",
      "InvalidFullName": "O nome inserido não é válido.",
      "InvalidFiliationMother": "O nome da mãe inserido não é válido.",
      "InvalidFiliationFather": "O nome do pai inserido não é válido.",
      "InvalidAddress": "O endereço inserido não é válido.",
      "InvalidCity": "A cidade inserida não é válida.",
      "InvalidCountry": "O país inserido não é válido.",
      "InvalidID": "O número do documento de identificação inserido não é válido."
    }
  }
}
</i18n>
