import Janus from '@/fw-modules/fw-meetings-vue/probojs/janus'
import probo from '@/fw-modules/fw-meetings-vue/probojs'

const SIMULCAST_LEVEL_MAP = {
  low: 0,
  normal: 1,
  high: 2,
}

export default {
  data() {
    return this.getJanusSubscribersDefaultData()
  },

  methods: {
    getJanusSubscribersDefaultData() {
      const self = this
      return {
        streamsQueue: new Set(),
        streamsQueueOnHold: new Set(),
        streamsQueueForce: new Set(),

        audios: {},
        audioStreamRef: {},
        audioTalking: new Set(),
        talkingNames: '',

        subscriber: null,
        streamsToAdd: [],
        streamsById: {},
        audioOutput: {
          deviceId: localStorage.getItem('device.audio.output') || null,
          setDeviceIdCore(deviceId) {
            console.info(`Speaker updated ${this.deviceId} => ${deviceId}`)
            this.deviceId = deviceId
            if (deviceId) localStorage.setItem('device.audio.output', deviceId)
            else localStorage.removeItem('device.audio.output')
          },
          setDevice(deviceId) {
            if (deviceId !== this.deviceId) {
              this.setDeviceIdCore(deviceId)
              for (const stream of Object.values(self.streamsById)) this.setSink(stream)
            }
          },
          reRunSink: {},
          sinkLoading: {},
          async setSink(stream) {
            if (!this.deviceId || !stream.htmlElement || stream.type !== 'audio') return

            if (this.sinkLoading[stream.id]) {
              this.reRunSink[stream.id] = true
              return
            }
            this.sinkLoading[stream.id] = true

            try {
              if (stream.htmlElement.sinkId !== this.deviceId) {
                await stream.htmlElement.setSinkId(this.deviceId)
              }
            } catch (error) {
              if (error.name && error.name.toLocaleLowerCase() === 'notfounderror') {
                console.warn(`Speaker device ${this.deviceId} not found, using default`)
                if (this.deviceId) this.setDeviceIdCore(null)
              } else {
                console.error(`Failed to set speaker ${this.deviceId} for stream ${stream.id}`, error)
              }
            } finally {
              this.sinkLoading[stream.id] = false
              if (this.reRunSink[stream.id]) {
                delete this.reRunSink[stream.id]
                this.setSink(stream)
              }
            }
          },
        },

        dummyAudio: {
          checking: false,
          show: false,
          stream: null,
          errorType: null,
        },
        volume: process.env.VUE_APP_KEY == 'ucdigitaldesk' ? 0.7 : 1.0,
      }
    },

    changeVolume(volume) {
      this.volume = volume
      for (const stream of Object.values(this.streamsById)) {
        if (stream.type === 'audio') {
          stream.htmlElement.volume = volume
        }
      }
    },

    async runStreamsQueue() {
      while (this.streamsQueue.size) {
        let stream = null
        let streamId = null
        let streamQueueId = null
        for (streamQueueId of this.streamsQueue) {
          stream = this.streamsById[streamQueueId]
          if (!stream) {
            this.streamsQueue.delete(streamQueueId)
            continue
          } else if (stream.runningOnQueue) {
            continue
          } else {
            streamId = streamQueueId
            break
          }
        }

        if (!streamId) break
        stream.runningOnQueue = true
        this.streamsQueue.delete(streamId)

        try {
          if (stream.type === 'audio') continue

          const force = this.streamsQueueForce.has(streamId)
          if (force) this.streamsQueueForce.delete(streamId)

          await this.privateSyncVideoStream(stream, force)
        } catch (error) {
          console.error('Failed to run', error)
        } finally {
          stream.runningOnQueue = false
        }
      }
    },
    async privateSyncVideoStream(stream, force) {
      const isScreenShare = stream.type === 'screen_share'
      const pauseStream = isScreenShare ? this.screenSharesDisabled : this.camerasDisabled
      if (pauseStream) {
        if (stream.enabled) {
          stream.subscriber.update(false, false)
          stream.enabled = false
          console.debug(`Stream ${stream.id}:${stream.type} disabled (paused)`)
        }
        return
      }

      if (force || stream.newEnabled !== stream.enabled) {
        const enabled = stream.newEnabled !== stream.enabled ? stream.newEnabled : stream.enabled
        if (enabled) {
          stream.subscriber.update(true, isScreenShare)
          stream.enabled = true
          console.debug(`Stream ${stream.id}:${stream.type} activated`)
        } else {
          stream.subscriber.update(false, false)
          stream.enabled = false
          console.debug(`Stream ${stream.id}:${stream.type} disabled`)
        }
      }

      if (stream.enabled && stream.simulcast) {
        const newSimulcastLevel = this.lowestQuality
          ? 'low'
          : process.env.VUE_APP_KEY == 'ucmeetingscreen'
          ? 'high'
          : stream.newSimulcastLevel
        if (newSimulcastLevel !== stream.simulcastLevel) {
          stream.simulcastLevel = newSimulcastLevel

          const expectedLevel = SIMULCAST_LEVEL_MAP[stream.simulcastLevel]
          stream.subscriber.handle.send({
            message: {
              request: 'configure',
              substream: expectedLevel,
              temporal: Math.max(1, expectedLevel),
            },
            success: function(data) {
              console.info(`Simulcast ${expectedLevel} defined for ${stream.id}`, data)
            },
            error: function(error) {
              console.error(`Updating simulcast ${expectedLevel} for ${stream.id}`, error)
            },
          })
        }
      }

      // Make sure video is running
      if (stream.enabled) this.attachMediaToStream(stream)
    },

    startSubscriberWatch() {
      const self = this
      this.subscriber = new probo.Publisher({
        session: this.oldSession,
        publish_media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: false,
          videoSend: false,
        },
        master: true,
        token: this.instance.janus.token,
        success: function() {
          for (const data of self.streamsToAdd) {
            self.subscriber.addSubscriber(data[0], data[1], data[2])
          }
          self.streamsToAdd = []
        },
        simulcast_listener(subscriber, msg) {
          let stream = self.streamsById[subscriber.id]
          if (!stream) return

          let updated = false
          let substream = msg['substream']
          if (Number.isInteger(substream) && stream.substream !== substream) {
            stream.substream = substream
            console.debug('Got stream simulcast substream', stream.id, substream)
            updated = true
          }

          let temporal = msg['temporal']
          if (Number.isInteger(temporal) && stream.temporal !== temporal) {
            stream.temporal = temporal
            console.debug('Got stream simulcast temporal', stream.id, temporal)
            updated = true
          }

          if (updated) {
            if (!stream.simulcast) stream.simulcast = true
            self.reloadStream(stream)
          }
        },
        remote_stream_listener: {
          on: function(subscriber) {
            if (self.streamIsLocal(subscriber.id)) return

            let stream = self.streamsById[subscriber.id]
            if (!stream) {
              stream = self.streamsById[subscriber.id] = {
                id: subscriber.id,
                subscriber: subscriber,
                type: subscriber.type,
                pod: null,
                htmlElement: null,
                // Video
                enabled: true,
                newEnabled: true,
                started: false,
                simulcast: false,
                simulcastLevel: null,
                newSimulcastLevel: null,
                substream: -1,
                temporal: -1,
                bitrate: {
                  vars: {},
                },
              }

              if (stream.type === 'audio') {
                stream.htmlElement = document.createElement('audio')
                stream.htmlElement.id = `audio-stream-${stream.id}`
                stream.htmlElement.autoplay = !self.forceMute()
                stream.htmlElement.volume = self.volume
                self.addAudioStream(stream)
              } else {
                stream.htmlElement = document.createElement('video')
                stream.htmlElement.autoplay = true
                stream.htmlElement.playsInline = true
                stream.htmlElement.playsinline = true
                stream.htmlElement.poster = '/img/avatars/stream-poster.png'
                stream.htmlElement.muted = self.forceMute() || stream.type !== 'screen_share'
                stream.htmlElement.width = 320
                stream.htmlElement.height = 240
                self.addStreamPod(stream)
              }
            } else if (stream.type !== 'audio') {
              if (stream.htmlElement.offsetWidth) self.attachMediaToStream(stream)
              else if (stream.pod) self.syncPodVideoStream(stream.pod)
            }
          },
          started: function(subscriber) {
            let stream = self.streamsById[subscriber.id]
            if (stream) {
              console.debug('Stream started', stream.id)

              setTimeout(() => {
                if (!self.streamsById[stream.id]) return
                stream.started = true
                if (self.streamsQueueOnHold.has(stream.id)) {
                  self.streamsQueueOnHold.delete(stream.id)
                  self.streamsQueue.add(stream.id)
                  self.runStreamsQueue()
                } else if (self.camerasDisabled) {
                  self.streamsQueue.add(stream.id)
                  self.runStreamsQueue()
                }

                self.reloadPage()
              }, 1000)
            }
          },
          off: function(args) {
            let stream = self.streamsById[args.subscriber.id]
            if (stream) {
              console.debug('Stream ended', stream.id)
              delete self.streamsById[args.subscriber.id]

              if (stream.type === 'audio') self.removeAudioStream(stream)
              else self.removeStreamPod(stream)

              self.reloadPage()
            }
          },
          talking: function(args) {
            self.setAudioStreamTalking(args.message.id, true)
          },
          stopped_talking: function(args) {
            self.setAudioStreamTalking(args.message.id, false)
          },
        },
        event_handler: function(source, message) {
          if (message.error_code === 433) console.error('Unauthorized to join janus!')
          else if (message.reason === 'kicked') console.debug('You were kicked out of the meeting')
        },
        subscriber_slow_link(streamId, uplink, lost) {
          let stream = self.streamsById[streamId]
          if (stream) {
            self.setLostPackets(stream.type === 'audio' ? 'audio' : 'video', uplink, lost)
          }
        },
      }).attach()
    },
    async stopSubscriberWatch() {
      if (this.subscriber) {
        await this.subscriber.left()
        console.debug('Subscriber watch stop done')
      }
    },

    addStreamSubscriber(type, id, attendee) {
      if (!attendee) attendee = null

      if (!this.subscriber || !this.subscriber.id) this.streamsToAdd.push([type, id, attendee])
      else this.subscriber.addSubscriber(type, id, attendee)
    },
    removeStreamSubscriber(type, id) {
      if (this.subscriber) this.subscriber.removeSubscriber(type, id)
    },
    async attachMediaToStream(stream) {
      if (!this.streamsById[stream.id]) return

      const element = stream.htmlElement
      const src = element.srcObject ? element.srcObject : element.src
      if (!src) {
        Janus.attachMediaStream(element, stream.subscriber.stream)
        console.debug(`Media attached to stream ${stream.id} srcObject:${!!element.srcObject} src:${!!element.src}`)
      } else if (stream.subscriber.stream !== src) {
        Janus.attachMediaStream(element, stream.subscriber.stream)
        console.debug(`Media reattached to stream ${stream.id} srcObject:${!!element.srcObject} src:${!!element.src}`)
      } else if (element.paused && this.inParticipantsView) {
        try {
          await element.play()
          console.debug(`Stream ${stream.id} was paused, playing`)
        } catch (error) {
          if (typeof error === 'object' && String(error.name).toLocaleLowerCase() === 'aborterror') {
            console.debug(`Failed to play stream: ${stream.id}, stream paused`)
          } else {
            console.error(`Failed to play stream: ${stream.id} - ${error.name}`, error)
          }
        }
      } else if (!src.active) {
        console.warn(`video ${stream.type}:${stream.id} src is inactive`)
        // TODO check stream and add a backoff of 30 seconds - this.reloadStream(stream, true)
      }
    },
    updateStream(stream, enable = true, simulcastLevel = null) {
      stream.newEnabled = enable
      if (simulcastLevel !== null) stream.newSimulcastLevel = simulcastLevel
      this.reloadStream(stream)
    },
    reloadStream(stream, force) {
      if (force) this.streamsQueueForce.add(stream.id)

      if (!stream.started) {
        this.streamsQueueOnHold.add(stream.id)
      } else {
        this.streamsQueue.add(stream.id)
        this.runStreamsQueue()
      }
    },
    toggleLowestQuality() {
      this.lowestQuality = !this.lowestQuality
      console.debug(`Pods video quality defined as low:${this.lowestQuality}`)

      for (let stream of Object.values(this.streamsById)) {
        if (stream.type !== 'audio') {
          if (stream.started) this.streamsQueue.add(stream.id)
          else this.streamsQueueOnHold.add(stream.id)
        }
      }
      this.runStreamsQueue()
    },
    toggleAllVideos() {
      this.camerasDisabled = !this.camerasDisabled
      console.debug(`Pods video disabled:${this.camerasDisabled}`)

      for (let stream of Object.values(this.streamsById)) {
        if (stream.type !== 'audio') {
          if (stream.started) this.streamsQueue.add(stream.id)
          else this.streamsQueueOnHold.add(stream.id)
        }
      }
      this.runStreamsQueue()
    },

    setAudios(audios, changeType = null) {
      if (changeType === 'new') {
        this.audios = {}
        this.audioStreamRef = {}
        this.audioTalking = new Set()
      }

      for (const [key, newAudio] of Object.entries(audios)) {
        const audio = this.audios[key]

        // Maybe we added janus stream first, before ws event
        const earlierKey = `stream-${newAudio.id}`
        const earlierAudio = this.audios[earlierKey]
        if (earlierAudio) {
          if (audio && audio.id !== earlierAudio.id) {
            this.privateRemoveAudio(earlierKey)
            this.privateSetAudio(key, newAudio)
            console.error(
              `Attendee ${key}(${newAudio.name}) audio ${newAudio.id} found for earlier stream and now is different old:${earlierAudio.id}`
            )
          } else {
            this.privateRemoveAudio(earlierKey, true)
            this.privateSetAudio(key, newAudio, earlierAudio.stream)
            console.warn(`Attendee ${key}(${newAudio.name}) audio ${newAudio.id} found for earlier stream`)
          }
        } else if (!audio) {
          this.privateSetAudio(key, newAudio)
          console.info(`Attendee ${key}(${newAudio.name}) audio ${newAudio.id} added`)
        } else {
          const oldAudioId = audio.id
          if (oldAudioId !== newAudio.id) {
            Object.assign(audio, newAudio)
            audio.stream = null
            this.audioStreamRef[audio.id] = key
            delete this.audioStreamRef[oldAudioId]

            this.removeStreamSubscriber('audio', oldAudioId)
            this.addStreamSubscriber('audio', newAudio.id)
            console.info(`Attendee ${key}(${audio.name}) audio ${oldAudioId} replaced by ${audio.id}`)
          }
        }
      }

      if (changeType === 'diff') {
        const removeKeys = []
        for (const key of Object.keys(this.audios)) {
          if (!audios[key]) {
            removeKeys.push(key)
          }
        }
        if (removeKeys.length) {
          this.removeAudios(removeKeys)
        }
      }
    },
    addAudioStream(stream) {
      const key = this.audioStreamRef[stream.id]
      if (key) {
        const audio = this.audios[key]
        audio.stream = stream
        console.info(`Stream ${stream.id} added to audio ${key}(${audio.name})`)
      } else {
        const earlierKey = `stream-${stream.id}`
        this.privateSetAudio(earlierKey, { id: stream.id, name: '' }, stream)
        console.error(`No attendee audio found for stream ${stream.id}, created ref ${earlierKey}`)
      }

      document.getElementById('audio-streams').appendChild(stream.htmlElement)
      this.attachMediaToStream(stream)
      this.audioOutput.setSink(stream)
    },
    privateSetAudio(key, audio, stream = null) {
      this.audios[key] = audio
      this.audioStreamRef[audio.id] = key
      audio.stream = stream

      const attendee = this.attendees[key]
      if (attendee && attendee.pod) attendee.pod.withAudio = true

      if (!stream) {
        this.addStreamSubscriber('audio', audio.id)
      }
    },
    privateRemoveAudio(key, keepStream = false, withLog = false) {
      const audio = this.audios[key]
      if (audio) {
        delete this.audios[key]
        delete this.audioStreamRef[audio.id]

        const attendee = this.attendees[key]
        if (attendee && attendee.pod) attendee.pod.withAudio = false
        this.updateAudioTalkingCore(key, false)

        if (!keepStream) {
          if (key === this.attendee.key) this.stopAudio()
          else this.removeStreamSubscriber('audio', audio.id)
        }

        if (withLog) {
          console.info(`Attendee ${key}(${audio.name}) audio ${audio.id} removed`)
        }
      }
    },
    updateAudioTalkingCore(key, talking) {
      if (talking) this.audioTalking.add(key)
      else this.audioTalking.delete(key)

      const attendee = this.attendees[key]
      if (attendee && attendee.pod) {
        attendee.pod.talking = talking
        attendee.pod.checkVisibility()
      }

      let talkingNames = ''
      for (const key of this.audioTalking) {
        if (key !== this.attendee.key) {
          const audio = this.audios[key]
          if (audio && audio.name) {
            if (talkingNames) talkingNames += ', '
            talkingNames += audio.name
          }
        }
      }

      if (this.talkingNames !== talkingNames) {
        this.talkingNames = talkingNames
      }
    },
    setAudioStreamTalking(streamId, talking) {
      const key = this.audioStreamRef[streamId]
      if (!key) {
        console.warn(`No attendee found for talking event on stream ${streamId}`)
      } else if ((talking && !this.audioTalking.has(key)) || (!talking && this.audioTalking.has(key))) {
        this.updateAudioTalkingCore(key, talking)
      }
    },
    removeAudioStream(stream) {
      let element = document.getElementById('audio-streams')
      if (element) element.removeChild(stream.htmlElement)
    },
    removeAudios(keys) {
      for (const key of keys) {
        this.privateRemoveAudio(key, false, true)
      }
    },

    async checkAllAudio() {
      if (this.dummyAudio.checking) return
      this.dummyAudio.checking = true

      try {
        const checkPaused = !this.dummyAudio.show && !this.dummyAudio.stream && !this.dummyAudio.errorType

        for (let stream of Object.values(this.streamsById)) {
          if (stream.type !== 'audio' || !stream.started || !stream.htmlElement) continue

          if (stream.htmlElement.muted && !this.forceMute()) stream.htmlElement.muted = false

          if (checkPaused && stream.htmlElement.paused && !this.forceMute()) {
            try {
              await stream.htmlElement.play()
              console.debug(`Audio ${stream.id} play done`)
            } catch (error) {
              if (typeof error === 'object' && String(error.name).toLocaleLowerCase() === 'notallowederror') {
                // On Sarafi+iOS if user don't have camera/audio active, they can't listen other users audio
                // Create a dummy audio to fix it
                console.warn('Failed to play audio, show dummy audio')
                this.showDummyAudioModal()
              } else {
                console.error(`Failed to play audio: ${JSON.stringify(error)}`)
              }

              break
            }
          }
        }
      } finally {
        this.dummyAudio.checking = false
      }
    },
    async startDummyAudio() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        this.dummyAudio.stream = stream
        this.dummyAudio.errorType = null
        this.dummyAudio.show = false

        for (let stream of Object.values(this.streamsById)) {
          if (stream.type === 'audio' && stream.started && stream.htmlElement && !this.forceMute()) {
            if (stream.htmlElement.paused) {
              try {
                await stream.htmlElement.play()
                console.debug(`Audio ${stream.id} play done`)
              } catch (error) {
                console.error('Failed to play audio', error)
              }
            }

            // Looks like this does the job to start playing audio
            stream.htmlElement.muted = true
            stream.htmlElement.muted = false
          }
        }

        this.reloadPage()
      } catch (error) {
        this.dummyAudio.stream = null
        this.dummyAudio.show = false

        if (typeof error === 'object' && String(error.name).toLocaleLowerCase() === 'notallowederror') {
          this.dummyAudio.errorType = 'notAllowed'
          console.info(`Failed to start dummy audio "${this.dummyAudio.errorType}" ${JSON.stringify(error)}`)
        } else {
          console.error(`Failed to start dummy audio: ${JSON.stringify(error)}`, error)
        }

        this.reloadPage()
      }
    },
    stopDummyAudio() {
      if (!this.dummyAudio.stream) return

      this.dummyAudio.stream.getTracks().forEach(function(track) {
        track.stop()
      })
    },
    showDummyAudioModal() {
      var isClassroomScreen = typeof window.localStorage.getItem('meetings.subscribed.room') !== 'undefined'
      if (isClassroomScreen || this.dummyAudio.show || this.dummyAudio.stream || this.dummyAudio.errorType) return
      this.dummyAudio.show = true

      const self = this
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        message: `<h4>Áudio não autorizado pelo dispositivo</h4>
            <div class="has-margin-top">O seu navegador tem uma limitação que impede a receção de áudio dos restantes
            participantes. Por isso, para manter a receção de áudio estável, o seu navegador vai solicitar
            permissão para usar o microfone. Apesar desta autorização, o estado do seu microfone (ligado ou desligado) será sempre refletido no ícone
            <faicon icon="microphone" size="xs"></faicon>.</div>`,
        indefinite: true,
        queue: false,
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        canCancel: true,
        onConfirm: this.startDummyAudio,
        onCancel: () => {
          self.dummyAudio.show = false
          console.debug('Dummy audio canceled')
        },
      })
    },

    async updateStreamStats(stream, withStats) {
      if (!this.streamsById[stream.id]) return

      const PC = stream.subscriber.handle ? stream.subscriber.handle.webrtcStuff.pc : null
      if (!PC) return

      const bitrate = stream.bitrate
      for (let statsItem of await PC.getStats()) {
        if (!statsItem) continue
        statsItem = statsItem[1]

        if (statsItem.type === 'inbound-rtp') {
          if (
            !statsItem.id.includes('rtcp') &&
            (statsItem.mediaType === 'video' || statsItem.id.toLowerCase().includes('video'))
          ) {
            this.buildBitrateStats(bitrate, statsItem, statsItem.bytesReceived, withStats)
          }
        } else if (withStats && statsItem.type === 'track' && statsItem.frameWidth) {
          if (bitrate.width !== statsItem.frameWidth) bitrate.width = statsItem.frameWidth
          if (bitrate.height !== statsItem.frameHeight) bitrate.height = statsItem.frameHeight
        }
      }
    },

    forceMute() {
      //check if element with app id has a data-mute attribute
      const element = document.getElementById('app')
      if (element && element.dataset.muted) {
        console.log('Forcing mute')
        return true
      }
      return false
    },
  },
}
