import { helpers } from 'vuelidate/lib/validators'
const CC_NUMBER_FROM_CHAR = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 18,
  J: 19,
  K: 20,
  L: 21,
  M: 22,
  N: 23,
  O: 24,
  P: 25,
  Q: 26,
  R: 27,
  S: 28,
  T: 29,
  U: 30,
  V: 31,
  W: 32,
  X: 33,
  Y: 34,
  Z: 35,
}

export const postalCode = prop =>
  helpers.withParams({ type: 'postalCode', prop }, value => {
    // prop used to check if validations can be run
    if (prop === false) return true
    if (!value) return false
    const postalCode = typeof value === 'string' ? value : value.toString()
    return /^\d{4}-\d{3}?$/.test(postalCode)
  })

export const nif = helpers.withParams({ type: 'nif' }, value => {
  if (!value) return false
  const nif = typeof value === 'string' ? value : value.toString()
  const validationSets = {
    one: ['1', '2', '3', '5', '6', '8'],
    two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99'],
  }
  if (nif.length !== 9) return false
  if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) return false
  const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2
  const modulo11 = Number(total) % 11
  const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11
  return checkDigit === Number(nif[8])
})

export const nifOptional = helpers.withParams({ type: 'nifOptional' }, value => {
  if (!value) return true
  else return nif(value)
})

export const vat = nif

export const validCC = helpers.withParams({ type: 'cc' }, (value, vm) => {
  if (!value) return false
  if (vm.identity_type != 'cc') return true
  const cc = typeof value === 'string' ? value.replace(/\s/g, '') : value.toString().replace(/\s/g, '')

  let sum = 0,
    secondDigit = false,
    index = cc.length - 1
  if (cc.length !== 12) return false

  for (index; index >= 0; index--) {
    let val = CC_NUMBER_FROM_CHAR[cc[index]]

    if (secondDigit === true) {
      val *= 2
      if (val > 9) val -= 9
    }
    sum += val
    secondDigit = !secondDigit
  }

  return sum % 10 == 0
})

export const academicYear = prop =>
  helpers.withParams({ type: 'academicYear', prop }, value => {
    // prop used to check if validations can be run
    if (prop === false) return true
    if (!value || !value.start || !value.end) return false

    const year1 = parseInt(value.start)
    const year2 = parseInt(value.end)

    // Min year is 1000
    if (year1 <= 1000 || year2 <= 1000) return false

    if (year1 + 1 != year2) return false

    // Max year is current year + 1
    return year2 <= new Date().getFullYear() + 1
  })

export const alphanumeric = helpers.withParams({ type: 'alphanumeric' }, value => {
  if (!value) return false

  const regex = new RegExp('^[a-zA-Z0-9]+$')
  if (!regex.test(value)) {
    return false
  }

  return true
})

export const minIf = helpers.withParams({ type: 'minIf' }, value => {
  return !helpers.req(value) || value.length > 2
})

export const oneOf = options =>
  helpers.withParams({ type: 'oneOf', value: options }, value => {
    if (Array.isArray(value)) {
      return !helpers.req(value) || value.every(item => options.indexOf(item) >= 0)
    }
    return !helpers.req(value) || value.indexOf(options) >= 0
  })

export const decimalPlacesCheck = helpers.withParams({ type: 'decimalPlacesCheck' }, value => {
  return !helpers.req(value) || /^\d+(?:\.\d{1,2})?$/.test(value)
})
