<template>
  <ul class="side-block-account flex-none opacity-75 hover:opacity-100">
    <li>
      <router-link
        to="/account"
        class="inline-flex items-center w-full text-sm font-medium text-gray-500 transition-colors duration-150 hover:text-primary leading-1"
      >
        <span class="p-1 rounded-xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="h-6 w-6 fill-current"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
            />
          </svg>
        </span>
        <span class="ml-2"> {{ language === 'en' ? 'Profile' : 'Perfil' }} </span>
      </router-link>
    </li>
    <li>
      <a
        target="_blank"
        :href="helpUrl"
        class="inline-flex items-center w-full text-sm font-medium text-gray-500 transition-colors duration-150 hover:text-primary leading-1"
      >
        <span class="p-1 rounded-xl">
          <svg
            class="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
            />
          </svg>
        </span>
        <span class="ml-2"> {{ language === 'en' ? 'Help & support' : 'Ajuda e suporte' }}</span>
      </a>
    </li>
    <li>
      <router-link
        to="/logout"
        class="inline-flex items-center w-full text-sm font-medium text-gray-500 transition-colors duration-150 hover:text-primary leading-1"
      >
        <span class="p-1 rounded-xl">
          <svg
            class="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M6.265 3.807l1.147 1.639a8 8 0 1 0 9.176 0l1.147-1.639A9.988 9.988 0 0 1 22 12c0 5.523-4.477 10-10 10S2 17.523 2 12a9.988 9.988 0 0 1 4.265-8.193zM11 12V2h2v10h-2z"
            />
          </svg>
        </span>

        <span class="ml-2"> {{ language === 'en' ? 'Logout' : 'Terminar sessão' }} </span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SideBlockAccount',
  props: {
    helpUrl: String,
    language: {
      type: String,
      default: 'pt',
    },
  },

  data() {
    return {
      openIntroNewVersionModal: false,
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
}
</script>
