<template>
  <div class=" flex-shrink-0 pb-5">
    <div class="mb-3 font-bold">
      1. Tendo em conta o seu nível de formação, como classifica a qualidade dos conteúdos do módulo?
    </div>
    <div class="flex feedback mb-5 border-b border-gray-50 pb-5">
      <div class="flex gap-2">
        <div class="flex items-center">
          <b-radio v-model="feedback['content_quality']" native-value="0"> </b-radio>
          <div class="text-sm">Muito baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['content_quality']" native-value="1"> </b-radio>
          <div class="text-sm">Baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['content_quality']" native-value="2"> </b-radio>
          <div class="text-sm">Moderada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['content_quality']" native-value="3"> </b-radio>
          <div class="text-sm">Elevada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['content_quality']" native-value="4"> </b-radio>
          <div class="text-sm">Muito elevada</div>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>

    <div class="mb-3 font-bold">
      2. Como classifica as competências de comunicação dos apresentadores (ex. clareza, confiança e profissionalismo)?
    </div>
    <div class="flex feedback mb-5 border-b border-gray-50 pb-5">
      <div class="flex gap-2">
        <div class="flex items-center">
          <b-radio v-model="feedback['speakers_competence']" native-value="0"> </b-radio>
          <div class="text-sm">Muito baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['speakers_competence']" native-value="1"> </b-radio>
          <div class="text-sm">Baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['speakers_competence']" native-value="2"> </b-radio>
          <div class="text-sm">Moderada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['speakers_competence']" native-value="3"> </b-radio>
          <div class="text-sm">Elevada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['speakers_competence']" native-value="4"> </b-radio>
          <div class="text-sm">Muito elevada</div>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>

    <div class="mb-3 font-bold">
      3. Como classifica a pertinência/interesse prático dos casos clínicos apresentados?
    </div>
    <div class="flex feedback mb-5 border-b border-gray-50 pb-5">
      <div class="flex gap-2">
        <div class="flex items-center">
          <b-radio v-model="feedback['clinicalcases_interest']" native-value="0"> </b-radio>
          <div class="text-sm">Muito baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['clinicalcases_interest']" native-value="1"> </b-radio>
          <div class="text-sm">Baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['clinicalcases_interest']" native-value="2"> </b-radio>
          <div class="text-sm">Moderada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['clinicalcases_interest']" native-value="3"> </b-radio>
          <div class="text-sm">Elevada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['clinicalcases_interest']" native-value="4"> </b-radio>
          <div class="text-sm">Muito elevada</div>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>

    <div class="mb-3 font-bold">
      4. Como classifica a qualidade do vídeo e do áudio das apresentações?
    </div>
    <div class="flex feedback mb-5 border-b border-gray-50 pb-5">
      <div class="flex gap-2">
        <div class="flex items-center">
          <b-radio v-model="feedback['video_quality']" native-value="0"> </b-radio>
          <div class="text-sm">Muito baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['video_quality']" native-value="1"> </b-radio>
          <div class="text-sm">Baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['video_quality']" native-value="2"> </b-radio>
          <div class="text-sm">Moderada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['video_quality']" native-value="3"> </b-radio>
          <div class="text-sm">Elevada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['video_quality']" native-value="4"> </b-radio>
          <div class="text-sm">Muito elevada</div>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>

    <div class="mb-3  font-bold">
      5. Como classifica a plataforma UC Student quanto à usabilidade (facilidade em aceder aos conteúdos e realizar a
      avaliação)?
    </div>
    <div class="flex feedback mb-5 border-b border-gray-50 pb-5">
      <div class="flex gap-2">
        <div class="flex items-center">
          <b-radio v-model="feedback['ucstudent']" native-value="0"> </b-radio>
          <div class="text-sm">Muito baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['ucstudent']" native-value="1"> </b-radio>
          <div class="text-sm">Baixa</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['ucstudent']" native-value="2"> </b-radio>
          <div class="text-sm">Moderada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['ucstudent']" native-value="3"> </b-radio>
          <div class="text-sm">Elevada</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['ucstudent']" native-value="4"> </b-radio>
          <div class="text-sm">Muito elevada</div>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>

    <div class="mb-3  font-bold">
      6. Recomendaria este módulo a um colega?
    </div>
    <div class="flex feedback mb-5 border-b border-gray-50 pb-5">
      <div class="flex gap-2">
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="1"> </b-radio>
          <div class="text-sm">1</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="2"> </b-radio>
          <div class="text-sm">2</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="3"> </b-radio>
          <div class="text-sm">3</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="4"> </b-radio>
          <div class="text-sm">4</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="5"> </b-radio>
          <div class="text-sm">5</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="6"> </b-radio>
          <div class="text-sm">6</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="7"> </b-radio>
          <div class="text-sm">7</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="8"> </b-radio>
          <div class="text-sm">8</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="9"> </b-radio>
          <div class="text-sm">9</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['recommendation']" native-value="10"> </b-radio>
          <div class="text-sm">10</div>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>
    <div class="mb-3  font-bold">
      7. Há algum conteúdo que não tenha sido abordado e que ache útil? <span class="text-xs pl-2">(Opcional)</span>
    </div>
    <b-field class="mb-6 border-b border-gray-50">
      <b-input v-model="feedback['content_suggestion']" maxlength="250" type="textarea"></b-input>
    </b-field>

    <div class="mb-3 font-bold">
      8. Caso deseje adicionar algum comentário ou sugestão, pode fazê-lo aqui.
      <span class="text-xs pl-2">(Opcional)</span>
    </div>
    <b-field class="mb-6 border-b border-gray-50">
      <b-input v-model="feedback['suggestions']" maxlength="250" type="textarea"></b-input>
    </b-field>

    <div class="mb-3 font-bold">
      9. Estou satisfeito de forma global com o módulo.
    </div>
    <div class="flex feedback mb-3">
      <div class="flex gap-2">
        <div class="flex items-center">
          <b-radio v-model="feedback['global_satisfation']" native-value="1"> </b-radio>
          <div class="text-sm">Sim</div>
        </div>
        <div class="flex items-center">
          <b-radio v-model="feedback['global_satisfation']" native-value="0"> </b-radio>
          <div class="text-sm">Não</div>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>
    <div class=" pb-2">
      Justificação da sua satisfação
      <span class="text-xs pl-2">(Opcional)</span>
    </div>
    <b-field class="mb-5">
      <b-input v-model="feedback['global_satisfation_comment']" maxlength="250" type="textarea"></b-input>
    </b-field>

    <div class="flex">
      <div class="flex-1"></div>
      <fw-button :type="saving ? 'disabled' : 'primary'" @click.native="$emit('save')">Guardar</fw-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PanelFeedbackModule',
  props: {
    feedback: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>
<style>
.feedback .b-radio.radio:not(.button) {
  @apply m-0;
}
.feedback .field {
  @apply flex items-center;
}
.feedback .field,
.feedback .field:not(:last-child) {
  @apply m-0;
}
</style>
