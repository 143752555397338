<template>
  <div v-if="sessions.length" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-5 text-base">
    <div
      v-for="session in sessions"
      :key="'s-' + session.key + '-' + session.unit.key"
      class="border-b pb-3 flex flex-col gap-0.5"
    >
      <div class="flex items-center justify-between gap-2">
        <fw-label marginless>{{ session.start_date | formatDayWithTime }}</fw-label>
        <div v-if="session.meeting_presence_type == 'online'"></div>
      </div>
      <div>
        <button class="font-bold text-left w-full leading-5" @click="goTo(session)">
          <span class="text-primary">{{ session.title }}</span> - {{ session.unit.title }}
        </button>
      </div>
      <div v-if="session.meeting_presence_type == 'online'" class="text-gray-500">
        <div class="text-sm font-medium flex items-center gap-2 mb-2">
          <fw-icon-live class="text-gray-500 w-5 h-5"></fw-icon-live> Online
        </div>
        <div class="text-xs">
          A sua participação nesta aula deve ser online, através da sala virtual da turma.
        </div>
      </div>
      <div
        v-if="session.meeting_presence_type != 'online' && (session.space || session.building)"
        class="text-sm text-gray-500 font-medium"
      >
        <span v-if="session.space">{{ session.space.title }}</span>
        <span v-if="session.building"> - {{ session.building.title }}</span>
      </div>
    </div>
  </div>
  <div v-else class="text-sm text-gray-500 text-center p-4">
    Não existem sessões para apresentar.
  </div>
</template>

<script>
export default {
  props: {
    sessions: Array,
    goToRoom: Boolean,
  },

  methods: {
    goTo(session) {
      return this.$router.push({
        name: this.goToRoom ? 'live' : 'class',
        params: { key: this.goToRoom ? session.edition.meeting.key_alias : session.edition.key },
      })
    },
  },
}
</script>
