<template>
  <FullPageLoading v-if="initialLoading" icon="survey" title="Exame" />
  <Forbidden v-else-if="forbidden" />
  <MobileNotAllowed v-else-if="isMobileOrSmallTablet && !isDigitalPad" />
  <LayoutDefault
    v-else
    :header-session-nav="false"
    :header-apps="false"
    :header-notifications="false"
    :main-footer="false"
    :disable-back-to="true"
    :listen-back-event="true"
    @back="exitExam"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div v-if="isDigitalPad" class="flex gap-2 items-center">
          <fw-tag v-if="debug && isDev" type="light-orange">DEBUG</fw-tag>
          <div v-if="noInternet" class="bg-gray-200 rounded-full h-7 w-7 flex flex-col justify-center items-center">
            <fw-icon-offline class="h-4 w-4 text-red-500" />
          </div>
          <fw-button type="transparent" @click.native="refreshPage()">
            <fw-icon-refresh class="h-5 w-5" />
          </fw-button>
        </div>
        <div class="flex flex-col transition-all">
          <v-clamp v-if="examTitle" autoresize :max-lines="1" class="font-semibold text-md leading-5 max-w-prose">
            {{ examTitle }}
          </v-clamp>
          <span v-else class="text-gray-200">{{ $t('notitle') }}</span>
          <fw-label size="xs" custom-class="hidden sm:flex items-center gap-1" marginless>
            <span v-if="instance">{{ instance.type == 'exam' ? 'Exame' : 'Inquérito' }}</span>
            <fw-icon-survey class="w-3 h-3" />
            <span class="is-family-code">{{ instanceKey }}</span>
          </fw-label>
        </div>
        <div
          v-if="instance && instance.status == 'running'"
          class="transition-all w-24"
          :class="{ 'opacity-0': !showTitle }"
        >
          <TextStatus>{{ $t('running') }}</TextStatus>
        </div>
      </div>
    </template>
    <template v-if="running" #header-toolbar>
      <div class="flex items-center gap-5">
        <ViewMode v-if="false" :view="view" @view="view = $event" />
        <div
          class="h-3 w-3 rounded-full"
          :class="{
            'bg-yellow-400': dirtyData && !saved,
            'bg-gray-200': !dirtyData && !saved,
            'bg-primary': saved,
          }"
        ></div>
        <SideBlockLanguage v-if="availableLanguages.length > 1" />
        <div class="flex gap-1 items-center">
          <div
            class="text-white rounded-full w-4 h-4 flex justify-center items-center"
            :class="{
              'bg-gray-300': answeredQuestions !== totalQuestions,
              'bg-primary': answeredQuestions === totalQuestions,
            }"
          >
            <fw-icon-check class="h-4/5 w-4/5"></fw-icon-check>
          </div>
          <div class="font-medium leading-5 text-right">
            <span>{{ answeredQuestions }}</span>
            <span class="opacity-80">/{{ totalQuestions }}</span>
          </div>
        </div>
        <div>
          <TimeCounter :remaining-seconds="remainingSeconds"></TimeCounter>
        </div>
        <div>
          <fw-button type="link-danger" :disabled="lockActions" @click.native="confirmDelivery">{{
            $t('endbutton')
          }}</fw-button>
          <fw-button type="link-muted" :disabled="lockActions" @click.native="confirmQuit(false)">{{
            $t('giveupbutton')
          }}</fw-button>
        </div>
      </div>
    </template>
    <template #main-content>
      <!-- Exam intro -->
      <div
        v-if="instance"
        :class="{ 'max-w-screen-lg mx-auto w-full pb-5 px-5': view != 'slides', 'py-12 px-12': view == 'slides' }"
      >
        <PanelExamCover
          :form="form"
          :instance="instance"
          :instance-key="instanceKey"
          :first-loading="initialLoading"
          :language="language"
          :exam-ended="examEnded"
          :can-start="canStart"
          :status="status"
          :is-running="isRunning"
          :loading="loading"
          :with-answer="withAnswer"
          :show-actions="canShowActions"
          :users="users"
          :is-form="isForm"
          :mode="instance.mode"
          class="mb-5"
          @start="startExam"
        >
        </PanelExamCover>
        <PanelExamInstructionsAndActions
          id="exam-instructions"
          :form="form"
          :instance="instance"
          :instance-key="instanceKey"
          :first-loading="initialLoading"
          :language="language"
          :exam-ended="examEnded"
          :can-start="canStart"
          :status="status"
          :loading="loading"
          :users="users"
          :is-running="running"
          :with-answer="withAnswer"
          :is-form="isForm"
        >
        </PanelExamInstructionsAndActions>
      </div>

      <!-- Exam content -->
      <div
        v-if="running && pages.length > 0"
        id="exam-start"
        :class="{ 'py-12 px-12': view == 'slides', 'max-w-4xl mx-auto py-12 px-5': view != 'slides' }"
      >
        <div class="flex gap-8">
          <div class="w-full flex-1 highlight-box">
            <div
              class="p-2 pb-7 mb-3 text-center text-gray-400 border-t border-dashed border-gray-300 nohighlight"
              style="font-size: 0.6rem; line-height: 1rem"
            >
              {{ $t('examstartlabel') }}
            </div>
            <div v-for="(page, p) in pages" :key="'page_' + p">
              <fw-form
                :id="page.key"
                :ref="'page_' + page.key"
                :exam="isExam"
                :font-size-ratio="fontSize"
                :language="language"
                :form="page.schema"
                :errors="{}"
                :data="pagesState[page.key]"
                :editable="true"
                :disable-context-menu="false"
                :key-name="'key'"
                :input-gap="'gap-6'"
                :section-gap="'gap-12'"
                :show-saving-indicators="true"
                :success-request="successRequest"
                :failed-request="failedRequest"
                :dirty-inputs="dirtyQuestions"
                :page-prefix="page.key + '_'"
                :saving="silentsaving"
                :text-area-save-button="true"
                :random-options="false"
                :view="view"
                @save-manual="questionChanged(page.key, $event, true)"
                @atomic-changed="questionChanged(page.key, $event)"
                @download="downloadFile"
                @logs="registerExamActivity($event)"
              ></fw-form>
            </div>
            <div
              class="p-2 mt-8 pb-7 text-center text-gray-400 border-t border-dashed border-gray-300 nohighlight"
              style="font-size: 0.6rem; line-height: 1rem"
            >
              {{ $t('examendlabel') }}
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar sections nav -->
      <div class="hidden fixed pl-5 top-20 left-0 bottom-0 lg:flex flex-col overflow-y-scroll no-scrollbar">
        <div class="flex-1 flex flex-col gap-2 justify-center transition-all">
          <!-- :class="{ 'opacity-0': !showTitle, 'opacity-100': showTitle }"-->
          <template>
            <button
              v-for="(question, q) in questionMap"
              :key="question.page + 'bt_nav_' + question.question"
              class="relative w-full text-gray-400 text-sm px-0 text-left py-1 flex items-center font-bold transition-colors duration-150 rounded-lg hover:opacity-75 focus:outline-none justify-start gap-2"
              :class="{ 'text-primary': question.answer == true }"
              @click="goToQuestion(question.page, question.question)"
            >
              <div
                class="h-3 w-3 rounded-full"
                :class="{ 'bg-primary': question.answer == true, 'bg-gray-200': question.answer != true }"
              ></div>

              <div
                class="bt_nav_lateral"
                :class="{ 'text-primary': question.answer == true, 'text-gray-500': question.answer != true }"
              >
                {{ q + 1 }}
              </div>
            </button>
          </template>
        </div>
      </div>

      <!-- Sidebar (right) up and down nav -->
      <div
        v-if="running"
        class="hidden fixed pr-5 top-1/2 -mt-20 right-0 flex-1 lg:flex flex-col gap-2 justify-center items-end transition-all"
      >
        <fw-button type="xlight" @click.native="scroll('up')">
          <fw-icon-chevron-up></fw-icon-chevron-up>
        </fw-button>
        <fw-button type="xlight" @click.native="scroll('down')">
          <fw-icon-chevron-down></fw-icon-chevron-down
        ></fw-button>
      </div>

      <!-- Accessibility -->
      <div
        v-if="running"
        class="hidden fixed lg:flex flex-col bottom-5 right-5 justify-end gap-3 opacity-70 hover:opacity-100"
      >
        <fw-button type="xlight" size="sm" class="text-2xl w-12" @click.native="changeFontSize('more')">A</fw-button>
        <fw-button type="xlight" size="sm" class="w-12" @click.native="changeFontSize('less')">A</fw-button>
      </div>
    </template>
    <template #modals>
      <fw-modal v-if="showReceipt" :active.sync="showReceipt" :can-cancel="false" size="xs">
        <template #default>
          <CheckmarkSuccess />
          <div class="text-center flex flex-col gap-3 px-5">
            <fw-heading v-if="autoReceipt" size="h3">{{ $t('modalsubmit.examended') }}</fw-heading>
            <fw-heading v-else size="h3">{{ $t('modalsubmit.examsubmited') }}</fw-heading>
            <div>
              <fw-label>{{ $t('modalsubmit.answeredquestions') }}</fw-label>
              <div class="text-xl font-medium">
                <span class="font-semibold text-primary">{{ answeredQuestions }}</span>
                <span class="text-gray-500">/{{ totalQuestions }}</span>
              </div>
            </div>
            <div class="text-xs text-gray-500">
              {{ $t('modalsubmit.instructions') }}
            </div>
          </div>
        </template>
        <template #footer>
          <fw-button v-if="isDigitalPad" type="black" expanded @click.native="signout()">
            {{ $t('modalsubmit.closeandsignout') }}
          </fw-button>
          <fw-button v-else type="black" expanded @click.native="goBack()">{{ $t('modalsubmit.close') }}</fw-button>
        </template>
      </fw-modal>

      <fw-modal v-if="showQuitReceipt" :active.sync="showQuitReceipt" :can-cancel="false" size="xs">
        <template #default>
          <div class="flex justify-center p-5">
            <fw-icon-close class="w-12 h-12 rounded-full p-2 bg-gray-500 text-white" />
          </div>
          <div class="text-center flex flex-col gap-3 px-5">
            <fw-heading size="h3">{{ $t('modalgiveup.title') }}</fw-heading>
            <div class="font-medium">{{ $t('modalgiveup.message') }}</div>
            <div class="text-xs text-gray-500">
              {{ $t('modalgiveup.instructions') }}
            </div>
          </div>
        </template>
        <template #footer>
          <fw-button type="black" expanded @click.native="goBack()">{{ $t('modalgiveup.close') }}</fw-button>
        </template>
      </fw-modal>

      <fw-modal v-if="showIntro" :active.sync="showIntro" :can-cancel="false">
        <template #default>
          <div class="flex flex-col items-center max-w-sm mx-auto text-left">
            <div v-if="introPage == 0" class="rounded text-sm font-medium flex flex-col gap-6 mx-auto text-gray-600">
              <fw-heading size="h3" muted>{{ $t('modalterms.title') }}</fw-heading>

              <!-- Aviso de respostas a descontar -->
              <div v-if="negativePoints" class="flex gap-3">
                <svg
                  class="fill-current flex-shrink-0 h-6 w-6 text-primary mt-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM4 18.385L5.763 17H20V5H4v13.385zM13.414 11l2.475 2.475-1.414 1.414L12 12.414 9.525 14.89l-1.414-1.414L10.586 11 8.11 8.525l1.414-1.414L12 9.586l2.475-2.475 1.414 1.414L13.414 11z"
                  />
                </svg>

                <div>
                  <fw-heading size="h3">{{ $t('modalterms.wronganswers') }}</fw-heading>
                  <span class="text-sm">
                    {{ $t('modalterms.wronganswersmessage') }}
                  </span>
                </div>
              </div>

              <!-- Aviso de tecnologia de monitorização-->
              <div class="flex gap-3">
                <svg
                  class="fill-current flex-shrink-0 h-6 w-6 text-primary mt-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 3a5 5 0 1 1-4.78 3.527A2.499 2.499 0 0 0 12 9.5a2.5 2.5 0 0 0-1.473-2.28c.466-.143.96-.22 1.473-.22z"
                  />
                </svg>
                <div>
                  <fw-heading size="h3">{{ $t('modalterms.monitoring') }}</fw-heading>
                  <span class="text-sm">{{ $t('modalterms.monitoringmessage') }}</span>
                  <div class="flex flex-wrap gap-1.5 mt-2">
                    <div class="bg-gray-200 rounded-full px-1.5 py-0.5 text-xs font-semibold whitespace-nowrap">
                      {{ $t('modalterms.monitoringaction1') }}
                    </div>
                    <div class="bg-gray-200 rounded-full px-1.5 py-0.5 text-xs font-semibold whitespace-nowrap">
                      {{ $t('modalterms.monitoringaction2') }}
                    </div>
                    <div class="bg-gray-200 rounded-full px-1.5 py-0.5 text-xs font-semibold whitespace-nowrap">
                      {{ $t('modalterms.monitoringaction3') }}
                    </div>
                    <div class="hidden bg-gray-200 rounded-full px-1.5 py-0.5 text-xs font-semibold whitespace-nowrap">
                      {{ $t('modalterms.monitoringaction4') }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Aviso de foto inicial -->
              <div v-if="takeUserPhoto" class="flex gap-3">
                <svg
                  class="fill-current flex-shrink-0 h-6 w-6 mt-0.5 text-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 21v-1.07A7.002 7.002 0 0 1 5 13V8a7 7 0 1 1 14 0v5a7.002 7.002 0 0 1-6 6.93V21h4v2H7v-2h4zm1-18a5 5 0 0 0-5 5v5a5 5 0 0 0 10 0V8a5 5 0 0 0-5-5zm0 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                  />
                </svg>
                <div>
                  <fw-heading size="h3">{{ $t('modalterms.photo') }}</fw-heading>
                  <span class="text-sm">{{ $t('modalterms.photomessage') }}</span>
                  <div class="text-xs text-gray-500 mt-1">
                    {{ $t('modalterms.photomessage2') }}
                  </div>
                </div>
              </div>

              <fw-button :type="'primary'" class="mt-2" @click.native="nextTerms()">
                {{ $t('modalterms.accept') }}
              </fw-button>
            </div>
            <div
              v-else-if="introPage == 1"
              class="rounded text-sm p-5 font-medium flex flex-col gap-3 mx-auto text-gray-600"
            >
              <fw-heading size="h2">{{ $t('modalterms.takephoto') }}</fw-heading>

              <fw-message v-if="!camaraAllowed" type="info" class="text-sm">
                {{ $t('modalterms.allowcameramessage') }}
              </fw-message>
              <fw-message v-else-if="camaraAllowed && selfieImage != null" type="info" class="text-sm">{{
                $t('modalterms.usephotomessage')
              }}</fw-message>
              <fw-message v-else type="info" class="text-sm">{{ $t('modalterms.photoinfomessage') }} </fw-message>

              <div v-show="camaraAllowed && selfieImage == null" class="flex justify-center relative">
                <video
                  id="video_camara"
                  ref="video_camara"
                  autoplay
                  playsinline
                  class="w-full overflow-hidden rounded-lg"
                ></video>
                <div
                  class="absolute h-64 w-40 border-4 opacity-50 border-white rounded-full top-1/2 -mt-32 left-1/2 -ml-20"
                ></div>
              </div>
              <div
                v-show="!camaraAllowed && selfieImage == null"
                class="w-full h-60 bg-gray-400 rounded-lg flex flex-col justify-center items-center"
              >
                <fw-button type="light" @click.native="createCameraStream()">
                  <div class="flex gap-2 items-center">
                    <svg
                      class="fill-current h-7 w-7"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M9.828 5l-2 2H4v12h16V7h-3.828l-2-2H9.828zM9 3h6l2 2h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4l2-2zm3 15a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0-2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                      />
                    </svg>
                    {{ $t('modalterms.activatecamerabutton') }}
                  </div>
                </fw-button>
              </div>
              <div v-show="selfieImage != null" class="flex justify-center">
                <img :src="selfieImage" class="w-full overflow-hidden rounded-lg" />
              </div>

              <div v-if="selfieImage != null" class="flex gap-5">
                <fw-button :type="'light'" size="xl" class="mt-2 flex-1" @click.native="selfieImage = null">
                  {{ $t('modalterms.camerabuttonagain') }}
                </fw-button>
                <fw-button :type="'primary'" size="xl" class="mt-2 flex-1" @click.native="nextTerms()">
                  {{ $t('modalterms.usephotobutton') }}
                </fw-button>
              </div>
              <fw-button
                v-else
                :type="camaraAllowed ? 'primary' : 'disabled'"
                size="xl"
                class="mt-2"
                @click.native="takePhoto()"
              >
                {{ $t('modalterms.takephotobutton') }}
              </fw-button>
            </div>
            <div v-if="introPage == 2" class="rounded text-sm font-semibold flex flex-col gap-6 mx-auto text-gray-600">
              <fw-heading size="h2">{{ $t('modalterms.fullscreen') }}</fw-heading>

              <!-- Aviso de tecnologia de monitorização-->
              <div class="flex flex-col gap-5 item">
                <svg
                  class="fill-current mx-auto flex-shrink-0 h-12 w-12 text-primary mt-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z" />
                </svg>
                <div class="text-sm">
                  {{ $t('modalterms.fullscreenmessage') }}
                </div>
                <div class="flex gap-4 text-xs items-center">
                  <svg
                    class="fill-current mx-auto flex-shrink-0 h-9 w-9 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"
                    />
                  </svg>
                  <div>
                    {{ $t('modalterms.fullscreenmessage2') }}
                  </div>
                </div>
              </div>

              <fw-button :type="'primary'" size="xl" class="mt-2" @click.native="nextTerms()">
                {{ $t('modalterms.fullscreenbutton') }}
              </fw-button>
            </div>
          </div>
        </template>
        <template v-if="false" #footer>
          <fw-button type="primary" expanded @click.native="ackTimeIsAlmostOver()"> OK. Continuar. </fw-button>
        </template>
      </fw-modal>

      <fw-modal
        v-if="timeWarningsEnabled"
        :active.sync="showTimeAlmostOverWarning"
        title="O exame está prestes a terminar!"
        title-align="center"
        :can-cancel="false"
        @close="ackTimeIsAlmostOver()"
      >
        <template #default>
          <div class="flex flex-col items-center text-center">
            <div class="flex justify-center pb-3">
              <TimeCounter size="lg" :remaining-seconds="remainingSeconds"> </TimeCounter>
            </div>
            <div class="flex flex-col gap-2">
              <div v-if="isAutomaticExam" class="font-semibold text-center">
                {{ $t('modaltimeover.autoend') }}
              </div>
              <div v-else class="font-semibold">
                {{ $t('modaltimeover.manualend') }}
              </div>
              <div v-if="isAutomaticExam" class="text-gay-500 text-xs mx-auto max-w-xs">
                {{ $t('modaltimeover.autoendmessage') }}
              </div>
              <div class="flex flex-col gap-3 text-sm p-3 bg-gray-100 rounded-xl">
                <div class="font-medium">
                  {{ $t('modaltimeover.message') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <fw-button type="primary" expanded @click.native="ackTimeIsAlmostOver()">
            {{ $t('modaltimeover.button') }}
          </fw-button>
        </template>
      </fw-modal>
    </template>
  </LayoutDefault>
</template>

<script>
import Vue from 'vue'
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import FwForm from '@/fw-modules/fw-core-vue/ui/components/form/Exam'
import CheckmarkSuccess from '@/fw-modules/fw-core-vue/ui/components/animation/CheckmarkSuccess'
import TimeCounter from '@/fw-modules/fw-core-vue/exams/components/text/TimeCounter'
import PanelExamCover from '@/fw-modules/fw-core-vue/exams/components/panels/PanelExamCover'
import PanelExamInstructionsAndActions from '@/fw-modules/fw-core-vue/exams/components/panels/PanelExamInstructionsAndActions'
import TextStatus from '@/fw-modules/fw-core-vue/exams/components/text/TextStatus'
import SideBlockLanguage from '@/fw-modules/fw-core-vue/ui/components/sidebars/blocks/SideBlockLanguage'
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import Forbidden from '@/fw-modules/fw-core-vue/id/views/Forbidden'
import FullPageLoading from '@/fw-modules/fw-core-vue/ui/views/FullPageLoading'
import MobileNotAllowed from '@/fw-modules/fw-core-vue/id/views/MobileNotAllowed'
import ServiceSettings from '../../id/services/ServiceSettings'
import Highlighter from 'web-highlighter'
import ViewMode from '@/fw-modules/fw-core-vue/ui/components/form/ViewMode'

const inputTypes = ['multiple_choice', 'text_area', 'upload_file']

export default {
  name: 'ViewExamination',
  components: {
    FwMessage,
    FwForm,
    LayoutDefault,
    TextStatus,
    PanelExamCover,
    SideBlockLanguage,
    PanelExamInstructionsAndActions,
    TimeCounter,
    CheckmarkSuccess,
    Forbidden,
    MobileNotAllowed,
    FullPageLoading,
    ViewMode,
  },

  filters: {
    time: function(value) {
      if (!value) return '00'
      if (value < 10) {
        return '0' + value
      }
      return value
    },
  },

  data: function() {
    return {
      noInternet: false,
      debug: localStorage.getItem('fw-exam-debug') == 'true',
      // Instance control
      status: 'closed',
      forbidden: false,
      showReceipt: false,
      autoReceipt: false, // True if the receipt was triggered by the automatic end of the exam or the teacher
      showQuitReceipt: false,
      // Layout helpers
      fontSize: 1,
      fixedlateral: false,
      withAnswer: false,
      showIntro: false,
      weHaveUserPhoto: false,
      introPage: 0,
      camaraAllowed: false,
      selfieImage: null,
      showSections: true,
      expandedInstructions: false,
      silentsaving: false,
      saving: false,
      examEnded: false,
      successRequest: false, // Indicate if the answers were saved
      failedRequest: false, // Indicate if the answers were not saved
      showTitle: false,
      running: false,
      localStartDate: null,
      timerInterval: null,
      countdown: true,
      remainingSeconds: 0,
      startDate: null,
      endDate: null,
      form: {
        instructions: {
          pt: '',
        },
        title: {
          pt: '',
        },
        options: {
          logInteractions: false,
          disableRightClick: true,
          availableLanguages: ['pt'],
          anonymous: false,
          //new
          points_correct_option: 10,
          points_wrong_option: 0,

          randomMultiplechoiceOptions: false,
          randomSections: false,
          negativePoints: false,
          limitNegativePoints: true,
          auto_monitor: false,
          cheat_protection: false,
        },
      },
      instance: null, //load instance first
      loading: false,
      initialLoading: true,
      silentloading: false,
      pages: [],
      users: null,
      getStatusTimer: null,
      //dirtyPages: new Set(),
      dirtyQuestions: new Set(), //used for atomically save questions
      pagesState: {},
      totalQuestions: 0,
      answeredQuestions: 0,
      debouce_timer: null,
      realtimeDebouncer: null,

      lockActions: false,

      // Time left warnings
      timeWarningsEnabled: false,
      secondsToShowTimeFriendlyWarning: 600, // 10 min (600 seconds)
      secondsToShowTimeAlmostOverWarning: 120, // 2 min (120 seconds)
      showTimeAlmostOverWarning: false, // Check if we already shown the second time warning
      timeFriendlyWarningShown: false,
      timeAlmostOverWarningShown: false,

      stream: null,

      // Helpers
      reenterExamStatus: ['closed', 'corrected', 'needs_manual_correction', 'withdraw'],
      questionMap: [],

      view: 'slides',
      saved: false,
      savedTimeout: null,

      autosaveTimer: null,
    }
  },

  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
    me() {
      return this.$store.getters.getUser
    },
    isDigitalPad() {
      return process.env.VUE_APP_KEY == 'ucdigitalpad'
    },
    // Make sure exam is not allowed in small devices
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },

    // Instance and exam
    examTitle() {
      return this.form && this.form.title[this.language]
    },
    examInstructions() {
      return this.form && this.form.instructions[this.language]
    },
    instanceKey() {
      return this.$route.params.key ? this.$route.params.key : null
    },

    // Checks and validations
    checks() {
      return {
        isRunning: this.isRunning,
        isAutomaticExam: this.isAutomaticExam,
      }
    },
    isRunning() {
      return this.running
    },
    isAutomaticExam() {
      return this.instance && this.instance.options && this.instance.options.autoStart
    },
    isForm() {
      return typeof this.$route.query.form != 'undefined'
    },
    isExam() {
      return true
    },
    hasInstructions() {
      return this.examInstructions && this.examInstructions.length > 7
    },

    // Permissions
    canStart() {
      return this.isWebSocketReady && this.instance && this.instance.status === 'running' ? true : false
    },
    canShowActions() {
      return this.canStart && !this.running && !this.showReceipt
    },

    // Data
    isWebSocketReady() {
      return !!(this.$store.state.socket && this.$store.state.socket.connectionId)
    },
    examWSMessages() {
      return this.$store.state.session.unreadExamWsMessages
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    formRemoteUpdates() {
      return this.$store.state.formUpdates
    },
    progress() {
      return this.totalQuestions > 0 && this.answeredQuestions > 0
        ? Math.floor((this.answeredQuestions / this.totalQuestions) * 100)
        : 0
    },
    language() {
      let userLanguage = this.$store.state.language || this.$i18n.locale
      console.log('userLanguage', userLanguage)
      return this.availableLanguages.includes(userLanguage) ? userLanguage.toLowerCase() : this.availableLanguages[0]
      //return this.availableLanguages.length > 1 ? this.$store.state.language || this.$i18n.locale || 'pt' : 'pt'
    },
    availableLanguages() {
      return this.form && this.form.options.availableLanguages ? this.form.options.availableLanguages : []
    },
    randomMultiplechoiceOptions() {
      return this.form && this.form.options.randomMultiplechoiceOptions
        ? this.form.options.randomMultiplechoiceOptions
        : false
    },
    takeUserPhoto() {
      return this.instance && this.instance.options && this.instance.options.validateIdentity ? true : false
    },
    disableRightClick() {
      return this.instance && this.instance.options && this.instance.options.disableRightClick ? true : false
    },
    randomSections() {
      return this.form && this.form.options.randomSections ? this.form.options.randomSections : false
    },
    negativePoints() {
      return this.form && this.form.options.negativePoints ? this.form.options.negativePoints : false
    },
    limitNegativePoints() {
      return this.form && this.form.options.limitNegativePoints ? this.form.options.limitNegativePoints : false
    },
    //as monitoring is active by default, we can use the logInteractions option to force full screen
    forceFullScreen() {
      return this.instance && this.instance.options && this.instance.options.logInteractions ? true : false
    },
    examPoints() {
      return 0
    },
    autoMonitor() {
      return this.instance && this.instance.options.logInteractions ? this.instance.options.logInteractions : false
    },
    dirtyData() {
      return this.$store.state.dirtyData
    },
  },

  watch: {
    examWSMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages && newMessages.length > 0) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('examWSMessages changed', newMessages)
          //change user state or number of answers
          //if not found, load answers again

          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if ((message.type == 'instanceStatus' || message.type == 'instanceUpdate') && message.remaining_time) {
              console.log('Update remaining time from websocket', message.remaining_time)
              this.remainingSeconds = message.remaining_time

              // Make sure we start timer after user starts to work
              if (this.timerInterval == null) {
                console.log('Start time tick after loading ws messages')
                this.startTime()
              }
            }
          }
          //submitExamByApp
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          this.realtimeDebouncer = null
        }, 1000)
      }
    },
    // whenever question changes, this function will run
    socketId(newSocketId) {
      if (newSocketId != null) {
        this.subscribeExamStart()
      }
    },
    formRemoteUpdates(newupdate) {
      console.log('formUPDATED')
      if (newupdate.length > 0) {
        console.log('formRemoteUpdates', newupdate)
        for (let index = 0; index < newupdate.length; index++) {
          const element = newupdate[index]
          console.log('element', element)
          if (element.instance_key == this.instanceKey) {
            if (element.status == 'stop') {
              console.log('stop exam')
              this.autoReceipt = true //stopted by the system (or teacher)
              this.submitExamByApp()
              this.instance.status = 'ended'
            } else if (element.status == 'start') {
              console.log('start exam')
              this.instance.status = 'running'
            }
          }
        }
      }
    },
  },

  created() {
    this.autoReceipt = false // Restart value
    this.verifyStart()
    this.subscribeExamStart()
  },

  mounted() {
    this.createScrollListner()
    this.loadFontSize()
  },

  beforeDestroy() {
    this.stopTime()
    this.createScrollListner(true)
    this.unsubscribeExamStart()
  },

  methods: {
    refreshPage() {
      this.$buefy.dialog.confirm({
        title: 'Tem a certeza que pretende atualizar a página?',
        message: 'Verifique ter guardado todas as respostas antes de atualizar a página.',
        confirmText: 'Atualizar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        onConfirm: () => {
          location.reload()
        },
      })
    },
    goToQuestion(page, question) {
      let el = document.getElementById('question-' + page + '-' + question)
      console.log('el', el)
      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    async loadFontSize() {
      if (this.isDigitalPad) {
        let fontSize = await ServiceSettings.getSetting('tabletFontSize')
        if (fontSize && fontSize.value) {
          this.fontSize = parseFloat(fontSize.value)
        }
      }
    },
    takePhoto() {
      if (!this.camaraAllowed) {
        return
      }
      let canvas = document.createElement('canvas')
      console.log(this.$refs.video_camara.videoWidth, this.$refs.video_camara.videoHeight)
      canvas.width = this.$refs.video_camara.videoWidth
      canvas.height = this.$refs.video_camara.videoHeight
      canvas
        .getContext('2d')
        .drawImage(
          this.$refs.video_camara,
          0,
          0,
          this.$refs.video_camara.videoWidth,
          this.$refs.video_camara.videoHeight
        )
      let image_data_url = canvas.toDataURL('image/jpeg')
      this.selfieImage = image_data_url
      console.log(image_data_url)
    },
    nextTerms() {
      //page 0 by default
      //page 1 take user photo
      //page 2 force full screen
      if (this.introPage == 0) {
        this.logActivity('exam-use-terms-accepted')
        this.sendWStoManagers('exam-use-terms-accepted', '')
        //if we need to take the photo to the user, we continue to page 1 (if we don't already have a photo)
        if (this.takeUserPhoto && !this.weHaveUserPhoto) {
          this.introPage = 1
          Vue.nextTick(() => {
            this.createCameraStream()
          })
        } else if (this.forceFullScreen) {
          console.log('save terms acceptance and close')
          //if we don't need to take the photo, we continue to page 2 or we save and close
          this.introPage = 2
        } else {
          console.log('save terms acceptance and close')
          //save terms acceptance and close
          this.savePhotoAndTerms(false, true)
        }
      } else if (this.introPage == 1 && this.selfieImage != null) {
        //upload photo
        //save accepted terms (close it if we don't need full screen)
        this.savePhotoAndTerms(true, !this.forceFullScreen)
        if (this.forceFullScreen) {
          this.introPage = 2
        }
      } else if (this.introPage == 2) {
        this.openFullScreen()
        this.showIntro = false
      }
    },
    openFullScreen() {
      let elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
      this.registerExamActivity({ action: 'enter_full_screen' })
    },
    async createCameraStream() {
      try {
        if (this.stream === null) {
          this.stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false,
          })
        }
        this.camaraAllowed = true
        this.$refs.video_camara.srcObject = this.stream
      } catch (e) {
        console.log(e)
        this.camaraAllowed = false
      }
    },
    deactivateCamera() {
      if (this.stream != null) {
        this.stream.getTracks().forEach(function(track) {
          track.stop()
        })
        this.stream = null
      }
    },
    async savePhotoAndTerms(uploadPhoto = false, closeModal = false) {
      if (this.selfieImage == null && uploadPhoto) {
        return
      }
      this.loading = true

      try {
        let photoUploadResult = null
        if (uploadPhoto) {
          photoUploadResult = await this.uploadPhoto(this.selfieImage)
        }
        console.log('saving terms')
        let resultTerms = await ServiceExams.savePhotoAndTerms(
          this.instance.key,
          photoUploadResult !== null ? photoUploadResult.key : null,
          true
        )
        console.log('resultTerms', resultTerms)
        if (closeModal) {
          console.log('close modal')
          this.showIntro = false
        }
        if (uploadPhoto) {
          this.deactivateCamera()
          this.logActivity('exam-selfie-taken')
          this.sendWStoManagers('exam-selfie-taken', '')
        }
      } catch (e) {
        this.showErrorAlert(this.$t('errorsavingtermsandphoto'), e)
      } finally {
        this.loading = false
      }
    },
    async uploadPhoto(binary) {
      //let bin = new Blob([binary], { type: 'text/plain' })
      let result = await ServiceStorage.putSmallFile(
        binary,
        'file',
        'text/plain',
        'exam_selfie_' + this.instanceKey + '.jpg'
      )
      console.log('PHOTO UPLOAD RESULT', result)
      return result
    },
    downloadFile(file) {
      const userToken = this.$store.state.session.user.token
      const fileurl = file.file ? file.file.url_format : file.url_format
      const filekey = file.file ? file.file.key : file.key
      const filename = file.file && file.file.filename ? file.file.filename : file.filename

      const url = fileurl
        .replaceAll('{TOKEN}', userToken)
        .replaceAll('{KEY}', filekey)
        .replaceAll('{FILENAME}', filename)

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()
    },

    // Actions
    endRunning() {
      this.stopTime()
      this.running = false
      // Kill timer
      clearInterval(this.timerInterval)
    },
    async confirmDelivery() {
      this.$buefy.dialog.confirm({
        title: this.$t('submittitle'),
        message:
          this.$t('submitmessage') +
          '<br>Respondeu a ' +
          this.answeredQuestions +
          ' de ' +
          this.totalQuestions +
          ' questões.',
        cancelText: this.$t('submitcancel'),
        confirmText: this.$t('submitconfirm'),
        type: 'is-danger',
        onConfirm: () => {
          // Delivery answers and show delivery receipt (modal)
          this.submitExamByUser()
          // Log this activty
          //this.logActivity('finish-exam')
        },
      })
    },
    async confirmQuit(force = false) {
      if (force) {
        try {
          this.lockActions = true
          await ServiceExams.giveup(this.instance.key)
          // Log this activty
          //this.logActivity('quit-exam')
          this.endRunning()
          this.showQuitReceipt = true
        } catch (e) {
          this.showErrorAlert(this.$t('genericerror'), e)
        } finally {
          this.lockActions = false
        }
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t('giveuptitle'),
          message: this.$t('giveupmessage'),
          cancelText: this.$t('giveupcancel'),
          confirmText: this.$t('giveupconfirm'),
          type: 'is-danger',
          onConfirm: () => {
            this.confirmQuit(true)
          },
        })
      }
    },

    // Main data management
    async verifyStart() {
      await this.getInstance()

      // Let user see first loading
      setTimeout(() => {
        this.initialLoading = false
      }, 750)

      //let self = this
      //await this.verifyTerms()
      /*this.getStatusTimer = setInterval(async function() {
        if (!self.silentloading && !self.examEnded) {
          await self.getInstance()
        } else if (self.examEnded) {
          clearInterval(self.getStatusTimer)
          self.getStatusTimer = null
        }
      }, 15000)*/
    },
    async getInstance() {
      if (this.silentloading === true) return

      this.silentloading = true
      try {
        let instanceData = await ServiceExams.getExamineeInstance(this.instanceKey)
        console.log('getInstance', instanceData)

        this.withAnswer = instanceData.withAnswer === true ? true : false
        this.status = instanceData.status
        this.instance = instanceData.instance
        this.form = instanceData.form
        this.users = instanceData.users

        this.weHaveUserPhoto = instanceData.photo !== null

        //Take photo of user if we don't have it yet
        if (instanceData.instance.options.validateIdentity && instanceData.photo === null) {
          this.showIntro = true
        }

        //Terms not acepted show modal
        if (instanceData.acceptedTermsDate == null) {
          this.showIntro = true
        }

        //We are using the logInteractions flag to force full screen or not (to be changed in the future!)
        //If we need the exam to be in full screen we need to force it
        if (this.instance && this.instance.options && this.instance.options.logInteractions) {
          this.showIntro = true
        }

        // Set initial remaining time (before ws messages)
        this.remainingSeconds = this.instance.remainingTime
        console.log('Initialize remaining time from api (wait ws to update it when ready).', this.remainingSeconds)

        if (instanceData.instance.status === 'ended') {
          if (this.running) {
            // Stop time and close editing / running mode
            this.endRunning()
          }
          if (this.getStatusTimer !== null) {
            clearInterval(this.getStatusTimer)
            this.getStatusTimer = null
          }
          this.examEnded = true
        }
      } catch (e) {
        console.log('getInstance error', e)
        let error = JSON.stringify(e)
        if (error.indexOf('Forbidden') !== -1 || error.indexOf('Unauthorized') !== -1) {
          this.forbidden = true
        }
        this.examEnded = true
      }
      this.silentloading = false
    },
    async getPage(instanceKey, pageKey) {
      return await ServiceExams.getExamineePage(instanceKey, pageKey)
    },
    async startExam() {
      if (!this.canStart || !this.instance || !this.form) return false

      this.loading = true
      let pagesPromises = []

      //let activityCode = 'open'

      // If closed or corrected or needs_manual_correction or withdraw, reopen instance
      if (this.reenterExamStatus.includes(this.status)) {
        try {
          await ServiceExams.reopenExam(this.instance.key)
          //activityCode = 'reenter-exam'
        } catch (e) {
          console.error(e)
        }
      }

      //save last exam in local storage
      localStorage.setItem('lastExam', this.instance.key)

      this.form.pages.forEach(page => {
        pagesPromises.push(this.getPage(this.instance.key, page.key))
      })

      let hasBackup = await this.restoreBackup()
      let totalQuestions = 0
      //let answeredQuestiosn = 0
      let self = this
      Promise.all(pagesPromises)
        .then(async result => {
          console.log('pagesLoaded', result)
          //let num_sections = 0

          // TODO: Convert all these lines to a method to keep things clean, please.
          for (let p = 0; p < result.length; p++) {
            let answers = result[p].answer
            if (hasBackup) {
              answers = this.pagesState[result[p].key]
            } else {
              console.log(result[p].key, 'answers', answers)
              Vue.set(this.pagesState, result[p].key, answers)
            }

            for (let s = 0; s < result[p].schema.length; s++) {
              //remove section boxes
              result[p].schema[s]['boxed'] = false

              //Shuffle content
              /*if (
                this.form.options.randomSections &&
                result[p].schema[s].content &&
                result[p].schema[s].content.length > 0
              ) {
                console.log('shuffle content')
                //pageResponse.schema[s].content =
                this.shuffle(result[p].schema[s].content)
              }*/

              //num_sections++
              for (let i = 0; i < result[p].schema[s].content.length; i++) {
                let type = result[p].schema[s].content[i].type
                if (inputTypes.includes(type)) {
                  totalQuestions++
                  //add to questions map
                  this.questionMap.push({
                    page: result[p].key,
                    question: result[p].schema[s].content[i].key,
                    answer:
                      result[p].answer[parseInt(result[p].schema[s].content[i].key)] &&
                      result[p].answer[parseInt(result[p].schema[s].content[i].key)].length > 0
                        ? true
                        : false,
                  })
                }
                if (result[p].schema[s].content[i].options) {
                  for (let o = 0; o < result[p].schema[s].content[i].options.length; o++) {
                    console.log(
                      'undefined:',
                      typeof result[p].schema[s].content[i] === 'undefined',
                      typeof result[p].schema[s].content[i].options[o] === 'undefined'
                    )
                    let optionskey = result[p].schema[s].content[i].options[o].key
                    console.log('input key:', result[p].schema[s].content[i].key)
                    console.log('verifying option:', optionskey)
                    console.log('selected answers:', answers[result[p].schema[s].content[i].key])
                    if (
                      answers[result[p].schema[s].content[i].key] &&
                      Array.isArray(answers[result[p].schema[s].content[i].key]) &&
                      answers[result[p].schema[s].content[i].key].includes(optionskey)
                    ) {
                      console.log('otpion selected!')
                      //answeredQuestiosn++
                      result[p].schema[s].content[i].options[o].selected = true
                    }
                  }
                }
              }
            }
          }

          //if (num_sections > 1) {
          //  this.showSections = true
          //}

          console.log('pages result', result)
          this.pages = result
          this.loading = false
          this.totalQuestions = totalQuestions

          if (hasBackup) {
            console.log('==== SAVING BACKUP ====')
            await this.saveAnswers(true)
          }

          //this.answeredQuestions = answeredQuestiosn
          //await this.loadFormData(this.instance.forms[Math.floor(Math.random() * this.instance.forms.length)].key)

          // User is doing it! Good luck!
          this.running = true

          this.calculateProgression()

          Vue.nextTick(() => {
            self.renderNav()
            //Hightlights desativado
            //self.startHightlight()
          })

          setTimeout(() => {
            console.log('Start time tick after starting exam')
            self.startTime()
            self.scrollToBegin()
            // Make sure we enabled warnings after user starts the exam (not before)
            self.timeWarningsEnabled = true
          }, 700)
        })
        .catch(e => {
          this.loading = false
          this.showErrorAlert(this.$t('errorloading'), e)
          console.error(e)
        })

      /*if (activityCode == 'open') {
        //the reopen activity log is already registered in the backend
        // Log this activty
        this.logActivity(activityCode)
      }*/
    },
    async submitExam(byUserInteraction = false) {
      // Avoid unnecessary and confusing warning if user did not started exam
      if (!this.running) return false

      // To be used by manual action (by clicking on the send button, this does not work if the exam is already closed)
      this.saving = true
      this.lockActions = byUserInteraction
      try {
        // save all state of the exam
        //await this.saveAnswers(true)

        // save all (and only) dirty questions
        if (this.dirtyQuestions.size > 0) {
          await this.saveAnswers()
        }

        // mark exam as closed
        await ServiceExams.submitAnswers(this.instance.key)

        this.endRunning()

        if (!byUserInteraction) this.autoReceipt = true
        this.showReceipt = true

        // Log this activty
        //REMOVED BECAUSE IS REDUNDANT TO WHAT THE SERVER LOGS
        //this.logActivity('submit-all-answers', {}, byUserInteraction)
      } catch (e) {
        this.showErrorAlert(this.$t('errorsubmitting'), e)
      } finally {
        this.lockActions = false
        this.saving = false
      }
    },
    async saveAnswers(all = false) {
      //this.calculateProgression()
      //loop throw dirty and save
      if (!this.silentsaving || all) {
        this.noInternet = false
        console.log('SAVING ANSWERS all?', all)
        this.silentsaving = true // Avoid weird behavior when saving all
        this.successRequest = false
        this.failedRequest = false
        let promises = []
        let pagesOrder = []
        if (all) {
          this.pages.forEach(page => {
            if (this.pagesState[page.key]) {
              promises.push(ServiceExams.saveAnswer(this.instance.key, page.key, this.pagesState[page.key]))
            }
          })
        } else {
          //only save the dirty questions - questions that were not saved yet
          //organize data per page, so we send only one request per page
          let toSaveStructure = {}

          this.dirtyQuestions.forEach(pageAndQuestionKeys => {
            let idParts = pageAndQuestionKeys.split('_')
            let pageKey = idParts[0]
            let questionKey = idParts[1]
            if (!toSaveStructure[pageKey]) {
              toSaveStructure[pageKey] = []
            }
            toSaveStructure[pageKey].push(questionKey)
          })

          //Create one request per exam page, with the questions that were not saved
          Object.keys(toSaveStructure).forEach(pageKey => {
            pagesOrder.push(pageKey)
            let pageData = {}
            toSaveStructure[pageKey].forEach(questionKey => {
              pageData[questionKey] = this.pagesState[pageKey][questionKey]
            })
            promises.push(ServiceExams.saveAnswer(this.instance.key, pageKey, pageData))
          })
        }

        try {
          let result = await Promise.all(promises)
          console.log('saveAnswers', result[0])
          if (result[0] && result[0]['count']) {
            //update sent answers with the value from the backend
            console.log('updating answers')
            this.answeredQuestions = result[0]['count']
          }

          pagesOrder.forEach((pageKey, p) => {
            console.log('updating ', 'page_' + pageKey)
            //send data from server from each page to the local state
            //TODO: Check why is an array and not a single object
            this.$refs['page_' + pageKey][0].updateAnswers(result[p].data)
            //update questionMap if we have an answer or not
            //TODO: TEST THIS
            Object.keys(result[p].data).forEach(questionKey => {
              let answer = result[p].data[questionKey]
              console.log('questionKey', questionKey, 'answer', answer)
              let questionMapIndex = this.questionMap.findIndex(q => q.page == pageKey && q.question == questionKey)
              if (questionMapIndex != -1) {
                this.questionMap[questionMapIndex].answer = answer != null && answer.length > 0 ? true : false
              }
            })
          })

          //reset dirty questions
          this.dirtyQuestions.clear()
          this.$store.commit('setDirtyData', false)
          this.successRequest = true
          this.saved = true
          //sucessfull save, erase backup
          this.eraseBackup()
        } catch (e) {
          if (e.code == 'ERR_NETWORK') {
            this.noInternet = true
          }
          //SAVE DATA BACKUP
          this.saveBackup()
          console.error('Problem saving data', e)
          this.failedRequest = true
        } finally {
          if (this.savedTimeout) clearTimeout(this.savedTimeout)
          this.savedTimeout = setTimeout(() => {
            this.saved = false
            this.silentsaving = false
          }, 1000)
        }
      }
    },
    saveBackup() {
      //SAVE DATA BACKUP
      localStorage.setItem('backup_' + this.me.key + '_' + this.instanceKey, JSON.stringify(this.pagesState))
    },
    eraseBackup() {
      localStorage.removeItem('backup_' + this.me.key + '_' + this.instanceKey)
    },
    async restoreBackup() {
      let backup = localStorage.getItem('backup_' + this.me.key + '_' + this.instanceKey)
      if (backup) {
        console.log('==== RESTORING BACKUP ====')
        this.pagesState = JSON.parse(backup)
        return true
      } else {
        return false
      }
    },
    verifyBackup() {
      let backup = localStorage.getItem('backup_' + this.me.key + '_' + this.instanceKey)
      if (backup) {
        this.$buefy.dialog.confirm({
          title: this.$t('recoverbackup'),
          message: this.$t('recoverbackupmessage'),
          cancelText: this.$t('recoverbackupcancel'),
          confirmText: this.$t('recoverbackupconfirm'),
          type: 'is-success',
          onConfirm: async () => {
            this.pagesState = JSON.parse(backup)
            await this.saveAnswers(true)
            this.$buefy.dialog.confirm({
              canCancel: false,
              title: this.$t('backuprecovered'),
              message: this.$t('backuprecoveredmessage'),
              confirmText: this.$t('backuprecoveredconfirm'),
              type: 'is-success',
              onConfirm: () => {
                window.location.reload()
              },
            })
          },
        })
      }
    },
    async loadFormData(formKey) {
      this.loading = true
      try {
        let response = await ServiceExams.getForm(formKey)
        //let availableLanguages = response.options.availableLanguages

        //verify if we have current language
        if (typeof response.title[this.language] === 'undefined') {
          response.title[this.language] = ''
        }

        this.form = response

        //this.loadAnswers(this.instance.key)

        let promises = []
        //response.pages.forEach(page => promises.push(ServiceExams.getPage(page.key)))
        response.pages.forEach(page => promises.push(this.loadFormPage(page.key)))

        this.pages = []
        Promise.all(promises)
          .then(result => {
            /* console.log('pagesLoaded', result)
            for (let p = 0; p < result.length; p++) {
              let answers = result[p].answers
              console.log(result[p].key, 'answers', answers)
              Vue.set(this.pagesState, result[p].key, answers)
              for (let s = 0; s < result[p].schema.length; s++) {
                for (let i = 0; i < result[p].schema[s].content.length; i++) {
                  for (let o = 0; i < result[p].schema[s].content[i].options.length; o++) {
                    let optionskey = result[p].schema[s].content[i].options[o].key
                    console.log('verifying option:', optionskey)
                    if (answers[result[p].schema[s].content[i].key].includes(optionskey)) {
                      result[p].schema[s].content[i].options[o].selected = true
                    }
                  }
                }
              }
            }*/
            this.pages = result
            this.loading = false
            //let self = this
            /*Vue.nextTick(function() {
              setTimeout(() => {
                self.calculateRightMargin()
              }, 500)
            })*/
          })
          .catch(error => {
            console.error(error)
            this.$buefy.dialog.alert({
              message: this.$t('errorloading2'),
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true,
            })
          })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: this.$t('errorloading2'),
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    shuffle(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1))
        var temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
    },
    async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await ServiceExams.getPage(pageKey)

      if (pageResponse.answers) {
        Vue.set(this.pagesState, pageKey, pageResponse.answers)
      }

      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        //Shuffle content
        if (
          this.form.options.randomSections &&
          pageResponse.schema[s].content &&
          pageResponse.schema[s].content.length > 0
        ) {
          //pageResponse.schema[s].content =
          this.shuffle(pageResponse.schema[s].content)
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score
          if (typeof pageResponse.schema[s].content[i].score === 'undefined') {
            pageResponse.schema[s].content[i].score = 0
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }

              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
            }
          }
        }
      }

      return pageResponse
    },
    async loadAnswers(instanceKey) {
      let answers = await ServiceExams.getAnswers(instanceKey)
      console.log('answers', answers)
    },

    // Deliver exam router (kind of)
    submitExamByApp() {
      this.submitExam()
    },
    submitExamByUser() {
      this.submitExam(true)
    },
    // When we change the one question atomically
    questionChanged(pageID, data, saveNow = false) {
      console.log('saveNow', saveNow)
      let inputID = data.id
      let value = data.value
      console.log('atomically changed', inputID, value)
      this.pagesState[pageID][inputID] = value
      this.dirtyQuestions.add(pageID + '_' + inputID)
      let self = this
      if (saveNow) {
        this.saveAnswers()
      } else {
        if (this.debouce_timer !== null) {
          clearTimeout(this.debouce_timer)
        }
        this.$store.commit('setDirtyData', true)
        this.debouce_timer = setTimeout(() => {
          console.log('debounced ' + pageID + ' activated!')
          //self.saveAnswer(pageID, id, value)
          self.saveAnswers()
        }, 5000)
      }
      this.saveBackup()
    },
    calculateProgression() {
      let numQuestionsAnswered = 0
      for (const [pagekey, page] of Object.entries(this.pagesState)) {
        for (const [inputkey, value] of Object.entries(page)) {
          console.log('evaluating ', pagekey, inputkey)
          if (value != null && value.length > 0) {
            numQuestionsAnswered++
          }
        }
      }
      this.answeredQuestions = numQuestionsAnswered
    },

    // Websockets
    async subscribeExamStart() {
      if (this.isWebSocketReady) {
        let connectionId = this.$store.state.socket.connectionId
        let subscriptionData = await ServiceExams.subscribeInstance(connectionId, this.instanceKey)
        console.log('WS exam subscription data', subscriptionData)
      } else {
        console.error('No socket connection')
      }
    },
    async unsubscribeExamStart() {
      if (this.$store.state.socket && this.$store.state.socket.connectionId) {
        let connectionId = this.$store.state.socket.connectionId
        let subscriptionData = await ServiceExams.unsubscribeInstance(connectionId, this.instanceKey)
        console.log('WS exam unsubscription data', subscriptionData)
      } else {
        console.error('No socket connection')
      }
    },

    // Deal with time
    stopTime() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    },
    startTime() {
      console.log('Start time tick')
      let self = this
      this.timeTick()
      if (this.timerInterval != null) {
        clearInterval(this.timerInterval)
      }
      this.timerInterval = setInterval(() => {
        self.timeTick()
      }, 1000)

      //create AutoSave every 60 seconds
      if (this.autosaveTimer != null) {
        clearInterval(this.autosaveTimer)
      }
      this.autosaveTimer = setInterval(() => {
        console.log('AutoSave every 60 seconds')
        self.saveAnswers()
      }, 60000)
    },
    timeTick() {
      this.remainingSeconds -= 1
      //console.log('time tick', this.remainingSeconds)

      if (!this.running) return

      // Manage time warnings and (auto) delivery
      this.checkTimeWarnings()
      //this.checkTimeForAutoExamDelivery()
    },
    /*checkTimeForAutoExamDelivery() {
      // Force end of the exam if this is an automatic exam
      if (this.isAutomaticExam && this.remainingSeconds == 0) {
        this.submitExamByApp()
      }
    },*/
    checkTimeWarnings() {
      if (!this.timeWarningsEnabled) return

      // Show non intrusive warning
      if (
        this.remainingSeconds > 0 &&
        this.remainingSeconds < this.secondsToShowTimeFriendlyWarning &&
        this.remainingSeconds > this.secondsToShowTimeAlmostOverWarning + 60 &&
        !this.timeFriendlyWarningShown
      ) {
        let minLeft = Math.ceil(this.remainingSeconds / 60)
        this.$buefy.snackbar.open({
          position: 'is-bottom',
          message: `${this.$t('timeovermessage1')} ${minLeft < 0 ? 0 : minLeft} ${this.$t('timeovermessage2')} ${
            this.isAutomaticExam ? this.$t('timeovermessage3') : this.$t('timeovermessage4')
          }`,
          type: 'is-warning',
        })
        this.timeFriendlyWarningShown = true
      }

      // Show critical warning - time is almost over dude!
      // Make sure we show this only when time is not over
      else if (
        this.remainingSeconds > 0 &&
        this.remainingSeconds < this.secondsToShowTimeAlmostOverWarning &&
        !this.timeAlmostOverWarningShown
      ) {
        this.showTimeAlmostOverWarning = true
        this.timeAlmostOverWarningShown = true
      }
    },
    ackTimeIsAlmostOver() {
      this.showTimeAlmostOverWarning = false
      this.timeWarningsEnabled = false
      // Log this activity
      this.logActivity('timeover-warning-ack')
      console.log('User ack warning of time almost over.')
    },

    // Nav and UX helpers
    exitExam(exit = false) {
      if (process.env.VUE_APP_KEY !== 'ucdigitalpad') {
        if (!exit && this.isRunning) {
          this.$buefy.dialog.confirm({
            title: this.$t('quitexamtitle'),
            message: this.$t('quitexammessage'),
            cancelText: this.$t('quitexamcancel'),
            confirmText: this.$t('quitexamconfirm'),
            type: 'is-danger',
            onConfirm: () => this.exitExam(true),
          })
        } else {
          this.logActivity('exit-exam')
          // Exit to the right url
          this.goBack()
        }
      } else if (process.env.VUE_APP_KEY == 'ucdigitalpad' && !this.running) {
        this.logActivity('exit-exam')
        // Exit to the right url
        this.goBack()
      }
    },
    showErrorAlert(message = 'Ocorreu um erro a realizar a operação.', error) {
      this.$buefy.snackbar.open({
        position: 'is-top-right',
        message: `<div class="font-semibold">${message}</div><div class="text-sm opacity-80">${error}</div>`,
        type: 'is-danger',
        duration: 5000,
      })
    },
    scroll(direction = 'up') {
      console.log('scroll', direction)
      if (direction === 'up') {
        document.getElementsByTagName('main')[0].scrollBy({
          top: -window.innerHeight * 0.8,
          behavior: 'smooth',
        })
      } else {
        document.getElementsByTagName('main')[0].scrollBy({
          top: window.innerHeight * 0.8,
          behavior: 'smooth',
        })
      }
    },
    async changeFontSize(option) {
      if (option == 'more' && this.fontSize < 2) {
        this.fontSize += 0.25
      } else if (option == 'less' && this.fontSize > 1) {
        this.fontSize -= 0.25
      }
      //TODO: Save to tablet profile (if we have one)
      if (this.isDigitalPad) {
        await ServiceSettings.setSetting('tabletFontSize', this.fontSize)
      }
    },
    createScrollListner(remove = false) {
      let mainElement = document.querySelector('main')
      let animScrollAfter = 200
      let fixLateralToolbar = 250
      let self = this
      if (remove) {
        if (mainElement) {
          mainElement.removeEventListener(
            'scroll',
            function() {
              if (mainElement.scrollTop > animScrollAfter) {
                self.showTitle = true
              } else {
                self.showTitle = false
              }
              if (mainElement.scrollTop > fixLateralToolbar) {
                self.fixedlateral = true
              } else {
                self.fixedlateral = false
              }
            },
            false
          )
        }
      } else {
        mainElement.addEventListener(
          'scroll',
          function() {
            if (mainElement.scrollTop > animScrollAfter) {
              self.showTitle = true
            } else {
              self.showTitle = false
            }
            if (mainElement.scrollTop > fixLateralToolbar) {
              self.fixedlateral = true
            } else {
              self.fixedlateral = false
            }
          },
          false
        )
      }
    },
    goBack() {
      if (this.$store.state.lastRoutePath !== null) {
        let path = this.$store.state.lastRoutePath
        this.$store.commit('setLastRoutePath', null)
        this.$router.push({ path: path })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    signout() {
      localStorage.setItem('shouldCheckout', 'true')
      this.$router.push('/logout')
    },
    goToSection(pageKey, sectionKey) {
      if (pageKey === 'start') {
        document.getElementsByTagName('main')[0].scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      } else {
        document.getElementsByTagName('main')[0].scrollTo({
          top:
            document.getElementById(pageKey + '_form_section_' + sectionKey).getBoundingClientRect().top +
            document.getElementsByTagName('main')[0].scrollTop -
            55,
          behavior: 'smooth',
        })
      }
    },
    startHightlight() {
      console.log(document.querySelector('.highlight-box'))
      const highlighter = new Highlighter({
        $root: document.querySelector('.highlight-box'),
        exceptSelectors: ['.nohighlight', 'textarea', 'input', 'img', '.my-remove-tip'],
      })
      let self = this
      highlighter.on(Highlighter.event.CREATE, ({ sources }) => {
        sources.forEach(s => {
          const position = self.getHightlightPosition(highlighter.getDoms(s.id)[0])
          self.createHighlightDeleteTag(position.top, position.left, s.id)
        })
      })
      document.querySelector('.highlight-box').addEventListener('click', e => {
        const $ele = e.target
        // delete highlight
        if ($ele.classList.contains('my-remove-tip')) {
          const id = $ele.dataset.id
          //highlighter.removeClass('highlight-wrap-hover', id);
          highlighter.remove(id)
          $ele.parentNode.removeChild($ele)
        }
      })
      //.on(Highlighter.event.REMOVE, ({ids}) => {
      //ids.forEach(id => store.remove(id))
      //})
      highlighter.run()
    },
    getHightlightPosition($node) {
      let offset = {
        top: 0,
        left: 0,
      }
      while ($node) {
        offset.top += $node.offsetTop
        offset.left += $node.offsetLeft
        $node = $node.offsetParent
      }

      return offset
    },
    createHighlightDeleteTag(top, left, id) {
      console.log('CREATING DELETE TAG')
      const $span = document.createElement('span')
      $span.style.left = `${left - 20}px`
      $span.style.top = `${top - 25}px`
      $span.dataset['id'] = id
      $span.textContent = 'delete'
      $span.classList.add('my-remove-tip')
      document.body.appendChild($span)
    },
    renderNav() {
      if (this.showSections) {
        let all = document.getElementsByClassName('empty_nav_bt')
        for (let i = 0; i < all.length; i++) {
          //if (all[i].classList.contains('empty_nav_bt')) {
          all[i].innerHTML = (this.language === 'pt' ? 'Secção ' : 'Section ') + (i + 1)
          //}
        }
      }
    },
    scrollToBegin() {
      let scrollToElementId = 'exam-start'

      // Make sure user see instructions
      if (this.hasInstructions) {
        scrollToElementId = 'exam-instructions'
      }

      let el = document.getElementById(scrollToElementId)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },

    // Data helpers
    async createContentHash(data) {
      return await this.simpleHashFunction(JSON.stringify(data))
    },
    async simpleHashFunction(str) {
      if (crypto && crypto.subtle) {
        console.log('Browser with crypto module')
        const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder('utf-8').encode(str))
        return Array.from(new Uint8Array(hashBuffer))
          .map(b => b.toString(16).padStart(2, '0'))
          .join('')
      } else {
        let hash = 0
        for (let i = 0, len = str.length; i < len; i++) {
          let chr = str.charCodeAt(i)
          hash = (hash << 5) - hash + chr
          hash |= 0 // Convert to 32bit integer
        }
        return Math.abs(hash)
      }
    },

    // Activity and logging
    registerExamActivity(log) {
      // Structure of a form log:
      //  time: new Date(),
      //  action: 'upload',
      //  inputID: id,
      //  elements: newfiles.map(file => file.key),
      console.log('registerExamActivity', log)
      if (
        log.action === 'writing' ||
        log.action == 'upload' ||
        log.action == 'removeFile' ||
        log.action == 'selecting_option'
      ) {
        if (log.value) {
          this.createContentHash(log.value).then(hash => {
            // Log this activity
            this.logActivity('answer-question', {
              'content-hash': hash,
              question: log.inputID,
              charPerSec: log.charPerSec ?? 0,
              details:
                log.action == 'upload' ? 'uploaded-file' : log.action == 'removeFile' ? 'removed-file' : 'answer',
            })
          })
        } else {
          // Log this activity
          this.logActivity('answer-question', {
            question: log.inputID,
            charPerSec: log.charPerSec ?? 0,
            details: log.action == 'upload' ? 'uploaded-file' : log.action == 'removeFile' ? 'removed-file' : 'answer',
          })
        }
        this.sendWStoManagers('answer-question', log.inputID)
      } else if (
        ((log.action == 'blur' || log.action == 'focus') && log.inputID === 'window') ||
        log.action === 'enter_full_screen' ||
        log.action === 'leaving_full_screen' ||
        log.action === 'copy' ||
        log.action === 'paste'
      ) {
        let activityCode =
          log.action == 'blur'
            ? 'leave-window'
            : log.action == 'focus'
            ? 'enter-window'
            : log.action.replaceAll('_', '-')
        this.logActivity(activityCode, {}, true)
        //Important anti-cheating events
        console.log('ANTI-CHEATING', activityCode)
        this.sendWStoManagers(activityCode, 'window')
      }
    },
    async sendWStoManagers(code, value) {
      value = typeof value == 'string' && value.length == 0 ? 'NONE' : value ?? 'NONE'
      try {
        let result = await ServiceExams.sendWSMessageToManagers(this.instance.key, code, value)
        console.log('sendWStoManagers', result)
      } catch (e) {
        console.log('Error sending WS message to managers', e)
      }
    },
    logActivity(action, metadata = {}, byUserInteraction = true) {
      this.$store.dispatch('saveActivityAction', {
        service: 'exams',
        metadata: metadata,
        action: action,
        elementType: 'instance',
        elementID: this.instance != null ? this.instance.key : 'NULL',
        userInteraction: byUserInteraction,
      })
    },
  },
}
</script>

<style scope>
.exam-content {
  @apply grid;
  grid-template-columns: 1fr 190px;
}

.instructions-box.expanded-instructions .instructions-text {
  max-height: none;
}

.instructions-box .instructions-text {
  max-height: 100px;
  overflow-y: hidden;
}

.exam-view {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.start-exam-btn {
  @apply rounded-md px-5 py-2.5 font-semibold text-sm text-center text-white text-opacity-90 bg-primary uppercase;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  letter-spacing: -0.025em;
}

.start-exam-btn:hover,
.start-exam-btn:active {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.start-exam-btn.disabled {
  @apply bg-gray-200 bg-opacity-40 shadow-none cursor-not-allowed text-gray-400;
}

.cover {
  font-weight: 700;
  letter-spacing: -0.025em;
  min-height: 520px;
  @apply text-gray-800 text-center p-5 relative;
}

.cover.exam-ended {
  @apply text-gray-400;
}

.cover-background.exam-ended {
  filter: grayscale(1);
}

.cover-hide {
  transform: translateY(-115vh) scale(1.2);
}

.cover-fade {
  opacity: 0;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>

<i18n>
  {
    "en": {
      "notitle": "No title",
      "running": "Running",
      "endbutton": "Submit",
      "giveupbutton": "Give up",
      "giveuptitle": "Give up",
      "giveupmessage": "Are you sure you want to give up the exam?",
      "giveupcancel": "Cancel",
      "giveupconfirm": "Yes, give up",
      "submittitle": "Submit exam",
      "submitmessage": "Are you sure you want to submit the exam?",
      "submitcancel": "Cancel",
      "submitconfirm": "Yes",
      "examstartlabel": "EXAM STARTS HERE",
      "examendlabel": "EXAM ENDS HERE",
      "genericerror": "An error occurred while performing the operation",
      "errorsavingtermsandphoto": "Error saving photo and terms acceptance. Please try again later.",
      "errorloading": "An error occurred while loading the exam. Please refresh the page to try again.",
      "errorsubmitting": "An error occurred while submitting the exam but your answers are saved and the exam will be considered.",
      "errorloading2": "An error occurred while loading the exam. Please try again later or contact support.",
      "timeovermessage1": "There are less than",
      "timeovermessage2": "minute(s) to",
      "timeovermessage3": "end the evaluation.",
      "timeovermessage4": "the evaluation predicted end.",
      "quitexamtitle": "Are you sure you want to quit this exam?",
      "quitexammessage": "If you leave, you may re-enter if the exam is still running. If you do not explicitly give up the exam, it will be considered automatically submitted at the end of the exam.",
      "quitexamcancel": "Cancel",
      "quitexamconfirm": "Exit",
      "recoverbackup": "Recover backup",
      "recoverbackupmessage": "It seems that you have a backup of this exam. Do you want to recover it?",
      "recoverbackupcancel": "No",
      "recoverbackupconfirm": "Yes",
      "backuprecovered": "Backup recovered",
      "backuprecoveredmessage": "The backup was successfully recovered. We will now reload the exam.",
      "backuprecoveredcancel": "Close",
      "backuprecoveredconfirm": "Reload",
      "modalsubmit": {
        "examended": "Exam ended",
        "examsubmited": "Exam submited",
        "answeredquestions": "Questions answered",
        "instructions": "Click the following button to close this window and return to the previous context.",
        "close": "Close",
        "closeandsignout": "Close and sign out"
      },
      "modalgiveup": {
        "title": "You gave up from the exam",
        "message": "Your answers will not be considered for evaluation.",
        "instructions": "Click the following button to close this window and return to the previous context.",
        "close": "Close"
      },
      "modalterms": {
        "title": "Use terms",
        "wronganswers": "The wrong answers decrease your score",
        "wronganswersmessage": "Warning: giving a wrong answer will decrease your final score. You should only give a wrong answer if you are sure that the correct answer is not available.",
        "monitoring": "Monitoring technology",
        "monitoringmessage": "We use non-invasive behavioral analysis technology to evaluate the integrity of the evaluation process. Actions such as the following will be recorded:",
        "monitoringaction1": "Copy",
        "monitoringaction2": "Paste",
        "monitoringaction3": "Leaving the window",
        "monitoringaction4": "Writting speed",
        "photo": "Control photo",
        "photomessage": "At the beginning of the exam we will take a selfie of the participant for future reference.",
        "photomessage2": "The photo will be deleted automatically after the correction of the exam.",
        "accept": "Accept",
        "takephoto": "Take photo",
        "allowcameramessage": "Allow access to the camera to verify your identity. Attention: if you are using Windows, check that the camera is not being used by another application before continuing.",
        "usephotomessage": "Looking good? If so, click \"Use photo\" to continue",
        "photoinfomessage": "Verify that your face is not cut and that it is well lit. To continue click on \"Take photo\"",
        "activatecamerabutton": "Activate camera",
        "camerabuttonagain": "Try again",
        "usephotobutton": "Use photo",
        "takephotobutton": "Take photo",
        "fullscreen": "Full screen required",
        "fullscreenmessage": "To perform the exam you need to activate full screen. To do this, click on the \"Activate full screen\" button.",
        "fullscreenmessage2": "Attention that you must keep the exam on full screen during the entire time of the exam. If you leave full screen, the monitor will be notified.",
        "fullscreenbutton": "Activate full screen"
      },
      "modaltimeover": {
        "autoend": "The exam will be automatically terminated.",
        "manualend": "The exam will be manually terminated by the responsible monitor.",
        "autoendmessage": "The presented time is an estimate based on the time defined for the start and end of the evaluation.",
        "message": "When the evaluation is over, your exam will be submitted automatically. If you want it not to be submitted, please click on the \"Give up\" option. If you want to submit your exam in advance, click on the \"Submit\" option.",
        "button": "OK, I understand. Continue."
      }
    },
    "pt": {
      "notitle": "Sem título",
      "running": "Em curso",
      "endbutton": "Terminar",
      "giveuptitle": "Desistir do exame",
      "giveupmessage": "Tem a certeza que quer desistir do exame?",
      "giveupcancel": "Cancelar",
      "giveupconfirm": "Sim",
      "giveupbutton": "Desistir",
      "submittitle": "Terminar exame",
      "submitmessage": "Tem a certeza que deseja terminar o exame?",
      "submitcancel": "Cancelar",
      "submitconfirm": "Sim",
      "examstartlabel": "INÍCIO DO EXAME",
      "examendlabel": "FIM DO EXAME",
      "genericerror": "Ocorreu um erro a realizar a operação",
      "errorsavingtermsandphoto": "Erro ao guardar a foto e aceitação dos termos. Por favor tente mais tarde.",
      "errorloading": "Ocorreu um erro ao carregar o exame. Por favor, atualize a página para tentar novamente.",
      "errorloading2": "Ocorreu um erro ao carregar o exame. Tente de novo mais tarde ou contacte o suporte.",
      "errorsubmitting": "Ocorreu um erro ao submeter o exame mas as suas respostas estão guardadas e o exame será considerado.",
      "timeovermessage1": "Faltam menos de",
      "timeovermessage2": "minuto(s) para",
      "timeovermessage3": "o exame terminar.",
      "timeovermessage4": "a duração prevista do exame terminar.",
      "quitexamtitle": "Tem a certeza que deseja sair deste exame?",
      "quitexammessage": "Se sair, poderá voltar a reentrar caso o exame ainda esteja a decorrer. Caso não desista explicitamente do exame, o mesmo será considerado automaticamente entregue no final do exame.",
      "quitexamcancel": "Cancelar",
      "quitexamconfirm": "Sair",
      "recoverbackup": "Recuperar cópia de segurança",
      "recoverbackupmessage": "Parece que tem uma cópia de segurança deste exame. Deseja recuperá-la?",
      "recoverbackupcancel": "Não",
      "recoverbackupconfirm": "Sim",
      "backuprecovered": "Cópia de segurança recuperada",
      "backuprecoveredmessage": "A cópia de segurança foi recuperada com sucesso. Vamos agora recarregar o exame.",
      "backuprecoveredconfirm": "Recarregar",
      "modalsubmit": {
        "examended": "Exame terminado",
        "examsubmited": "Exame submetido",
        "answeredquestions": "Perguntas respondidas",
        "instructions": "Carregue no botão seguinte para fechar esta janela e voltar ao contexto anterior.",
        "close": "Fechar",
        "closeandsignout": "Fechar e terminar sessão"
      },
      "modalgiveup": {
        "title": "Desistiu do exame",
        "message": "O seu exame não será considerado para a avaliação.",
        "instructions": "Carregue no botão seguinte para fechar esta janela e voltar ao contexto anterior.",
        "close": "Fechar"
      },
      "modalterms": {
        "title": "Termos de utilização",
        "wronganswers": "Respostas erradas descontam",
        "wronganswersmessage": "Atenção que selecionar uma opção errada desconta na pontuação do exame, pelo que no final poderá ter uma pontuação negativa.",
        "monitoring": "Tecnologia de monitorização",
        "monitoringmessage": "Os monitores deste exame serão notificados caso execute uma das seguintes operações:",
        "monitoringaction1": "Copiar",
        "monitoringaction2": "Colar",
        "monitoringaction3": "Sair da janela",
        "monitoringaction4": "Velocidade de escrita",
        "photo": "Verificação de identidade",
        "photomessage": "No início do exame iremos tirar uma selfie ao participante para referência posterior.",
        "photomessage2": "O registo fotográfico é automaticamente eliminado após a correção do exame.",
        "accept": "Aceito",
        "takephoto": "Tirar foto",
        "allowcameramessage": "Permita o acesso à câmara para podermos verificar a sua identidade. Atenção: se usar Windows verifique que a câmara não está a ser usada por outra aplicação antes de continuar.",
        "usephotomessage": "Parece-lhe bem? Se sim clique em \"Usar foto\" para continuar",
        "photoinfomessage" :"Verifique que a sua cara não está cortada e que está bem iluminada. Para continuar clique em \"Tirar foto\"",
        "activatecamerabutton": "Ativar câmara",
        "camerabuttonagain": "Tirar novamente",
        "usephotobutton": "Usar foto",
        "takephotobutton": "Tirar foto",
        "fullscreen": "Ecrã inteiro obrigatório",
        "fullscreenmessage": "Para realizar o exame necessita de ativar o ecrã inteiro. Para isso, clique no botão \"Ativar ecrã inteiro\".",
        "fullscreenmessage2": " Atenção que deverá manter o exame em ecrã inteiro durante todo o tempo de realização do exame. Caso saia do ecrã inteiro, o vigilante será notificado.",
        "fullscreenbutton": "Ativar ecrã inteiro"
      },
      "modaltimeover": {
        "autoend": "O exame será terminado automaticamente.",
        "manualend": "O exame será terminado pelo(a) vigilante responsável.",
        "autoendmessage": "O tempo apresentado é uma estimativa com base na hora definida para início e fim da avaliação.",
        "message": "Quando a avaliação foi terminada, o seu exame será submetido de forma automática. Se desejar que o mesmo não seja submetido, por favor, carregue na opção \"Desistir\". Caso pretenda submeter antecipadamente o seu exame, carregue na opção \"Terminar\".",
        "button": "OK, compreendo. Continuar."
      }
    }
  }
  </i18n>
