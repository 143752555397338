var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center rounded-md",class:{
      'bg-gray-100': _vm.color == 'default',
      'bg-white': _vm.color == 'white',
    }},[(_vm.hasPrefix)?_c('div',{staticClass:"rounded-l-lg h-full px-2.5 py-2 text-gray-500 border shadow-sm border-gray-200 bg-gray-200 bg-opacity-40"},[_vm._t("prefix")],2):_vm._e(),_c('div',{staticClass:"flex-1"},[(_vm.hasMain)?_c('div',{staticClass:"pr-1"},[_vm._t("main")],2):_c('input',{staticClass:"input w-full py-1 outline-none border-0 ring-0 focus:outline-none focus:border-0 focus:ring-0",class:[
          {
            'rounded-none': _vm.hasPrefix || _vm.hasSuffix,
            'rounded-r-md': _vm.hasPrefix && !_vm.hasSuffix,
            'rounded-l-md': _vm.hasSuffix && !_vm.hasPrefix,
            'rounded-md': !_vm.hasPrefix && !_vm.hasSuffix,
            'px-2.5': !_vm.paddingless,
            'px-0': _vm.paddingless,
            'bg-white': _vm.color == 'white',
          },
          _vm.customClass,
        ],attrs:{"id":_vm.id,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"type":_vm.type,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"name":_vm.id,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.emitInput($event.target.value)},"change":function($event){return _vm.emitChange($event.target.value)},"focus":function($event){return _vm.emitFocus()},"blur":function($event){return _vm.emitBlur()}}})]),(_vm.hasSuffix)?_c('div',{staticClass:"rounded-r-lg h-full px-2.5 py-2 text-gray-500 border shadow-sm border-gray-200 bg-gray-200 bg-opacity-40"},[_vm._t("suffix")],2):_vm._e()]),(_vm.help)?_c('div',{staticClass:"text-xs text-gray-400 py-1"},[_vm._v(" "+_vm._s(_vm.help)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }