<template>
  <div class=" flex flex-col text-center justify-center justify-items-center max-w-screen-lg mx-auto">
    <div v-if="showManagers" class="flex gap-5 text-left w-full">
      <div class="bg-white rounded-xl flex w-1/2">
        <div v-if="firstLoading" class="animate-pulse h-12 rounded-md bg-gray-800 bg-opacity-10 w-1/2"></div>
        <div v-else-if="examCreator" class="px-4 pb-3 pt-2 flex-1">
          <fw-label>
            {{ $t('instructions_' + (isForm ? 'form' : 'exam') + '.examcreatedby') }}
          </fw-label>
          <Person :person="examCreator"></Person>
        </div>
      </div>
      <div class="bg-white rounded-xl px-4 pb-3 pt-2 w-1/2">
        <div v-if="firstLoading" class="animate-pulse h-12 rounded-md bg-gray-800 bg-opacity-10"></div>
        <div v-else>
          <fw-label :color="'text-primary'">
            {{ $t('instructions_' + (isForm ? 'form' : 'exam') + '.yourid') }}
          </fw-label>
          <Person v-if="me" :person="me"></Person>
        </div>
      </div>
    </div>
    <div class="max-w-3xl w-full mx-auto flex flex-col">
      <div v-if="firstLoading" class="animate-pulse h-8 rounded-md bg-gray-800 bg-opacity-10 w-1/6 mx-auto"></div>
      <div v-if="firstLoading" class="w-5/6 sm:w-4/6 mx-auto">
        <div class="animate-pulse h-7 rounded-md bg-gray-400 bg-opacity-10 mb-3.5"></div>
        <div class="animate-pulse h-7 rounded-md bg-gray-400 bg-opacity-10 w-5/6"></div>
      </div>
      <div
        v-if="examEnded && showActions && !firstLoading"
        class="bg-gray-50 bg-opacity-40 rounded-md p-4 text-lg text-gray-400"
      >
        {{ $t('status_' + (isForm ? 'form' : 'exam') + '.ended') }}
      </div>
      <div v-if="showInstructions" class="text-left flex flex-col items-stretch mt-5">
        <fw-label>Instruções</fw-label>
        <div class="items-center font-normal text-base leading-6 justify-start group " v-html="examInstructions"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'

export default {
  name: 'PanelExamCover',
  components: { Person },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    instanceKey: {
      type: String,
      required: true,
    },
    firstLoading: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
    language: {
      type: String,
      default: 'pt',
    },
    instance: {
      type: Object,
    },
    users: {
      type: Object,
    },
    examEnded: {
      type: Boolean,
      default: false,
    },
    canStart: {
      type: Boolean,
      default: false,
    },
    isRunning: {
      type: Boolean,
      default: false,
    },
    withAnswer: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
    showManagers: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showInstructions() {
      return this.isRunning && !this.firstLoading && this.examInstructions && this.examInstructions.length > 7
    },
    questionNumb() {
      return 0
    },
    sectionNumb() {
      return 0
    },
    predictedStartTime() {
      return '00:00'
    },
    predictedStartDate() {
      return '10/12/2022'
    },
    examDuration() {
      return this.instance && this.instance.duration ? this.instance.duration.toString() : '0'
    },
    me() {
      return this.$store.getters.getUser
    },
    examCreator() {
      return this.users != null && this.users[this.instance.user_key] ? this.users[this.instance.user_key] : null
    },
    examDynamizer() {
      return this.users != null && this.instance.managers.length > 0 ? this.users[this.instance.managers[0]] : null
    },
    examName() {
      return this.form && this.form.title[this.language]
    },
    examInstructions() {
      return this.form && this.form.instructions[this.language]
    },
    availableLanguages() {
      return this.form && this.form.options.availableLanguages ? this.form.options.availableLanguages : []
    },
    randomMultiplechoiceOptions() {
      return this.form && this.form.options.randomMultiplechoiceOptions
        ? this.form.options.randomMultiplechoiceOptions
        : false
    },
    randomSections() {
      return this.form && this.form.options.randomSections ? this.form.options.randomSections : false
    },
    negativePoints() {
      return this.form && this.form.options.negativePoints ? this.form.options.negativePoints : false
    },
    limitNegativePoints() {
      return this.form && this.form.options.limitNegativePoints ? this.form.options.limitNegativePoints : false
    },
    examPoints() {
      return 0
    },
    autoMonitor() {
      return this.instance && this.instance.options.logInteractions ? this.instance.options.logInteractions : false
    },
  },
  methods: {
    startExam() {
      this.$emit('start')
    },
  },
}
</script>
<i18n>
  {
    "en": {
      "status_exam": {
        "running": "In progress",
        "waiting": "Wait for the start of the exam",
        "ended": "Exam ended"
      },
      "status_form": {
        "running": "In progress",
        "waiting": "Wait for the start of the survey",
        "ended": "Survey ended"
      },
      "action_exam": {
        "reopen": "Reopen",
        "continue": "Continue",
        "start": "Start exam",
        "wait": "Wait"
      },
      "action_form": {
        "reopen": "Reopen",
        "continue": "Continue",
        "start": "Start survey",
        "wait": "Wait"
      },
      "metadata_exam": {
        "examkey": "Exame Key",
        "sections": "Sections",
        "questions": "Questions",
        "duration": "Duration",
        "predictedStartTime": "Predicted start time",
        "predictedStartDate": "Predicted start date",
        "predictedStart":  "Predicted start",
        "presential": "Presential exam",
        "remote": "Remote exam",
        "participants": "Participants",
        "access": "Access",
        "versions": "Versions",
        "open": "Public",
        "restricted": "Restricted"
      },
      "metadata_form": {
        "examkey": "Survey Key",
        "sections": "Sections",
        "questions": "Questions",
        "duration": "Duration",
        "predictedStartTime": "Predicted start time",
        "predictedStartDate": "Predicted start date",
        "predictedStart":  "Predicted start",
        "presential": "Presential survey",
        "remote": "Remote survey",
        "participants": "Participants",
        "access": "Access",
        "versions": "Versions",
        "open": "Public",
        "restricted": "Restricted"
      },
      "instructions_exam": {
        "attention": "Attention",
        "negativePointsLimit": "Selecting wrong options take points from the question",
        "negativePoints": "Selecting wrong options take points from the exam",
        "points": "Points",
        "autoMonitor": "This exam uses automated monitoring tech",
        "knowmore": "Saber mais",
        "examcreatedby": "Exam created by",
        "examdynamizedby": "Exam dynamized by",
        "yourid": "Your id"
      },
      "instructions_form": {
        "attention": "Attention",
        "negativePointsLimit": "Selecting wrong options take points from the question",
        "negativePoints": "Selecting wrong options take points from the exam",
        "points": "Points",
        "autoMonitor": "This survey uses automated monitoring tech",
        "knowmore": "Saber mais",
        "examcreatedby": "Survey created by",
        "examdynamizedby": "Survey dynamized by",
        "yourid": "Your id"
      }
    },
    "pt": {
      "status_exam": {
        "running": "Em curso",
        "waiting": "Aguarde pelo exame começar",
        "ended": "Exame terminado"
      },
      "status_form": {
        "running": "Em curso",
        "waiting": "Aguarde pelo questionário começar",
        "ended": "Questionário terminado"
      },
      "action_exam": {
        "reopen": "Reabrir",
        "continue": "Continuar",
        "start": "Abrir Exame",
        "wait": "Aguarde"
      },
      "action_form": {
        "reopen": "Reabrir",
        "continue": "Continuar",
        "start": "Abrir Questionário",
        "wait": "Aguarde"
      },
      "metadata_exam": {
        "examkey": "Chave do exame",
        "sections": "Secções",
        "questions": "Questões",
        "duration": "Duração",
        "predictedStartTime": "Início previsto",
        "predictedStartDate": "Data prevista",
        "predictedStart":  "Início previsto",
        "presential": "Presencial",
        "remote": "Remoto",
        "participants": "Participantes",
        "access": "Acesso",
        "versions": "Versões",
        "open": "Aberto",
        "restricted": "Restrito"
      },
      "metadata_form": {
        "examkey": "Chave do questionário",
        "sections": "Secções",
        "questions": "Questões",
        "duration": "Duração",
        "predictedStartTime": "Início previsto",
        "predictedStartDate": "Data prevista",
        "predictedStart":  "Início previsto",
        "presential": "Questionário presencial",
        "remote": "Questionário remoto",
        "participants": "Participantes",
        "access": "Acesso",
        "versions": "Versões",
        "open": "Aberto",
        "restricted": "Restrito"
      },
      "instructions_exam": {
        "attention": "Atenção",
        "negativePointsLimit": "Selecionar opções erradas descontam na própria pergunta",
        "negativePoints": "Selecionar opções erradas descontam",
        "points": "Pontuação",
        "autoMonitor": "Este exame usa tecnologia de monitorização automática",
        "knowmore": "Saber mais",
        "examcreatedby": "Exame criado por",
        "examdynamizedby": "Exame dinamizado por",
        "yourid": "A sua identificação"
      },
      "instructions_form": {
        "attention": "Atenção",
        "negativePointsLimit": "Selecionar opções erradas descontam na própria pergunta",
        "negativePoints": "Selecionar opções erradas descontam",
        "points": "Pontuação",
        "autoMonitor": "Este questionário usa tecnologia de monitorização automática",
        "knowmore": "Saber mais",
        "examcreatedby": "Questionário criado por",
        "examdynamizedby": "Questionário dinamizado por",
        "yourid": "A sua identificação"
      }
    }
  }
  </i18n>
