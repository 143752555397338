<template>
  <TapbarBase center>
    <template #buttons>
      <ButtonTapbar :active="view == 'classes'" label="Turmas" @click.native="$emit('go-to-view', 'classes')">
        <template #svg>
          <fw-icon-people class="w-7 h-7 p-0.5" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar :active="view == 'buckets'" label="Materiais" @click.native="$emit('go-to-view', 'buckets')">
        <template #svg>
          <fw-icon-box-open class="w-7 h-7" />
        </template>
      </ButtonTapbar>
    </template>
  </TapbarBase>
</template>

<script>
import TapbarBase from '@/fw-modules/fw-core-vue/ui/components/tapbars/TapbarBase'
import ButtonTapbar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonTapbar'

export default {
  components: {
    ButtonTapbar,
    TapbarBase,
  },

  props: {
    view: String,
    isTeacher: Boolean,
  },
}
</script>
