export default [
  {
    path: '/support',
    name: 'tasks-support',
    component: () => import('@/fw-modules/fw-core-vue/tasks/views/ViewTasks'),
    meta: { requiresAuth: true },
  },
  {
    path: '/support/new',
    name: 'tasks-support-new',
    component: () => import('@/fw-modules/fw-core-vue/tasks/views/ViewTasks'),
    meta: { requiresAuth: true },
  },
  {
    path: '/support/task/:key',
    name: 'tasks-support-task',
    component: () => import('@/fw-modules/fw-core-vue/tasks/views/ViewTasks'),
    meta: { requiresAuth: true },
  },
  {
    path: '/support/task/t/:token',
    name: 'tasks-support-task-no-auth',
    component: () => import('@/fw-modules/fw-core-vue/tasks/views/ViewTask'),
    meta: { requiresNoAuth: true },
  },
]
