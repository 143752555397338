<template>
  <div v-if="classes.length" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-10 py-2">
    <CardUnitClass
      v-for="unitClass in classes"
      :key="unitClass.key"
      :unit-class="unitClass"
      :profile="profile"
      @click.native="$router.push({ name: 'class', params: { key: unitClass.key } })"
    />
  </div>

  <div
    v-else
    class="border border-gray-300 flex flex-col text-center items-center justify-center text-gray-500 p-10 rounded-xl"
  >
    <div>Esta disciplina não tem turmas associadas.</div>
  </div>
</template>

<script>
import CardUnitClass from '@/fw-modules/fw-core-vue/academic/components/cards/CardUnitClass'

export default {
  components: {
    CardUnitClass,
  },

  props: {
    unit: Object,
    classes: Array,
    profile: {
      type: String,
      default: 'teacher',
    },
  },
}
</script>
