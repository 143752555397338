<template>
  <div>
    <multiselect
      v-model="value"
      :disabled="disabled"
      :options="countries"
      :show-labels="false"
      :clear-on-select="false"
      :allow-empty="false"
      placeholder="Escolha um país"
      label="title"
      track-by="code"
      :max-height="200"
      @input="updateParent"
    >
      <template slot="singleLabel" slot-scope="props"> {{ props.option.code }} - {{ props.option.title }} </template>
      <template slot="option" slot-scope="props">{{ props.option.code }} - {{ props.option.title }} </template>
    </multiselect>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Multiselect from 'vue-multiselect'
import ServiceUtilities from '@/fw-modules/fw-core-vue/id/services/ServiceUtilities'

export default {
  name: 'PhoneCountriesSelect',
  components: {
    Multiselect,
  },
  props: {
    input: [Object, String],
    disabled: Boolean,
  },
  data() {
    return {
      countries: [],
      value: null,
    }
  },

  mounted() {
    this.getCountries()
  },

  methods: {
    updateParent() {
      this.$emit('update', this.value)
    },

    async getCountries() {
      this.countries = await ServiceUtilities.getPhoneCountries()

      // Define Portugal como default para facilitar o preenchimento
      if (isEmpty(this.input)) {
        this.value = this.countries[this.countries.findIndex(item => item.key == 'PT')]
        //this.updateParent()
      } else {
        if (typeof this.input === 'string') {
          this.value = this.countries[this.countries.findIndex(item => item.key == this.input.toUpperCase())]
        } else {
          this.value = this.input
        }
      }
    },
  },
}
</script>
