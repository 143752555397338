<template>
  <div>
    <button-quick-access
      :disabled="showEnterMeetingToJoin"
      label="Acesso rápido"
      title="Entrar numa sala"
      footer="Entrar com ID da sala"
      @clicked="toggleModal"
    />
    <ModalEnterMeetingToJoin :show="showEnterMeetingToJoin" @close="toggleModal" />
  </div>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import ModalEnterMeetingToJoin from '@/fw-modules/fw-meetings-vue/components/modals/ModalEnterMeetingToJoin'

export default {
  components: {
    ButtonQuickAccess,
    ModalEnterMeetingToJoin,
  },

  data() {
    return {
      showEnterMeetingToJoin: false,
    }
  },

  computed: {
    isMobile() {
      return this.window.width < 640
    },
  },

  methods: {
    toggleModal() {
      this.showEnterMeetingToJoin = !this.showEnterMeetingToJoin
    },
  },
}
</script>
