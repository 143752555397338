<template>
  <div>
    <div class="px-2.5 rounded relative double-input" :class="{ disabled: disabled === true }">
      <div v-for="(inp, i) in innerInputs" :key="'innerinput_' + i" class="relative">
        <label :for="'double_input_' + i + $vnode.key" class="hidden">{{ ariaLabels[inp.text] }}</label>
        <textarea
          v-if="multiline === true"
          :id="'double_input_' + i + $vnode.key"
          :ref="'textarea_' + i"
          :placeholder="
            innerInputs.length > 0 && placeholder && placeholder[inp.text]
              ? placeholder[inp.text]
              : typeof placeholder == 'string'
              ? placeholder
              : ''
          "
          :disabled="disabled === true"
          :value="inp.value"
          :rows="rows"
          :maxlength="maxlength == 0 ? 65000 : maxlength"
          :minlength="minlength"
          class="inp w-full p-1.5 text-lg"
          :class="{ 'pl-10': innerInputs.length > 1, autogrow: autogrow === true }"
          :style="{ minHeight: minHeight > 0 ? minHeight + 'px' : 'auto' }"
          @click="emptyInput()"
          @input="changeInput(i, $event)"
          @change="saveData()"
          @blur="eventInput('blur')"
        ></textarea>
        <input
          v-else
          :id="'double_input_' + i + $vnode.key"
          :placeholder="
            innerInputs.length > 0 && placeholder && placeholder[inp.text]
              ? placeholder[inp.text]
              : typeof placeholder == 'string'
              ? placeholder
              : ''
          "
          type="text"
          :value="inp.value"
          :maxlength="maxlength == 0 ? 65000 : maxlength"
          :minlength="minlength"
          class="inp w-full pl-10 py-2"
          :disabled="disabled === true"
          @click="emptyInput()"
          @input="changeInput(i, $event)"
          @blur="eventInput('blur')"
          @change="saveData()"
        />
        <div
          v-if="inp.text.length > 0"
          class="legend absolute px-1 py-0.5 bg-white uppercase rounded shadow leading-4 text-xs font-semibold top-2 left-1"
        >
          {{ inp.text }}
        </div>
        <div v-if="i !== innerInputs.length - 1" class="border-t border-gray-200"></div>
      </div>
    </div>
    <div v-if="help" class="text-xs text-gray-400 py-1">
      {{ help }}
    </div>
    <div class="flex mt-2">
      <div v-if="maxchars > 0" class="text-left text-xs" :class="{ 'font-bold text-red-600 ': exceedsMaximum }">
        {{ currentCharacters }}/{{ maxchars }}
      </div>
      <div class="flex-1"></div>
      <fw-button
        v-if="showSaveButton"
        :type="saving ? 'disabled' : 'primary'"
        :size="tablet ? 'md' : 'sm'"
        @click.native="saveData(true)"
        >{{ saving ? $t('saving') : $t('save') }}</fw-button
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'TranslatedInput',
  props: {
    value: {
      type: [String, Object],
    },
    placeholder: {
      type: [String, Object],
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableAutowrite: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    help: {
      type: String,
    },
    minlength: {
      type: Number,
      default: 1,
    },
    maxlength: {
      type: Number,
      default: 65000,
    },
    maxchars: {
      type: Number,
      default: 0,
    },
    autogrow: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Number,
      default: 150,
    },
    showSaveButton: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    tablet: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      ariaLabels: {
        pt: 'Texto em português',
        en: 'Texto em inglês',
      },
      innerInputs: [],
      emptyInp: false,
    }
  },
  computed: {
    exceedsMaximum() {
      return this.currentCharacters >= this.maxchars && this.maxchars > 0
    },
    currentCharacters() {
      let max = 0
      for (let i = 0; i < this.innerInputs.length; i++) {
        //result += this.innerInputs[i].value.length
        if (this.innerInputs[i].value.length > max) {
          max = this.innerInputs[i].value.length
        }
      }
      return max
    },
  },
  created() {
    if (typeof this.value === 'string' || this.value === null) {
      this.innerInputs = [
        {
          text: '',
          value: this.value === null ? '' : this.value,
        },
      ]
    } else {
      let result = []
      for (const [key, value] of Object.entries(this.value)) {
        // console.log(key, value)
        result.push({
          text: key,
          value: value === null ? '' : value,
        })
      }
      this.innerInputs = result
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.innerInputs.forEach((inp, i) => {
        this.autoGrow(i)
      })
    })
  },
  methods: {
    autoGrow(idx_org) {
      if (this.autogrow && this.multiline) {
        this.$refs['textarea_' + idx_org][0].style.height = 0 + 'px'
        let height = this.$refs['textarea_' + idx_org][0].scrollHeight
        this.$refs['textarea_' + idx_org][0].style.height = height + 'px'
      }
    },
    emptyInput() {
      // console.log('inner click', this.value)
      //for (const value of Object.values(this.value)) {
      for (let i = 0; i < this.innerInputs.length; i++) {
        let value = this.innerInputs[i].value
        // console.log('value', value)
        if (value !== '') {
          this.emptyInp = false
          // console.log('not empty: ' + value)
          return
        }
      }
      this.emptyInp = true
      this.eventInput('focus')
    },
    changeInput(idx_org, event) {
      //Auto grow text area on change
      this.autoGrow(idx_org)
      if (this.emptyInp && this.disableAutowrite !== true) {
        let inp = this.innerInputs
        for (let i = 0; i < inp.length; i++) {
          if (i !== idx_org) {
            Vue.set(this.innerInputs, i, {
              text: this.innerInputs[i].text,
              value: event.target.value,
            })
          }
        }
      }
      Vue.set(this.innerInputs, idx_org, {
        text: this.innerInputs[idx_org].text,
        value: event.target.value,
      })
      this.saveData()
    },
    saveData(save = false) {
      if (this.innerInputs.length > 1 || (this.innerInputs.length > 0 && this.innerInputs[0].text.length > 0)) {
        let rehidratedObj = {}
        let inp = this.innerInputs
        for (let i = 0; i < inp.length; i++) {
          if (this.maxchars == 0) {
            rehidratedObj[inp[i]['text']] = inp[i]['value']
          } else {
            rehidratedObj[inp[i]['text']] = inp[i]['value'].substring(0, this.maxchars)
          }
        }
        if (save) {
          this.$emit('save', rehidratedObj)
        } else {
          this.$emit('input', rehidratedObj)
        }
      } else if (this.innerInputs.length > 0) {
        if (save) {
          this.$emit('save', this.innerInputs[0].value)
        } else {
          this.$emit('input', this.innerInputs[0].value)
        }
      }
    },
    eventInput(type) {
      if (type === 'focus') {
        this.$emit('focus')
      } else {
        this.$emit('blur')
      }
    },
  },
}
</script>

<style>
.double-input.disabled .legend {
  @apply text-gray-400;
}
.double-input .inp:focus + .legend {
  color: rgba(3, 164, 121, 0.7);
}
.double-input.disabled {
  @apply cursor-not-allowed;
}

.double-input.disabled input {
  @apply cursor-not-allowed;
}
.double-input.disabled textarea {
  @apply cursor-not-allowed;
}
.double-input {
  min-height: 38px;
}
.double-input input {
  background-color: transparent !important;
  outline: none;
  border: none;
}
.double-input input:focus {
  outline: none;
  border: none;
}

.double-input.disabled input {
  @apply text-gray-500;
}

.double-input.disabled textarea {
  @apply text-gray-500;
}

.double-input textarea {
  background-color: transparent !important;
  outline: none;
  border: none;
}
.double-input textarea:focus {
  outline: none;
  border: none;
}

.double-input textarea.autogrow {
  resize: none;
  min-height: 50px;
  overflow: hidden;
  box-sizing: border-box;
}

.double-input:focus-within {
  outline: solid 2px rgba(3, 164, 121, 0.7);
  outline-offset: 2px;
}
</style>

<i18n>
{
  "pt": {
    "save": "Guardar",
    "cancel": "Cancelar",
    "saving": "A guardar..."
  },
  "en": {
    "save": "Save",
    "cancel": "Cancel",
    "saving": "Saving..."
  }
}
</i18n>
