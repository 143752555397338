import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlContent
    return api
  },

  async getPageVersions(pageKey) {
    let response = await this.base().get(`/v1/pages/${pageKey}?with_blocks=true`)
    return response.data
  },

  async getPageVersionContent(pageKey, versionKey) {
    let response = await this.base().get(`/v1/pages/${pageKey}/versions/${versionKey}?with_blocks=true`)
    return response.data
  },

  /**
   * Create new page
   * @param {*} payload
   * Example:
   * {
   *  "application": "buckets",
   *   "context": "bucket",
   *   "context_key": "00dqhd34",
   *   "language": "pt",
   *   "title": "Title"
   * }
   * @returns
   */

  async getPages(params) {
    const response = await this.base().get('/v1/pages', {
      params: params,
    })
    return response.data
  },

  async createPage(payload) {
    const response = await this.base().post('/v1/pages', payload)
    return response.data
  },

  async updatePage(pageKey, payload) {
    const response = await this.base().put(`/v1/pages/${pageKey}`, payload)
    return response.data
  },

  async deletePage(pageKey) {
    const response = await this.base().delete(`/v1/pages/${pageKey}`)
    return response.data
  },

  async deletePageVersion(pageKey, versionKey) {
    const response = await this.base().delete(`/v1/pages/${pageKey}/versions/${versionKey}`)
    return response.data
  },

  async createPageVersion(pageKey, language) {
    const response = await this.base().post(`/v1/pages/${pageKey}/versions`, {
      language: language,
      is_default: false,
    })
    return response.data
  },

  async updatePageTitle(pageKey, versionKey, title) {
    const response = await this.base().put(`/v1/pages/${pageKey}/versions/${versionKey}`, {
      title: title,
    })
    return response.data
  },

  async updatePageContent(pageKey, versionKey, payload) {
    const response = await this.base().put(`/v1/pages/${pageKey}/versions/${versionKey}`, payload)
    return response.data
  },

  async createPageContentBlock(pageKey, versionKey, blockType, content = {}, index = 0) {
    console.log('createPageContentBlock', pageKey, versionKey, blockType, content, index)
    const response = await this.base().post(`/v1/pages/${pageKey}/versions/${versionKey}/blocks`, {
      type: blockType,
      content: content,
      index: index,
    })
    return response.data
  },

  async updatePageContentBlocksBulk(pageKey, versionKey, blocks = []) {
    console.log('updatePageContentBlocksBulk', pageKey, versionKey, blocks)
    let payload = {}
    for (let index = 0; index < blocks.length; index++) {
      const block = blocks[index]
      payload[block.key] = block
      //console.log(payload)
    }
    const response = await this.base().put(`/v1/pages/${pageKey}/versions/${versionKey}/blocks`, payload)
    return response.data
  },

  async reorderContentBlock(pageKey, versionKey, blockKey, newIndex = 0) {
    const response = await this.base().put(`/v1/pages/${pageKey}/versions/${versionKey}/blocks/${blockKey}`, {
      index: newIndex,
    })
    return response.data
  },

  async deleteContentBlock(pageKey, versionKey, blockKey) {
    const response = await this.base().delete(`/v1/pages/${pageKey}/versions/${versionKey}/blocks/${blockKey}`)
    return response.data
  },

  async filesPreview(fileKeys = []) {
    const response = await this.base().post(`/v1/files/preview`, {
      files: fileKeys,
    })
    return response.data
  },

  async addFiles(pageKey, versionKey, fileKeys = []) {
    const response = await this.base().post(
      `/v1/pages/${pageKey}/versions/${versionKey}/files`,
      fileKeys.map(fileKey => {
        return {
          key: fileKey,
        }
      })
    )
    return response.data
  },

  async deleteFiles(pageKey, versionKey, fileKeys = []) {
    const response = await this.base().delete(
      `/v1/pages/${pageKey}/versions/${versionKey}/files`,
      fileKeys.map(fileKey => {
        return {
          key: fileKey,
        }
      })
    )
    return response.data
  },

  async getFiles(pageKey, versionKey, fileKeys = []) {
    const response = await this.base().post(`/v1/pages/${pageKey}/versions/${versionKey}/file`, fileKeys)
    return response.data
  },

  async getInvites(pageKey) {
    const response = await this.base().get(`/v1/pages/${pageKey}/invites`)
    return response.data
  },

  async changePageState(pageKey, versionKey, state) {
    const response = await this.base().post(`/v1/pages/${pageKey}/versions/${versionKey}`, {
      state: state,
    })
    return response.data
  },

  async createInvite(pageKey, email, role) {
    const response = await this.base().post(`/v1/pages/${pageKey}/invites`, {
      email: email,
      role: role,
    })
    return response.data
  },

  async changePageUserRole(pageKey, userKey, role) {
    let data = {}
    data[userKey] = role
    const response = await this.base().post(`/v1/pages/${pageKey}/permissions`, data)
    return response.data
  },

  async pageRemoveUser(pageKey, userKey, role) {
    let data = {}
    data[userKey] = role
    const response = await this.base().delete(`/v1/pages/${pageKey}/permissions`)
    return response.data
  },

  async deleteInvite(pageKey, inviteKey) {
    const response = await this.base().delete(`/v1/pages/${pageKey}/invites/${inviteKey}`)
    return response.data
  },

  async setPageShareLink(pageKey, share = true) {
    const response = await this.base().put(`/v1/pages/${pageKey}`, {
      options: {
        allow_public_url: share,
      },
    })
    return response.data
  },

  async getInvite(pageKey, token) {
    const response = await this.base().get(`/v1/pages/${pageKey}/invites/${token}`)
    return response.data
  },

  async acceptInvite(pageKey, token) {
    const response = await this.base().post(`/v1/pages/${pageKey}/invites/${token}`)
    return response.data
  },

  //Create snapshot of a page version
  async createSnapshot(pageKey, versionKey) {
    const response = await this.base().post(`/v1/pages/${pageKey}/versions/${versionKey}/snapshots`, {})
    return response.data
  },

  //WS, code = 'subscribe_editor' || 'unsubscribe_editor'
  async subscribeToPage(code = 'subscribe_editor', pageKey, versionKey, connectionId) {
    if (connectionId != null) {
      console.log('page_version_key', versionKey)
      const response = await this.base().post(`/v1/ws`, {
        code: code,
        connectionId: connectionId,
        body: {
          page_key: pageKey,
          page_version_key: versionKey,
        },
      })
      return response.data
    }
  },

  //WS, code = 'select_block' || 'unselect_block'
  async broadcastSelect(code = 'select_block', pageKey, versionKey, blockKey, connectionId) {
    const response = await this.base().post(`/v1/ws`, {
      code: code,
      connectionId: connectionId,
      body: {
        page_key: pageKey,
        page_version_key: versionKey,
        block_key: blockKey,
      },
    })
    return response.data
  },

  /*
   * e-Portfolio
   */
  async competenceSearch(query) {
    let response = await this.base().get(`/v1/competences?query=${query}`)
    return response.data
  },
  async competenceCreate(en = '', pt = '') {
    let response = await this.base().post(`/v1/competences`, {
      name: {
        en: en,
        pt: pt,
      },
    })
    return response.data
  },
  async getPortfolio(portfolioKey) {
    let response = await this.base().get(`/v1/portfolio/${portfolioKey}`)
    return response.data
  },
  async savePortfolio(portfolioKey, data) {
    let response = await this.base().put(`/v1/portfolio/${portfolioKey}`, data)
    return response.data
  },
  async publishPortfolio(portfolioKey, publish = true) {
    let response = await this.base().post(`/v1/portfolio/${portfolioKey}`, {
      status: publish ? 'public' : 'private',
    })
    return response.data
  },
  async getOrganicUnits() {
    let response = await this.base().get(`/v1/organic_units`)
    return response.data
  },
  //Activate tablet digitalpad
  async activateDigitalPad(activationKey, referenceKey, description) {
    let response = await this.base().post(`/v1/devices/activate/${activationKey}`, {
      reference_key: referenceKey,
      description: description,
    })
    return response.data
  },
}
