<template>
  <ChartNextClasses :total-stats="totalStats" :next-stats="nextStats" :done-stats="doneStats" />
</template>

<script>
import ChartNextClasses from '@/fw-modules/fw-core-vue/academic/components/charts/ChartNextClasses'

export default {
  components: {
    ChartNextClasses,
  },

  props: {
    totalStats: Array,
    nextStats: Array,
    doneStats: Array,
  },
}
</script>
