<template>
  <div class="w-full group h-full cursor-pointer">
    <div
      class="rounded-xl overflow-hidden aspect-w-16 aspect-h-9 w-full shadow-lg hover:shadow-2xl mb-2 bg-no-repeat bg-cover bg-center"
      :style="`background-image: url('${course.thumb}')`"
    >
      <div>
        <div class="h-full w-full relative bg-course-linear-gradient p-4">
          <slot name="overlay"></slot>
          <div
            class="absolute right-4 bottom-3 w-1/2 text-right font-bold text-primary leading-5"
            v-html="timeInfo"
          ></div>
        </div>
      </div>
    </div>
    <div class="px-3 relative">
      <v-clamp v-if="course.title" autoresize :max-lines="2" class="text-lg font-bold mb-1">
        {{ course.title }}
      </v-clamp>
      <v-clamp v-if="course.summary" autoresize :max-lines="5" class="text-sm font-semibold text-opacity-80">
        {{ course.summary }}
      </v-clamp>
      <div v-if="showCalendar" class="text-primary font-semibold">
        <div v-if="course.edition && course.edition != null">
          Inscrições abertas de {{ course.edition.signup_start_date | formatDateTime }} até
          {{ course.edition.signup_end_date | formatDateTime }}
        </div>
      </div>
      <div v-if="showMeta" class="flex flex-col gap-3 text-sm my-5">
        <div v-if="course.working_mode" class="flex gap-3 font-semibold items-center">
          <fw-icon-course-mode class="w-5"></fw-icon-course-mode>
          {{ course.working_mode }}
        </div>
        <div v-if="course.ects" class="flex gap-3 font-semibold items-center">
          <fw-icon-course-ects class="w-5"></fw-icon-course-ects>
          {{ course.ects }} ECTS
        </div>
        <div v-if="course.price && course.price > 0" class="flex gap-3 font-semibold items-center">
          <fw-icon-course-price class="w-5"></fw-icon-course-price>
          {{ course.price }}€
        </div>
        <div v-if="course.working_mode" class="flex gap-3 font-semibold items-center">
          <fw-icon-course-time class="w-5"></fw-icon-course-time>
          {{ course.duration }} {{ course.duration == 1 ? $t('hour') : $t('hours') }}
        </div>
        <div v-if="course.edition && course.edition != null" class="flex gap-3 font-semibold items-center">
          <fw-icon-course-calendar class="w-5"></fw-icon-course-calendar>
          {{ $t('startsat') }} {{ course.edition.start_date | formatDate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCourse',
  props: {
    course: {
      type: Object,
      required: true,
    },
    showMeta: {
      type: Boolean,
      default: true,
    },
    showCalendar: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    timeInfo() {
      if (this.course.dates && !this.myCourse) {
        if (this.course.dates.signup_passed && this.course.dates.next_session_date != null) {
          return this.$t('courseopenson') + this.course.dates.next_session_date
        } else if (this.course.dates.is_signup_open) {
          return this.$t('coursesignupopen') + this.course.dates.signup_end_date
        } else if (!this.course.dates.signup_passed && this.course.dates.next_signup_date != null) {
          return this.$t('coursesignupstart') + this.course.dates.next_signup_date
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
  },
}
</script>
<style scoped>
.bg-course-linear-gradient {
  background: rgb(50, 68, 87);
  background: linear-gradient(0deg, rgba(50, 68, 87, 0.9) 20%, rgba(50, 68, 87, 0) 80%);
}
</style>
<i18n>
  {
    "en": {
      "hours": "hours",
      "hour": "hour",
      "startsat": "Starts at",
      "courseopenson": "Course opens<br />on the ",
      "coursesignupopen": "Registration open<br />until ",
      "coursesignupstart": "Registration open<br />on the "
    },
    "pt": {
      "hours": "horas",
      "hour": "hora",
      "startsat": "Início em",
      "courseopenson": "Curso abre<br />a ",
      "coursesignupopen": "Inscrições abertas<br />até dia ",
      "coursesignupstart": "Inscrições abrem<br />a "
    }
  }
</i18n>
