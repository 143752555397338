<template>
  <div
    class="html-editor rounded-md"
    :class="{
      'no-minimum-height': !minimumHeight,
    }"
  >
    <div v-if="editor && !disabled" class="menubar">
      <button
        v-if="formatTools.includes('heading')"
        class="button is-text is-small ml-0"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 2 })
            .run()
        "
      >
        H1
      </button>

      <button
        v-if="formatTools.includes('heading')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 3 })
            .run()
        "
      >
        H2
      </button>

      <button
        v-if="formatTools.includes('heading')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 4 })
            .run()
        "
      >
        H3
      </button>

      <button
        v-if="formatTools.includes('bold')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('bold') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
      >
        <icon name="bold" />
      </button>

      <button
        v-if="formatTools.includes('italic')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('italic') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
      >
        <icon name="italic" />
      </button>

      <button
        v-if="formatTools.includes('underline')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('underline') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleUnderline()
            .run()
        "
      >
        <icon name="underline" />
      </button>

      <button
        v-if="false"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        @click="editor.setParagraph()"
      >
        <icon name="paragraph" />
      </button>

      <button
        v-if="formatTools.includes('bulletList')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        "
      >
        <icon name="ul" />
      </button>

      <button
        v-if="formatTools.includes('codeBlock')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleCodeBlock()
            .run()
        "
      >
        <icon name="code" />
      </button>
      <button v-if="formatTools.includes('link')" class="button is-text is-small" @click="setLink()">
        <fw-icon-link class="h-5 w-5" />
      </button>
      <button
        v-if="formatTools.includes('link')"
        class="button is-text is-small"
        :class="{ 'is-active': editor.isActive('link') }"
        @click="
          editor
            .chain()
            .focus()
            .unsetLink()
            .run()
        "
      >
        <fw-icon-unlink class="h-5 w-5" />
      </button>
    </div>
    <div
      :class="{
        'overflow-y-scroll': maxHeight != null,
      }"
      :style="{
        maxHeight: maxHeight + 'px',
      }"
    >
      <editor-content :editor="editor" @focus="emitFocus()" />
    </div>
  </div>
</template>

<script>
// https://github.com/ueberdosis/tiptap/blob/v1/examples/Components/Routes/Basic/index.vue
import { Editor, EditorContent } from '@tiptap/vue-2'

import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import CodeBlock from '@tiptap/extension-code-block'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
//import FileHandler from '@tiptap-pro/extension-file-handler'
import Image from '@tiptap/extension-image'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import _ from 'lodash'
export default {
  name: 'BlockEditor',
  components: {
    EditorContent,
  },
  props: {
    minimumHeight: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: 'htmlEditor',
    },
    value: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: 'Escreva aqui...',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editorClass: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    formatTools: {
      type: Array,
      default: () => ['bold', 'italic', 'underline', 'bulletList', 'codeBlock', 'link'],
    },
    extraFormatTools: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    saveContentUserSettings: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editor: null,
      formatToolsAvailable: [].concat(this.formatTools, this.extraFormatTools),
    }
  },

  computed: {
    contentId() {
      return 'editor-draft:' + btoa(window.location.pathname)
    },
  },

  watch: {
    value(value) {
      if (this.editor.getHTML() === value) return
      this.editor.commands.setContent(this.value, false)
    },
  },

  async mounted() {
    const classText = `editor ${this.editorClass}`
    let tmpValue = this.value
    try {
      if (this.saveContentUserSettings && tmpValue.length === 0) {
        const userSettings = await ServiceSettings.getSetting(this.contentId)
        console.log('userSettings loaded', userSettings)
        if (userSettings) {
          tmpValue = userSettings.value
          this.$emit('input', tmpValue)
        }
      }
    } catch (e) {
      console.error('Error loading draft', e)
    }
    const self = this
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: classText,
        },
      },
      editable: !self.disabled,
      injectCSS: false,
      content: tmpValue,
      autofocus: this.autofocus,
      extensions: [
        Link.configure({
          openOnClick: false,
        }),
        Underline,
        StarterKit,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: self.placeholderText,
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
        Image.configure({
          allowBase64: true,
        }),
        CodeBlock,
        /*FileHandler.configure({
          allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
          onPaste: (currentEditor, files) => {
            // do something with the files
            // and insert the file into the editor

            // in some cases (for example copy / pasted gifs from other apps) you should probably not use the file directly
            // as the file parser will only have a single gif frame as png
            // in this case, you can extract the url from the htmlContent and use it instead, let other inputRules handle insertion
            // or do anything else with the htmlContent pasted into here
            files.forEach(file => {
              const fileReader = new FileReader()
              fileReader.readAsDataURL(file)
              fileReader.onload = () => {
                currentEditor
                  .chain()
                  .insertContentAt(currentEditor.state.selection.anchor, {
                    type: 'image',
                    attrs: {
                      src: fileReader.result,
                    },
                  })
                  .focus()
                  .run()
              }
            })
          },
        }),*/
      ],
      onUpdate: () => {
        this.$emit('input', self.editor.getHTML())
        this.debouncedSaveDraft()
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  methods: {
    debouncedSaveDraft: _.debounce(
      function() {
        this.saveDraft()
      },
      1000,
      { maxWait: 5000 }
    ),
    async saveDraft() {
      if (!this.saveContentUserSettings) return
      try {
        await ServiceSettings.setSetting(this.contentId, this.editor.getHTML())
      } catch (e) {
        console.error('Error saving user draft', e)
      }
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      //const url = window.prompt('URL', previousUrl)
      this.$buefy.dialog.prompt({
        message: 'URL',
        inputAttrs: {
          value: previousUrl,
        },
        onConfirm: url => {
          // cancelled
          if (url === null) {
            return
          }

          // empty
          if (url === '') {
            this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .unsetLink()
              .run()

            return
          }

          // update link
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run()
        },
      })
    },
    emitFocus() {
      this.$emit('focus', this.id)
    },
    async deleteDraft() {
      if (this.saveContentUserSettings) {
        //the data was saved and we can delete the user settings data
        try {
          await ServiceSettings.deleteSetting(this.contentId)
        } catch (e) {
          console.error('Error deleting user settings', e)
        }
      }
    },
  },
}
</script>

<style>
.html-editor .max-heigth {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background-color: #f7fafc;
}
.html-editor .drop-cursor {
  border-radius: 3px;
  overflow: hidden;
}

.html-editor .menubar button:first-child {
  margin: 0;
}

.html-editor .ProseMirror h1 {
  font-size: 2em;
  font-weight: bold;
}

.html-editor .ProseMirror h2 {
  font-size: 1.6em;
  font-weight: bold;
}

.html-editor .ProseMirror pre {
  background-color: #444444 !important;
  color: #ffffff;
  font-family: monospace;
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 0.9em;
}
.html-editor .ProseMirror ul {
  padding-left: 20px;
  list-style-type: disc;
}

.html-editor .ProseMirror h3 {
  font-size: 1.4em;
  font-weight: bold;
}

.html-editor .ProseMirror h4 {
  font-size: 1.2em;
  font-weight: bold;
}

.html-editor .ProseMirror.editor {
  background-color: transparent !important;
  min-height: 130px;
  @apply p-3;
}

.html-editor.no-minimum-height .ProseMirror.editor {
  min-height: 0 !important;
}

.html-editor .ProseMirror.editor p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  @apply text-gray-600;
}

.html-editor .ProseMirror.editor[contenteditable='false'] {
  @apply cursor-not-allowed text-gray-500;
}

.html-editor .content p + p {
  margin-bottom: 1em;
}
.html-editor .content p {
  margin-bottom: 1em;
}
.content p:not(:last-child) {
  margin-bottom: 1em !important;
}
.html-editor a {
  color: #0bb470;
  text-decoration: underline;
}
</style>
