import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlContent
    return api
  },

  async getCollectionPosts(collectionKey, page = 1, limit = 30, type = 'post') {
    let response = await this.base().get(`/v1/collections/${collectionKey}`, {
      params: {
        page: page,
        limit: limit,
        type: type,
      },
    })
    return response.data
  },

  async getPost(postKey) {
    let response = await this.base().get(`/v1/posts/${postKey}`)
    return response.data
  },

  async updatePost(postKey, data, metadata = {}) {
    if (metadata) {
      data.metadata = metadata
    }
    let response = await this.base().put(`/v1/posts/${postKey}`, data)
    return response.data
  },

  async createPost(data, collectionKey, metadata = {}) {
    if (metadata) {
      data.metadata = metadata
    }
    let response = await this.base().post(`/v1/collections/${collectionKey}/posts`, data)
    return response.data
  },

  async detelePost(postKey) {
    let response = await this.base().delete(`/v1/posts/${postKey}`)
    return response.data
  },

  async reactPost(postKey, emoji) {
    let response = await this.base().post(`/v1/posts/${postKey}/reactions`, {
      emoji: emoji,
    })
    return response.data
  },

  async getPostComments(postKey, page = 1, limit = 30) {
    let response = await this.base().get(`/v1/posts/${postKey}/comments`, {
      params: {
        page: page,
        limit: limit,
      },
    })
    return response.data
  },

  async createPostComment(postKey, data, metadata = {}) {
    if (metadata) {
      data.metadata = metadata
    }
    let response = await this.base().post(`/v1/posts/${postKey}/comments`, data)
    return response.data
  },

  async getFeed(page = 1, limit = 30, collections = [], type = 'post') {
    let response = await this.base().get('/v1/posts', {
      params: {
        page: page,
        limit: limit,
        collection_key: collections,
        type: type,
      },
    })
    return response.data
  },
}
