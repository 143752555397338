<template>
  <SidebarBase v-if="edition">
    <template #main>
      <div>
        <fw-heading size="h2">{{ edition.title }}</fw-heading>
        <fw-heading size="h4" class="mb-1">{{ edition.unit.title }}</fw-heading>
        <div class="text-sm">{{ edition.profile }}</div>
        <div class="text-sm">{{ edition.academic_year }}</div>
        <div class="my-5">
          <fw-label>Sala virtual</fw-label>
          <fw-button
            :type="!edition.meeting.running ? 'light-primary' : 'primary'"
            custom-class="flex justify-between gap-3 items-center is-family-code lowercase"
            label="Entrar na sala virtual da turma"
            @click.native="$router.push({ name: 'live', params: { key: edition.meeting.key_alias } })"
          >
            <fw-icon-live class="w-6 h-6" :class="{ 'animate-pulse': edition.meeting.running }" />
            <v-clamp autoresize :max-lines="1" tag="span" class="text-lg">
              {{ edition.meeting.key_alias }}
            </v-clamp>
            <fw-icon-arrow-up class="w-6 h-6" />
          </fw-button>
          <fw-button type="link" size="xs" @click.native="toClipboardMeetingId">Copiar ID</fw-button>
        </div>
      </div>
      <div class="flex-1">
        <ButtonSidebar :active="view == 'liveclasses'" label="Aulas" @click.native="$emit('go-to-view', 'liveclasses')">
          <template #svg>
            <fw-icon-class-unit class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar :active="view == 'buckets'" label="Materiais" @click.native="$emit('go-to-view', 'buckets')">
          <template #svg>
            <fw-icon-box-open class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar :active="view == 'channels'" label="Canais" @click.native="$emit('go-to-view', 'channels')">
          <template #svg>
            <fw-icon-chats class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar :active="view == 'calendar'" label="Calendário" @click.native="$emit('go-to-view', 'calendar')">
          <template #svg>
            <fw-icon-calendar class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar
          v-if="teachers.length"
          :active="view == 'teachers'"
          label="Docentes"
          @click.native="$emit('go-to-view', 'teachers')"
        >
          <template #svg>
            <fw-icon-user class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar
          v-if="profile == 'teacher'"
          :active="view == 'students'"
          label="Estudantes"
          @click.native="$emit('go-to-view', 'students')"
        >
          <template #svg>
            <fw-icon-people class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar
          v-if="profile == 'teacher'"
          :active="view == 'settings'"
          label="Configurações"
          @click.native="$emit('go-to-view', 'settings')"
        >
          <template #svg>
            <fw-icon-list-settings class="w-6 h-6" />
          </template>
        </ButtonSidebar>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
  },

  props: {
    edition: {
      type: Object,
    },
    view: {
      type: String,
      default: 'liveclasses',
    },
    profile: {
      type: String,
      default: 'teacher',
    },
    teachers: {
      type: Array,
    },
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student'))
    },
  },

  methods: {
    toClipboardMeetingId() {
      this.$copyText(`${this.edition.meeting.key_alias}`).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `ID copiado para a área de transferência.`,
          position: 'is-top',
        })
      })
    },
  },
}
</script>

<style>
a span:last-child {
  @apply text-black;
}
</style>
