<template>
  <div class="bg-cover bg-center overflow-hidden rounded-2xl text-white" :style="{ backgroundImage: backgroundImage }">
    <div class="bg-course-linear-gredient flex flex-col px-6 pt-6 pb-3">
      <div class="flex items-center text-sm">
        <div class="hidden md:block w-24 pl-3 text-new-primary">
          <fw-icon-b-learning-line class="w-8 h-8" />
        </div>
        <div
          v-if="course.current_edition && course.current_edition != null"
          class="flex-1 text-new-primary font-semibold"
        >
          {{ signup_dates_message }}
        </div>
        <div v-else class="flex-1 text-white text-opacity-80 font-semibold">
          {{ $t('noeditions') }}
        </div>
        <ButtonNotificationsToogle @toogle="subscribe(course)" />
      </div>
      <div class="md:px-24 flex flex-col pt-8">
        <v-clamp autoresize :max-lines="2" class="text-2xl font-bold pb-4">
          {{ course.title }}
        </v-clamp>

        <div class="flex">
          <v-clamp autoresize :max-lines="3" class="text-white font-semibold md:w-3/5">
            {{ course.summary }}
          </v-clamp>
          <div class="flex-1"></div>
        </div>

        <div class="flex py-6 gap-6">
          <fw-button
            v-if="course && course.current_edition != null"
            type="primary"
            class="px-12 py-2"
            @click.native.stop="register(course)"
          >
            {{ $t('enroll') }}
          </fw-button>
          <fw-button
            :type="course && course.current_edition != null ? 'link' : 'primary'"
            class="px-6 py-2"
            @click.native="$router.push({ name: 'nondegree-course-info', params: { key: course.key } })"
          >
            {{ $t('explore') }}
          </fw-button>
        </div>
        <div class="flex gap-5 text-white items-center pb-6 text-xs">
          <div class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-mode class="h-5 hidden md:block "></fw-icon-course-mode>
            {{ course.working_mode }}
          </div>
          <div class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-ects class="h-5 hidden md:block "></fw-icon-course-ects>
            {{ course.ects }}
          </div>
          <div v-if="course.price && course.price > 0" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-price class="h-5 hidden md:block "></fw-icon-course-price>
            {{ course.price }}€
          </div>
          <div class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-time class="h-5 hidden md:block "></fw-icon-course-time>
            {{ course.duration }} {{ $t('hours') }}
          </div>
          <div
            v-if="course.current_edition && course.current_edition != null"
            class="flex gap-2.5 text-white font-semibold items-center"
          >
            <fw-icon-course-calendar class="h-5 hidden md:block "></fw-icon-course-calendar>
            {{ $t('startsat') }} {{ course.current_edition.start_date | formatDate }}
          </div>
        </div>
      </div>

      <div v-if="showFooter" class="border-t text-sm font-semibold border-white flex  items-center pt-2 justify-center">
        <div class="flex-1 hidden sm:block">
          {{ $t('knowall') }}
        </div>
        <fw-button type="link-white" class="text-white">{{ $t('exploreall') }}</fw-button>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonNotificationsToogle from '../buttons/ButtonNotificationsToogle.vue'
export default {
  name: 'BannerCourse',
  components: { ButtonNotificationsToogle },
  props: {
    course: {
      type: Object,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    backgroundImage() {
      return `url(${this.course.cover})`
    },
    signup_dates_message() {
      if (this.course.current_edition && this.course.current_edition != null) {
        var now = new Date()
        var start_date = new Date(this.course.current_edition.signup_start_date)
        var end_date = new Date(this.course.current_edition.signup_end_date)
        if (now < start_date) {
          return this.$t('registrationopen') + ' ' + this.$options.filters.formatDate(start_date)
        } else if (now > end_date) {
          return this.$t('registrationclosed')
        } else {
          return this.$t('registrationopenuntil') + ' ' + this.$options.filters.formatDate(end_date)
        }
      } else {
        return ''
      }
    },
  },
  methods: {
    subscribe(course) {
      this.$emit('subscribe', course)
    },
    register(course) {
      this.$emit('register', course)
    },
    explore(course) {
      this.$emit('explore', course)
    },
  },
}
/*
certification:null
cover:"https://ucpages.uc.pt/site/assets/files/709524/vackground-hdq-dujqn8e-unsplash.1200x0.jpeg"
created_date:"2022-10-03T12:09:04.809473"
current_edition:Object
duration:27
ects:28
enrolled_in:Array[0]
is_prr:true
key:"juqorl"
languages:Array[2]
methodology:"<p>Esta ação de formação será organizada na modalidade de curso de formação, com sessões teóricas, modo conferência, visitas patrimoniais e complementadas com aplicação prática.<br />\nA componente teórica organiza-se em conferências plenárias seguidas de debate;<br />\nA componente teórico-prática, organiza-se em trabalho de campo, com visitas e interações com recursos(documentos) formativos passíveis de utilização em contexto escolar.</p>"
pages_id:709506
price:999
public_url:"https://ucpages.uc.pt/cursos-nao-conferentes-de-grau/cursos/cncg-demonstracao/"
scientific_area:"Arquitetura e construção"
summary:"Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Nulla quis lorem ut libero malesuada feugiat."
thumb:"https://ucpages.uc.pt/site/assets/files/709524/vackground-hdq-dujqn8e-unsplash.600x0.jpeg"
title:"CNCG Demonstração"
type:"nondegree"
updated_date:"2022-10-03T12:09:04.809473"
working_mode:"Presencial"

*/
</script>
<style>
.bg-course-linear-gredient {
  background: rgb(50, 68, 87);
  background: linear-gradient(0deg, rgba(50, 68, 87, 1) 20%, rgba(0, 0, 0, 0.5) 80%);
}
.text-new-primary {
  color: #30a898;
}
.bg-new-primary {
  background-color: #30a898;
}
</style>
<i18n>
{
    "en": {
        "noeditions": "No calendarized editions of this course for now.",
        "enroll": "Enroll",
        "explore": " Explore course",
        "hours": "hours",
        "exploreall": "Explore all courses",
        "knowall": "Know all the non-degree and b-learning courses available.",
        "startsat": "Starts",
        "registrationopen": "Registration open from",
        "registrationclosed": "Registration closed",
        "registrationopenuntil": "Registration open until"
    },
    "pt": {
        "noeditions": "Ainda não existem novas edições calendarizadas.",
        "enroll": "Inscrever",
        "explore": " Explorar curso",
        "hours": "horas",
        "exploreall": "Explorar todos os cursos",
        "knowall": "Conheça todos os cursos não conferentes degrau e b-learning que temos disponíveis.",
        "startsat": "Começa a",
        "registrationopen": "Inscrições abrem a",
        "registrationclosed": "Inscrições encerradas",
        "registrationopenuntil": "Inscrições abertas até dia"
    }
}
</i18n>
