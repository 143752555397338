import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = process.env.VUE_APP_UCPAGES_API || FwEnvConfig.apiUrlPages + '/api'
    api.defaults.withCredentials = false
    api.defaults.headers.common['Authorization'] =
      localStorage.getItem('pages-token') || '8af1b8e830f1b5a892fe0b37832d412eb2a7e9a990'
    api.defaults.quietly = true
    return api
  },

  async getNewsFeatured() {
    const response = await this.base().get(`/v1-get-news`)
    return response.data
  },

  async getEventApp() {
    const response = await this.base().get('/get-event-app?key=' + localStorage.getItem('event-id'))
    return response.data
  },

  // For Signage Data on UC MotionScreen
  async getScreen() {
    const response = await this.base().get('/v2-get-screen?key=' + localStorage.getItem('screen-key'))
    return response.data
  },
}
