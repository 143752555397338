<template>
  <SidebarBase>
    <template #main>
      <div class="flex-1">
        <fw-heading size="h2" class="pl-2 pb-4">Cursos</fw-heading>
        <div
          class="text-sm
      flex-shrink-0
      rounded
      inline-flex
      gap-2
      p-2
      items-center
      font-semibold"
        >
          <span
            class="rounded-xl
        text-gray-500
        group-hover:text-primary
        opacity-50
        group-hover:opacity-90
        w-7
        min-w-max
        flex-shrink-0"
          >
            <fw-icon-b-learning-line class="w-6 h-6" />
          </span>
          <span class="text-left text-sm">Catálogo</span>
        </div>

        <div class="mb-5 pl-11 pt-1">
          <fw-label>Áreas científicas</fw-label>
          <div
            class="filter"
            :class="{ selected: selectedFilters['scientific_area'] == null }"
            @click="changeFilter('scientific_area', 'all')"
          >
            Todas
          </div>
          <div
            v-for="(filter, f) in filters.scientific_area"
            :key="'filter_' + f"
            class="filter"
            :class="{ selected: selectedFilters['scientific_area'] == filter.id }"
            @click="changeFilter('scientific_area', filter.id)"
          >
            {{ filter.text }}
            <div class="filterbadge">{{ filter.ammount }}</div>
          </div>
        </div>
        <div class="mb-5 pl-11">
          <fw-label>Modo de estudo</fw-label>
          <div
            class="filter"
            :class="{ selected: selectedFilters['working_mode'] == null }"
            @click="changeFilter('working_mode', 'all')"
          >
            Todas
          </div>
          <div
            v-for="(filter, f) in filters.working_mode"
            :key="'filter_1_' + f"
            class="filter"
            :class="{ selected: selectedFilters['working_mode'] == filter.id }"
            @click="changeFilter('working_mode', filter.id)"
          >
            {{ filter.text }}
            <div class="filterbadge">{{ filter.ammount }}</div>
          </div>
        </div>
        <div class="mb-5 pl-11">
          <fw-label>Idiomas</fw-label>
          <div
            class="filter"
            :class="{ selected: selectedFilters['languages'] == null }"
            @click="changeFilter('languages', 'all')"
          >
            Todas
          </div>
          <div
            v-for="(filter, f) in filters.languages"
            :key="'filter_2_' + f"
            class="filter"
            :class="{ selected: selectedFilters['languages'] == filter.id }"
            @click="changeFilter('languages', filter.id)"
          >
            {{ filter.text }}
            <div class="filterbadge">{{ filter.ammount }}</div>
          </div>
        </div>
        <div class="mb-5 pl-11">
          <fw-label>Financiamento</fw-label>
          <div
            class="filter"
            :class="{ selected: selectedFilters['is_prr'] == null }"
            @click="changeFilter('is_prr', 'all')"
          >
            Todas
          </div>
          <div
            class="filter"
            :class="{ selected: selectedFilters['is_prr'] == 'true' }"
            @click="changeFilter('is_prr', 'true')"
          >
            PRR
            <div class="filterbadge">{{ filters.is_prr.yes }}</div>
          </div>
          <div
            class="filter"
            :class="{ selected: selectedFilters['is_prr'] == 'false' }"
            @click="changeFilter('is_prr', 'false')"
          >
            Não PRR
            <div class="filterbadge">{{ filters.is_prr.no }}</div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <SideBlockAccount
        help-url="https://ucpages.uc.pt/ucframework/apps/ucstudent"
        @open-intro-modal="$emit('open-intro-modal')"
      />
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
//import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import SideBlockAccount from '@/fw-modules/fw-core-vue/id/components/sidebars/blocks/SideBlockAccount'

export default {
  components: {
    SideBlockAccount,
    //ButtonSidebar,
    SidebarBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    selectedFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
  },
  methods: {
    changeFilter(filter, value) {
      this.selectedFilters[filter] = value == 'all' ? null : value
      this.$emit('change-filter', this.selectedFilters)
    },
  },
}
</script>

<style scoped>
.filter {
  @apply text-sm cursor-pointer hover:text-opacity-75 pb-1 flex items-center gap-3 text-gray-800;
}
.filter.selected {
  @apply text-sm font-bold;
}
.filterbadge {
  @apply text-xs bg-gray-200 rounded-full h-5 w-5  leading-5 text-center flex-none font-semibold text-gray-800;
}
</style>
