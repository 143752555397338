<template>
  <div v-if="readOnly" class="flex items-center gap-0.5 py-1.5 px-3" :class="{ 'text-gray-500': !number }">
    <fw-icon-heart v-if="emoji == 'love'" class="h-4 w-4" />
    <fw-icon-thumbs-up v-else-if="emoji == 'thumbs_up'" class="h-4 w-4" />
    <fw-icon-thumbs-down v-else-if="emoji == 'dislike'" class="h-4 w-4" />
    <fw-icon-clap v-else-if="emoji == 'clap'" class="h-4 w-4" />
    <div
      v-if="number != null"
      class="text-center font-semibold"
      :class="{
        'opacity-70': !clicked,
        'w-4': tmpNumber < 10,
        'w-5': tmpNumber >= 10 && tmpNumber < 100,
        'w-7': tmpNumber >= 100 && tmpNumber < 1000,
        'w-10': tmpNumber >= 1000,
      }"
    >
      {{ formatedNumber }}
    </div>
  </div>
  <button
    v-else
    class="transition-all rounded-full group"
    :class="{
      'bg-gray-50 hover:bg-gray-200 text-gray-800': !clicked,
      'bg-gray-700 hover:bg-gray-600 text-white': clicked,
      'py-1.5 px-3': size == 'md',
      'py-1 px-2': size == 'sm' || size == 'xs',
      'text-sm': size == 'md',
      'text-xs': size == 'sm' || size == 'xs',
    }"
    @click="buttonClick()"
  >
    <div class="flex items-center gap-0.5">
      <fw-icon-heart
        v-if="emoji == 'love'"
        :class="{
          'h-4 w-4': size == 'md',
          'h-3 w-3': size == 'sm' || size == 'xs',
        }"
      />
      <fw-icon-thumbs-up
        v-else-if="emoji == 'thumbs_up'"
        :class="{
          'h-4 w-4': size == 'md',
          'h-3 w-3': size == 'sm' || size == 'xs',
        }"
      />
      <fw-icon-thumbs-down
        v-else-if="emoji == 'dislike'"
        :class="{
          'h-4 w-4': size == 'md',
          'h-3 w-3': size == 'sm' || size == 'xs',
        }"
      />
      <fw-icon-clap
        v-else-if="emoji == 'clap'"
        :class="{
          'h-4 w-4': size == 'md',
          'h-3 w-3': size == 'sm' || size == 'xs',
        }"
      />
      <div
        v-if="number != null"
        class="text-center font-semibold"
        :class="{
          'opacity-70': !clicked,
          'w-4': tmpNumber < 10,
          'w-5': tmpNumber >= 10 && tmpNumber < 100,
          'w-7': tmpNumber >= 100 && tmpNumber < 1000,
          'w-10': tmpNumber >= 1000,
        }"
      >
        {{ formatedNumber }}
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ButtonEmoji',
  props: {
    emoji: {
      type: String,
      required: true,
    },
    number: {
      default: null,
      type: Number,
    },
    userClicked: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  data() {
    return {
      clicked: false,
      tmpNumber: 0,
      active: false,
    }
  },
  computed: {
    formatedNumber() {
      if (this.tmpNumber > 999) {
        return '999+'
      }
      return this.tmpNumber
    },
  },
  mounted() {
    this.tmpNumber = this.number
    this.clicked = this.userClicked
  },
  methods: {
    cancel() {
      if (this.clicked) {
        this.tmpNumber -= 1
      }
      this.clicked = false
    },
    revert() {
      this.buttonClick(false)
    },
    buttonClick(animate = true) {
      if (this.readOnly) {
        return
      }
      this.active = true
      if (!this.clicked) {
        this.tmpNumber += 1
      } else {
        this.tmpNumber -= 1
      }
      this.clicked = !this.clicked
      if (animate) {
        this.$emit('reaction')
      }
    },
  },
}
</script>
