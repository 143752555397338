<template>
  <div class="relative flex flex-col gap-1 px-5 pt-3 pb-2 bg-white">
    <!-- Post Header -->
    <div class="flex flex-col gap-2">
      <div>
        <fw-heading size="h4" muted>{{
          post.collection ? post.collection.name : $t(`postType.${post.type}`)
        }}</fw-heading>
      </div>
      <div class="flex gap-3">
        <fw-icon-chat-thread v-if="type == 'discussion'" class="w-10 h-10 flex-shrink-0" />
        <fw-heading v-if="post.title" size="2xl" class="flex-1" :class="{ 'pt-1': type == 'discussion' }">{{
          post.title
        }}</fw-heading>
      </div>
      <div v-if="type != 'discussion'" class="text-gray-500">
        <fw-person :person="users[post.user_key]" size="xs" paddingless>
          <template #secondline>
            <div class="text-gray-500 font-semibold text-sm">
              {{ post.updated_date || post.created_date | humanDateTimePT }}
            </div>
          </template>
        </fw-person>
      </div>
    </div>
    <!-- Post Comments -->
    <div class="flex gap-3 justify-between items-center">
      <div class="text-gray-500 text-sm">
        {{ post.comments.total }} {{ post.comments.total == 1 ? 'comentário' : 'comentários' }}
      </div>
      <div class="flex gap-2 items-center">
        <ButtonEmoji ref="love" emoji="love" read-only :number="post.reactions.love.total" />
        <ButtonEmoji ref="applause" emoji="clap" read-only :number="post.reactions.applause.total" />
        <ButtonEmoji ref="like" emoji="thumbs_up" read-only :number="post.reactions.like.total" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonEmoji from '@/fw-modules/fw-core-vue/posts/components/buttons/ButtonEmoji'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  name: 'RecordPost',

  components: {
    ButtonEmoji,
  },

  props: {
    post: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'post',
    },
    users: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    coverImage() {
      return this.post.cover && this.post.cover.type == 'image' ? ServiceStorage.getImageViewUrl(this.post.cover) : null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "postType": {
      "post": "Publicação",
      "discussion": "Discussão",
      "comment": "Comentário"
    }
  },
  "en": {
    "postType": {
      "post": "Publicação",
      "discussion": "Discussão",
      "comment": "Comentário"
    }
  }
}
</i18n>
