<template>
  <div class="bg-white rounded-lg invite-users p-5">
    <div>
      <b-tabs v-model="activeTab" :animated="false" @input="updateFocus">
        <b-tab-item :label="$t('searchInUC')" value="search-user">
          <!-- <div v-if="!isAllowedEmail" class="notification has-text-centered is-rounded">
            {{ $t('useInstitutionalAccount') }}
          </div> -->
          <div
            class="flex flex-col w-full items-center bg-gray-200 bg-opacity-50 mb-5 outline-none focus:ring-2 ring-primary ring-opacity-30 rounded-xl"
          >
            <div class="flex w-full items-center px-3 py-1.5">
              <div class="w-6 ml-1">
                <fw-icon-loading v-if="searching" class="w-6 h-6" />
                <fw-icon-search v-else class="w-6 h-6 opacity-50" />
              </div>
              <slot name="prefix"></slot>
              <input
                ref="searchIdUser"
                v-model="searchQueryId"
                class="outline-none font-semibold flex-1 px-1 py-0.5 bg-transparent ml-1"
                type="text"
                :placeholder="$t('search')"
                @keypress.enter="searchIdUsers"
              />
              <fw-button type="transparent-primary" class="ml-3" @click.native="searchIdUsers">{{
                $t('searchText')
              }}</fw-button>
            </div>
          </div>
          <div style="height: 275px;" class="overflow-auto">
            <LoadingPlaceholder v-if="searching" class="px-2 py-2"></LoadingPlaceholder>
            <fw-panel
              v-if="!searching && searchedPeople.length"
              :title="$t('results')"
              :counter="searchedPeople.length"
            >
              <RecycleScroller
                v-slot="{ item, index }"
                :items="searchedPeople"
                :item-size="55"
                :buffer="200"
                :key-field="idKey"
                class="h-full"
              >
                <Person
                  :key="index"
                  :no-style="false"
                  :person="item"
                  :selectable="!item.is_apply_member"
                  :clickable="!item.is_apply_member"
                  :checked="inviteSelected.includes(item)"
                  :custom-class="item.is_apply_member ? 'pl-6' : ''"
                  @selected="invitePerson(item, ...arguments)"
                  @clicked="invitePerson(item, ...arguments)"
                >
                  <template
                    v-if="showPersonAttributes.length > 0 || item.active === false || item.is_apply_member"
                    #options
                  >
                    <div
                      v-if="item.is_apply_member"
                      class="text-white text-xs px-2 py-1 rounded-full bg-primary font-semibold"
                    >
                      {{ $t('applyMember') }}
                    </div>
                    <div v-if="item.active === false" class="inline-flex gap-2 text-gray-500 text-xs items-center">
                      {{ $t('pendingInvite') }}
                    </div>
                    <div
                      v-for="(attr, a) in showPersonAttributes"
                      :key="'attr_' + a"
                      :class="{ 'text-opacity-60': item.rejected }"
                    >
                      {{ item[attr] ? item[attr] : '--' }}
                    </div>
                  </template>
                </Person>
              </RecycleScroller>
              <fw-tip>{{ $t('limitedNumResults') }}.<br />{{ $t('defineKeywords') }}.</fw-tip>
            </fw-panel>
            <fw-panel-info v-else-if="!searching" empty clean class="h-full items-center justify-center flex">
              {{ $t('noDataFound') }}.
            </fw-panel-info>
          </div>
          <div v-if="inviteSelected.length && withRoles" class="mt-5">
            <fw-label>{{ $t('permissionsToAdd') }}</fw-label>
            <div v-if="selectedRoles.length < 1" class="text-sm my-1" style="color: #F15F1FFF">
              <div class="error">{{ $t('chooseAtLeastOne') }}.</div>
            </div>
            <div class="flex gap-1 items-center">
              <b-checkbox
                v-for="(role, key) in roles"
                :key="key"
                v-model="selectedRoles"
                :native-value="key"
                class="pt-2"
                >{{ role }}</b-checkbox
              >
            </div>
          </div>
          <fw-tip v-if="!inviteSelected.length && hasErrors" error>
            <span v-if="searchedPeople.every(el => el.is_apply_member)">{{ $t('allMembersApply') }}</span>
            <span v-else> {{ $t('selectAtLeastOne') }}</span>
          </fw-tip>
        </b-tab-item>
        <b-tab-item :label="$t('inviteByEmail')" value="invite-email">
          <div class="text-lg font-bold mb-2">{{ $t('inviteUser') }}</div>

          <div class="py-1">
            <fw-label>{{ $t('name') }}</fw-label>
            <input ref="inviteName" v-model.trim="inviteData.name" type="text" class="input w-full mb-2" />
            <fw-tip v-if="$v.inviteData.name.$error" error>
              <div v-if="!$v.inviteData.name.required" class="error">{{ $t('nameRequired') }}.</div>
            </fw-tip>

            <fw-label>Email</fw-label>
            <input v-model.trim="inviteData.email" type="text" class="input w-full" />
            <fw-tip v-if="$v.inviteData.email.$error" error>
              <div v-if="!$v.inviteData.email.required" class="error">{{ $t('emailRequired') }}.</div>
              <div v-if="!$v.inviteData.email.email" class="error">{{ $t('emailInvalid') }}.</div>
              <div v-if="!isAllowedEmail && inviteData.email" class="error">{{ $t('useInstitutionalAccount') }}.</div>
            </fw-tip>

            <div class="mt-4">
              <fw-label>{{ $t('permissions') }}</fw-label>
              <div v-if="withRoles" class="flex gap-1 mb-4 items-center">
                <b-checkbox
                  v-for="(role, key) in roles"
                  :key="key"
                  v-model="selectedRoles"
                  :native-value="key"
                  class="pt-2"
                  >{{ role }}</b-checkbox
                >
              </div>

              <fw-tip v-if="$v.selectedRoles.$error" error>
                <div class="error">{{ $t('chooseAtLeastOne') }}.</div>
              </fw-tip>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <div class="flex-1 flex justify-end items-center gap-4">
      <fw-button type="light" @click.native="$emit('close')">{{ $t('cancel') }}</fw-button>
      <fw-button :type="'primary'" @click.native="saveInvite()">{{ $t('invite') }}</fw-button>
    </div>
  </div>
</template>

<script>
import ServiceApply from '@/fw-modules/fw-core-vue/apply/services/ServiceApply'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'

import { RecycleScroller } from 'vue-virtual-scroller'
import { required, email, minLength } from 'vuelidate/lib/validators'
import utilsId from '@/fw-modules/fw-core-vue/id/utils'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  name: 'InviteUsersModal',
  components: {
    Person,
    RecycleScroller,
    LoadingPlaceholder,
  },
  props: {
    idKey: {
      type: String,
      default: 'key',
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    showPersonAttributes: {
      type: Array,
      default: function() {
        return []
      },
    },
    withRoles: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Object,
      default: function() {
        return {
          jury: this.$t('roles.jury'),
          manager: this.$t('roles.manager'),
          'procedure-manager': this.$t('roles.procedure-manager'),
        }
      },
    },
  },

  data() {
    return {
      hasErrors: false,
      searchQueryId: '',
      inviteSelected: [],
      searchedPeople: [],
      selectedRoles: ['jury'],
      inviteData: {
        email: '',
        name: '',
      },
      activeTab: 'search-user',
      debounceTimer: null,
      searching: false,
      totalPages: 1,
      totalLimit: 20,
      page: 1,
    }
  },

  validations: {
    inviteData: {
      email: { required, email, min: minLength(5) },
      name: { required, min: minLength(1) },
    },
    selectedRoles: { required, min: minLength(1) },
  },

  computed: {
    canInvite() {
      return (
        ((this.activeTab === 'search-user' && this.inviteSelected.length) ||
          (this.activeTab === 'invite-email' && !this.$v.$invalid && this.isAllowedEmail)) &&
        this.selectedRoles.length
      )
    },
    allMembersApply() {
      return this.searchedPeople.every(el => el.is_apply_member)
    },
    allowExternalAccountsRegister() {
      return FwEnvConfig.allowExternalAccountsRegister
    },
    isAllowedEmail() {
      return this.allowExternalAccountsRegister || (!this.allowExternalAccountsRegister && !this.isInstitutionalEmail)
    },
    isInstitutionalEmail() {
      if (this.inviteData.email) return utilsId.isInstitutionalEmail(this.inviteData.email)
      return false
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.activeTab == 'invite-email' ? this.$refs.inviteName.focus() : this.$refs.searchIdUser.focus()
    })
  },

  methods: {
    updateFocus(tab) {
      console.log('tab :>> ', tab)
      this.$nextTick(() => {
        this.activeTab == 'invite-email' ? this.$refs.inviteName.focus() : this.$refs.searchIdUser.focus()
      })
    },

    debounce(func, timeout = 300) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer)
      }

      this.debounceTimer = setTimeout(() => {
        func.apply()
      }, timeout)
    },

    async searchIdUsers() {
      this.$v.$reset
      this.hasErrors = false
      if (this.searchQueryId.length > 0) {
        try {
          this.searching = true
          const resultUsers = await ServiceApply.searchIdUsers({ q: this.searchQueryId, limit: this.totalLimit })
          console.log('Search result ==> ', resultUsers)

          this.totalPages = resultUsers.total_pages
          this.searchedPeople = resultUsers.data

          console.log('this.inviteSelected', this.inviteSelected)
          this.searchedPeople = this.searchedPeople.concat(this.inviteSelected)

          console.log('this.searchedPeople', this.searchedPeople)
          this.searching = false
        } catch (error) {
          console.error('Search went wrong', error)
        }
      }
    },

    invitePerson(person, selected) {
      console.log('INVITE THIS ONE', person, selected)
      let index = this.inviteSelected.findIndex(i => i == person.key) // === person.key
      if (selected && index === -1) {
        this.inviteSelected.push(person)
      } else {
        this.inviteSelected.splice(index, 1)
      }
    },

    saveInvite() {
      this.$v.$touch()
      if (this.activeTab === 'search-user' && !this.inviteSelected.length) this.hasErrors = true
      if (!this.canInvite) return

      this.$v.$reset
      if (this.activeTab == 'invite-email') {
        this.$emit('save-invite', [this.inviteData], this.selectedRoles, 'invite')
      } else {
        const toInvite = this.inviteSelected.map(el => {
          return { user_key: el.key, roles: this.selectedRoles }
        })
        this.$emit('save-invite', toInvite, null, 'add')
      }
      this.hasErrors = false
      this.searchedPeople = []
      this.inviteSelected = []
      this.searchQueryId = ''
      this.inviteData = {
        email: '',
        name: '',
      }
    },
  },
}
</script>

<style>
.invite-users.keyboard_input .textarea:focus,
.invite-users.keyboard_input .input:focus,
.invite-users.keyboard_input .taginput .taginput-container.is-focusable:focus,
.invite-users.keyboard_input .datepicker .dropdown .input:focus,
.invite-users.keyboard_input .datepicker .dropdown-trigger .input[readonly]:focus,
.invite-users.keyboard_input button:focus,
.invite-users.keyboard_input select:focus,
.invite-users.keyboard_input button:focus {
  outline: solid 2px rgba(3, 164, 121, 0.7) !important;
  outline-offset: 2px !important;
}
</style>

<i18n>
{
  "pt": {
    "noDataFound": "Sem resultados",
    "searchInUC": "Pesquisar na UC",
    "useInstitutionalAccount": "Por favor use a conta institucional do utilizador",
    "search": "Pesquisar...",
    "searchText": "Pesquisar",
    "results": "Resultados",
    "applyMember": "Membro Apply",
    "pendingInvite": "Convite pendente",
    "limitedNumResults": "Esta pesquisa apresenta um número limitado de resultados",
    "defineKeywords": "Defina melhor as palavras chaves para encontrar o registo que procura",
    "permissionsToAdd": "Permissões para adicionar",
    "chooseAtLeastOne": "Escolha pelo menos uma permissão",
    "inviteUser": "Convidar utilizador",
    "name": "Nome",
    "nameRequired": "Insira o nome do utilizador",
    "emailRequired": "Insira o email do utilizador",
    "emailInvalid": "Email não é válido",
    "permissions": "Permissões",
    "cancel": "Cancelar",
    "add": "Adicionar",
    "invite": "Convidar",
    "inviteByEmail": "Convidar por email",
    "selectAtLeastOne": "Escolha pelo menos um utilizador",
    "allMembersApply": "Todos as pessoas listadas já são da Apply pelo que não é necessario convidar",
    "roles": {
      "admin": "Administrador",
      "manager": "Gestor",
      "jury": "Júri",
      "procedure-manager": "Secretariado"
    }
  },
  "en": {
    "noDataFound": "No data found",
    "searchInUC": "Search in UC",
    "useInstitutionalAccount": "Please use the user's institutional account",
    "search": "Search...",
    "searchText": "Search",
    "results": "Results",
    "applyMember": "Apply Member",
    "pendingInvite": "Pending invite",
    "limitedNumResults": "This search presents a limited number of results",
    "defineKeywords": "Better define your keywords to find the record you are looking for",
    "permissionsToAdd": "Permissions to add",
    "chooseAtLeastOne": "Choose at least one permission",
    "inviteUser": "Invite user",
    "name": "Name",
    "nameRequired": "Enter the user name",
    "emailRequired": "Enter the user's email",
    "emailInvalid": "Email is not valid",
    "permissions": "Permissions",
    "cancel": "Cancel",
    "add": "Add",
    "invite": "Invite",
    "inviteByEmail": "Invite by email",
    "selectAtLeastOne": "Choose at least one user",
    "allMembersApply": "All the people listed are already from Apply so there is no need to invite",
    "roles": {
      "admin": "Administrator",
      "manager": "Manager",
      "jury": "Jury",
      "procedure-manager": "Secretariat"
    }
  }
}
</i18n>
