<template>
  <TapbarBase>
    <template #buttons>
      <ButtonTapbar :active="view == 'liveclasses'" label="Aulas" @click.native="$emit('go-to-view', 'liveclasses')">
        <template #svg>
          <fw-icon-class-unit class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar :active="view == 'buckets'" label="Materiais" @click.native="$emit('go-to-view', 'buckets')">
        <template #svg>
          <fw-icon-box-open class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar :active="view == 'channels'" label="Canais" @click.native="$emit('go-to-view', 'channels')">
        <template #svg>
          <fw-icon-chats class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar :active="view == 'calendar'" label="Calendário" @click.native="$emit('go-to-view', 'calendar')">
        <template #svg>
          <fw-icon-calendar class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar
        v-if="isTeacher"
        :active="view == 'teachers'"
        label="Docentes"
        @click.native="$emit('go-to-view', 'teachers')"
      >
        <template #svg>
          <fw-icon-user class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar
        v-if="isTeacher"
        :active="view == 'students'"
        label="Estudantes"
        @click.native="$emit('go-to-view', 'students')"
      >
        <template #svg>
          <fw-icon-people class="w-7 h-7" />
        </template>
      </ButtonTapbar>
    </template>
  </TapbarBase>
</template>

<script>
import TapbarBase from '@/fw-modules/fw-core-vue/ui/components/tapbars/TapbarBase'
import ButtonTapbar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonTapbar'

export default {
  components: {
    ButtonTapbar,
    TapbarBase,
  },

  props: {
    view: String,
    isTeacher: Boolean,
  },
}
</script>
