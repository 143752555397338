<template>
  <div
    class="flex-col gap-9 bg-white rounded-md flex-1"
    :class="{
      'px-2 py-4': !paddingless,
    }"
  >
    <div v-if="loading" class="flex flex-col gap-2.5">
      <div class="h-6 w-2/3 mx-2.5 bg-gray-100 rounded-md animate-pulse"></div>
      <div class="h-6 w-2/4 mx-2.5 bg-gray-100 rounded-md animate-pulse"></div>
      <div class="h-6 w-2/5 mx-2.5 bg-gray-100 rounded-md animate-pulse"></div>
      <div class="h-6 w-2/3 mx-2.5 bg-gray-100 rounded-md animate-pulse"></div>
      <div class="h-6 w-1/4 mx-2.5 bg-gray-100 rounded-md animate-pulse"></div>
    </div>
    <div v-else-if="blocks.length > 0">
      <div
        v-for="(input, i) in blocks"
        :key="'input_original_' + input.key + '_' + (originalBlocksHeight[i] ? originalBlocksHeight[i] : 'auto')"
        class="relative content-block-original"
        :style="{ minHeight: originalBlocksHeight[i] ? originalBlocksHeight[i] + 'px' : 'auto' }"
      >
        <ContentTextBlock
          v-if="input.type == 'text'"
          :ref="input.key"
          class="w-full"
          :content="input.content?.text ?? ''"
          :editable="false"
        ></ContentTextBlock>
        <div v-else-if="input.type == 'image'" class="w-full mb-2">
          <div
            v-if="input.files != null && input.files.length > 0"
            class="overflow-hidden rounded-2xl mb-2.5 bg-gray-200 image_direct"
          >
            <img :src="getThumbnail(input.files[0])" class="w-full" />
          </div>

          <input
            v-if="input.files != null && input.files.length > 0 && input.files[0].description != null"
            v-model="input.files[0].description"
            disabled
            class="text-sm outline-none w-full text-gray-400 italic bg-transparent mt-1 mx-2"
            :placeholder="$t('image_description')"
          />
        </div>
        <div v-else-if="input.type == 'video'" class="w-full mb-2">
          <div
            v-if="input.files != null && input.files.length > 0"
            class="overflow-hidden rounded-2xl mb-2.5 bg-gray-200 image_direct"
          >
            <video controls class="w-full">
              <source :src="getFileUrl(input.files[0])" type="video/mp4" />
            </video>
          </div>

          <input
            v-if="input.files != null && input.files.length > 0 && input.files[0].description != null"
            v-model="input.files[0].description"
            class="text-sm outline-none w-full text-gray-400 italic bg-transparent mt-1 mx-2"
            :placeholder="$t('video_description')"
          />
        </div>
        <div v-if="input.type == 'file'" class="mb-2 relative">
          <div v-if="input.files != null && input.files.length > 0">
            <RecordFileEntry
              v-for="file in input.files"
              :key="file.id"
              :can-edit="false"
              :file="file"
              :can-download="file.token != null"
              :can-remove="false"
              @download="downloadFile(file)"
            />
          </div>
        </div>
        <input
          v-if="input.type === 'heading'"
          v-model="input.content.text"
          class="text-sm outline-none w-full placeholder:text-gray-400 font-bold bg-transparent mt-1 mx-2"
          :class="{
            'text-2xl': input.content.style == 'h1',
            'text-xl': input.content.style == 'h2',
            'text-lg': input.content.size == 'h3',
          }"
          :placeholder="$t('subtitle')"
          disabled
          @focus="$emit('set-active-block', i)"
        />
      </div>
    </div>
    <div v-else class="text-center text-gray-500 text-sm py-5">Sem conteúdo.</div>
  </div>
</template>
<script>
import ContentTextBlock from '@/fw-modules/fw-core-vue/pages/components/blocks/ContentTextBlock'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'PanelPagePreview',
  components: {
    ContentTextBlock,
    RecordFileEntry,
  },
  props: {
    pageId: {
      type: String,
    },
    versionId: {
      type: String,
    },
    content: {
      type: Object,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      originalBlocksHeight: [],
      blocks: [],
      currentVersionKey: null,
      page: null,
      users: {},
    }
  },
  created() {
    if (this.content && this.content.blocks.length > 0) {
      this.blocks = this.content.blocks
    } else {
      //load page
      if (this.versionId && this.pageId) {
        this.loadPageVersion()
        this.currentVersionKey = this.versionId
      } else if (this.pageId) {
        this.loadPageContent()
      }
    }
  },
  methods: {
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file, null)
    },
    calculateMinBlockHeight() {},
    getThumbnail(file) {
      if (file) {
        return file.thumb_url_format
          .replace('{KEY}', file.key)
          .replace('{TOKEN}', file.token)
          .replace('{SIZE}', 'max2k')
          .replace('{FILENAME}', file.thumb_filename || file.filename)
      } else {
        return ''
      }
    },
    async loadPageContent() {
      try {
        if (this.page == null) {
          let pageVersions = await ServicePages.getPageVersions(this.pageId)
          this.page = pageVersions.page
          this.users = pageVersions.users
          let versionIndex = this.page.versions.findIndex(element => element.key == this.versionId)

          if (versionIndex == -1) {
            versionIndex = 0
          }

          this.currentVersionKey = this.page.versions[versionIndex].key
          this.loadPageVersion()
        }
      } catch (e) {
        console.error(e)
      }
    },
    loadPageVersion() {
      this.loading = true
      ServicePages.getPageVersionContent(this.pageId, this.currentVersionKey)
        .then(result => {
          console.log('loadPageVersion', result)
          if (result.blocks != null) {
            this.blocks = result.blocks
          }
          delete result.blocks
          this.page = result
          this.$nextTick(() => {
            this.calculateMinBlockHeight()
          })
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
