<template>
  <SidebarBase v-if="course" class="sticky top-0">
    <template #main>
      <div class="mx-2 mb-3">
        <div v-if="classroom || isManager">
          <fw-label size="sm" class="flex gap-1 items-center" marginless>
            Turma
          </fw-label>
          <div class="font-semibold">
            {{ classroom.name }}
          </div>

          <div v-if="classes.length > 1" class="mb-2">
            <b-dropdown aria-role="list" :scrollable="true" :max-height="200" class="w-72">
              <fw-button-dropdown slot="trigger" aria-role="listitem" type="link" size="xs" :centered="false" expanded>
                <template #default>
                  Escolher outra
                </template>
              </fw-button-dropdown>
              <b-dropdown-item
                v-for="(classItem, classItemKey) in classes"
                :key="classItemKey"
                aria-role="listitem"
                has-link
                :focusable="false"
                class="font-medium text-sm cursor-pointer px-5 py-1.5 border-b"
                @click.native="setActiveClass(classItem)"
              >
                {{ classItem.title }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div
            v-if="
              (classroom.meeting && classroom.meeting.key_alias) || (classroom.meeting && classroom.meeting.key_alias)
            "
            class="flex gap-2 mt-2"
          >
            <b-tooltip
              v-if="classroom.meeting && classroom.meeting.key_alias"
              label="Sala Virtual"
              position="is-bottom"
              type="is-light"
            >
              <fw-button
                type="light-primary"
                label="Sala virtual"
                @click.native="$router.push({ name: 'live', params: { key: classroom.meeting.key_alias } })"
              >
                <fw-icon-live class="w-5 h-5" />
                <fw-dot v-if="classroom.meeting.running" />
              </fw-button>
            </b-tooltip>
            <b-tooltip
              v-if="classroom.chat && classroom.chat.key"
              label="Canal de conversação"
              position="is-bottom"
              type="is-light"
            >
              <fw-button
                type="light-primary"
                label="Canal de conversação"
                @click.native="$router.push({ name: 'chats', hash: '#' + classroom.chat.key })"
              >
                <fw-icon-chats class="w-5 h-5" />
              </fw-button>
            </b-tooltip>
          </div>
        </div>
      </div>

      <div v-if="checks.isCourseEditionUnlocked && !isExceptionCourse" class="mx-2 mt-2 mb-4">
        <fw-label size="sm">O seu progresso</fw-label>
        <ProgressIcon
          :progress="progress"
          show-percentage
          percentage-pos="right"
          :color="
            !activeUnitKey || progress == 100
              ? 'bg-gradient-to-r from-teal-300 to-teal-400'
              : 'bg-gray-500 bg-opacity-80'
          "
        />
      </div>

      <ButtonSidebar
        :active="!view || view == 'dashboard'"
        label="Dashboard"
        @click.native="$emit('go-to-view', 'dashboard')"
      >
        <template #svg>
          <fw-icon-dashboard class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ButtonSidebar
        :active="checks.isCourseEditionUnlocked && (view == 'units' || view == 'unit')"
        :disabled="!checks.isCourseEditionUnlocked"
        label="Módulos"
        @click.native="$emit('go-to-view', 'units')"
      >
        <template #svg>
          <fw-icon-book-mark-solid class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ul
        v-if="checks.isCourseEditionUnlocked && editionUnits && activeUnitKey && (view == 'units' || view == 'unit')"
        class="ml-1.5"
      >
        <li v-if="false" class="mt-4 mb-3">
          <fw-label>Módulos ({{ editionUnits.length }})</fw-label>
        </li>
        <li v-for="(unit, index) in editionUnits" :key="unit.key + '_' + edition.key" class="my-2">
          <button
            class="w-full font-medium transition-colors duration-150 "
            :class="{
              'text-primary hover:text-primary':
                activeUnitKey && activeUnitKey == unit.key && checks.isCourseEditionUnlocked,
              'text-gray-500 hover:text-primary':
                (!activeUnitKey || activeUnitKey != unit.key) && checks.isCourseEditionUnlocked,
              'text-gray-400 cursor-not-allowed': !checks.isCourseEditionUnlocked || !Object.keys(unit.topics).length,
              'mb-2': activeUnitKey && activeUnitKey == unit.key,
            }"
            :disabled="
              !checks.isCourseEditionUnlocked ||
                (activeUnitKey && activeUnitKey == unit.key) ||
                !Object.keys(unit.topics).length
            "
            @click="$emit('go-to-unit', unit.key)"
          >
            <div class="flex">
              <div class="ml-2 mr-2.5 opacity-50">
                <fw-icon-list-view class="w-5 h-5" />
              </div>
              <span class="hidden font-bold w-10 text-sm mr-1">M{{ index + 1 }}</span>
              <div class="flex flex-1 flex-col">
                <v-clamp class="w-full text-sm text-left" autoresize :max-lines="2">
                  {{ unit.title }}
                </v-clamp>
                <ProgressIcon
                  v-if="
                    activeUnitKey && activeUnitKey == unit.key && checks.isCourseEditionUnlocked && !isExceptionCourse
                  "
                  :progress="unit.progress"
                  show-percentage
                  percentage-pos="right"
                  class="mt-1"
                  color="bg-primary bg-opacity-80"
                />
              </div>
            </div>
          </button>
        </li>
      </ul>

      <ButtonSidebar
        v-if="checks.isManager"
        :active="view == 'people'"
        label="Pessoas"
        @click.native="$emit('go-to-view', 'people')"
      >
        <template #svg>
          <fw-icon-people class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ButtonSidebar
        v-if="checks.isManager && !isExceptionCourse"
        :active="view == 'results'"
        label="Resultados"
        @click.native="$emit('go-to-view', 'results')"
      >
        <template #svg>
          <fw-icon-survey class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ButtonSidebar
        v-if="checks.isManager && !isExceptionCourse"
        label="Agendamentos"
        @click.native="$emit('go-to-view', 'calendar')"
      >
        <template #svg>
          <fw-icon-calendar class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ButtonSidebar
        v-if="false && checks.isStudent && !isExceptionCourse"
        :active="view == 'enrollment'"
        label="Inscrição"
        @click.native="$emit('go-to-view', 'enrollment')"
      >
        <template #svg>
          <fw-icon-info class="w-6 h-6" />
        </template>
      </ButtonSidebar>

      <ButtonSidebar
        v-if="checks.isCourseEditionUnlocked"
        :active="view == 'about'"
        label="Sobre o curso"
        @click.native="$emit('go-to-view', 'about')"
      >
        <template #svg>
          <fw-icon-article class="w-6 h-6" />
        </template>
      </ButtonSidebar>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
    ProgressIcon,
  },

  props: {
    // now: Object,
    course: Object,
    units: Array,
    progress: Number,
    edition: Object,
    checks: Object,
    classes: Array,
    activeUnitKey: String,
    activeClassKey: String,
    classroom: Object,
    view: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      openGoToSessionsManagerModal: false,
    }
  },

  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    now() {
      return this.$store.state.now
    },
    user() {
      return this.$store.getters.getUser
    },
    editionUnits() {
      if (this.units == null || typeof this.units == 'undefined') return []
      let units = this.units
      // units.sort((a, b) => a.index - b.index)
      return units
    },
    editions() {
      if (this.course.editions == null) return []
      return Object.values(this.course.editions).map(value => {
        return value
      })
    },
    // TODO: Do not forget to remove this, Valentim
    isExceptionCourse() {
      return (process.env.VUE_APP_ENV == 'development' && this.course.key === 'oowvtc') || this.course.key === '4slkfk'
      // return this.course.key === 'oowvtc'
    },
  },

  methods: {
    setActiveClass(activeClass) {
      this.$emit('set-active-class', activeClass.key)
    },
    setActiveEdition(activeEdition) {
      this.$emit('set-active-edition', activeEdition.key)
    },
  },
}
</script>
