var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cursor-pointer flex px-4 gap-3 items-center text-lg transition-all",class:{
    'pb-7 pt-4 opacity-90 hover:opacity-100': _vm.type == 'comment',
    'pb-3 pt-3 opacity-70 hover:opacity-100': _vm.type != 'comment',
  },on:{"click":function($event){return _vm.$emit('create')}}},[(_vm.type == 'comment')?_c('div',[_c('Avatar',{attrs:{"user":_vm.user,"size":"sm"}})],1):_vm._e(),_c('div',{staticClass:"flex-1 pl-0.5",class:{
      'text-gray-500': _vm.type != 'comment',
      'text-gray-600': _vm.type == 'comment',
    }},[_vm._v(" "+_vm._s(_vm.type == 'comment' ? 'Nova resposta' : 'Criar nova publicação')+" ")]),_c('div',{staticClass:"bg-primary text-white text-sm py-2 px-4 font-bold rounded-full opacity-40"},[_vm._v(" "+_vm._s(_vm.type == 'comment' ? 'Responder' : 'Publicar')+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }